/**
 * Created by marks on 01.01.2017.
 */

import {isNewSession} from "../utils/errorTools";
import i18next from "i18next";

let telemetry;
if (process.env.NODE_ENV !== "access" && process.env.NODE_ENV !== "test")
    telemetry = require("../utils/Telemetry");

export const ERROR_RAISED = 'ERROR_RAISED';
export const ERROR_DISMISSED = 'ERROR_DISMISSED';
export const ERROR_NEW_SESSION = 'ERROR_NEW_SESSION';

export function errorRaised(error) {

    if (isNewSession(error)) {
        return {
            type: ERROR_RAISED,
            error: i18next.t("error.sessionEnd"),
            isNewSession: true
        };
    }

    telemetry.trackError(error);

    return {type: ERROR_RAISED, error: error, isNewSession: false};
}

export function errorDissmissed() {
    return (dispatch, getState) => {
        const newSession = getState().errorStatus.newSession;
        if (newSession)
        {
            window.location.reload();
        }

        dispatch({type: ERROR_DISMISSED});
    }
}
