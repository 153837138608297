import React, {Component} from "react";
import ClassPanel from "./ClassPanel";
import ClassExPanel from "./ClassEx/ClassExPanel";
import PropTypes from 'prop-types';
import {
  CLASS_TYPE_TREE,
  syncSelectedItems,
  unmountClassManager,
  singleItemSelected, updateControlText, reInitSelectSetFields
} from "@mnjsplatform/data/lib/actions/classActions";
import {connect} from "react-redux";
import {errorRaised} from "@mnjsplatform/data/lib/actions/errorActions";
import Spinner from "../Spinner";
import debounce from "lodash/debounce";
import MnComboBase from "../Dropdown/MnComboBase";
import i18next from "i18next";
import formatData from "@mnjsplatform/data/lib/utils/formatData";
import textUtils from "@mnjsplatform/data/lib/utils/textUtils";
import MultiSelectWrapper from "../MultiSelect/MultiSelectWrapper";
import MultiSelectRows from "../MultiSelect/MultiSelectRows";
import ClassMultiSelectDropDown from "./ClassMultiSelectDropDown";

class ClassSelectorBase extends Component {
  constructor(props, context) {
    super(props, context);

    if (props.allowInlineSearch) {
      this.searchCallback = debounce(this.searchValue, 500);

      this.allowMultiSelect = false;
      this.classExMode = false;
      this.autoOpenPanelMode = false;

      //Тут что-то непонятное. Multiselect будет включен только в том случае, когда он стоит сразу после имени классификатора
      //Формат описания параметров классификатора Имя_классификатора[пробел]Параметры через запятую.
      const classAndParams = props.fieldInfo.EditorObject.split(',');
      if (classAndParams.length > 0) {
        const classOptions = classAndParams[0].split(' ');
        this.allowMultiSelect = classOptions.some(o => o === "MultiSelect=1");
      }

      //Поэтому делаем новый парсинг. Сначала разбиваем через пробел, а потом правую часть через запятую.
      let classPars = props.fieldInfo.EditorObject.split(' ');

      if(classPars.length > 1)
      {
        classPars = classPars[1].split(',');
        this.classExMode = classPars.some(o => o === "ClassExMode=1");
        this.autoOpenPanelMode = classPars.some(o => o === "OnlyPanel=1");//this.classExMode;
      }
    }

    if (this.props.getOpenHandler) {
      this.props.getOpenHandler(this.onOpenClass);
    }

    this.openClassPanelButtonRef = React.createRef();

    this.state = {
      classPanelVisible: false,
      inputValue: props.value,
      classSearchInitialValue: ""
    }
  }

  componentWillUnmount() {
    this.props.dispatch(unmountClassManager(this.props.classKey));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== prevProps.value && !this.allowMultiSelect) {
      this.setState({inputValue: this.props.value});
    }
  }

  componentDidMount() {
    if(this.autoOpenPanelMode)
      this.onOpenClass();
  }

  get symbolsCountToSearch() {
    if (!this._symbolsCountToSearch) {
      if (this.props.fieldInfo
        && this.props.fieldInfo.EditorForm
        && this.props.fieldInfo.EditorForm.FormOptions
        && !isNaN(this.props.fieldInfo.EditorForm.FormOptions.MinCharsDDSearch)
      ) {
        this._symbolsCountToSearch = this.props.fieldInfo.EditorForm.FormOptions.MinCharsDDSearch;
      }
      else {
        this._symbolsCountToSearch = 1;
      }
    }

    return this._symbolsCountToSearch;
  }

  get dropDownSearchItem() {
    if (!this.props.classState || !this.props.classState.classDescription) {
      return null;
    }
    if (this.props.classState.classDescription.ClassSelectSource === CLASS_TYPE_TREE) {
      return this.props.dropDownSearchInTreeItem;
    }
    return this.props.dropDownSearchInListItem;
  }

  searchValue = async(value) => {
    if (this.dropDownOptions) {
      if (value.trim().length >= this.symbolsCountToSearch) {
        try {
          await this.initClassManagerInternal();

          if (this.dropDownSearchItem) {
            await this.props.dispatch(this.dropDownSearchItem.execute({
              FindVal: value.trim()
            }, {withParams: false}));
            this.dropDownOptions.showDropDown();
          }
        }
        catch (e) {
          console.log(e);
        }
      }
    }
  };

  syncValueWithProps = () => {
    this.setState((state, props) => ({inputValue: props.value}));
  };

  hideClassPanel = () => {
    this.setState({
      classPanelVisible: false,
      classSearchInitialValue: ""
    });
  };

  onOpenClass = async(event, multiSelectProps, searchValue) => {
    if(event)
      event.preventDefault();

    if (this.dropDownOptions) {
      this.dropDownOptions.hideDropDown();
    }

    try {
      if (!!multiSelectProps) {
        await multiSelectProps.init();
      }
      else {
        await this.initClassManagerInternal();
      }
      await this.syncSelectedItemsInternal();

      const newState = {classPanelVisible: true};
      if (!!searchValue) {
        newState.classSearchInitialValue = searchValue;
      }
      this.setState(newState);
    }
    catch (error) {
      this.hideClassPanel();
      await this.props.dispatch(errorRaised(error));
    }
  };

  onCloseClass = (dismissOnly = false) => {
    if (dismissOnly) {
      this.hideClassPanel();
      return;
    }

    this.onValueChanged();
  };

  onValueChanged = (needHideClassPanel = true) => {
    const p = !this.allowMultiSelect
      ? this.props.saveValueToItem(this.props.classKey)
        .then( data => {
          this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, data.changedFields))
        })
      : (needHideClassPanel ? this.updateLabel() : Promise.resolve());

    return p
      .catch(error => this.props.dispatch(errorRaised(error)))
      .then(() => {
        if (needHideClassPanel) {
          this.hideClassPanel();
        }

        if(this.props.onSelected)
          this.props.onSelected();
      });
  };

  onClearClass = () => {
    return this.initClassManagerInternal()
      .then(() => this.props.clearSelection(this.props.classKey))
      .then( data => this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, data.changedFields)))
      .then(() => this.syncSelectedItemsInternal())
      .catch(error => this.props.dispatch(errorRaised(error)))
      .then(() => {
        this.hideClassPanel();

        if(this.props.onSelected)
          this.props.onSelected();
      });
  };

  initClassManagerInternal = async() => {
    let data = null;
    if (!this.props.classState) {
      data = await this.props.dispatch(this.props.initClassManager(this.props.fieldInfo, this.props.classKey,
        this.props.parentDataItem.controllerName,
        this.props.parentDataItem.methodName,
        this.props.isParamset, this.props.rowIndex, this.classExMode));
      if (data.changedFields) {
        this.selectSetFields = Object.keys(data.changedFields);
      }
    }
    else if (this.selectSetFields && this.selectSetFields.some(f => this.props.parentDataItem.getField(this.props.rowIndex, f) === null)) {
      data = await reInitSelectSetFields(this.props.classKey);
    }

    if (data && data.changedFields) {
      await this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, data.changedFields));
    }
  }

  syncSelectedItemsInternal()
  {
    let syncStatus = Promise.resolve();

    if(this.props.classState && this.props.classState.classDescription)
      syncStatus = this.props.dispatch(syncSelectedItems(this.props.classState.classDescription, this.props.classKey, this.props.rowIndex))
        .catch(error => this.props.dispatch(errorRaised(error)));

    return syncStatus;
  }

  renderDropDownText = (text) => {
    return (
      <div style={{paddingLeft: 20, paddingTop: 3, paddingBottom: 3}}>
        {text}
      </div>
    );
  };

  renderDropDownRows = (currentIndex, selectHandle) => {
    let moreSymbolsCount = this.symbolsCountToSearch - this.state.inputValue.trim().length;
    if (moreSymbolsCount > 0) {
      return this.renderDropDownText(i18next.t("TypeMoreSymbolsToSearch", {
        symbolsCount: textUtils.pluralize_i18next(moreSymbolsCount, "SymbolsCountPostfix")
      }));
    }

    if (!this.dropDownSearchItem) {
      return null;
    }

    if (this.dropDownSearchItem.inOperation) {
      return this.renderDropDownText(i18next.t("Searching"));
    }

    let rows = this.dropDownSearchItem.dataTable();

    if (rows.length === 0) {
      return this.renderDropDownText(i18next.t('GridViewEmptyLabel'));
    }

    return rows.map((r, i) => {
      let line = (row) => {
        let values = [];

        for (let fieldInfo of this.dropDownSearchItem.rawData.ResultFieldInfo) {
          if (this.dropDownSearchItem.getFieldIsHidden(fieldInfo))
            continue;

          let name = fieldInfo.Name;
          let val = formatData(fieldInfo, row[name]);

          values.push(val);
        }

        return values;
      };

      let valueToShow = line(r).filter(e => !!e).join(', ');

      return (
        <li role="presentation" key={i}>
          <a className="dropdown-item"
             href=""
             title={valueToShow}
             onClick={async(e) => {
               e.preventDefault();
               await singleItemSelected(this.props.classKey, i, true);
               await this.onValueChanged();
               await selectHandle(i);
               await this.syncValueWithProps();
             }}
          >
            <span>{valueToShow}</span>
          </a>
        </li>
      );
    });
  };

  updateLabel = async() => {
    const data = await updateControlText(this.props.classKey);
    if (data.changedFields) {
      await this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, data.changedFields));
    }
  };

  onInputValueChange = (value) => this.setState({inputValue: value});

  renderDropDownMultiSelect = (multiSelectProps) => {
    if (!multiSelectProps.subgridItem) {
      return null;
    }

    return (
      <ClassMultiSelectDropDown parentDataItem={this.props.parentDataItem}
                                rowIndex={this.props.rowIndex}
                                fieldInfo={this.props.fieldInfo}
                                multiSelectProps={multiSelectProps}
                                readOnly={this.props.readOnly}
                                inputValue={this.state.inputValue}
                                updateLabel={this.updateLabel}
                                onInputValueChange={this.onInputValueChange}
      />
    );
  };

  dropDownOutsideClickHandle = (e) => {
    e.preventDefault();
    if (this.openClassPanelButtonRef.current && this.openClassPanelButtonRef.current.contains(e.target)) {
      let searchValue = null;
      if (this.allowMultiSelect || this.state.inputValue !== this.props.value) {
        searchValue = this.state.inputValue;
      }
      this.onOpenClass(e, null, searchValue);
    }
    if (!this.allowMultiSelect) {
      this.syncValueWithProps();
    }
  };

  dropDownAfterClosed = async() => {
    if (!this.props.readOnly) {
      await this.syncSelectedItemsInternal();
      await this.onValueChanged(false);
    }
  };

  renderFormControlWrapper = () => {
    if (!this.props.allowInlineSearch) {
      return this.renderFormControl({
        inputAdditionalProps: {
          disabled: "disabled"
        },
        inputValue: this.props.value
      });
    }

    if (this.allowMultiSelect) {
      const renderControl = (options, multiSelectProps) => {
        this.dropDownOptions = options;
        return this.renderFormControl({
          inputAdditionalProps: {
            readOnly: true,
            onClick: (e) => {
              e.preventDefault();
              this.dropDownOptions.onToggle();
            }
          },
          inputValue: this.props.value,
          multiSelectProps,
          inputStyle: {
            cursor: "pointer",
            backgroundColor: "#ffffff"
          }
        });
      };

      return (
        <MultiSelectWrapper {...this.props}
                            rootPath={this.props.classKey}
                            initComponent={this.initClassManagerInternal}
        >
          {multiSelectProps => {
            const onAfterOpen = async() => {
              this.setState({inputValue: ""});
              await multiSelectProps.init();
              if (!!multiSelectProps.subgridItem) {
                await this.props.dispatch(multiSelectProps.subgridItem.execute({FindVal: ""}, {withParams: false}));
              }
            };

            return (
              <MnComboBase renderFormControl={options => renderControl(options, multiSelectProps)}
                           onAfterOpen={onAfterOpen}
                           renderRows={() => this.renderDropDownMultiSelect(multiSelectProps)}
                           afterOutsideClick={this.dropDownOutsideClickHandle}
                           onAfterClosed={this.dropDownAfterClosed}
                           forceOutsideElementArray={[this.openClassPanelButtonRef]}
              />
            );
          }}
        </MultiSelectWrapper>
      );
    }

    const renderControl = (options) => {
      this.dropDownOptions = options;
      return this.renderFormControl({
        inputAdditionalProps: {
          placeholder: i18next.t("StartTypingToSearch"),
          onFocus: (e) => {
            if (!e.target.value && this.dropDownOptions) {
              this.dropDownOptions.showDropDown();
            }
          },
          onChange: (e) => {
            e.preventDefault();
            this.setState({inputValue: e.target.value}, () => {
              if (this.dropDownOptions) {
                this.dropDownOptions.showDropDown();
              }
            });
            this.searchCallback(e.target.value);
          }
        },
        inputValue: this.state.inputValue
      });
    };
    return (
      <MnComboBase renderFormControl={renderControl}
                   renderRows={this.renderDropDownRows}
                   afterOutsideClick={this.dropDownOutsideClickHandle}
                   forceOutsideElementArray={[this.openClassPanelButtonRef]}
      />
    );
  };

  renderFormControl = ({inputAdditionalProps, inputValue, multiSelectProps, inputStyle = {}}) => {
    const containerStyle = {
      display: 'inline-block',
      position: 'absolute',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
      height: '100%',
      zIndex: 2
    };

    let showSpinner = this.props.classState && this.props.classState.inInitialization;
    if (!showSpinner && this.dropDownSearchItem) {
      showSpinner = this.dropDownSearchItem.inOperation;
    }

    return (
      <div className="input-group classControl" style={{minWidth: "100px"}}>
        <input
          type="text"
          className="form-control"
          value={inputValue}
          title={this.props.value}
          style={{paddingRight: "15px", ...inputStyle}}
          {...inputAdditionalProps}
        />
        {!this.props.readOnly &&
        <>
          <div onClick={this.onClearClass}><span className="clear zmdi zmdi-close"/></div>
          <div className="input-group-append">
            <button ref={this.openClassPanelButtonRef}
                    className="btn"
                    type="button"
                    onClick={(e) => this.onOpenClass(e, multiSelectProps)}
            >
              {showSpinner && <Spinner size={"20px"} colorHex={'FFFFFF'} containerStyle={containerStyle}/>}
              {showSpinner
                ? <i className="search zmdi zmdi-view-list-alt" style={{color: 'transparent'}}/>
                : <i className="search zmdi zmdi-view-list-alt"/>
              }
            </button>
          </div>
        </>
        }
      </div>
    );
  };

  render() {

    if(!this.autoOpenPanelMode && this.props.readOnly && !this.allowMultiSelect){
      return (<span>{this.props.value}</span>);
    }

    return (
      <>
        {this.state.classPanelVisible && this.classExMode &&
        <ClassExPanel {...this.props}
                    classKey = {this.props.classKey}
                    onClose={this.onCloseClass}
                    searchValue={this.state.classSearchInitialValue}
        />
        }
        {this.state.classPanelVisible && !this.classExMode &&
            <ClassPanel {...this.props}
                          classKey = {this.props.classKey}
                          onClose={this.onCloseClass}
                          searchValue={this.state.classSearchInitialValue}
            />
        }
        {!this.autoOpenPanelMode && this.renderFormControlWrapper()}
      </>
    );
  }
}

ClassSelectorBase.propTypes = {
  dispatch: PropTypes.func,
  parentDataItem: PropTypes.object,
  rowIndex: PropTypes.number,
  isParamset  : PropTypes.bool,
  fieldInfo: PropTypes.object,
  value: PropTypes.string,
  onSelected: PropTypes.func,
  classKey: PropTypes.string,
  readOnly: PropTypes.bool,
  allowInlineSearch: PropTypes.bool,
  saveValueToItem: PropTypes.func.isRequired,
  clearSelection: PropTypes.func.isRequired,
  initClassManager: PropTypes.func.isRequired,
  getOpenHandler: PropTypes.func
};

ClassSelectorBase.defaultProps = {
  classKey: "",
  allowInlineSearch: false,
  getOpenHandler: () => {}
};

function mapStateToProps(state, ownProps) {
  let o = {
    classState: state.classRepository[ownProps.classKey]
  };

  if (o.classState && o.classState.classDescription) {
    o.selectItem = state.dynState[o.classState.classDescription.selectItemPath];
    o.dropDownSearchInTreeItem = state.dynState[o.classState.classDescription.dropDownSearchInTreeItemPath];
    o.dropDownSearchInListItem = state.dynState[o.classState.classDescription.dropDownSearchInListItemPath];
  }

  return o;
}

export default connect(mapStateToProps)(ClassSelectorBase);