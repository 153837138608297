/**
 * Created by marks on 18.03.2016.
 */
import PropTypes from 'prop-types';
import React from 'react';
import debounce from 'lodash/debounce';
import {connect} from 'react-redux';
import i18next from "i18next";

export class SearchBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchVisible: true,
            textValue: ""
        };

        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.delayedCallback = debounce(this.applyValue, 500);
    }

    componentDidMount() {
        if (this.props.dataItem.isInitialized && this.props.dataItem.rawData.Filter)
            this.setState({textValue: this.props.dataItem.rawData.Filter});
    }

    async applyValue(value) {
        try {
            if (!this.props.dataItem.isInitialized)
                return;

            if (this.props.autoFields)
                this.f = this.props.dataItem.rawData.ResultFieldInfo.filter(f => !this.props.dataItem.getFieldIsHidden(f)).map(f => f.Name);
            else
                this.f = this.props.fieldSet;

            if (this.props.beforeApplyValue) {
                await this.props.beforeApplyValue();
            }

            if (this.props.onChange)
                await this.props.onChange(value);
            else
                await this.props.dispatch(this.props.dataItem.filterRows(value, this.f));

            if (this.props.afterApplyValue) {
                await this.props.afterApplyValue();
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    handleClearSearch(event) {
        event.preventDefault();
        this.setState({textValue: ""});
        this.applyValue("");
    }

    handleFilterChange(event) {
        event.preventDefault();
        this.setState({textValue: event.target.value});
        this.delayedCallback(event.target.value);
    }

    render() {

        const defText = (this.props.backgroundText) ? this.props.backgroundText : i18next.t("searchBar.defaultBackground");

        return (
          <div className="searchBar w100pc ellips -icon">
              <input autoFocus={this.props.autoFocus} type="text" className="form-control"
                     placeholder={defText} onChange={this.handleFilterChange}
                     value={this.state.textValue} disabled={!this.props.dataItem.isInitialized}/>
              <i className="clear zmdi zmdi-close" onClick={this.handleClearSearch}/>
          </div>);
    }
}

SearchBar.defaultProps = {
    autoFocus: false,
    autoFields: true,
    fieldSet: []
};

SearchBar.propTypes = {
    onChange: PropTypes.func,
    dispatch: PropTypes.func,
    dataItem: PropTypes.object.isRequired,
    backgroundText: PropTypes.string,
    autoFocus: PropTypes.bool,
    autoFields: PropTypes.bool,
    fieldSet: PropTypes.array,
    beforeApplyValue: PropTypes.func,
    afterApplyValue: PropTypes.func
};

export default connect()(SearchBar);