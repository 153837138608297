import React, {Component} from "react";
import PropTypes from "prop-types";
import "bootstrap";
import {connect} from "react-redux";
import i18next from "i18next";
import LanguageChanger from "@mnjsplatform/data/lib/api/LanguageChanger";
import {errorRaised} from "@mnjsplatform/data/lib/actions/errorActions";
import PlaceholderWrapper from "@mnjsplatform/components/lib/common/PlaceholderWrapper";
import InviteEdit from "./InviteEdit";

class Home extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            inOperation : true
        }
    }

    changeLanguage(e, lcid, langCode) {
        e.preventDefault();
        LanguageChanger.setLanguage(lcid, langCode, "SrHome")
            .catch(ed => this.props.dispatch(errorRaised(ed)));
    }

    componentWillUnmount() {
    }

    componentDidMount() {
        this.loadData().catch(e=>console.error(e));
    }

    async loadData() {
        await this.props.dispatch(this.props.inviteStatusItem.execute())
        this.setState({inOperation:false});
    }

    isShowWorkplaces()
    {
        return this.props.appParams.isLoginActive && this.props.appParams.workplaceList.length > 0;
    }

    render() {
        return (
            <div>
                {this.renderChangeLanguage()}
                { this.isShowWorkplaces() ? this.renderWorkplaceList() : this.renderRegistration()}
            </div>
        );
    }

    renderChangeLanguage() {

        let currentLangId = this.props.appParams.languageId;
        if (currentLangId > 1024)
            currentLangId = currentLangId - 1024;

        return <p className="fs14 mb-2">
            <span className="fs14 text-grey">{i18next.t('SwitchLang')}</span>&nbsp;

            {this.props.languages.map( row=>{return this.renderLangItem(row, currentLangId)})}
        </p>
    }

    renderLangItem(row, currentLangId) {

        let lnk = (<span key={row.LangId}><a href="" onClick={(e) => this.changeLanguage(e, row.LangId, row.LangName)} style={{cursor: "pointer"}}>{row.LangName}</a>&nbsp;</span>)

        if (row.LangId === currentLangId)
            return (<strong>{lnk}</strong>);

        return lnk;
    }

    renderWorkplaceList() {
        return(<PlaceholderWrapper placeholderId="70" canCollapse={true}
                            title={i18next.t("AvailableWorkplaces")}>

            {this.props.appParams.workplaceList.map( row=>{return this.renderWorkplaceItem(row)})}

        </PlaceholderWrapper>);
    }

    renderWorkplaceItem(row) {
        const url = row.Url;
        return <div><a href={url}>{row.Title}</a></div>
    }

    renderRegistration() {

        if (!this.props.inviteStatusItem.isInitialized || this.state.inOperation || this.props.inviteStatusItem.rowsCount() === 0)
            return null;

        const regData = this.props.inviteStatusItem.getRow(0);

        return <PlaceholderWrapper placeholderId="70" canCollapse={true}
                                   title={regData.DisplayMessage}><div>{regData.DisplayMessageNote}</div>
            <div className="mb-5"/>
                <InviteEdit regDataItem={this.props.inviteStatusItem} />
            </PlaceholderWrapper>
    }
}

Home.propTypes = {
    dispatch: PropTypes.func.isRequired
};


Home.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state, ownProps) {
    return {
        appParams: state.appState.appParams,
        languages: state.appState.appParams.languages,
        inviteStatusItem: state.regState.EDRequestInviteStatus
    };
}

export default connect(mapStateToProps)(Home);