import React, {Component} from "react";
import PropTypes from "prop-types";
import DropDownMenuCombo from "./DropDownMenuCombo";

class DropDownMenuComboLink extends Component {
    render() {
        return (
          <DropDownMenuCombo {...this.props} />
        );
    }
}

DropDownMenuComboLink.propTypes = {
    menuAdapter: PropTypes.object.isRequired,
    onSelected: PropTypes.func,
    renderInvisibleItem: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    readOnly: PropTypes.bool,
    alignRight: PropTypes.bool,
    onBeforeOpen: PropTypes.func,
    onAfterOpen: PropTypes.func,
    buttonClassName: PropTypes.string
};

DropDownMenuComboLink.defaultProps = {
    readOnly: false,
    alignRight: false,
    buttonClassName: "btn btn-input -link -icon dropdown-toggle d-block"
};

export default DropDownMenuComboLink;