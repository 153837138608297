import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SubgridPlaceholder from "./SubgridPlaceholder";
import SubgridOpenLink from "./SubgridOpenLink";
import SubgridPanel from "./SubgridPanel";
import { initSubgrid, unmountSubgrid } from "@mnjsplatform/data/lib/actions/subgridActions";

class SubgridInline extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { subgridKey: null };
  }

  initSubgrid = async(fieldName, rowIndex) => {
    const key = await this.props.dispatch(initSubgrid(this.props.dataItem, fieldName, rowIndex, this.props.path, this.props.readOnly));
    this.setState({subgridKey: key});
  }

  closeSubgrid = async() => {
    await this.props.dispatch(unmountSubgrid(this.props.dataItem, this.state.subgridKey));
    this.setState({subgridKey: null}, this.props.onEndEditField);
  }

  renderSubgrid() {
    return (<SubgridPlaceholder subgridKey={this.state.subgridKey}>
      {(subgridItem) => <SubgridPanel dataItem={subgridItem} onClose={this.closeSubgrid} readOnly={this.props.readOnly}
                                      title={this.props.fieldInfo.Title}
                                      maxWidthBody={this.props.maxWidthBody}
                                      renderRowEx={this.props.renderRowEx}
                                      showHeader={this.props.showHeader}
      />}
    </SubgridPlaceholder>);
  }

  render() {
    return (<React.Fragment>{this.renderSubgrid()}<SubgridOpenLink value={this.props.formattedVal} onShow={this.initSubgrid}
                                                                   fieldName={this.props.fieldInfo.Name}
                                                                   rowIndex={this.props.rowIndex}/></React.Fragment>);
  }
}

SubgridInline.propTypes = {
  dispatch: PropTypes.func,
  formattedVal: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  readOnly: PropTypes.bool.isRequired,
  dataItem: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  onEndEditField: PropTypes.func,
  renderRowEx: PropTypes.func,
  showHeader: PropTypes.bool,
  maxWidthBody: PropTypes.string
}

SubgridInline.defaultProps = {
  readOnly: true,
  onEndEditField: (fieldDescriptor, forceApplyParams) => {},
  showSubgridHeader: true
};


export default connect()(SubgridInline);