import $ from 'jquery';
import {isNewSession} from "../utils/errorTools";
import queryString from "query-string";
import Hashids from "../utils/hashids";
import AuthProvider from "../security/authProvider";

const jQuery = $;
window.$ = $;

(function ($) {

// jQuery on an empty object, we are going to use this as our Queue
    const ajaxQueue = $({});

    $.ajaxQueue = function (ajaxOpts) {
        let jqXHR,
            dfd = $.Deferred(),
            promise = dfd.promise();

        // queue our ajax request
        ajaxQueue.queue(doRequest);

        // add the abort method
        promise.abort = function (statusText) {

            // proxy abort to the jqXHR if it is active
            if (jqXHR) {
                return jqXHR.abort(statusText);
            }

            // if there wasn't already a jqXHR we need to remove from queue
            const queue = ajaxQueue.queue(),
                index = $.inArray(doRequest, queue);

            if (index > -1) {
                queue.splice(index, 1);
            }

            // and then reject the deferred
            dfd.rejectWith(ajaxOpts.context || ajaxOpts,
                [promise, statusText, ""]);

            return promise;
        };

        // run the actual query
        function doRequest(next) {
            jqXHR = $.ajax(ajaxOpts)
                .done(dfd.resolve)
                .fail(dfd.reject)
                .then(next, next);
        }

        return promise;
    };

})(jQuery);

const hashId = new Hashids();

class HttpQuery {
    constructor() {
        this._apiRoot = window.config.apiRoot;
        this._loginRoot = window.config.loginRoot;
        this._appId = window.AppInstanceId;
        this._prevAppId = sessionStorage.getItem('prevAppInstanceId');
        this._asyncProcessor = HttpQuery.getAsyncProcessor();
    }

    static getAsyncProcessor() {
        return window.asyncProcessor ? window.asyncProcessor : () => {
        };
    }

    static processError(jqXHR, reject, textStatus, errorThrown = null) {

        if (
            jqXHR.getResponseHeader("Content-Type") &&
            jqXHR.getResponseHeader("Content-Type").indexOf("application/json") > -1
        ) {
            const errorData = JSON.parse(jqXHR.responseText);
            const newSessionProcessor = window.NewSessionProcessor;

            if (isNewSession(errorData) && newSessionProcessor) {
                HttpQuery.getAsyncProcessor()(false);
                newSessionProcessor(errorData);
                return;
            }

            reject(errorData);
        } else if (jqXHR.status === 401) {
            if (!AuthProvider.handleUnauthorized())
                reject(jqXHR);
            else return;
        } else if (errorThrown) {
            reject(errorThrown)
            return;
        } else {
            reject(jqXHR);
        }

        reject(textStatus);
    }

    loadScript(scriptUrl) {

        this._asyncProcessor(true);

        return AuthProvider.getToken()
            .then(token => this.loadScriptInternal(scriptUrl, token)).finally(() => this._asyncProcessor(false))
    }

    loadScriptInternal(scriptUrl, token) {
        return new Promise((resolve, reject) => {
            $.ajax({
                type: "GET",
                url: scriptUrl,
                dataType: 'text',
                crossDomain: true,
                cache: false,
                xhrFields: {
                    withCredentials: true
                },
                success: (data) => {

                    const s = document.createElement('script');
                    s.text = data;
                    document.getElementsByTagName('head')[0].appendChild(s);

                    resolve(data);
                },
                headers: token ? {"Authorization": "Bearer " + token} : {},
                error: (jqXHR, textStatus) => {
                    HttpQuery.processError(jqXHR, reject, textStatus);
                },
                async: true
            });
        });
    }

    async beaconAiCall(apiMethodName, data) {
        let token = await AuthProvider.getToken();
        return this.beaconAiCallInternal(apiMethodName, token, data);
    }

    beaconAiCallInternal(apiMethodName, token = "", data) {
        const apiRoot = this._apiRoot;
        const appId = this._appId;

        const queryParams = {};
        queryParams.appid = appId + "_" + getUrlId();

        if (token)
            queryParams.b_token = token;

        if (this._prevAppId)
            queryParams.pappid = this._prevAppId;

        queryParams.t = now();

        const qs = queryString.stringify(queryParams);
        const url = apiRoot + "/" + apiMethodName + "?" + qs;

        return navigator.sendBeacon(url, JSON.stringify(data));
    }

    makeApiCall(apiMethodName) {
        this._asyncProcessor(true, apiMethodName);

        return AuthProvider.getToken()
            .then(token => this.makeApiCallInternal(apiMethodName, token)).finally(() => this._asyncProcessor(false, apiMethodName));
    }

    makeApiCallInternal(apiMethodName, token = "") {

        const apiRoot = this._apiRoot;
        const appId = this._appId;

        const queryParams = {};
        queryParams.appid = appId + "_" + getUrlId();

        if (token)
            queryParams.b_token = token;

        if (this._prevAppId)
            queryParams.pappid = this._prevAppId;

        queryParams.t = now();

        const qs = queryString.stringify(queryParams);
        const url = apiRoot + "/" + apiMethodName + "?" + qs;

        return new Promise((resolve, reject) => {
            $.ajaxQueue({
                type: "GET",
                url: url,
                crossDomain: true,
                cache: false,
                converters: {
                    "text json": function (response) {
                        return (response === "") ? null : JSON.parse(response);
                    },
                },
                xhrFields: {
                    withCredentials: true
                },
                success: data => {
                    resolve(data);
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    HttpQuery.processError(jqXHR, reject, textStatus, errorThrown);
                },
                dataType: "json",
                contentType: "application/json"
            });
        });
    }

    makeApiCallPost(apiMethodName, data) {
        this._asyncProcessor(true, apiMethodName);

        return AuthProvider.getToken()
            .then(token => this.makeApiCallPostInternal(apiMethodName, data, token)).finally(() => this._asyncProcessor(false, apiMethodName));
    }

    makeApiCallPostInternal(apiMethodName, inputData, token = "") {
        const apiRoot = this._apiRoot;
        const appId = this._appId;

        const queryParams = {};
        queryParams.appid = appId + "_" + getUrlId();

        if (token)
            queryParams.b_token = token;

        const triggerAppId = sessionStorage.getItem("triggerAppId");
        if (triggerAppId) {
            sessionStorage.removeItem("triggerAppId");
            queryParams.pappid = triggerAppId;
        }

        queryParams.t = now();

        const qs = queryString.stringify(queryParams);
        const url = apiRoot + "/" + apiMethodName + "?" + qs;

        return new Promise(function (resolve, reject) {
            $.ajaxQueue({
                type: "POST",
                url: url,
                crossDomain: true,
                cache: false,
                xhrFields: {
                    withCredentials: true
                },
                converters: {
                    "text json": function (response) {
                        return (response === "") ? null : JSON.parse(response);
                    },
                },
                data: JSON.stringify(inputData),
                xhr: function () {
                    let xhr = $.ajaxSettings.xhr();
                    if (inputData.onProgress)
                        xhr.upload.addEventListener('progress', inputData.onProgress, true, true);

                    return xhr;
                },
                success: resultData => {
                    if (inputData.onProgress) {
                        let xhr = $.ajaxSettings.xhr();
                        xhr.upload.removeEventListener('progress', inputData.onProgress, true, true);
                    }

                    resolve(resultData);
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    let xhr = $.ajaxSettings.xhr();
                    if (inputData.onProgress)
                        xhr.upload.removeEventListener('progress', inputData.onProgress, true, true);
                    HttpQuery.processError(jqXHR, reject, textStatus, errorThrown);
                },
                dataType: "json",
                contentType: "application/json",
            });
        });
    }

    makeApiCallUploadFile(data, progressHandler, controller) {

        this._asyncProcessor(true, controller);

        return AuthProvider.getToken()
            .then(token => this.makeApiCallUploadFileInternal(data, progressHandler, token, controller)).finally(() => this._asyncProcessor(false, controller));
    }

    makeApiCallUploadFileInternal(data, progressHandler = () => {
    }, token = "", controller = "/File/Upload") {

        const apiRoot = this._apiRoot;
        const xhr = $.ajaxSettings.xhr();

        return new Promise(function (resolve, reject) {
            $.ajax({
                type: "POST",
                url: apiRoot + controller,
                data: data,
                crossDomain: true,
                cache: false,
                processData: false,
                contentType: false,
                xhrFields: {
                    withCredentials: true
                },
                headers: token ? {"Authorization": "Bearer " + token} : {},
                xhr: function () {
                    xhr.upload.addEventListener('progress', progressHandler, true, true);
                    return xhr;
                },
                success: function (data) {
                    xhr.upload.removeEventListener('progress', progressHandler, true, true);
                    resolve(data)
                },
                error: function (jqXHR, textStatus) {
                    xhr.upload.removeEventListener('progress', progressHandler, true, true);
                    HttpQuery.processError(jqXHR, reject, textStatus);
                }
            });
        })
    }

    makeLoginPost(data) {
        let loginRoot = this._loginRoot;
        return new Promise(function (resolve, reject) {
            $.ajaxQueue({
                type: "POST",
                url: loginRoot,
                crossDomain: true,
                cache: false,
                xhrFields: {
                    withCredentials: true
                },
                data: data,
                success: function (data) {
                    let jData = JSON.parse(data);
                    resolve(jData);
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    HttpQuery.processError(jqXHR, reject, textStatus);
                },
                dataType: "text"
            });
        });
    }
}

function getUrlId() {
    return hashId.encode([hashCode(window.location.href)]);
}

function now() {
    return Math.round(new Date().getTime() / 1000.0);
}

function hashCode(s) {

    const str = s.split('#')[0];

    return Math.abs(str.split("").reduce(function (a, b) {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a;
    }, 0));
}

export default HttpQuery;
