import PropTypes from 'prop-types';
/**
 * Created by marks on 04.02.2017.
 */
import React from 'react';
import {connect} from 'react-redux';
import i18next from "i18next";
import {GlobalDebugMode} from "@mnjsplatform/data/lib";

class BaseMnTree extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.rowIndex = 0;
        this.expand = this.expand.bind(this);
        this.collapse = this.collapse.bind(this);
        this.renderRow = this.renderRow.bind(this);
        this.updateTreeView = this.updateTreeView.bind(this);
        this.renderByBranch = this.renderByBranch.bind(this);
    }

    componentDidMount() {

        if (this.props.openRootNodeByDefault && this.props.dataItem.rawData.CurrentRowIndex === 0) {
            this.props.dispatch(this.props.dataItem.treeOperation(0, true));
        }

        this.updateTreeView();
    }

    componentDidUpdate() {
        this.updateTreeView();
    }

    updateTreeView() {
        if (!this.props.byBranchMode)
            return;

        this.props.treeView = this.buildTree(this.props.dataItem);
    }

    buildTree(treeDataItem) {
        if (!treeDataItem || !treeDataItem.isInitialized || treeDataItem.inOperation || !treeDataItem.rawData || treeDataItem.rowsCount() === 0)
            return null;

        let ownerKey = {};
        let nodeKey = {};

        for (let fieldInfo of treeDataItem.rawData.ResultFieldInfo) {
            if (fieldInfo.Action.Parent) {
                ownerKey[fieldInfo.Name] = null;
                nodeKey[fieldInfo.Action.Parent] = null;
            }
        }

        let nodesList = {};
        let childList = {};

        treeDataItem.rawData.DataTable.map(function (row, rowIndex) {
            let rowOwner = Object.assign({}, nodeKey);
            let rowKey = Object.assign({}, nodeKey);

            let ownerKeys = Object.keys(ownerKey);
            let rowKeys = Object.keys(rowKey);

            for (let i = 0; i < ownerKeys.length; i++) {
                let rowKeyField = rowKeys[i];
                let ownerKeyField = ownerKeys[i];

                rowKey[rowKeyField] = row[rowKeyField];
                rowOwner[rowKeyField] = row[ownerKeyField];
            }

            let node = {
                id: JSON.stringify(rowKey),
                parentId: JSON.stringify(rowOwner),
                parentKey: rowOwner,
                key: rowKey,
                data: row,
                rowIndex: rowIndex,
                children: [],
                hasParent: false
            };

            nodesList[node.id] = node;

            if (nodesList[node.parentId]) {
                nodesList[node.parentId].children.push(node);
                node.hasParent = true;
            }
            else {
                if (childList[node.parentId])
                    childList[node.parentId].push(node);
                else
                    childList[node.parentId] = [];
            }

            if (childList[node.id]) {
                node.children = childList[node.id];
                childList.forEach((node) => {
                    node.hasParent = true;
                })
                childList[node.id] = null;
            }
        }, this);

        let rootNodes = [];
        Object.keys(nodesList).forEach((key) => {
            if (!nodesList[key].hasParent) rootNodes.push(nodesList[key])
        });

        return {rootNodes: rootNodes, nodesList: nodesList};
    }

    expand(rowIndex) {
        this.props.dispatch(this.props.dataItem.treeOperation(rowIndex, true));
    }

    collapse(rowIndex) {
        this.props.dispatch(this.props.dataItem.treeOperation(rowIndex, false));
    }

    renderRow(r, i, labelField = "Name") {
        const treeData = this.props.dataItem.rawData.RowTreeInfo[i];
        if (!treeData)
            return <div>{i18next.t('GridViewEmptyLabel')}</div>;

        const lbl = r[labelField];

        if (this.props.renderRow) {
            return this.props.renderRow({
                treeData,
                row: r,
                rowIndex: i,
                label: lbl,
                isSelected: this.props.dataItem.rawData.CurrentRowIndex === i,
                collapse: () => this.collapse(i),
                expand: () => this.expand(i)
            });
        }

        const self = this;

        let image = <span/>;
        let ctrl = null;
        let cursorType = "auto";

        if (treeData.IsExpanded) {
            ctrl = function (e) {
                e.preventDefault();
                self.collapse(i);
            };
            image = <i className="zmdi zmdi-folder-outline" onClick={ctrl}/>;
            cursorType = "pointer";
        }
        else if (!treeData.IsExpanded && !treeData.IsEmpty) {
            ctrl = function (e) {
                e.preventDefault();
                self.expand(i);
            };
            image = <i className="zmdi zmdi-folder" onClick={ctrl}/>;
            cursorType = "pointer";
        }
        else {
            image = <i className="zmdi zmdi-folder-outline" style={{color: "#17b169"}}/>;
            ctrl = function (e) {
            };
        }

        const isSelected = this.props.dataItem.rawData.CurrentRowIndex === i;
        const backColor = isSelected ? "#DDDDDD" : "transparent";
        const ref = isSelected && this.scrollTo || null;

        return (
            <div style={{
                marginLeft: treeData.Level * 20,
                marginTop: "5px",
                marginBottom: "5px",
                backgroundColor: backColor
            }} key={`${r[this.props.keyFieldName]}_${i}`} ref={ref}>
                <label className={"lt-label"} onClick={this.props.controlOnlyByImage ? () => {
                } : ctrl} style={{cursor: cursorType, paddingLeft: "5px", paddingRight: "5px"}}>
                    {image} {this.props.nodeRender ? this.props.nodeRender(r, i, lbl) :
                    <span style={{cursor: "pointer"}}>{lbl}</span>}
                </label>
            </div>
        );
    }

    scrollTo = (ref) => {
        if (ref) {
            setTimeout(() => {
                ref.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 500);
        }
    }

    render() {
        const self = this;

        if (!this.props.dataItem.isInitialized && this.props.dataItem.inOperation)
            return (<div>{i18next.t('LoadingLabel')}</div>);
        else if (!this.props.dataItem.isInitialized && !this.props.dataItem.inOperation)
            return (<div>{i18next.t('GridViewEmptyLabel')}</div>);
        else if (this.props.dataItem.isInitialized && !this.props.dataItem.inOperation && this.props.dataItem.rawData.Rows === 0)
            return (<div>{i18next.t('GridViewEmptyLabel')}</div>);

        const labelField = this.getLabelfield();

        return this.props.dataItem.rawData.DataTable ? (
            <div>
                {GlobalDebugMode && this.props.dataItem && this.props.dataItem.methodName}
                {this.props.dataItem.rawData.DataTable.map((r, i) => self.renderRow(r, i, labelField))}
            </div>
        ) : (
            <div>{i18next.t('LoadingLabel')}</div>
        );
    }

    renderByBranch() {

    }

    getLabelfield() {
        for (let fieldInfo of this.props.dataItem.rawData.ResultFieldInfo) {
            if (fieldInfo.Action.IsLabel)
                return fieldInfo.Name;
        }
    }
}

BaseMnTree.defaultProps = {
    rowIndex: 0,
    byBranchMode: false,
    controlOnlyByImage: true,
    keyFieldName: "Id",
    openRootNodeByDefault: false
};

BaseMnTree.propTypes = {
    dispatch: PropTypes.func,
    dataItem: PropTypes.object.isRequired,
    nodeRender: PropTypes.func,
    renderRow: PropTypes.func,
    byBranchMode: PropTypes.bool,
    treeView: PropTypes.object,
    controlOnlyByImage: PropTypes.bool,
    keyFieldName: PropTypes.string,
    updateStateFlag: PropTypes.any,
    openRootNodeByDefault: PropTypes.bool
};

export default connect()(BaseMnTree);
