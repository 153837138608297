export const OPERATION_STARTED = 'OPERATION_STARTED';
export const OPERATION_COMPLETED  = 'OPERATION_COMPLETED';

let timerHandler;
let opCount = 0;
let triggered = false;

let timerHandlerOff;
let offWaiting = false;

const filteredItems = ['Report/CreateReport', 'Report/GetTaskStatus', 'Report/CancelTask', 'Combo/GetSelectedIndex'];

export function operationStarted(d, data) {

    //console.log("in " + JSON.stringify(d) + " " + data);

    if (skip(data))
        return;

    opCount++;

    if (offWaiting)
    {
        clearTimeout(timerHandlerOff);
        offWaiting = false;
    }

    if (triggered || opCount>1)
        return;

    triggered = true;
    d({type: OPERATION_STARTED});

    /*
    timerHandler = setTimeout(()=>{

        console.log("Fire");

        triggered = true;

        d({type: OPERATION_STARTED});
    }, 1000);
     */
}

export function operationComplete(d, data, timeToOff = 300) {

    //console.log("out " + JSON.stringify(d) + " " + data);

    if (skip(data))
        return;

    if (opCount>0)
        opCount--;

    if (opCount>0)
        return;

    if (!triggered) {
        //clearTimeout(timerHandler);
    }
    else
    {
        offWaiting = true;

        timerHandlerOff = setTimeout(() => {

            offWaiting = false;
            triggered = false;

            d({type: OPERATION_COMPLETED});
        }, timeToOff);
    }
}

function skip(data)
{
    return filteredItems.includes(data);
}