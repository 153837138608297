import React, { Component } from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import GridView from "../GridView/GridView";
import PopupWrapper from "../PopupWrapper";

class SubgridPanel extends Component {
  constructor(props, context) {
    super(props, context);
  }

  closePanel = () => {
    if (this.props.onClose)
      this.props.onClose();
  }

  render() {
    return (
        <PopupWrapper onClose={this.closePanel} popupVisible={true} title={this.props.title} maxWidthBody={this.props.maxWidthBody}>
          <GridView dataItem={this.props.dataItem} readOnly={this.props.readOnly} renderRowEx={this.props.renderRowEx}
                    showHeader={this.props.showHeader}
          />
        </PopupWrapper>
    )
  }
}

SubgridPanel.propTypes = {
  dataItem: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  renderRowEx: PropTypes.func,
  showHeader: PropTypes.bool,
  maxWidthBody: PropTypes.string
};


SubgridPanel.defaultProps = {
  readOnly : false,
  title: "Подтаблица",
  showSubgridHeader: true
};

export default SubgridPanel;