import PropTypes from 'prop-types';
import React from "react";
import {connect} from 'react-redux';

import SearchBar from './SearchBar';
import debounce from 'lodash/debounce';
import DataItem from '@mnjsplatform/data/lib/api/DataItem';
import formatData from '@mnjsplatform/data/lib/utils/formatData';
import editorType from '@mnjsplatform/data/lib/utils/editorType';
import ClassSelectorEmbedded from "./ClassSelector/ClassSelectorEmbedded";
import MnComboInline2 from "./Dropdown/MnComboInline2";
import MnComboInlineEnum from "./Dropdown/MnComboInlineEnum";
import i18next from "i18next";
import {GlobalDebugMode} from "@mnjsplatform/data/lib";
import MnHtmlEditor from "./MnHtmlEditor";
import ClassSelectorAdvanced from "./ClassSelector/ClassSelectorAdvanced";

import MnInlineDatePicker from "./Datepicker/MnInlineDatePicker";
import InputFieldInline from "./InputField/InputFieldInline";
import MnInlineTimePicker,{isTimePickerAcceptableEditFormat} from "./MnInlineTimePicker";
import MnInlineDateTimeEdit,{isDateTimeEditAcceptableEditFormat} from "./MnInlineDateTimeEdit";
import SubgridInline from "./Subgrid/SubgridInline";
import SelectorFIAS from "./Fias/SelectorFIAS";
import MnInlinePhoneEdit, { isPhoneEditBoilerPicture } from "./MnInlinePhoneEdit";
import CheckBox from "./InputField/CheckBox";
import FileInline from "./InputField/FileInline";
import ColorPicker from "./ColorPicker";
import IconPicker from "./IconPicker";
import UrProvider from "@mnjsplatform/data/lib/api/UrProvider";
import MnComboInlineMultiSelect from "./Dropdown/MnComboInlineMultiSelect";
import MnComboInlineMultiSelectSearch from "./Dropdown/MnComboInlineMultiSelectSearch";
import {WebCustomTemplate_ToggleButton} from "./MnToggleButton";
import { MnComboDatePickerSimple } from "./Datepicker/MnComboDatePicker";
import MnComboDatePickerEx from "./Datepicker/MnComboDatePicker";
import ExtLink from "./InputField/ExtLink";
import MnComboInlineTree from "./Dropdown/MnComboInlineTree";
import InputFieldMask from "./InputField/InputFieldMask";

const generalWebCustomTemplateMap = {
    MnComboDatePicker: (props) => <MnComboDatePickerSimple {...props} />,
    MnComboDatePickerEx: (props) => <MnComboDatePickerEx {...props} />,
    ToggleButton: (props) => <WebCustomTemplate_ToggleButton {...props} />,
    ExtLink : (props) => <ExtLink {...props} />
};

export class ComplexParam extends React.Component {

    constructor(props, context) {
      super(props, context);

      this.isFieldChanged = false;
      this.autoFocusField = props.autoFocusField;
    }


    shouldComponentUpdate(nextProps, nextState) {
      return this.props !== nextProps || this.state !== nextState;
    }

    get customFieldExMap() {
        return {
            ...generalWebCustomTemplateMap,
            ...(this.props.customFieldExMap || {})
        };
    }

    checkAutoApply = (f) => {
      if (this.props.autoApplyParams)
        return true;

      if (!this.props.autoApplyParamsFunc)
        return false;

      return this.props.autoApplyParamsFunc(f);
    }

    getFieldsData = ()=> {
        const fields = [];

        for (let fieldInfo of this.props.dataItem.rawData.ResultFieldInfo) {
            if (this.props.dataItem.getFieldIsHidden(fieldInfo, this.props.rowIndex) || !fieldInfo.Title)
                continue;

            if (this.props.overrideVisibility[fieldInfo.Name] !== undefined && !this.props.overrideVisibility[fieldInfo.Name]) {
                continue;
            }

            if (!this.props.dataItem.rawData.DataTable[this.props.rowIndex])
                continue;

            const row = this.props.dataItem.rawData.DataTable[this.props.rowIndex];
            const value = row[fieldInfo.Name];

            if (this.props.hideEmptyValues && this.props.readOnly) {

                let val = value;

                if (fieldInfo.Action.VirtCol) {
                    val = this.props.dataItem.getVirtualFieldValue(fieldInfo, row);
                }

                if (val == null || value === "")
                    continue;
            }

            let fieldDescriptor = {
                fieldInfo,
                Value: value,
                IsHidden: false,//fieldInfo.IsHidden, - IsHidden поля пропускаются в первом if внутри этого цикла
                Name: fieldInfo.Name
            };

            fields.push(fieldDescriptor);
        }

        // сортировка в Google Chrome меняет порядок, если ViewNumber одинаков
        let allColumnsHaveSameViewOrder = true;
        let prevViewNumber;

        for (let i = 0; i < fields.length; i++) {
            if (i === 0) {
                prevViewNumber = fields[i].fieldInfo.ViewNumber;
            }
            else if (prevViewNumber !== fields[i].fieldInfo.ViewNumber) {
                allColumnsHaveSameViewOrder = false;
                break;
            }
        }

        if (allColumnsHaveSameViewOrder) {
            return fields;
        }
        else {
            return fields.sort((a, b) => a.fieldInfo.ViewNumber - b.fieldInfo.ViewNumber);
        }
    }

    onChangeField = (e, fieldDescriptor) => {
      const target = e.target;
      const targetType = e.target.type;
      const value = targetType === 'checkbox' ? target.checked : target.getContent ? target.getContent() : target.value;

      this.props.dispatch(this.props.dataItem.setField(this.props.rowIndex, fieldDescriptor.Name, value))
        .then(() => {
          if (this.checkAutoApply(fieldDescriptor)) {
            if (targetType === 'checkbox') {
              this.applyParams();
            }
            else {
              this.isFieldChanged = true;
              debounce(() => {
                this.onEndEditField(fieldDescriptor);
              }, this.props.applyParamDebounce)();
            }
          }
        });
    };

    onEndEditField = (fieldDescriptor, forceApplyParams) => {
        if (this.checkAutoApply(fieldDescriptor) && (forceApplyParams || this.isFieldChanged)) {
            this.applyParams();
            this.isFieldChanged = false;
        }
        if (this.props.onEndEditField) {
            this.props.onEndEditField(this.props.rowIndex, fieldDescriptor, forceApplyParams);
        }
    }

    onApplyParams = (e) => {
        e.preventDefault();
        this.applyParams();
    }

    applyParams = () => {
        if (this.props.beforeApplyParams) {
            this.props.beforeApplyParams();
        }

        this.props.dispatch(this.props.parentDataItem.applyParams(this.props.dataItem, this.props.refreshParamsOnApply))
            .then(() => {
                this.props.afterApplyParams();
            });
    };

    getSearchBar = () => {
      return (
          <SearchBar dataItem={this.props.parentDataItem}
                     beforeApplyValue={this.props.beforeApplyParams}
                     afterApplyValue={this.props.afterApplyParams}
          />
      );
    }

    getFieldControl = (fieldDescriptor, params ={}) => {
        const rawVal = fieldDescriptor.Value;
        const val = formatData(fieldDescriptor.fieldInfo, rawVal);
        const self = this;

        const readOnly = !!(this.props.readOnly || this.props.dataItem.getFieldIsReadOnly(fieldDescriptor.fieldInfo, this.props.rowIndex) || params.readOnly);
        const isParamset = this.props.dataItem.isParamItem; //this.props.parentDataItem != null && this.props.parentDataItem.methodName != null && this.props.parentDataItem.methodName.length > 0;

        if (fieldDescriptor.fieldInfo.EditorType === editorType.Enum && fieldDescriptor.fieldInfo.EditorMethod === "WebColors") {
            return (
              <ColorPicker value={val}
                           parentDataItem={this.props.dataItem}
                           rowIndex={this.props.rowIndex}
                           fieldInfo={fieldDescriptor.fieldInfo}
                           onValueChanged={() => self.onEndEditField(fieldDescriptor, true)}
              />
            );
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditForm && fieldDescriptor.fieldInfo.EditorObject.startsWith("WebIconSelect")) {
            return (
              <IconPicker value={val}
                          parentDataItem={this.props.dataItem}
                          rowIndex={this.props.rowIndex}
                          fieldInfo={fieldDescriptor.fieldInfo}
                          onValueChanged={() => self.onEndEditField(fieldDescriptor, true)}
              />
            );
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditForm && fieldDescriptor.fieldInfo.EditorObject.startsWith("ComboBoxMultiSelect")) {
            return (
                <MnComboInlineMultiSelectSearch parentDataItem={this.props.dataItem}
                                          fieldInfo={fieldDescriptor.fieldInfo}
                                          rootPath={this.props.rootPath + "_" + fieldDescriptor.fieldInfo.Name}
                                          rowIndex={this.props.rowIndex}
                                          readOnly={readOnly}
                                          value={val}
                                          onEndEditField={() => {
                                              self.onEndEditField(fieldDescriptor, true);
                                          }}
                />
            );
        }
        else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditForm && fieldDescriptor.fieldInfo.EditorObject.startsWith("ComboBoxTreeSelect")) {

            let selectOnlyLeafs = fieldDescriptor.fieldInfo?.EnumInfo?.includes("SelectOnlyLeafs");
            let disableSearch = fieldDescriptor.fieldInfo?.EnumInfo?.includes("DisableSearch");

            return (<MnComboInlineTree
                    parentDataItem={this.props.dataItem}
                    fieldInfo={fieldDescriptor.fieldInfo}
                    rootPath={this.props.rootPath + "_" + fieldDescriptor.fieldInfo.Name}
                    rowIndex={this.props.rowIndex}
                    readOnly={readOnly}
                    selectOnlyLeafs = {selectOnlyLeafs}
                    disableSearch={disableSearch}
                    onSelected={() => {
                        self.onEndEditField(fieldDescriptor, true);
                    }}
                />
            )
        }
        else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditForm && fieldDescriptor.fieldInfo.EditorObject === "WebHtmlEditor") {
            return <MnHtmlEditor
              fieldInfo={fieldDescriptor.fieldInfo}
              rowIndex={this.props.rowIndex}
              dataItem={this.props.dataItem}
              fieldAttributes={this.props.fieldAttributes}
              value={val}
              readOnly={readOnly}
              onEndEditField={() => {
                  self.onEndEditField(fieldDescriptor, true);
              }}
            />
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditForm && fieldDescriptor.fieldInfo.EditorObject && fieldDescriptor.fieldInfo.EditorObject.startsWith("MonNT.Cell")) {
            const classKey = `${this.props.rootPath}_${fieldDescriptor.fieldInfo.Name}_${this.props.rowIndex}`;
            return <ClassSelectorAdvanced
              parentDataItem={this.props.dataItem}
              rowIndex={this.props.rowIndex}
              isParamset={isParamset}
              fieldInfo={fieldDescriptor.fieldInfo}
              value={val}
              onSelected={function() {
                  self.onEndEditField(fieldDescriptor, true);
              }}
              classKey={classKey}
              readOnly={readOnly}
              allowInlineSearch={(window && window.config) ? !!window.config.classManagerAllowInlineSearch : false} //TODO: управлять этим из fieldEx
            />;
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditForm && fieldDescriptor.fieldInfo.EditorForm
          && fieldDescriptor.fieldInfo.EditorForm.FormOptions && fieldDescriptor.fieldInfo.EditorForm.FormOptions.Script === "SelectFIAS") {
            return <SelectorFIAS
              parentDataItem={this.props.dataItem}
              rowIndex={this.props.rowIndex}
              fieldInfo={fieldDescriptor.fieldInfo}
              location={val}
              onSelected={() => {
                  self.onEndEditField(fieldDescriptor, true);
              }}
              execMap={this.props.execMap}
              rootPath={`${this.props.rootPath}_${fieldDescriptor.fieldInfo.Name}`}
              readOnly={readOnly}
            />;
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditForm && fieldDescriptor.fieldInfo.EditorObject && fieldDescriptor.fieldInfo.EditorObject.startsWith("MonFrm.Cell")) {
            return val;
        }
        else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditForm && fieldDescriptor.fieldInfo.EditorObject && fieldDescriptor.fieldInfo.EditorObject.startsWith("WebFile")) {
            return <FileInline formattedVal={val} dataItem={this.props.dataItem} fieldInfo={fieldDescriptor.fieldInfo} rowIndex={this.props.rowIndex} readOnly={readOnly} />
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditForm && fieldDescriptor.fieldInfo.EditorObject === "WebCustomTemplate") {

            let fieldEx = this.customFieldExMap[fieldDescriptor.fieldInfo.EditorMethod];
            if (fieldEx) {
                return fieldEx({
                    hideLabelText: params && params.hideLabelText || false,
                    dataItem: this.props.dataItem,
                    fieldInfo: fieldDescriptor.fieldInfo,
                    rowIndex: this.props.rowIndex,
                    row: this.props.dataItem.getRow(this.props.rowIndex),
                    readOnly: readOnly,
                    value: rawVal,
                    formattedVal: val,

                    onEndEditField: () => {
                        self.onEndEditField(fieldDescriptor, true);
                    },

                    onApply: this.applyParams,
                    autoApplyParams :this.props.autoApplyParams,
                    applyOnEnter:this.props.applyOnEnterKey,
                    autoFocusedInput: this.props.autoFocusField === fieldDescriptor.fieldInfo.Name
                });
            }
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditForm) {
            const classKey = `${this.props.rootPath}_${fieldDescriptor.fieldInfo.Name}_${this.props.rowIndex}`;
            return (
              <ClassSelectorEmbedded
                parentDataItem={this.props.dataItem}
                rowIndex={this.props.rowIndex}
                isParamset={isParamset}
                fieldInfo={fieldDescriptor.fieldInfo}
                value={val}
                onSelected={() => {
                    self.onEndEditField(fieldDescriptor, true);
                }}
                classKey={classKey}
                readOnly={readOnly}
                allowInlineSearch={(window && window.config) ? !!window.config.classManagerAllowInlineSearch : false} //TODO: управлять этим из fieldEx
                getOpenHandler={params.getOpenHandler}
              />
            );
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.Enum && fieldDescriptor.fieldInfo.EnumInfo === "*YESNO:?,0,1") {
            return (
                <CheckBox
                    checked={rawVal}
                    readOnly={readOnly}
                    rowIndex={this.props.rowIndex}
                    dataItem={this.props.dataItem}
                    fieldInfo={fieldDescriptor.fieldInfo}
                    onSelected={() => {
                        self.onEndEditField(fieldDescriptor, true);
                    }}
                    forId={params.forId}
                    style={params.style}
                 />
            );
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.ComboBox || fieldDescriptor.fieldInfo.EditorType === editorType.Enum) {
            if (!fieldDescriptor.fieldInfo.EditorInfo) {
                return (<MnComboInline2
                    parentDataItem={this.props.dataItem}
                    fieldInfo={fieldDescriptor.fieldInfo}
                    rootPath={this.props.rootPath + "_" + fieldDescriptor.fieldInfo.Name}
                    rowIndex={this.props.rowIndex}
                    readOnly={readOnly}
                    onSelected={() => {
                        self.onEndEditField(fieldDescriptor, true);
                    }}
                  />
                )
            } else {
                return (<MnComboInlineEnum parentDataItem={this.props.dataItem}
                                           fieldInfo={fieldDescriptor.fieldInfo}
                                           rowIndex={this.props.rowIndex}
                                           readOnly={readOnly}
                                           onSelected={() => {
                                               self.onEndEditField(fieldDescriptor, true);
                                           }}/>);
            }
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditLine && fieldDescriptor.fieldInfo.EditorForm.FormOptions.EditStyle === "2") {
            if (isPhoneEditBoilerPicture(fieldDescriptor.fieldInfo.EditorForm.FormOptions.BoilerPicture))
                return <MnInlinePhoneEdit
                  boilerPicture={fieldDescriptor.fieldInfo.EditorForm.FormOptions.BoilerPicture}
                  fieldInfo={fieldDescriptor.fieldInfo}
                  rowIndex={this.props.rowIndex}
                  dataItem={this.props.dataItem}
                  readOnly={readOnly}
                  value={val}
                  rawVal={rawVal}
                  onChange={(e) => {
                      self.onChangeField(e, fieldDescriptor);
                  }}
                  onEndEditField={() => {
                      self.onEndEditField(fieldDescriptor, true);
                  }}
                />;
            else if (isTimePickerAcceptableEditFormat(fieldDescriptor.fieldInfo.EditorForm.FormOptions.BoilerPicture))
                return <MnInlineTimePicker
                  boilerPicture={fieldDescriptor.fieldInfo.EditorForm.FormOptions.BoilerPicture}
                  fieldInfo={fieldDescriptor.fieldInfo}
                  readOnly={readOnly}
                  value={val}
                  rawVal={rawVal}
                  onChange={(e) => {
                      self.onChangeField(e, fieldDescriptor);
                  }}
                />;
            else if (isDateTimeEditAcceptableEditFormat(fieldDescriptor.fieldInfo.EditorForm.FormOptions.BoilerPicture))
                return <MnInlineDateTimeEdit
                    boilerPicture={fieldDescriptor.fieldInfo.EditorForm.FormOptions.BoilerPicture}
                    fieldInfo={fieldDescriptor.fieldInfo}
                    readOnly={readOnly}
                    value={val}
                    rawVal={rawVal}
                    onChange={(e) => {
                        self.onChangeField(e, fieldDescriptor);
                    }}
                />;
            else return <InputFieldMask
                    boilerPicture={fieldDescriptor.fieldInfo.EditorForm.FormOptions.BoilerPicture}
                    fieldInfo={fieldDescriptor.fieldInfo}
                    rowIndex={this.props.rowIndex}
                    dataItem={this.props.dataItem}
                    readOnly={readOnly}
                    value={val}
                    rawVal={rawVal}
                    onChange={(e) => {
                        self.onChangeField(e, fieldDescriptor);
                    }}
                    onEndEditField={() => {
                        self.onEndEditField(fieldDescriptor, true);
                    }}
                />;
        } else if (fieldDescriptor.fieldInfo.DataType === "System.DateTime") {
            return (<MnInlineDatePicker
              formattedVal={val}
              rawVal={rawVal}
              readOnly={readOnly}
              rowIndex={this.props.rowIndex}
              dataItem={this.props.dataItem}
              fieldInfo={fieldDescriptor.fieldInfo}
              onSelected={() => {
                  self.onEndEditField(fieldDescriptor, true);
              }}/>);
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.EditSubGrid) {
            return (<SubgridInline fieldInfo={fieldDescriptor.fieldInfo}
                                   readOnly={this.props.readOnly}
                                   rowIndex={this.props.rowIndex}
                                   dataItem={this.props.dataItem}
                                   onEndEditField={() => {
                                       self.onEndEditField(fieldDescriptor, true);
                                   }}
                                   renderRowEx={this.props.renderSubgridRowEx}
                                   showHeader={this.props.showSubgridHeader}
                                   maxWidthBody={this.props.maxSubgridWidthBody}
                                   path={`${this.props.rootPath}_${fieldDescriptor.fieldInfo.Name}`}
                                   formattedVal={val || i18next.t('Selected') + ' (0)'}/>)
        } else if (fieldDescriptor.fieldInfo.EditorType === editorType.MultiLineText) {
            return (
              <InputFieldInline fieldInfo={fieldDescriptor.fieldInfo}
                                multiline={true}
                                readOnly={readOnly}
                                rowIndex={this.props.rowIndex}
                                dataItem={this.props.dataItem}
                                formattedVal={val}
                                onEndEditField={() => {
                                    self.onEndEditField(fieldDescriptor, true);
                                }}
                                onApply={this.applyParams}
                                autoApplyParams = {this.props.autoApplyParams}
                                applyOnEnter={this.props.applyOnEnterKey}
                                autoFocusedInput={this.props.autoFocusField === fieldDescriptor.fieldInfo.Name}
              />
            );
        }
        return <InputFieldInline fieldInfo={fieldDescriptor.fieldInfo}
                                 readOnly={readOnly}
                                 rowIndex={this.props.rowIndex}
                                 dataItem={this.props.dataItem}
                                 formattedVal={val}
                                 onEndEditField={() => {
                                     self.onEndEditField(fieldDescriptor, true);
                                 }}
                                 onApply={this.applyParams}
                                 autoApplyParams = {this.props.autoApplyParams}
                                 applyOnEnter={this.props.applyOnEnterKey}
                                 autoFocusedInput={this.props.autoFocusField === fieldDescriptor.fieldInfo.Name}
        />
    }

    renderDefault = (h) => {
        return (<div>
            <table className="table table-hover">
                <tbody>
                {h.fs.map(fld => (<tr key={fld.fieldInfo.Name}>
                    <td className="col-md-4">{h.lbl(fld.Name)}</td>
                    <td>{h.ed(fld.Name)}</td>
                </tr>))}
                <tr>
                    <td className="col-md-4">&nbsp;</td>
                    <td>
                        <button title="Apply" onClick={this.onApplyParams} className="btn btn-primary">{i18next.t('Accept')}
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>);
    }

    renderInline = (h) => {
        return (
            <div className="form-inline">
                {h.fs.map(fld => (
                    <div className="form-group" key={fld.fieldInfo.Name}>
                        <label>{h.lbl(fld.Name)}</label>&nbsp;{h.ed(fld.Name)}
                    </div>))}
                {this.props.showSearchBar && this.getSearchBar()}
                {this.props.showApplyButton &&
                <button title="Apply" onClick={this.onApplyParams} className="btn btn-primary">{i18next.t('Accept')}</button>}
            </div>);
    }

    render() {
        const isParentLoading = (this.props.parentDataItem && this.props.parentDataItem.inOperation);
        const isLoading = !this.props.dataItem.isInitialized && (this.props.dataItem.inOperation || isParentLoading);
        const noData = !this.props.dataItem.isInitialized && !(this.props.dataItem.inOperation || isParentLoading);

        const wrongRows = this.props.rowIndex >= this.props.dataItem.rawData.Rows || this.props.rowIndex < 0;

        if (isLoading && isParentLoading)
            return null;

        if (isLoading)
            return (<div>{i18next.t('LoadingLabel')}</div>);

        else if (noData || wrongRows)
            return (<div>{i18next.t('GridViewEmptyLabel')}</div>);
        else {

            let fs = this.getFieldsData();
            let gfc = this.getFieldControl;
            const dataItem = this.props.dataItem;
            const readOnly = this.props.readOnly;
            const needToHighlightRequiredFields = this.props.needToHighlightRequiredFields;
            const rowIndex = this.props.rowIndex;
            const row = dataItem.dataTable()[this.props.rowIndex];

            let map = Object.assign({}, ...fs.map((f) => {
                let ro = {};
                ro[f.fieldInfo.Name] = f;
                return ro;
            }));

            let handler =
                {
                    ed: function (n, params) {

                        if (!map[n])
                            return "";

                        let fa = map[n];

                        const fieldInfo = fa.fieldInfo;

                        if (fieldInfo.Action.VirtCol) {
                            const realFieldInfo = dataItem.getVirtualFieldInfo(fieldInfo, row);

                            if (!realFieldInfo)
                                return null;

                            fa.fieldInfo = realFieldInfo;
                            fa.Value = row[realFieldInfo['Name']];
                            fa.Name = realFieldInfo['Name'];
                        }

                        fa.fieldInfo = {
                            ...fa.fieldInfo,
                            WebView_Name: n //имя для проверки WebView_ аттрибутов
                        };
                        return gfc(fa, params);
                    },
                    lbl: function (n) {

                        if (!map[n])
                            return "";

                        let fa = map[n];

                        //let title = fa.fieldInfo.Title ? fa.fieldInfo.Title.replace(/\|/g, ' ') : "";
                        let title = dataItem.getTitle(fa.fieldInfo, rowIndex);
                        title = title ? title.replace(/\|/g, ' ') : "";

                        if (GlobalDebugMode) {
                            const readOnly = readOnly || dataItem.getFieldIsReadOnly(fa.fieldInfo, rowIndex);
                            title = title + " [" + fa.Name + "] RO: " + JSON.stringify(readOnly);
                        }

                        if (needToHighlightRequiredFields && !(readOnly || dataItem.getFieldIsReadOnly(fa.fieldInfo, rowIndex)) && dataItem.getFieldIsRequired(fa.fieldInfo, rowIndex)) {
                            return (<span>{title}&nbsp;<span style={{color: "red"}}>*</span></span>);
                        }

                        return (title);
                    },
                    fi: function (n) {
                        if (!map[n])
                            return null;

                        let fa = map[n];

                        return fa.fieldInfo;
                    },
                    row: this.props.dataItem.dataTable()[this.props.rowIndex],
                    rowIndex: this.props.rowIndex,
                    fs: fs,
                    applyParams: this.onApplyParams
                };

            const rp = this.props.renderParams ? this.props.renderParams : this.renderInline;

            if (this.props.renderWithoutContainer) {
                return (
                    <>
                        {GlobalDebugMode && this.props.dataItem.methodName}
                        {rp(handler)}
                    </>
                );
            }

            return (<div>
                {GlobalDebugMode && this.props.dataItem.methodName}
                {rp(handler)}
            </div>);
        }
    }
}

ComplexParam.defaultProps = {
    parentDataItem: new DataItem(),
    readOnly: false,
    showSearchBar: true,
    autoApplyParams: false,
    showApplyButton: true,
    needToHighlightRequiredFields: true,
    rowIndex: 0,
    hideEmptyValues: false,
    rootPath: "",
    autoFocusField: "",
    overrideVisibility: {},
    applyOnEnterKey: false,
    afterApplyParams: () => {
    },
    fieldAttributes: {},
    applyParamDebounce: 500,
    renderWithoutContainer: false,
    refreshParamsOnApply: false,
    showSubgridHeader: true
};

ComplexParam.propTypes = {
    dispatch: PropTypes.func,
    dataItem: PropTypes.object.isRequired,
    parentDataItem: PropTypes.object,
    renderParams: PropTypes.func,
    readOnly: PropTypes.bool,
    showSearchBar: PropTypes.bool,
    autoApplyParams: PropTypes.bool,
    autoApplyParamsFunc: PropTypes.func,
    showApplyButton: PropTypes.bool,
    beforeApplyParams: PropTypes.func,
    afterApplyParams: PropTypes.func,
    applyOnEnterKey: PropTypes.bool,
    needToHighlightRequiredFields: PropTypes.bool,
    rowIndex: PropTypes.number.isRequired,
    hideEmptyValues: PropTypes.bool,
    rootPath: PropTypes.string,
    autoFocusField: PropTypes.string,
    overrideVisibility: PropTypes.object,
    fieldAttributes: PropTypes.object,
    applyParamDebounce: PropTypes.number,
    execMap: PropTypes.string,
    onEndEditField: PropTypes.func,
    renderWithoutContainer: PropTypes.bool,
    customFieldExMap: PropTypes.object,
    updateStateFlag: PropTypes.any,
    refreshParamsOnApply: PropTypes.bool,
    renderSubgridRowEx: PropTypes.func,
    showSubgridHeader: PropTypes.bool,
    maxSubgridWidthBody: PropTypes.string
};

export default connect()(ComplexParam);