import PropTypes from 'prop-types';
import React from 'react';
import GridViewHeader from './GridViewHeader';
import GridViewHeaderDropdown from './GridViewHeaderDropdown';
import GridViewRow from './GridViewRow';
import {connect} from 'react-redux';
import {userSettingsSave} from '@mnjsplatform/data/lib/actions/userSettingsActions';
import PopupWrapper from './../PopupWrapper';
import ComplexParam from './../ComplexParam';
import {ScreenFormatContext, GlobalDebugMode} from "@mnjsplatform/data";
import i18next from "i18next";
import confirm from "../ConfirmFn";
import {getCustomizationByMethodName} from "./defaultCustomization";
import DrawAttributeProvider from "./DrawAttributeProvider";
import scrollIntoView from "scroll-into-view-if-needed";
import IntersectionObserverWrapper from "../IntersectionObserverWrapper";

const GRIDVIEW_SETTINGS_PREFIX = 'GRIDVIEW_SETTINGS_';

export const GridRowEditMode = {
    popupForm: 1,
    inline: 2
};

class GridViewInternal extends React.Component {
    constructor(props) {
        super(props);

        this.state = Object.assign(
            {},
            {
                sortFieldName: props.sortFieldName,
                sortAsc: props.sortAsc,
                activePage: props.activePage,
                activePageSize: props.activePageSize,
                pageSize: props.pageSize,
                editorVisible: false,
                currentRow: -1,
                addMode: 0
            },
            props.storeGridViewSettings ? props.gridViewSettings : {}
        );

        this.sliceOperation = false;
        this.visibleField = null;

        this.contentContainerRef = React.createRef();

        this.onSort = this.onSort.bind(this);
        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.onShowNextPage = this.onShowNextPage.bind(this);
        this.onAddRow = this.onAddRow.bind(this);
        this.onEditRow = this.onEditRow.bind(this);
        this.onSaveRow = this.onSaveRow.bind(this);
        this.onSaveRowButtonClick = this.onSaveRowButtonClick.bind(this);
        this.onSaveRowAdd = this.onSaveRowAdd.bind(this);
        this.onSaveRowAddButtonClick = this.onSaveRowAddButtonClick.bind(this);
        this.onDeleteRow = this.onDeleteRow.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onCancelAdd = this.onCancelAdd.bind(this);
        this.renderRowDefault = this.renderRowDefault.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.props.dataItem.rawData !== nextProps.dataItem.rawData
            || this.state !== nextState
            || this.props.dataItem.inOperation !== nextProps.dataItem.inOperation
            || this.props.dataItem.isInitialized !== nextProps.dataItem.isInitialized
            || this.props.mobileMode !== nextProps.mobileMode
            || this.props.readOnly !== nextProps.readOnly
            || this.props.overrideTitle !== nextProps.overrideTitle
            || this.props.updateStateFlag !== nextProps.updateStateFlag);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.sliceOperation)
            return false;

        if (this.props.dataItem.isInitialized) {
            let range = this.getRangeOfIndicesOfRows(this.props.allowPagination, this.state.activePageSize, this.state.activePage, this.props.dataItem.rawData.Rows);
            this.sliceOperation = true;
            this.props.dispatch(this.props.dataItem.ensureRows(range.startRow, range.endRow))
                .finally(()=>this.sliceOperation = false);
        }
    }

    showAllRows = async() => {
        let rowsCount = this.props.dataItem.rowsCount();
        if (this.props.allowPagination && this.state.activePageSize < rowsCount) {
            await this.props.dispatch(this.props.dataItem.ensureRows(0, rowsCount - 1));

            const settings = {activePageSize: rowsCount, activePage: 1};
            this.setState(settings);
            this.saveSettings(settings);
        }
    };

    onAddRow(e) {
        e.preventDefault();

        const callback = async() => {
            //при кастомной форме редактирования операции с dataItem должны быть внутри формы
            if (!this.props.renderAddForm) {
                await this.props.dispatch(this.props.dataItem.beginAdd());
            }

            if (!this.props.dataItem.isError) {
                this.setState({editorVisible: true, currentRow: this.props.dataItem.rowInEditIndex, addMode: true});

                if (this.props.rowEditMode === GridRowEditMode.inline) {
                    await this.showAllRows();

                    //scroll to editing row
                    let tr = document.getElementsByClassName("gridView-inlineEdit-editingRow");
                    if (tr.length === 1) {
                        scrollIntoView(tr[0], {scrollMode: "if-needed", block: "nearest"});
                    }
                }
            }
        };

        if (this.props.rowEditMode === GridRowEditMode.inline && this.props.dataItem.rowInEditIndex !== -1) {
            this.onSaveRow(callback);
        }
        else {
            callback();
        }
    }

    onEditRow(rowIndex) {
        //при кастомной форме редактирования операции с dataItem должны быть внутри формы
        if (!this.props.renderEditForm) {
            this.props.dispatch(this.props.dataItem.beginEdit(rowIndex));
        }
        this.setState({editorVisible: true, currentRow: rowIndex, addMode: false});
    }

    onSaveRowButtonClick(e) {
        e.preventDefault();
        this.onSaveRow();
    }

    onSaveRow(onSuccess) {
        this.props.dispatch(this.props.dataItem.save(this.state.currentRow))
            .then(async() => {
                if (!this.props.dataItem.isError) {
                    this.hideEditor();
                    if (onSuccess) {
                        await onSuccess();
                    }

                    if (this.props.afterEditRow) {
                        await this.props.afterEditRow();
                    }
                }
            });
    }

    onSaveRowAddButtonClick(e) {
        e.preventDefault();
        this.onSaveRowAdd();
    }

    onSaveRowAdd(onSuccess) {
        this.props.dispatch(this.props.dataItem.saveAdd(this.state.currentRow))
            .then(async(data) => {
                if (!this.props.dataItem.isError) {
                    this.hideEditor();
                    if (onSuccess) {
                        await onSuccess();
                    }

                    if (this.props.afterAddRow) {
                        await this.props.afterAddRow();
                    }
                }
            });
    }

    onDeleteRow(rowIndex) {
        confirm(i18next.t('DeleteRowQ'))
            .then(() => this.props.dispatch(this.props.dataItem.deleteRow(rowIndex)))
            .then(async () => {
                if (!this.props.dataItem.isError) {
                    this.hideEditor();

                    if (this.props.afterDeleteRow) {
                        await this.props.afterDeleteRow();
                    }
                }
            })
            .catch(() => {});
    }

    onCancelEdit() {
        this.hideEditor();
        this.props.dispatch(this.props.dataItem.cancelEdit());
    }

    onCancelAdd = async() => {
        this.hideEditor();
        await this.props.dispatch(this.props.dataItem.cancelAdd());

        if (this.props.rowEditMode === GridRowEditMode.inline && this.state.activePageSize > this.props.dataItem.rowsCount()) {
            const settings = {activePageSize: this.props.dataItem.rowsCount()};
            this.setState(settings);
            this.saveSettings(settings);
        }
    };

    hideEditor = () => {
        this.setState({editorVisible: false});
    };

    refreshDataItem = async() => {
        try {
            await this.props.dispatch(this.props.dataItem.refresh());
        }
        catch (e) {
            console.log(e);
        }
    };

    onSort(fieldInfo) {
        let realFieldInfo = fieldInfo;
        if (this.props.getRealFieldInfo) {
            realFieldInfo = this.props.getRealFieldInfo({dataItem: this.props.dataItem, fieldInfo});
        }
        const callback = async() => {
            let sortAsc = this.state.sortFieldName === fieldInfo.Name ? !this.state.sortAsc : true;
            let sortFieldName = fieldInfo.Name;


            await this.props.dispatch(this.props.dataItem.sortRows(realFieldInfo.Name, sortAsc));
            const settings = {sortFieldName: sortFieldName, sortAsc: sortAsc, activePage: 1};
            this.setState(settings);

            if (this.props.onAfterSort) {
                await this.props.onAfterSort({
                    fieldName: sortFieldName,
                    realFieldName: realFieldInfo.Name,
                    asc: sortAsc
                });
            }

            this.saveSettings(settings);
        };

        if (this.props.rowEditMode === GridRowEditMode.inline && this.props.dataItem.rowInEditIndex !== -1) {
            this.onSaveRow(callback);
        }
        else {
            callback();
        }
    }

    onExpand = (rowIndex) => {
        this.props.dispatch(this.props.dataItem.treeOperation(rowIndex, true));
    };

    onCollapse = (rowIndex) => {
        this.props.dispatch(this.props.dataItem.treeOperation(rowIndex, false));
    };

    saveSettings(settings) {
        this.props.dispatch(userSettingsSave(GRIDVIEW_SETTINGS_PREFIX + this.props.settingsKey + this.props.dataItem.getFullName(), settings));
    }

    getRangeOfIndicesOfRows(allowPagination, activePageSize, activePageNumber, RowCount) {

        if (!allowPagination)
            return {
                startRow: 0,
                endRow: RowCount - 1
            };

        return {
            startRow: 0,
            endRow: Math.min(activePageSize, RowCount) - 1
        }
    }

    onShowNextPage() {
        const callback = () => {
            let activePageSize = this.state.activePageSize + this.state.pageSize;
            activePageSize = activePageSize > this.props.dataItem.rawData.Rows ? this.props.dataItem.rawData.Rows : activePageSize;

            const activePage = 1;
            const settings = {activePageSize: activePageSize, activePage: activePage};

            const range = this.getRangeOfIndicesOfRows(this.props.allowPagination, activePageSize, activePage, this.props.dataItem.rawData.Rows);

            this.props.dispatch(this.props.dataItem.ensureRows(range.startRow, range.endRow))
                .then(data => {
                    this.setState(settings);
                    this.saveSettings(settings);
                });
        };

        if (this.props.rowEditMode === GridRowEditMode.inline && this.props.dataItem.rowInEditIndex !== -1) {
            this.onSaveRow(callback);
        }
        else {
            callback();
        }
    }

    get visibleFields() {
        const self = this;

        const getVisibleFields = (resultFieldInfo) => {
            const ord = (fi) => self.props.overrideOrder[fi.Name] || fi.ViewNumber;

            let result = resultFieldInfo
                .filter(function (fieldInfo) {
                    if (self.props.visibleFields.length > 0 && self.props.visibleFields.indexOf(fieldInfo.Name) < 0)
                        return false;

                    if (self.props.overrideVisibility[fieldInfo.Name] !== undefined) {
                        return !!self.props.overrideVisibility[fieldInfo.Name];
                    }

                    return !self.props.dataItem.getFieldIsHidden(fieldInfo);
                });

            //для 2-строчного заголовка, чтобы скрыть объединяющую ячейку, если скрыты все вложенные
            if (result.some(fi => fi.Action && fi.Action.Unite)) {
                result = result.filter(fi => {
                    if (!fi.Action || !fi.Action.Unite) {
                        return true;
                    }

                    return fi.Action.Unite.split(',').some(name => result.some(vf => vf.Name === name));
                });
            }

            result.sort(function (a, b) {
                return ord(a) - ord(b);
            });

            return result;
        };

        if (self.visibleFieldKey!==self.props.dataItem.rawData.ResultFieldInfo) {
            self.visibleField = getVisibleFields(self.props.dataItem.rawData.ResultFieldInfo);
            self.visibleFieldKey = self.props.dataItem.rawData.ResultFieldInfo;
        }

        return self.visibleField;
    }

    onPageSizeChanged(event) {
        const pageSize = parseInt(event.target.value, 10);
        const activePage = 1;

        const settings = {pageSize: pageSize, activePage: activePage};
        this.setState(settings);

        this.saveSettings(settings);
    }

    getPageCount(allowPagination, rowCount, pageSize) {
        return (allowPagination ? Math.floor((rowCount - 1) / pageSize) + 1 : 1) || 1;
    }

    renderHeader = () => {

        let sortInfo = {FieldName: this.state.sortFieldName, Asc: this.state.sortAsc};

        if (this.props.renderHeader) {
            return this.props.renderHeader(this.props.adaptiveThreshold, this.visibleFields, this.onSort, sortInfo, this.props.readOnly, this.props.dataItem, this.renderHeaderDefault, this.props.overrideTitle);
        }

        return this.renderHeaderDefault(this.props.adaptiveThreshold, this.visibleFields, this.onSort, sortInfo, this.props.readOnly, this.props.dataItem, this.props.overrideTitle, this.props.renderHeaderCell);
    };

    renderHeaderDefault = (adaptiveThreshold, visibleFields, onSort, sortInfo, readOnly, dataItem, overrideTitle, renderHeaderCell) => {

        if (this.props.mobileMode)
            return (<GridViewHeaderDropdown resultFieldInfo={visibleFields} onSort={onSort} sortInfo={sortInfo} overrideTitle={overrideTitle} updateStateFlag={this.props.updateStateFlag}/>);

        let onHeaderClick = this.props.onHeaderClick;
        if (this.props.rowEditMode === GridRowEditMode.inline) {
            onHeaderClick = (e) => {
                e.preventDefault();
                if (this.props.dataItem.rowInEditIndex !== -1) {
                    this.onSaveRow(() => {
                        if (this.props.onHeaderClick) {
                            this.props.onHeaderClick();
                        }
                    });
                }
            };
        }

        return (<GridViewHeader resultFieldInfo={visibleFields} onSort={onSort} readOnly={readOnly}
                                sortInfo={sortInfo}
                                dataItem={dataItem} overrideTitle={overrideTitle}
                                contentContainerRef={this.props.fixHeader ? this.contentContainerRef : null}
                                headerStyleProvider={this.props.headerStyleProvider}
                                updateStateFlag={this.props.updateStateFlag}
                                onHeaderClick={onHeaderClick}
                                renderHeaderCell={renderHeaderCell}
                                headerCellStyleProvider={this.props.headerCellStyleProvider}
        />)
    }

    renderBody() {
        let range = this.getRangeOfIndicesOfRows(this.props.allowPagination, this.state.activePageSize, this.state.activePage, this.props.dataItem.rawData.Rows);
        let visibleRows = this.props.dataItem.rawData.DataTable.slice(range.startRow, range.endRow + 1);

        for (let i = visibleRows.length; i <= range.endRow - range.startRow; i++) {
            visibleRows[i] = null;
        }

        return this.props.flexBox
          ? (<FlexRowsWrapper>
                {visibleRows.map((row, rowIndex) => { return this.renderRow(row, rowIndex + range.startRow); }, this)}
             </FlexRowsWrapper>)
          : (<RowsWrapper>
                {visibleRows.map((row, rowIndex) => {return this.renderRow(row, rowIndex + range.startRow); }, this)}
             </RowsWrapper>);
    }

    renderRow(row, rowIndex) {
        if (this.props.renderRow) {
            return this.props.renderRow(row, rowIndex, this.renderRowDefault, this.props.dataItem, this.props.cellAttributes, this.props.onCellClick, this.visibleFields, this.props.mobileMode);
        }
        else if (this.props.renderRowEx)
        {
            const rowParams = this.getRowParams(row, rowIndex);
            return this.props.renderRowEx(rowParams);
        }

        return this.renderRowDefault(row, rowIndex);
    }

    renderRowDefault(row, rowIndex) {
        return this.renderRowFromParams(this.getRowParams(row, rowIndex));
    }

    renderRowFromParams = (rowParams) => {
        return <GridViewRow {...rowParams} />;
    };

    getRowParams(row, rowIndex)
    {
        const rowParams = {
            key:rowIndex,
            row:row,
            rowIndex:rowIndex,
            resultFieldInfo:this.visibleFields,
            renderCell:this.props.renderCell,
            renderCellEx:this.props.renderCellEx,
            dataItem:this.props.dataItem,
            adaptiveThreshold:this.props.adaptiveThreshold,
            adaptiveMaxColumns:this.props.adaptiveMaxColumns,
            onEditRow:this.onEditRow,
            onDeleteRow:this.onDeleteRow,
            onSaveRow: this.props.dataItem.isAddRow ? this.onSaveRowAdd : this.onSaveRow,
            onCancelEditRow: this.props.dataItem.isAddRow ? this.onCancelAdd : this.onCancelEdit,
            onRowClick:this.props.onRowClick,
            rowCursor:this.props.rowCursor,
            readOnly:this.props.readOnly || (this.props.dataItem.rawData.ResultRowInfo && this.props.dataItem.rawData.ResultRowInfo[rowIndex] && this.props.dataItem.rawData.ResultRowInfo[rowIndex].ReadOnly),
            mobileMode:this.props.mobileMode,
            cellAttributesthis:this.props.cellAttributes,
            mobileHeaderVisibility:this.props.mobileHeaderVisibility,
            overrideTitle:this.props.overrideTitle,
            overrideVisibility:this.props.overrideVisibility,
            onExpand:this.onExpand,
            onCollapse:this.onCollapse,
            updateStateFlag:this.props.updateStateFlag,
            onShowSubgrid:this.initSubgrid,
            customFieldExMap:this.props.customFieldExMap,
            onCellClick:this.props.onCellClick,
            rootPath:this.props.rootStore,
            rowEditMode: this.props.rowEditMode,
            rowStyleProvider:this.props.useItemStyle ? DrawAttributeProvider.rowStyleProvider :  this.props.rowStyleProvider,
            cellStyleProvider:this.props.useItemStyle ? DrawAttributeProvider.cellStyleProvider :this.props.cellStyleProvider,
            renderRowDefault: this.renderRowDefault,
            renderRowFromParams: this.renderRowFromParams,
            enableCellLinesLimit: this.props.enableCellLinesLimit,
            cellLongTextMaxLength: this.props.cellLongTextMaxLength,
            pageHeaderHeight: this.props.pageHeaderHeight,
            isCollapseByRowClick: this.props.isCollapseByRowClick,
            renderSubgridRowEx: this.props.renderSubgridRowEx,
            showSubgridHeader: this.props.showSubgridHeader,
            maxSubgridWidthBody: this.props.maxSubgridWidthBody
        };

        return rowParams;
    }

    renderEditor() {

        if (!this.state.editorVisible || this.props.rowEditMode !== GridRowEditMode.popupForm)
            return null;

        if ((this.props.renderEditForm && !this.state.addMode)
            || (this.props.renderAddForm && this.state.addMode)
        ) {
            const params = {
                dataItem: this.props.dataItem,
                row: !this.state.addMode ? this.props.dataItem.getRow(this.state.currentRow) : null,
                customFieldExMap: this.props.customFieldExMap,
                refreshDataItem: this.refreshDataItem,
                hideEditor: this.hideEditor
            };

            return this.state.addMode? this.props.renderAddForm(params) : this.props.renderEditForm(params);
        }

        const self = this;

        const renderButtons = function () {
            return (<div className="modal-footer text-right">
                <div className="text-right">
                    <button className="btn btn-regular"
                            onClick={self.state.addMode ? self.onSaveRowAddButtonClick : self.onSaveRowButtonClick}>{i18next.t('Save')}
                    </button>
                    &nbsp;
                    <button className="btn btn-regular"
                            onClick={self.state.addMode ? self.onCancelAdd : self.onCancelEdit}>{i18next.t('Cancel')}
                    </button>
                </div>
            </div>);
        };

        return (
            <PopupWrapper popupVisible title={i18next.t('Edit')}
                          onClose={this.state.addMode ? this.onCancelAdd : this.onCancelEdit}
                          renderButtons={renderButtons}>
                <ComplexParam dataItem={this.props.dataItem} rootPath={this.props.rootStore}
                              renderParams={this.paramRender}
                              rowIndex={this.state.currentRow}
                              overrideVisibility={this.props.overrideVisibilityEdit}
                              customFieldExMap={this.props.customFieldExMap}
                />
            </PopupWrapper>
        );
    }

    paramRender = (h) => {

        return (<div className="row">
            <div className="col-md-12">
                {h.fs.map((fi) => {

                    const fn = fi.Name;

                    return (<React.Fragment key={fn}>

                        <div className="row">
                            <div className="col-md-3 field-title">
                                {h.lbl(fn)}
                            </div>
                            <div className="col-md-9">
                                {h.ed(fn)}
                            </div>
                        </div>
                        <hr/>
                    </React.Fragment>)
                })}

            </div>
        </div>)
    };

    renderPagerMore() {
        const rowsToShow = this.state.activePageSize + this.state.pageSize > this.props.dataItem.rawData.Rows ? this.props.dataItem.rawData.Rows - this.state.activePageSize : this.state.pageSize;
        if (rowsToShow <= 0) {
            return null;
        }

        const button = (
            <button className="btn btn-regular -wide -wide100p"
                    onClick={this.onShowNextPage}>
                {`${i18next.t('ShowMeMore')} ${rowsToShow} ${i18next.t('ShowMeMoreof')} ${this.props.dataItem.rawData.Rows}`}
            </button>
        );

        const hiddenButton = (
            <span>&nbsp;</span>
        );

        const pageControl = this.props.autoLoadRows
            ? (
                <IntersectionObserverWrapper onShow={this.onShowNextPage}>
                    {this.props.showMoreRowsBtnWhenAutoLoad ? button : hiddenButton}
                </IntersectionObserverWrapper>
            )
            : button;

        return (
            <div className="col-12">
                {pageControl}
            </div>
        );
    }

    render() {

        if (!this.props.dataItem.isInitialized && this.props.dataItem.inOperation)
            return (<div>{i18next.t('LoadingLabel')}</div>);
        else if (!this.props.dataItem.isInitialized && !this.props.dataItem.inOperation)
            return (<div>{i18next.t('GridViewEmptyLabel')}</div>);

        const canInsert = this.props.dataItem.getCanInsert() && !this.props.readOnly;
        const showPager = this.props.allowPagination && this.state.activePageSize < this.props.dataItem.rawData.Rows;

        let gridContainerStyle = {};
        if (this.props.allowHorizontalScrolling) {
            gridContainerStyle.overflowX = "auto";
        }
        if (this.props.fixHeader) {
            gridContainerStyle.overflowY = "auto";
        }
        gridContainerStyle = {
            ...gridContainerStyle,
            ...(this.props.contentContainerStyleProvider({canInsert, showPager, mobileMode: !!this.props.mobileMode}) || {})
        };

        return (
            <div key={this.props.dataItem.methodName}>
                {GlobalDebugMode && this.props.dataItem.methodName}
                {GlobalDebugMode && " Editable: " + JSON.stringify(!this.props.readOnly)}
                {this.renderEditor()}
                <div ref="GridViewBlock" className="gridview">
                  <div ref={this.contentContainerRef} style={gridContainerStyle}>
                    {this.props.flexBox ? (
                      <FlexBodyWrapper flexBoxWidthPercent = {this.props.flexBoxWidthPercent} style={this.props.bodyStyle}>
                        {this.props.showHeader ? this.renderHeader() : null}
                        {this.renderBody()}
                      </FlexBodyWrapper>
                    ) : (
                      <BodyWrapper style={this.props.bodyStyle}
                                   addClassName={!!this.props.fixHeader && !this.props.mobileMode ? "table-header-sticky" : ""}>
                          {this.props.showHeader ? this.renderHeader() : null}
                          {this.renderBody()}
                      </BodyWrapper>
                    )}

                    {window.config.debug && (<span>{this.props.dataItem.methodName}</span>)}

                    {this.props.dataItem.rowsCount() === 0 ?
                        <div className="text-muted"
                             style={{textAlign: 'center'}}>{i18next.t('GridViewEmptyLabel')}</div>
                        :
                        null
                    }

                    {(this.props.autoLoadRows && showPager) && this.renderPagerMore()}
                  </div>

                  { (canInsert || (!this.props.autoLoadRows && showPager)) &&
                      <div>
                          {canInsert &&
                          <button type="button" className="btn btn-regular -godefault mt-1" onClick={this.onAddRow} disabled={this.props.dataItem.isAddRow}><span
                            className="fa fa-plus"/>&nbsp;{this.props.addButtonText || i18next.t('Add')}</button>}
                          &nbsp;
                          {showPager && this.renderPagerMore()}
                      </div>
                  }
                </div>
            </div>
        );
    }
}

function BodyWrapper(props) {
    const className = `table table-hover table-condensed table-scroll ${props.addClassName}`;
    return <table className={className} style={props.style}>{props.children}</table>
}

function FlexBodyWrapper(props) {
    let style = Object.assign({}, {width: `${props.flexBoxWidthPercent}%`, margin: "auto"}, props.style);
    return <div style={style}>{props.children}</div>
}

function RowsWrapper(props) {
    return <tbody>{props.children}</tbody>
}

function FlexRowsWrapper(props) {
    return <div className="table-flex-custom" style={{justifyContent: "flex-start"}}>{props.children}</div>
}

export class GridView extends React.Component {
    render() {
        const customProps = getCustomizationByMethodName(this.props.dataItem.methodName, this.props.customObject);

        return <ScreenFormatContext.Consumer>
            {isDesktop => {
                customProps["mobileMode"] = !isDesktop && !this.props.useOnlyDesktopMode;
                return (<GridViewInternal {...this.props} {...customProps} />)
            }}</ScreenFormatContext.Consumer>
    }
}

GridView.defaultProps = {
    showHeader: true,
    allowPagination: false,
    activePage: 1,
    activePageSize: 10,
    pageSize: 10,
    scrollIntoViewOffset: 60,
    adaptiveThreshold: 1000,
    storeGridViewSettings: true,
    readOnly: false,
    adaptiveMaxColumns: 2,
    rowCursor: "auto",

    onRowClick: () => {
    },
    settingsKey: "",
    customObject: null,
    visibleFields: [],
    mobileMode: false,
    useOnlyDesktopMode: false,
    rowEditMode: GridRowEditMode.popupForm,

    cellAttributes: {},
    overrideVisibility: {},
    overrideVisibilityEdit : {},
    overrideOrder: {},
    mobileHeaderVisibility: {},
    overrideTitle : {},
    flexBox: false,
    flexBoxWidth: 100,
    useItemStyle : false,
    addButtonText : null,
    enableCellLinesLimit: false,
    cellLongTextMaxLength: null,
    pageHeaderHeight: null,
    headerStyleProvider: () => ({}),
    allowHorizontalScrolling: true,
    fixHeader: false,
    contentContainerStyleProvider: () => ({}),
    autoLoadRows: false,
    showMoreRowsBtnWhenAutoLoad: true,
    headerCellStyleProvider: () => ({})
};

GridView.propTypes = {
    dataItem: PropTypes.object.isRequired,

    renderHeader: PropTypes.func,
    renderRow: PropTypes.func,
    renderRowEx: PropTypes.func,
    renderCell: PropTypes.func,
    renderHeaderCell: PropTypes.func,
    renderAddForm: PropTypes.func,
    renderEditForm: PropTypes.func,

    showHeader: PropTypes.bool,
    visibleFields: PropTypes.array,
    dispatch: PropTypes.func,
    activePage: PropTypes.number,
    pageSize: PropTypes.number,
    activePageSize: PropTypes.number,
    allowPagination: PropTypes.bool,
    sortFieldName: PropTypes.string,
    sortAsc: PropTypes.bool,
    scrollIntoViewOffset: PropTypes.number,
    gridViewSettings: PropTypes.object,
    adaptiveThreshold: PropTypes.number,
    storeGridViewSettings: PropTypes.bool,
    readOnly: PropTypes.bool,
    rootStore: PropTypes.string,
    adaptiveMaxColumns: PropTypes.number,
    onRowClick: PropTypes.func,
    onHeaderClick: PropTypes.func,
    rowCursor: PropTypes.string,
    settingsKey: PropTypes.string,
    mobileMode: PropTypes.bool,
    useOnlyDesktopMode: PropTypes.bool,
    rowEditMode: PropTypes.number,

    cellAttributes: PropTypes.object,
    overrideVisibility: PropTypes.object,
    overrideVisibilityEdit: PropTypes.object,
    overrideOrder: PropTypes.object,
    overrideTitle: PropTypes.object,
    mobileHeaderVisibility : PropTypes.object,
    updateStateFlag: PropTypes.any,
    onCellClick: PropTypes.func,
    flexBox: PropTypes.bool,
    flexBoxWidthPercent: PropTypes.number,
    rowStyleProvider: PropTypes.func,
    cellStyleProvider: PropTypes.func,
    headerStyleProvider: PropTypes.func,
    useItemStyle: PropTypes.bool,
    bodyStyle: PropTypes.object,
    addButtonText: PropTypes.string,
    renderCellEx: PropTypes.func,
    customFieldExMap: PropTypes.object,
    enableCellLinesLimit: PropTypes.bool,
    cellLongTextMaxLength: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    allowHorizontalScrolling: PropTypes.bool,
    /**
     * При использовании fixHeader необходимо управлять высотой контейнера с помощью contentContainerStyleProvider
     */
    fixHeader: PropTypes.bool,
    contentContainerStyleProvider: PropTypes.func,
    autoLoadRows: PropTypes.bool,
    showMoreRowsBtnWhenAutoLoad: PropTypes.bool,

    afterAddRow: PropTypes.func,
    afterEditRow: PropTypes.func,
    afterDeleteRow: PropTypes.func,
    getRealFieldInfo: PropTypes.func,
    onAfterSort: PropTypes.func,

    isCollapseByRowClick: PropTypes.bool,
    showSubgridHeader: PropTypes.bool,
    renderSubgridRowEx: PropTypes.func,
    maxSubgridWidthBody: PropTypes.string,
    headerCellStyleProvider: PropTypes.func
};

GridViewInternal.defaultProps = GridView.defaultProps;
GridViewInternal.propTypes = GridView.propTypes;

function mapStateToProps(state, ownProps) {
    const settingsPath = GRIDVIEW_SETTINGS_PREFIX + (ownProps.settingsKey ? ownProps.settingsKey : "") + ownProps.dataItem.getFullName();

    return {
        gridViewSettings: state.userSettingsState[settingsPath]
    };
}

export default connect(mapStateToProps)(GridView);