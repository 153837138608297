import * as types from '../actions/operationActions';

export default function inOperationReducer(state = false, action) {
    if (action.type === types.OPERATION_STARTED) {
        return true
    }
    else if (action.type === types.OPERATION_COMPLETED) {
        return false
    }

    return state;
}