import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class ValueIndicator extends Component {

  constructor(props, context) {
    super(props, context);
  }

  render() {

    if (this.props.value === undefined || this.props.value == null)
      return null;

    const colorValue = this.props.map[this.props.value];
    const controlStyle = this.props.onClick ? {display: 'inline', cursor: 'pointer'} : {display: 'inline'};

    if (colorValue === undefined)
      return null;

    if (this.props.map)
      return (<div style={controlStyle} onClick={this.props.onClick}>
          <i className={"fas fa-traffic-light fa-" + (this.props.size) + "x"} style={{ color: colorValue }}></i>
            {this.props.showValue && <>&nbsp;{this.props.value}</>}
            {this.props.showText && <>&nbsp;{this.props.text}</>}
        </div>
      )
  }
}

ValueIndicator.propTypes = {
  map: PropTypes.object,
  value: PropTypes.number,
  text: PropTypes.string,
  showValue: PropTypes.bool,
  showText: PropTypes.bool,
  size: PropTypes.number,
  onClick: PropTypes.func
};

ValueIndicator.defaultProps = {
  map: {'-1': '#6c757d', '0': '#343a40', '1': '#28a745', '2': '#ffc107', '3': '#dc3545'},
  value: null,
  text: null,
  showValue: false,
  showText: false,
  size: 1,
  onClick: null
};

export default ValueIndicator;