import React from "react";

export default function paramRender(h) {

    return (<div className="row">
        <div className="col-md-12">
            {h.fs.map((fi) => {

                const fn = fi.Name;

                return (<React.Fragment key={fn}>

                    <div className="row">
                        <div className="col-md-3 field-title">
                            {h.lbl(fn)}
                        </div>
                        <div className="col-md-9">
                            {h.ed(fn)}
                        </div>
                    </div>
                    <hr/>
                </React.Fragment>)
            })}

        </div>
    </div>)
};