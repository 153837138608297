import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {initSubgrid, reinitializeSubgrid, unmountSubgrid} from "@mnjsplatform/data/lib/actions/subgridActions";
import SubgridPlaceholder from "../Subgrid/SubgridPlaceholder";

class MultiSelectWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subgridKey: null
    };
  }

  async componentWillUnmount() {
    await this.unmount();
  }

  initSubgrid = async() => {
    await this.props.initComponent();
    if (!this.state.subgridKey) {
      const key = await this.props.dispatch(initSubgrid(
        this.props.parentDataItem,
        this.props.fieldInfo.Name,
        this.props.rowIndex,
        this.props.rootPath,
        this.props.readOnly,
        this.props.withSubgridParams
      ));
      this.setState({ subgridKey: key });
    }
  };

  fillSubgrid = async(toSelect) => {
    const parameters = {
      ToFillSSet: toSelect ? 1 : 0
    };
    await this.props.dispatch(reinitializeSubgrid(this.props.parentDataItem, this.state.subgridKey, parameters));
  };

  unmount = async() => {
    if (this.props.unmountComponent) {
      await this.props.unmountComponent();
    }
    if (this.state.subgridKey) {
      await this.props.dispatch(unmountSubgrid(this.props.parentDataItem, this.state.subgridKey));
    }
  };

  render() {
    return (
      <SubgridPlaceholder subgridKey={this.state.subgridKey}
                          alwaysRenderChildren={true}
      >
        {(subgridItem, subgridItemParams) => this.props.children({
          ...this.props,
          subgridItem,
          subgridItemParams,
          init: this.initSubgrid,
          fill: this.fillSubgrid
        })}
      </SubgridPlaceholder>
    );
  }
}

MultiSelectWrapper.propTypes = {
  initComponent: PropTypes.func.isRequired,
  unmountComponent: PropTypes.func,
  dispatch: PropTypes.func,
  parentDataItem: PropTypes.object.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  rootPath: PropTypes.string,
  readOnly: PropTypes.bool,
  withSubgridParams: PropTypes.bool
};

MultiSelectWrapper.defaultProps = {
  withSubgridParams: false
};

export default connect()(MultiSelectWrapper);