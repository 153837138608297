import PropTypes from 'prop-types';
import React from 'react';
import formatData from '@mnjsplatform/data/lib/utils/formatData';
import editorType from '@mnjsplatform/data/lib/utils/editorType';
import i18next from "i18next";
import SubgridInline from "../Subgrid/SubgridInline";
import FileDownloadHandle from "../InputField/FileDownloadHandle";
import $ from "jquery";
import "bootstrap";
import ExtLink from "../InputField/ExtLink";
import HtmlCleanup from "../HtmlCleanup";
import LongTextWrapper from "../LongTextWrapper";
import ValueIndicator from "../ValueIndicator";

class GridViewCell extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.cellRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.enableCellLinesLimit && this.cellRef.current) {
            let el = this.cellRef.current;
            if (el.scrollHeight > el.offsetHeight) {
                let originalText = el.innerHTML;

                el.title = originalText;
                $(el).tooltip({delay: {show: 500, hide: 200}});

                let wordArray = originalText.split(' ');
                while (el.scrollHeight > el.offsetHeight) {
                    wordArray.pop();
                    el.innerHTML = wordArray.join(' ') + '...';
                }
            }
        }
    }

    render() {
        if (!this.props.row || !this.props.fieldInfo) {
            return <span>&nbsp;</span>;
        }

        let fieldInfo = this.props.fieldInfo;
        let value = this.props.row[fieldInfo.Name];
        const readOnly = this.props.readOnly || this.props.dataItem.getFieldIsReadOnly(fieldInfo, this.props.rowIndex);
        //Y!!! const style = Object.assign({}, this.props.cellStyleProvider(this.props.dataItem, this.props.rowIndex, fieldInfo));
        const style = {};

        let control = this.props.formattedValue || formatData(fieldInfo, value);

        if (fieldInfo.EditorType === editorType.Enum && fieldInfo.EnumInfo === "*YESNO:?,0,1") {
            control = (value === null ? "" : !!value ? i18next.t('Yes') : i18next.t('No'));
        }
        else if (fieldInfo.EditorType === editorType.Enum && fieldInfo.EnumInfo && fieldInfo.EnumInfo.includes("SEMAPHORE")) {
            control = (value === null ? "" : <ValueIndicator value={value} map = {{'-1': '#6c757d', '1': '#28a745', '2': '#ffc107', '3': '#dc3545'}} />);
        }
        else if (fieldInfo.EditorType === editorType.ComboBox || fieldInfo.EditorType === editorType.Enum) {
            if (fieldInfo.EditorInfo) {
                  const val = fieldInfo.EditorInfo.enumList.find(i => value != null && i.DBValue === value.toString());
                  if (val)
                    control = val.ENValue;
            }
        }
        else if (fieldInfo.EditorType === editorType.EditForm && fieldInfo.EditorObject && fieldInfo.EditorObject.startsWith("WebFile")){
            return <FileDownloadHandle fieldInfo={this.props.fieldInfo} rowIndex={this.props.rowIndex} dataItem={this.props.dataItem} formattedVal={control} />
        }
        else if (fieldInfo.EditorType === editorType.EditForm && fieldInfo.EditorObject === "WebCustomTemplate" && fieldInfo.EditorMethod === ("ExtLink")){
            return <ExtLink fieldInfo={this.props.fieldInfo} rowIndex={this.props.rowIndex} dataItem={this.props.dataItem} formattedVal={control} value={value} readOnly />
        }
        else if (fieldInfo.EditorType === editorType.EditForm && fieldInfo.EditorObject === "WebCustomTemplate" && fieldInfo.EditorMethod === ("HtmlCleanup")){
            return <HtmlCleanup value={value}/>;
        }
        else if (fieldInfo.EditorType === editorType.EditForm && fieldInfo.EditorObject === "WebCustomTemplate" && this.props.customFieldExMap) {
            let fieldEx = this.props.customFieldExMap[fieldInfo.EditorMethod];
            if (fieldEx) {
                control = fieldEx({
                    dataItem: this.props.dataItem,
                    fieldInfo: fieldInfo,
                    rowIndex: this.props.rowIndex,
                    row: this.props.row,
                    readOnly: this.props.readOnly,
                    value: value
                });
            }
        } else {
            if (fieldInfo.EditorType === editorType.EditSubGrid) {
                return <SubgridInline fieldInfo={this.props.fieldInfo}
                                      readOnly={readOnly}
                                      rowIndex={this.props.rowIndex}
                                      dataItem={this.props.dataItem}
                                      path={`${this.props.rootPath}_${fieldInfo.Name}`}
                                      formattedVal={control}
                                      renderRowEx={this.props.renderSubgridRowEx}
                                      showHeader={this.props.showSubgridHeader}
                                      maxWidthBody={this.props.maxSubgridWidthBody}
                />
            }
        }

        let cellAttributes = {...this.props.cellAttributes};
        if (this.props.enableCellLinesLimit) {
            cellAttributes.className = (cellAttributes.className || "") + " line-clamp line-clamp-2";
            cellAttributes["data-toggle"] = "tooltip";
        }

        return (
            <span ref={this.cellRef} {...cellAttributes} style={style}>
                {
                    typeof control === 'string' && this.props.cellLongTextMaxLength
                    && <LongTextWrapper charCount={this.props.cellLongTextMaxLength} pageHeaderHeight={this.props.pageHeaderHeight} text={control} />
                    || control
                }
            </span>
        );
    }
}

GridViewCell.defaultProps = {
    cellAttributes: {},
    cellStyleProvider : () => {{}}
};

GridViewCell.propTypes = {
    fieldInfo: PropTypes.object.isRequired,
    cellAttributes: PropTypes.object,
    row: PropTypes.object,
    updateStateFlag: PropTypes.any,
    rowIndex : PropTypes.number.isRequired,
    dataItem : PropTypes.object,
    readOnly : PropTypes.bool,
    customFieldExMap: PropTypes.object,
    formattedVal: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    rootPath: PropTypes.string,
    cellStyleProvider: PropTypes.func,
    enableCellLinesLimit: PropTypes.bool,
    cellLongTextMaxLength: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    showSubgridHeader: PropTypes.bool,
    renderSubgridRowEx: PropTypes.func,
    maxSubgridWidthBody: PropTypes.string
};

export default GridViewCell;
