import homeReducer from "./homeReducer";
import loginReducer from "./loginReducer";
import {registrationReducer} from "./registrationReducer";

const rootReducer = {
    appState: homeReducer,
    loginState: loginReducer,
    regState: registrationReducer()
};

export default rootReducer;