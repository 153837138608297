import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { buildReport, getReportParams, unmountReport } from "@mnjsplatform/data/lib/actions/reportActions";
import ReportBuildViewer from "./ReportBuildViewer";
import i18next from "i18next";

class ReportPlaceholder extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isLoading : false
    }
  }

  onClose = () => {
  };

  async componentDidMount() {
    this.setState({isLoading:true});
    try {
      await this.props.dispatch(getReportParams(this.props.reportId, this.props.params, this.props.reportKey));
    }
    finally {
      this.setState({isLoading:false});
    }
  }

  async componentWillUnmount() {
    await this.props.dispatch(unmountReport(this.props.reportKey));
  }

  onBuildReport = async ()=> {
    await this.props.dispatch(this.props.reportParamsItem.save());

    if (!this.props.reportParamsItem.isError)
      await this.props.dispatch(buildReport(this.props.reportId, undefined, this.props.reportKey, true));

  };

  render() {

    if (this.state.isLoading) {
      if (this.props.showLabelOnLoading) {
        return <div>{i18next.t('LoadingLabel')}</div>;
      }
      else {
        return null;
      }
    }

    if (!this.props.reportKey || !this.props.reportParamsItem || !this.props.children)
      return null;

    return <>{this.props.children(this.props.reportParamsItem, this.onBuildReport)}<ReportBuildViewer reportKey={this.props.reportKey}/></>;
  }
}

ReportPlaceholder.propTypes = {
  dispatch: PropTypes.func.isRequired,
  reportId: PropTypes.string.isRequired,
  reportKey: PropTypes.string,
  params: PropTypes.object,
  reportParamsItem : PropTypes.object,
  showLabelOnLoading: PropTypes.bool
};

ReportPlaceholder.defaultProps = {
  reportKey : "defaultReport",
  showLabelOnLoading: true
};

function mapStateToProps(state, ownProps) {

  let reportKey = "defaultReport";

  if (ownProps.reportKey)
    reportKey = ownProps.reportKey;

  if (!state.reportBuildState[reportKey])
    return {};

  const repParamPath = state.reportBuildState[reportKey].paramPath;

  if (!repParamPath)
    return {};

  const reportItem = state.dynState[repParamPath];

  return {
    reportParamsItem: reportItem
  };
}

export default connect(mapStateToProps)(ReportPlaceholder);