import JSONUtils from "./JSONUtils";

/**
 * @param resourcesArr {[]}
 * @param languagesArr {["en", "ru", "uk"]}
 * @returns {{ru: {translation: {}}, en: {translation: {}}, uk: {translation: {}}}}
 */
export default function (resourcesArr, languagesArr = ["en", "ru", "uk"]) {
  const result = {};

  const resources = JSONUtils.mergeObjects(resourcesArr);

  languagesArr.forEach(l => {
    if (resources.hasOwnProperty(l)) {
      result[l] = {
        translation: resources[l]
      };
    }
  });

  return result;
}