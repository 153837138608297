import PropTypes from "prop-types";
import React from "react";
import InputFieldInline from "./InputFieldInline";
import UrProvider from "@mnjsplatform/data/lib/api/UrProvider";
import i18next from "i18next";

const ExtLink = (props) => {

    if (!props.readOnly)
        return <InputFieldInline {...props} />

    const urProcessed = props.dataItem.rawData.ColumnUpdateRules[props.fieldInfo.Name];
    const urProvider = new UrProvider(urProcessed);

    const params = urProvider.getParamsForExecRules(props.dataItem, props.rowIndex);
    const title = params["ExtLinkTitle"] ? params["ExtLinkTitle"] : i18next.t('ExtLinkDefaultTitle');

    return <a href={props.value}>{title}</a>;
};

ExtLink.propTypes = {
    checked: PropTypes.any,
    readOnly: PropTypes.bool,
    rowIndex: PropTypes.number,
    dataItem: PropTypes.object,
    fieldInfo: PropTypes.object,
    onSelected: PropTypes.func,
    value: PropTypes.object
};

export default ExtLink;