import React, {Component} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {errorRaised} from "@mnjsplatform/data/lib/actions/errorActions";
import Spinner from "../Spinner";
import FIAS from "./FIAS";

class SelectorFIAS extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            panelVisible: false
        }
    }

    componentDidMount() {
        console.log("FIAS mount:" + this.props.rootPath);
    }

    componentWillUnmount() {
        console.log("FIAS unmount:" + this.props.rootPath);
    }

    onInit = (reset) => {
        return Promise.resolve();
    }

    onOpenForm = (event) => {
        event.preventDefault();

        this.onInit(true)
          .then(() => this.setState({ panelVisible: true }))
          .catch(error => {
                this.setState({ panelVisible: false });
                this.props.dispatch(errorRaised(error));
            }
          )
    }

    onCloseForm = (changedFields, dismissOnly=false) => {
        if (dismissOnly) {
            this.setState({ panelVisible: false });
            return;
        }
        /*
                FrmItem ctrlKladrAddress = oChoseForm.FindItem("edlKladrAddress");
                FrmItem ctrlIndex = oChoseForm.FindItem("edlIndex");

                oRetValues = new BaseKey(6);
                oRetValues.SetName(0, "KladrCODE");
                oRetValues.SetValue(0, oChoseForm.Params["KladrCODE"]);
                oRetValues.SetName(1, "Location");
                oRetValues.SetValue(1, ctrlKladrAddress.GetData());
                oRetValues.SetName(2, "ZipCode");
                oRetValues.SetValue(2, ctrlIndex.GetData());
                oRetValues.SetName(3, "KladrLocation");
                oRetValues.SetValue(3, oChoseForm.Params["KladrLocation"]);
                oRetValues.SetName(4, "Comment");
                oRetValues.SetValue(4, oChoseForm.Params["Comment"]);
                oRetValues.SetName(5, "FiasGUID");
                oRetValues.SetValue(5, oChoseForm.Params["FiasGUID"]);
                oEditItem.ScriptRow = oRetValues;
        */

        this.props.dispatch(this.props.parentDataItem.setField(this.props.rowIndex, "Location", changedFields.Location))
          .then(() => {
                delete changedFields.Location;
                return this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, changedFields));
            }
          )
          .catch(error => this.props.dispatch(errorRaised(error)))
          .then(() => {
              this.setState({ panelVisible: false });

              if (this.props.onSelected)
                  this.props.onSelected();
          });
    }

    clearSelection = (key) => {
        return clearSelectionEx(key)
    }

    onClearForm = () => {
        this.props.dispatch(this.props.parentDataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, null))
          .catch(error => this.props.dispatch(errorRaised(error)))
          .then(() => {
              this.setState({ panelVisible: false, location: "" });

              if (this.props.onSelected)
                  this.props.onSelected();
          });
    }

    syncSelectedItemsInternal = () => {
        let syncStatus = Promise.resolve();

        if (this.props.classState && this.props.classState.classDescription)
            syncStatus = this.props.dispatch(syncSelectedItems(this.props.classState.classDescription, this.props.rootPath, this.props.rowIndex))
              .catch(error => this.props.dispatch(errorRaised(error)));

        return syncStatus;
    }

    render() {
        const containerStyle = {
                display: 'inline-block',
                position: 'absolute',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                height: '100%',
                zIndex: 2
        };

        let showSpinner = false;
        if(this.props.readOnly){
           return (<span>{this.props.location}</span>);
        }

        let row = this.props.parentDataItem.getRow(this.props.rowIndex);
        return (
            <span>
                {this.state.panelVisible && <FIAS {...this.props} row={row} onClose={this.onCloseForm} onClear={this.clearSelection}/>}
                <div className="input-group classControl">
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.location}
                        disabled
                    />
                    <div onClick={this.onClearForm}><span className="clear zmdi zmdi-close"/></div>
                    <div className="input-group-append">
                        <button
                            className="btn"
                            type="button"
                            onClick={this.onOpenForm}
                        >
                            {showSpinner && <Spinner size={"20px"} colorHex={'FFFFFF'} containerStyle={containerStyle}/>}
                            {showSpinner
                                ? <i className="search zmdi zmdi-view-list-alt" style={{color: 'transparent'}}/>
                                : <i className="search zmdi zmdi-view-list-alt"/>
                            }
                        </button>
                    </div>
                </div>
            </span>
        );
    }
}

SelectorFIAS.propTypes = {
    parentDataItem: PropTypes.object,
    rowIndex: PropTypes.number,
    fieldInfo: PropTypes.object,
    location: PropTypes.string,
    onSelected: PropTypes.func,
    rootPath: PropTypes.string,
    readOnly: PropTypes.bool,
    execMap: PropTypes.string
}

SelectorFIAS.defaultProps = {
    rootPath: ""
};

function mapStateToProps(state, ownProps) {
    return {};
}

export default connect(mapStateToProps)(SelectorFIAS);
