import React from "react";
import PropTypes from "prop-types";
import "../style/ComponentsStyle.css";
import i18next from "i18next";
import {connect} from "react-redux";
import {errorRaised} from "@mnjsplatform/data/lib/actions/errorActions";

class FileDropPanel extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      dragCounter: 0
    };
  }

  get isDragOver() {
    return this.state.dragCounter > 0;
  }

  get allowDragAndDrop() {
    return this.props.enabled && typeof window.FileReader !== 'undefined';
  }

  get dropFileText() {
    return this.props.dropFileText || i18next.t("DropFileText");
  }
  
  get denyDropText() {
    return this.props.denyDropText || i18next.t("DenyDropFileText");
  }

  tryUploadFiles = (files) => {
    this.props.onFilesDropped(files);
  };

  onDragEnter = (e) => {
    e.preventDefault();
    this.setState(state => ({dragCounter: state.dragCounter + 1}));
  };

  onDragLeave = (e) => {
    e.preventDefault();
    this.setState(state => ({dragCounter: state.dragCounter - 1}));
  };

  onDragOver = (e) => {
    e.preventDefault();
  };

  onDrop = (e) => {
    e.preventDefault();
    this.setState({dragCounter: 0});
    if (this.props.allowDropFiles) {
      this.tryUploadFiles(e.dataTransfer.files);
    }
    else {
      this.props.dispatch(errorRaised(this.denyDropText));
    }
  };

  renderContent = () => {
    if (this.props.children) {
      return this.props.children;
    }

    const text = this.allowDragAndDrop
      ? this.dropFileText
      : this.denyDropText;

    return (
      <>
        <div className="dropFile-standardContent">
          {text}
        </div>
      </>
    );
  };

  render() {
    const ddProperties = this.allowDragAndDrop
      ? {
        onDragEnter: this.onDragEnter,
        onDragLeave: this.onDragLeave,
        onDragOver: this.onDragOver,
        onDrop: this.onDrop,
      }
      : {};

    const dropZoneClass = `dropFileZone-hoverMessage${this.props.allowDropFiles ? "" : " denyDrop"}`;
    const dropZoneText = this.props.allowDropFiles
      ? this.dropFileText
      : this.denyDropText;

    return (
      <div className="dropFileZone"
           {...ddProperties}
      >
        {this.renderContent()}

        {this.allowDragAndDrop && this.isDragOver &&
        <div className={dropZoneClass}>
          <span>{dropZoneText}</span>
        </div>
        }
      </div>
    );
  }
}

FileDropPanel.propTypes = {
  dispatch: PropTypes.func,
  onFilesDropped: PropTypes.func.isRequired,
  enabled: PropTypes.bool,
  allowDropFiles: PropTypes.bool,
  dropFileText: PropTypes.string,
  denyDropText: PropTypes.string
};

FileDropPanel.defaultProps = {
  enabled: true,
  allowDropFiles: true
};

export default connect()(FileDropPanel);