import HttpQuery from './HttpQuery';

class ComboApi {
    static updateParentItem(controllerName, methodName, parentMethodName, parentRowIndex, rowIndex, fieldName, isParamset) {
        const hq = new HttpQuery();
        return hq.makeApiCallPost("Combo/UpdateParentDataItem", {controllerName, methodName, parentMethodName, parentRowIndex, rowIndex, fieldName, isParamset});
    }

    static getSelectedIndex(controllerName, methodName, parentMethodName, parentRowIndex, fieldName, isParamset) {
        const hq = new HttpQuery();
        return hq.makeApiCallPost("Combo/GetSelectedIndex", {controllerName, methodName, parentMethodName, parentRowIndex, fieldName, isParamset});
    }

    static initialize(controllerName, parentMethodName, parentRowIndex, fieldName, isParamset) {
        const hq = new HttpQuery();
        return hq.makeApiCallPost("Combo/InitializeMultiSelect", {controllerName, parentMethodName, parentRowIndex, fieldName, isParamset});
    }

    static unmount(controllerName, parentMethodName, parentRowIndex, fieldName, isParamset) {
        const hq = new HttpQuery();
        return hq.makeApiCallPost("Combo/UnmountMultiSelect", {controllerName, parentMethodName, parentRowIndex, fieldName, isParamset});
    }

    static getComboText(controllerName, parentMethodName, parentRowIndex, fieldName, isParamset) {
        const hq = new HttpQuery();
        return hq.makeApiCallPost("Combo/GetComboText", {controllerName, parentMethodName, parentRowIndex, fieldName, isParamset});
    }

    static reInitSelectSetFields(controllerName, parentMethodName, parentRowIndex, fieldName, isParamset) {
        const hq = new HttpQuery();
        return hq.makeApiCallPost("Combo/ReInitSelectSetFields", {controllerName, parentMethodName, parentRowIndex, fieldName, isParamset});
    }
}
export default ComboApi
