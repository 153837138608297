import HttpQuery from "./HttpQuery";
import i18next from 'i18next';
import moment from "moment";
import "moment/locale/ru";

class LanguageChanger {
    static setLanguage(targetLcid, langCode, langChangeController = "ApplicationHome") {
        let hq = new HttpQuery();
        return hq
            .makeApiCallPost(`${langChangeController}/SetLanguage`, {
                targetLcid
            })
            .then(i18next.changeLanguage(langCode))
            .then(d => window.location.reload());
    }

    static syncUiLanguage(langCode) {
        i18next.changeLanguage(langCode);
        moment.locale(langCode);
    }
}

export default LanguageChanger;
