/**
 * Created by marks on 14.02.2017.
 */
import i18next from "i18next";

export default function formatError(errorData) {
    return (error => {
        if (error.ExceptionMessage) return error.ExceptionMessage;

        if (error.Message && error.MessageDetail)
            return error.Message + " " + error.MessageDetail;

        if (error.Message) return error.Message;

        if (error.message) return error.message;

        if (error.error_description) return error.error_description;

        if (error.statusText === "error") return i18next.t('NetworkError');

        if (error.statusText) return error.statusText;

        if (error.error) return error.error;

        if (error) return error;

        return "error";
    })(errorData);
}

export function isAuthFailed(errorData) {
    return !!(errorData && errorData.status === 401);
}

export function isUserException(error) {
    if (error && error.ExceptionType && (error.ExceptionType === "Monolit.Data.BODAC.Common.UserException"))
        return true;

    return false;
}

export function isNewSession(error) {
    if (error && error.ExceptionType &&
        (error.ExceptionType === "Monolit.Web2.WebUI.Telesale.NewSessionException"
            || error.ExceptionType === "Monolit.Web2.WebUI.Telesale.SessionUnsynchronizedException"
            || error.ExceptionType === "Monolit.Web2.WebApi.Platform.Owin.NewSessionException")
            || error.ExceptionType === "Monolit.Web2.WebApi.Platform.Owin.SessionUnsynchronizedException") {
        return true;
    }

    return false;
}
