import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";

class FileDownloadHandle extends Component {
  constructor(props, context) {
    super(props, context);
  }

  getQuery = ()=> {
    const query = {
      controllerName: this.props.dataItem.controllerName,
      methodName: this.props.dataItem.methodName,
      fieldName: this.props.fieldInfo.Name,
      rowIndex: this.props.rowIndex,
      appid: window.AppInstanceId
    };

    const qs = queryString.stringify(query);
    return `${window.config.apiRoot}/FileEx/Download?${qs}`;
  };

  render() {
    return (<a href={this.getQuery()}>{this.props.formattedVal}</a>);
  }

}

FileDownloadHandle.propTypes = {
  dispatch: PropTypes.func.isRequired,
  formattedVal: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  readOnly: PropTypes.bool.isRequired,
  dataItem: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  fieldInfo: PropTypes.object.isRequired
};


FileDownloadHandle.defaultProps = {};

function mapStateToProps(state, ownProps) {
  return {
    state: state
  };
}

export default connect(mapStateToProps)(FileDownloadHandle);