/* http://www.daterangepicker.com */

import React, {Component} from "react";
import PropTypes from "prop-types";
import $ from "jquery";

import "./sources/daterangepicker";
import "./sources/daterangepicker.css";

import "./dateRangePickerStyle.css";
import * as moment from "moment";
import i18next from "i18next";

class MnDateRangePicker extends Component {
    componentDidMount() {
        this.initControl();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.readOnly !== prevProps.readOnly) {
            if (this.props.readOnly) {
                $(this.el).data('daterangepicker').remove();
            }
            else {
                this.initControl();
            }
        }

        this.setValueFromProps();
    }

    setValueFromProps = () => {
        if (!this.props.readOnly) {
            let elem = $(this.el).data('daterangepicker');
            let startDate = null;
            let endDate = null;

            if (elem) {
                if (this.props.startDate && this.props.endDate) {
                    startDate = this.props.startDate;
                    endDate = this.props.endDate;
                }
                elem.setStartDate(startDate || new Date());
                elem.setEndDate(endDate || new Date());
            }
            if (this.props.allowClear && !startDate) {
                this.clear();
            }
        }
        else {
            if (this.props.startDate && this.props.endDate) {
                let format = moment.localeData().longDateFormat('L');
                let sd = moment(this.props.startDate, format);
                let ed = moment(this.props.endDate, format);

                $(this.el).val(sd.format(format) + " - " + ed.format(format));
            }
            else {
                $(this.el).val("");
            }
        }
    };

    initControl = () => {
        if (!this.props.readOnly) {
            $(this.el).daterangepicker({...this.props}, this.onChange);
            if (!this.props.startDate || !this.props.endDate) {
                this.setValueFromProps();
            }
        }
        else {
            this.setValueFromProps();
        }
    };

    show = () => {
        $(this.el).trigger('click');
    };

    clear = () => {
        $(this.el).val('');

        if (this.props.startDate || this.props.endDate) {
            this.onChange(null, null);
        }
    };

    onChange = (start, end) => {
        this.props.onChange(start, end);
    };

    render () {
        return (
            <div className="mn_dateRangePicker_container">
                <input type="text"
                       className="form-control"
                       placeholder={i18next.t("SelectRange")}
                       readOnly={this.props.readOnly}
                       ref={el => this.el = el}
                />
                {!this.props.readOnly && this.props.allowClear && <i className="mn_dateRangePicker_clear zmdi zmdi-close" onClick={this.clear} />}
                {this.props.showCalendarIcon && <i className="mn_dateRangePicker_image zmdi zmdi-calendar" onClick={this.show} />}
            </div>
        );
    }
}

MnDateRangePicker.propTypes = {
    /*control properties*/
    startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    maxSpan: PropTypes.object,
    showDropdowns: PropTypes.bool,
    minYear: PropTypes.number,
    maxYear: PropTypes.number,
    showWeekNumbers: PropTypes.bool,
    showISOWeekNumbers: PropTypes.bool,
    timePicker: PropTypes.bool,
    timePickerIncrement: PropTypes.number,
    timePicker24Hour: PropTypes.bool,
    timePickerSeconds: PropTypes.bool,
    ranges: PropTypes.object,
    showCustomRangeLabel: PropTypes.bool,
    alwaysShowCalendars: PropTypes.bool,
    opens: PropTypes.string,
    drops: PropTypes.string,
    buttonClasses: PropTypes.string,
    applyButtonClasses: PropTypes.string,
    cancelButtonClasses: PropTypes.string,
    locale: PropTypes.object,
    singleDatePicker: PropTypes.bool,
    autoApply: PropTypes.bool,
    linkedCalendars: PropTypes.bool,
    isInvalidDate: PropTypes.func,
    isCustomDate: PropTypes.func,
    autoUpdateInput: PropTypes.bool,
    parentEl: PropTypes.object,

    /*custom properties*/
    readOnly: PropTypes.bool,
    allowClear: PropTypes.bool,
    onChange: PropTypes.func,
    showCalendarIcon: PropTypes.bool
};

MnDateRangePicker.defaultProps = {
    /*control properties*/
    autoApply: true,

    /*custom properties*/
    readOnly: false,
    allowClear: false,
    onChange: (start, end) => {
        console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
    },
    showCalendarIcon: true
};

export default MnDateRangePicker;