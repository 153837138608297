import React from "react";
import PropTypes from "prop-types";
import {debounce} from "lodash";

class Tabs extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.tabsContainerRef = React.createRef();
        this.ulRef = React.createRef();

        this.stopScrolling = debounce(this.stopScrolling, 100);

        this.state = {
            showButtons: false,
            position: 0,
            isMouseDown: false,
            mouseDownPosition: null,
            isScrolling: false,
            prevClientX: null
        };
    }

    componentDidMount() {
        this.initByWidth();
        window.addEventListener('resize', this.initByWidth);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.tabs !== prevProps.tabs)
        {
            this.initByWidth();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.initByWidth);
    }

    initByWidth = () => {
        if (!this.tabsContainerRef.current || !this.ulRef.current) {
            return setTimeout(this.initByWidth, 100);
        }

        const containerWidth = this.tabsContainerRef.current.offsetWidth;
        const tabsWidth = this.ulRef.current.offsetWidth;

        if (containerWidth === 0 && tabsWidth === 0) {
            return setTimeout(this.initByWidth, 100);
        }

        this.setState({showButtons: tabsWidth > containerWidth});
    };

    get leftNavButtonIsActive() {
        return this.state.position > 0;
    }

    get rightNavButtonIsActive() {
        const containerWidth = this.tabsContainerRef.current.offsetWidth;
        const tabsWidth = this.ulRef.current.offsetWidth;
        return containerWidth + this.state.position < tabsWidth;
    }

    onSelected = (e, tab) => {
        e.preventDefault();

        if (this.state.isScrolling) {
            return;
        }

        if (this.state.showButtons) {
            const containerWidth = this.tabsContainerRef.current.offsetWidth;
            const offsetLeft = e.currentTarget.parentElement.offsetLeft;
            const offsetWidth = e.currentTarget.parentElement.offsetWidth + 3;

            this.setState(state => {
                if (offsetLeft < 0) {
                    return { position: state.position + offsetLeft };
                }
                if (offsetWidth >= containerWidth) {
                    return { position: state.position + offsetLeft };
                }
                if (offsetLeft + offsetWidth > containerWidth) {
                    return { position: state.position + (offsetLeft + offsetWidth - containerWidth) };
                }
                return {};
            });
        }

        if (this.props.onSelected) {
            this.props.onSelected(tab);
        }
    };

    scroll = (diff) => {
        this.setState(state => {
            let newPosition = state.position + diff;

            const containerWidth = this.tabsContainerRef.current.offsetWidth;
            const tabsWidth = this.ulRef.current.offsetWidth;

            if (containerWidth + newPosition > tabsWidth) {
                newPosition = tabsWidth - containerWidth;
            }

            if (newPosition < 0) {
                newPosition = 0;
            }

            return { position: newPosition };
        });
    };

    leftButtonClick = (e) => {
        e.preventDefault();
        if (this.leftNavButtonIsActive) {
            this.scroll(-100);
        }
    };

    rightButtonClick = (e) => {
        e.preventDefault();
        if (this.rightNavButtonIsActive) {
            this.scroll(100);
        }
    };

    onMouseDown = (e) => {
        e.preventDefault();
        this.startMonitoring(e);
    };

    onMouseUp = (e) => {
        e.preventDefault();
        this.stopScrolling();
    };

    onMouseMove = (e) => {
        e.preventDefault();
        this.handleMove(e.clientX, e.movementX);
    };

    onTouchStart = (e) => {
        const touch = e.touches[0];
        if (touch) {
            this.startMonitoring(touch);
        }
    };

    onTouchEnd = (e) => {
        this.stopScrolling();
    };

    onTouchMove = (e) => {
        const touch = e.touches[0];
        if (touch) {
            const diff = this.state.prevClientX === null ? 0 : Math.floor(touch.clientX - this.state.prevClientX);
            if (diff !== 0) {
                this.handleMove(touch.clientX, diff);
            }
            this.setState({ prevClientX: touch.clientX });
        }
    };

    startMonitoring = (e) => {
        window.addEventListener('mousemove', this.onMouseMove);
        window.addEventListener('mouseup', this.stopScrolling);
        window.addEventListener('touchmove', this.onTouchMove);
        window.addEventListener('touchend', this.onTouchEnd);
        this.setState({
            isMouseDown: true,
            mouseDownPosition: e.clientX
        });
    };

    startScrolling = () => {
        this.setState({ isScrolling: true });
    };

    stopScrolling = () => {
        window.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('mouseup', this.stopScrolling);
        window.removeEventListener('touchmove', this.onTouchMove);
        window.removeEventListener('touchend', this.onTouchEnd);
        this.setState({ isScrolling: false, isMouseDown: false, prevClientX: null });
    };

    handleMove = (clientX, movementX) => {
        if (this.state.isScrolling) {
            if (movementX !== 0) {
                this.scroll(-movementX);
            }
        }
        else if (this.state.isMouseDown && Math.abs(clientX - this.state.mouseDownPosition) > 5) {
            this.startScrolling();
        }
    };

    render() {
        const showNavButtons = this.state.showButtons;
        const tabsContainerStyle = { overflow: "hidden" };
        const ulProps = {};

        if (showNavButtons) {
            tabsContainerStyle.position = "relative";
            tabsContainerStyle.marginLeft = "30px";
            tabsContainerStyle.marginRight = "30px";

            ulProps.style = {};
            if (this.state.position !== 0) {
                ulProps.style.marginLeft = -this.state.position + "px";
            }

            ulProps.onMouseDown = this.onMouseDown;
            ulProps.onMouseUp = this.onMouseUp;
            ulProps.onTouchStart = this.onTouchStart;
            ulProps.onTouchEnd = this.onTouchEnd;
        }

        return (
            <div className={this.props.className || ""}>
                <div ref={this.tabsContainerRef} style={tabsContainerStyle}>
                    <ul ref={this.ulRef} className="nav nav-tabs" role="tablist" {...ulProps}>
                        {this.props.tabs.map((tab) => {
                            const activeClassName = tab[this.props.valueField] === this.props.activeTabValue ? " active" : "";

                            return (
                                <li key={tab[this.props.valueField]}
                                    className={`nav-item ${this.props.tabClassName || ""} ${activeClassName}`}
                                    role="tab"
                                >
                                    <button className={`btn nav-link tabs-btn ${activeClassName}`}
                                            onClick={(e) => this.onSelected(e, tab)}>{tab[this.props.textField]}</button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                {showNavButtons &&
                <>
                    <div className={`tabs-navButton tabs-leftNavButton ${this.leftNavButtonIsActive ? "" : "disabled"}`}
                         onClick={this.leftButtonClick}>
                        <i className="zmdi zmdi-chevron-left" />
                    </div>
                    <div className={`tabs-navButton tabs-rightNavButton ${this.rightNavButtonIsActive ? "" : "disabled"}`}
                         onClick={this.rightButtonClick}>
                        <i className="zmdi zmdi-chevron-right" />
                    </div>
                </>
                }
            </div>
        );
    }
}

Tabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    activeTabValue: PropTypes.any,
    valueField: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
    onSelected: PropTypes.func.isRequired,
    className: PropTypes.string,
    tabClassName: PropTypes.string
};

Tabs.defaultProps = {};

export default Tabs;