import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import MnComboBase from "./MnComboBase";
import i18next from "i18next";

class MnComboInlineEnum extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.onToggle = this.onToggle.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onResetClick = this.onResetClick.bind(this);
    }

    componentDidMount() {
    }

    isDataReady() {
        return this.isParentReady();
    }

    isParentReady() {
        return this.props.parentDataItem.isInitialized;
    }

    getTitle() {
      if (!this.isParentReady())
        return i18next.t('GridViewEmptyLabel');

      let enumList = this.props.fieldInfo.EditorInfo.enumList;
      let val = this.props.parentDataItem.rawData.DataTable[this.props.rowIndex][this.props.fieldInfo.Name];
      let txt = enumList.find(i => val != null && i.DBValue === val.toString());
      if (!txt) {
        if (!this.props.readOnly)
          return i18next.t('PlsSelectLabel');
        else
          return val;
      }
      else
        return txt.ENValue;
    }

    getComboRows(selectedIndex, selectHandle) {
        if (!this.isDataReady())
            return (<div>{i18next.t('LoadingLabel')}</div>);

        let enumList = this.props.fieldInfo.EditorInfo.enumList;

        return enumList.map((row, idx) => {

            const className = (selectedIndex === idx) ? "dropdown-item selected" : "dropdown-item";

            return (<li role="presentation" key={idx}><a className={className}
                                                         onClick={function (e) {
                                                              e.preventDefault();
                                                              selectHandle(idx)
                                                         }}><span>{row.ENValue}</span></a></li>)
        });
    }


    searchCurrentIndex() {
        if (!this.isDataReady())
            return -1;

        const enumList = this.props.fieldInfo.EditorInfo.enumList;
        const val = this.props.parentDataItem.rawData.DataTable[this.props.rowIndex][this.props.fieldInfo.Name];

        for (let i = 0; i < enumList.length; i++) {
            const ee = enumList[i];
            if ((null == val && null == ee.DBValue) || (val && ee.DBValue === val.toString())) {
                return i;
            }
        }
    }

    onToggle(isOpen) {
    }

    onSelect(selectedIndex) {

        const enumList = this.props.fieldInfo.EditorInfo.enumList;
        const selectedEntry = enumList[selectedIndex];

        const ee = enumList.find(i => i.DBValue === selectedEntry.DBValue);
        this.props.dispatch(this.props.parentDataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, ee.DBValue))
            .then(() => {
                if (this.props.onSelected) {
                    this.props.onSelected(this.props.rowIndex);
                }
            });
    }

    onResetClick() {
        this.props.dispatch(this.props.parentDataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, null))
            .then(() => this.props.onSelected(-1));
    }

    render() {

        const isRequired = !this.props.allowReset || this.props.parentDataItem.getFieldIsRequired(this.props.fieldInfo, this.props.rowIndex);
        const self = this;
        const currentIndex = self.searchCurrentIndex();

        return (
            <MnComboBase allowReset={!isRequired} onReset={this.onResetClick}
                         onSelected={(index) => this.onSelect(index)}
                         renderRows={(idx, h) => self.getComboRows(idx, h)} value={this.getTitle()}
                         readOnly={this.props.readOnly}
                         selectedIndex={currentIndex} isDataReady={this.isDataReady()}
            />
        )
    }
}

MnComboInlineEnum.defaultProps = {
    rowIndex: 0,
    allowReset: true
};

MnComboInlineEnum.propTypes = {
    dispatch: PropTypes.func,
    parentDataItem: PropTypes.object.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    rowIndex: PropTypes.number,
    allowReset: PropTypes.bool,
    onSelected: PropTypes.func,
    readOnly: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
    return {};
}

export default connect(mapStateToProps)(MnComboInlineEnum);
