import React, { Component } from 'react';
import { hot } from 'react-hot-loader'
import {Route, Switch, withRouter} from "react-router";
import Master from "./Master/Master";
import Sandbox from "./Sandbox/Sandbox";

class App extends Component {

  componentDidMount() {
  }

  render() {
    return (
        <React.Fragment>
          <Switch>
              <Route path="/" render={() => <Master />}/>
          </Switch>
        </React.Fragment>
    );
  }
}

export default hot(module)(withRouter(App));
