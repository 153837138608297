import { createConfirmation } from 'react-confirm';
import ConfirmEx from './ConfirmEx';
import ConfirmCommentDialog from './ConfirmComment';

// create confirm function
const confirm = createConfirmation(ConfirmEx);

export default function(confirmation, options = {}) {
    return confirm({ confirmation, options });
}

const confirmWithComment = createConfirmation(ConfirmCommentDialog);

export function confirmComment(confirmation, options = {}) {
    return confirmWithComment({ confirmation, options });
}