import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {
  initComboBox,
  unmountComboBox,
  getComboText,
  reInitSelectSetFields
} from "@mnjsplatform/data/lib/actions/comboActions";
import MultiSelectWrapper from "../MultiSelect/MultiSelectWrapper";
import MnComboBase from "./MnComboBase";
import MultiSelectRows from "../MultiSelect/MultiSelectRows";

class MnComboInlineMultiSelect extends React.Component {
  init = async() => {
    let data = null;
    if (!this.isInitialized) {
      data = await this.props.dispatch(initComboBox(this.props.parentDataItem, this.props.rowIndex, this.props.fieldInfo.Name));
      this.isInitialized = true;
      if (data.changedFields) {
        this.selectSetFields = Object.keys(data.changedFields);
      }
    }
    else if (this.selectSetFields && this.selectSetFields.some(f => this.props.parentDataItem.getField(this.props.rowIndex, f) === null)) {
      data = await reInitSelectSetFields(this.props.parentDataItem, this.props.rowIndex, this.props.fieldInfo.Name);
    }

    if (data && data.changedFields) {
      await this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, data.changedFields));
    }
  };

  unmount = async() => {
    await this.props.dispatch(unmountComboBox(this.props.parentDataItem, this.props.rowIndex, this.props.fieldInfo.Name));
  };

  updateLabel = async() => {
    let label;
    const res = await this.props.dispatch(getComboText(this.props.parentDataItem, this.props.rowIndex, this.props.fieldInfo.Name));
    if (res && res.label) {
      label = res.label;
    }
    await this.props.dispatch(this.props.parentDataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, label || null));
  };

  onEndEditField = () => {
    if (this.props.onEndEditField) {
      this.props.onEndEditField();
    }
  };

  onResetClick = async(multiSelectProps) => {
    await multiSelectProps.init();
    await multiSelectProps.fill(false);
    await this.updateLabel();
    this.onEndEditField();
  };

  getComboRows = (multiSelectProps) => {
    if (!multiSelectProps.subgridItem) {
      return null;
    }
    return (
      <MultiSelectRows parentDataItem={this.props.parentDataItem}
                       subgridDataItem={multiSelectProps.subgridItem}
                       rowIndex={this.props.rowIndex}
                       fieldInfo={this.props.fieldInfo}
                       readOnly={this.props.readOnly}
                       showSelectAll={true}
                       updateLabel={this.updateLabel}
                       fillSubgrid={multiSelectProps.fill}
      />
    );
  };

  setHandler = (multiSelectProps) => {
    if (typeof this.props.setHandler === 'function') {
      this.props.setHandler({
        reset: () => this.onResetClick(multiSelectProps)
      })
    }
  }

  render() {
    const isRequired = !this.props.allowReset || this.props.parentDataItem.getFieldIsRequired(this.props.fieldInfo, this.props.rowIndex);
    const value = this.props.value;

    return (
      <MultiSelectWrapper {...this.props}
                          initComponent={this.init}
                          unmountComponent={this.unmount}

      >
        {multiSelectProps => {
          this.setHandler(multiSelectProps);
          return (
              <MnComboBase allowReset={!isRequired && !this.props.readOnly}
                           onAfterOpen={multiSelectProps.init}
                           onReset={() => this.onResetClick(multiSelectProps)}
                           renderRows={() => this.getComboRows(multiSelectProps)}
                           value={value}
                           onAfterClosed={this.onEndEditField}
              />
          )
        }}
      </MultiSelectWrapper>
    );
  }
}

MnComboInlineMultiSelect.defaultProps = {
  rowIndex: 0,
  allowReset: true
};

MnComboInlineMultiSelect.propTypes = {
  dispatch: PropTypes.func,
  parentDataItem: PropTypes.object.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  value: PropTypes.string,
  allowReset: PropTypes.bool,
  rootPath: PropTypes.string,
  readOnly: PropTypes.bool,
  onEndEditField: PropTypes.func,
  setHandler: PropTypes.func
};

export default connect()(MnComboInlineMultiSelect);