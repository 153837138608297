import {QUERY_SUBGRID_INIT_OK, QUERY_SUBGRID_INIT_BEGIN, QUERY_SUBGRID_INIT_FAILED, QUERY_SUBGRID_UNMOUNT} from "../actions/subgridActions";

export default function subgridRepositoryReducer(state = {}, action) {

  switch (action.type)
  {
    case QUERY_SUBGRID_INIT_BEGIN:
      return Object.assign({}, state, {
        [action.subgridKey]: {inInitialization: true, subgridDescription: null}
      });
    case QUERY_SUBGRID_INIT_FAILED:
      return Object.assign({}, state, {
        [action.subgridKey]: {inInitialization: false, subgridDescription: null}
      });
    case QUERY_SUBGRID_INIT_OK:
      return Object.assign({}, state, {
        [action.subgridKey]: {inInitialization: false, subgridDescription: action.data.subgridDescription}
      });
    case QUERY_SUBGRID_UNMOUNT:
      const newObj = Object.assign({}, state);
      delete newObj[action.subgridKey];
      return newObj;
  }

  return state;
}
