import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class SubgridPlaceholder extends Component {
  render() {
    if ((!this.props.subgridKey || !this.props.subgridItem || !this.props.children) && !this.props.alwaysRenderChildren) {
      return null;
    }

    return this.props.children(this.props.subgridItem, this.props.subgridItemParams);
  }
}

SubgridPlaceholder.propTypes = {
  dispatch: PropTypes.func.isRequired,
  subgridKey: PropTypes.string,
  subgridItem: PropTypes.object,
  subgridItemParams: PropTypes.object,
  alwaysRenderChildren: PropTypes.bool
};

SubgridPlaceholder.defaultProps = {
  alwaysRenderChildren: false
};

function mapStateToProps(state, ownProps) {

  if (!ownProps.subgridKey)
    return {};

  const repItem = state.subgridRepository[ownProps.subgridKey];
  if (!repItem || !repItem.subgridDescription)
    return {};

  const subgridItem = state.dynState[repItem.subgridDescription.subgridItemPath];
  const subgridItemParams = repItem.subgridDescription.subgridItemParamsPath ? state.dynState[repItem.subgridDescription.subgridItemParamsPath] : null;

  return {
    subgridItem: subgridItem,
    subgridItemParams : subgridItemParams
  };
}

export default connect(mapStateToProps)(SubgridPlaceholder);