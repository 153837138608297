import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import i18next from "i18next";

const ConfirmDialogEx = ({show, proceed, dismiss, cancel, confirmation, options}) => {

    if (!show)
        return null;

    return (<div className="modal fade show" id="myModal" style={{display:"block"}}>
        <div className="modal-dialog">
            <div className="modal-content">

                <div className="modal-header">
                    <h4 className="modal-title">{options.header ? options.header : i18next.t('Confirm')}</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={dismiss}>&times;</button>
                </div>

                <div className="modal-body">
                    {confirmation}
                </div>

                <div className="modal-footer text-right">
                    <button type="button" className="btn btn-regular" data-dismiss="modal" onClick={proceed}>OK</button>
                    <button type="button" className="btn btn-regular" data-dismiss="modal" onClick={cancel}>{i18next.t('Cancel')}</button>
                </div>
            </div>
        </div>
    </div>)
};

/*
    <Dialog onHide={dismiss} show={show}>
        {confirmation}
        <button onClick={() => cancel('arguments will be passed to the callback')}>CANCEL</button>
        <button onClick={() => proceed('same as cancel')}>OK</button>
    </Dialog>
 */

ConfirmDialogEx.propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
    confirmation: PropTypes.oneOfType([     // arguments of your confirm function
        PropTypes.string,
        PropTypes.object
    ]),
    options: PropTypes.object        // arguments of your confirm function
};

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmDialogEx);