import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from 'react-redux'

const CheckBox = (props) => {

    const dispatch = useDispatch();
    
    const c = async (e) => {
        const isChecked = e.target.checked;
        await dispatch(props.dataItem.setField(props.rowIndex, props.fieldInfo.Name, isChecked));
        if (props.onSelected)
            props.onSelected();
    };

    return <input
      className="checkbox"
      type="checkbox"
      data-toggle="toggle"
      value={!!props.checked}
      checked={!!props.checked}
      readOnly={props.readOnly}
      disabled={props.readOnly}
      onChange={c}
      id={props.forId}
      style={props.style || {}}
    />;
};

CheckBox.propTypes = {
    checked: PropTypes.any,
    readOnly: PropTypes.bool,
    rowIndex: PropTypes.number,
    dataItem: PropTypes.object,
    fieldInfo: PropTypes.object,
    onSelected: PropTypes.func,
    forId: PropTypes.string,
    style: PropTypes.object
};

export default CheckBox;