import * as types from "../actions/reportActions";

export const REPORT_INACTIVE = "REPORT_INACTIVE";
export const REPORT_PREPARE = "REPORT_PREPARE";
export const REPORT_INITIALIZED = "REPORT_INITIALIZED";
export const REPORT_CREATED = "REPORT_CREATED";
export const REPORT_COMPLETE = "REPORT_COMPLETE";
export const REPORT_ERROR = "REPORT_ERROR";

const defaultState = {
    currentState: REPORT_INACTIVE,
    statusText: null,
    statusLog: [],
    error: null,
    taskId: null,
    reportType: null,
    paramPath: ""
};

const defaultReportSet = {defaultReport: defaultState};

export default function reportSetReducer(state = defaultReportSet, action)
{
    if (!types[action.type])
        return state;

    let reportKey = "defaultReport";
    if (!!action.reportKey)
        reportKey = action.reportKey;

    const newReportSet = Object.assign({}, state);
    if (action.type === types.REPORT_UNMOUNT)
        delete newReportSet[reportKey];
    else
        newReportSet[reportKey] = reportReducer(state[reportKey], action);

    return newReportSet;
}

function reportReducer(
    state = defaultState,
    action
) {
    if (action.type === types.REPORT_PREPARE) {
        return Object.assign({}, defaultState, {currentState: REPORT_PREPARE});
    }
    else if (action.type === types.REPORT_INITIALIZED) {
        return Object.assign({}, defaultState, {currentState: REPORT_INITIALIZED, paramPath: state.paramPath });
    }
    else if (action.type === types.REPORT_CREATED) {
        return Object.assign({}, defaultState, {
            currentState: REPORT_CREATED,
            taskId: action.data.taskId,
            reportType: action.data.reportType,
            paramPath: state.paramPath
        });
    }
    else if (action.type === types.REPORT_UPDATE_TASK_STATUS) {
        const statusLog = [].concat(state.statusLog).concat(action.data.ReportEvents);
        return Object.assign({}, state, {statusText: action.data.statusText, statusLog: statusLog});
    }
    else if (action.type === types.REPORT_COMPLETE) {
        const statusLog = [].concat(state.statusLog).concat(action.data.ReportEvents);
        return Object.assign({}, state, {
            currentState: REPORT_COMPLETE,
            statusText: action.data.statusText,
            statusLog: statusLog
        });
    }
    else if (action.type === types.REPORT_BUILD_ERROR) {
        return Object.assign({}, state, {currentState: REPORT_ERROR, error: action.data});
    }
    else if (action.type === types.REPORT_DISMISS) {
        return Object.assign({}, defaultState, {paramPath: state.paramPath});
    }
    else if (action.type === types.REPORT_PARAMS_AVAILABLE) {
        return Object.assign({}, state, { paramPath: action.paramPath });
    }

    return state;
}