import React from 'react';
import PropTypes from 'prop-types';

export default function SubgridOpenLink(props) {

  const clickLink = function(e) {
    e.preventDefault();
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    if (props.onShow)
      props.onShow(props.fieldName, props.rowIndex);
  }

  return (<a href="" onClick={clickLink}>{props.value}</a>);
}

SubgridOpenLink.propTypes = {
  onShow : PropTypes.func.isRequired,
  fieldName : PropTypes.string.isRequired,
  rowIndex : PropTypes.number.isRequired,
  value : PropTypes.any
};