import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import {useSelector} from "react-redux";

const shortText = (text, charCount) => {
    if (text && text.length > charCount) {
        return text.slice(0, charCount).trim() + '...';
    }
    return text;
}

function isElementInViewport(el, pageHeaderHeight) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= pageHeaderHeight
    );
}

const LongTextWrapper = ({ text, charCount, pageHeaderHeight }) => {

    const [cellText, setCellText] = useState('');
    const [isExpanded, setExpand] = useState(false);
    const [isScrollToTop, setScrollToTop] = useState(false);
    const cellRef = useRef(null);

    useEffect(() => {
        setCellText(isExpanded ? text : shortText(text, charCount));
        /* Скролл к верхней границы текста при сворачивание текста */
        if (isScrollToTop) {
            const isInViewport = isElementInViewport(cellRef.current, pageHeaderHeight);
            setScrollToTop(false);
            !isInViewport && cellRef.current && cellRef.current.scrollIntoView(true);
        }
    }, [text, charCount, isExpanded]);

    const toggle = (e) => {
        e.stopPropagation();
        setScrollToTop(isExpanded);
        setExpand(!isExpanded);
    }

    if (typeof text !== "string" || !charCount || text.length <= charCount) {
        return text;
    }

    return (
        <span onClick={e => e.stopPropagation()} style={{"cursor": "Default"}} ref={cellRef}>
            {cellText}
            <div onClick={toggle} style={{"color": "#578ece", "fontWeight": "700", "cursor": "Pointer", "textDecoration": "underline"}}> {i18next.t(isExpanded ? "ShowLess" : "ShowMore")}</div>
        </span>
    );

}

LongTextWrapper.propTypes = {
    text: PropTypes.string,
    charCount: PropTypes.number,
    pageHeaderHeight: PropTypes.number
};

LongTextWrapper.defaultProps = {
    text: "",
    charCount: 50,
    pageHeaderHeight: 0
};

export default LongTextWrapper;