import React, {Component} from "react";
import PropTypes from "prop-types";
import DropDownMenuCombo from "./DropDownMenuCombo";

class DropdownMenu extends Component {

    render() {

        const menuItems = this.props.menuAdapter.getMenuItems().slice(0);

        const visibleItemsCount = this.props.menuAdapter.getVisibleItemsCount();

        if (visibleItemsCount > 0) {
            const selectedIndex = menuItems.findIndex(i => i.IsActive);
            if (selectedIndex >= 0 && selectedIndex > visibleItemsCount - 1) {
                const item = menuItems[selectedIndex];
                menuItems.splice(selectedIndex, 1);
                menuItems.splice(visibleItemsCount - 1, 0, item);
            }
        }

        const visibleItems = menuItems.slice(0, Math.min(menuItems.length, visibleItemsCount));
        const invisibleItems = menuItems.slice(visibleItemsCount);

        return (
            <div className={this.props.className}>
                {visibleItems.map((item, itemIndex) => this.renderVisibleItem(item, itemIndex))}
                {this.renderDropDownMenuCombo(invisibleItems)}
            </div>
        );
    }

    renderVisibleItem(item, itemIndex) {
        if (this.props.renderVisibleItem)
            return this.props.renderVisibleItem(item, itemIndex);

        const className = !item.IsActive ? "btn btn-link" : "btn btn-link btn-selected";
        const self = this;

        return <a href="" className={className} key={item.MenuItemId}
                  onClick={(e) =>{ e.preventDefault(); self.props.onSelected(item)}}>{item.MenuText}</a>
    }

    renderDropDownMenuCombo = (invisibleItems) => {
        if (invisibleItems.length === 0) {
            return null;
        }
        return (
          <DropDownMenuCombo {...this.props}
                             renderMoreItem={this.renderMoreItem}
                             dropDownItems={invisibleItems}
                             alignRight={this.props.alignRight}
                             className={this.props.moreItemClassName}
                             renderCustomContainer={this.renderDropDownMenuComboContainer}
          />
        );
    };

    renderDropDownMenuComboContainer = ({props, children}) => {
        return (
          <span {...props}>
              {children}
          </span>
        );
    };

    renderMoreItem = ({defaultText, onClick}) => {
        if (this.props.renderMoreItem)
            return this.props.renderMoreItem({defaultText, onClick});

        return (
          <button className="btn btn-link dropdown-toggle" type="button"
                  onClick={onClick}
                  title={defaultText}
          >
            <b>{defaultText}</b>
            <i className="zmdi zmdi-chevron-down"/>
          </button>
        );
    };
}

DropdownMenu.propTypes = {
    menuAdapter: PropTypes.object,
    onSelected: PropTypes.func,
    renderVisibleItem: PropTypes.func,
    renderMoreItem: PropTypes.func,
    renderInvisibleItem: PropTypes.func,
    className: PropTypes.string,
    moreItemClassName: PropTypes.string,
    alignRight: PropTypes.bool
};

DropdownMenu.defaultProps = {
    className: "text-right",
    moreItemClassName: "",
    alignRight: true

};

export default DropdownMenu;