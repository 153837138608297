import React from 'react';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import i18next from "i18next";
import MessageBox from "./MessageBox";

@confirmable
class ConfirmCommentDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            commentText: props.options.commentText,
            showCommentIsEmptyError: false
        };

    }

    onConfirmClick = () => {
        if(!this.props.options.commentRequired || !this.isCommentEmpty())
            this.props.proceed(this.state.commentText);
        else
            this.setState({showCommentIsEmptyError: true});
    };

    isCommentEmpty = () => {
        return this.state.commentText == null || this.state.commentText === "";
    };

    onErrorMessageClose = () => {
        this.setState({showCommentIsEmptyError: false});
    };

    render() {
        if (!this.props.show)
            return null;

        let self = this;

        return (
            <React.Fragment>
                <MessageBox visible={this.state.showCommentIsEmptyError}
                            title={i18next.t('Error')}
                            messageText={i18next.t('NeedFillComment')}
                            onClose={this.onErrorMessageClose}
                />
                <div className="modal fade show" id="myModal" style={{display:"block"}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">{this.props.options.header ? this.props.options.header : i18next.t('Confirm')}</h4>
                                <button type="button" className="close" data-dismiss="modal" onClick={this.props.dismiss}>&times;</button>
                            </div>

                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="control-label">
                                            {this.props.confirmation}
                                        </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{marginTop: '25px'}}>
                                        {i18next.t('Comment')}
                                    </div>
                                </div>
                                <textarea
                                  className="form-control"
                                  style={{ height: 100 }}
                                  value={this.state.commentText}
                                  onChange={function (e) { self.setState({commentText: e.target.value}); }}
                                />
                                {this.props.options.commentRequired && this.isCommentEmpty() &&
                                    <div className="row">
                                        <div className="col-md-12" style={{ color: "red" }}>
                                            {i18next.t('NeedFillComment')}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="modal-footer text-right">
                                <button type="button" className="btn btn-regular" data-dismiss="modal" onClick={this.onConfirmClick}>OK</button>
                                <button type="button" className="btn btn-regular" data-dismiss="modal" onClick={this.props.cancel}>{i18next.t('Cancel')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>)
    }
}

/*const ConfirmCommentDialog = ({show, proceed, dismiss, cancel, confirmation, options}) => {

    if (!show)
        return null;

    return (<div className="modal fade show" id="myModal" style={{display:"block"}}>
        <div className="modal-dialog">
            <div className="modal-content">

                <div className="modal-header">
                    <h4 className="modal-title">{options.header ? options.header : i18next.t('Confirm')}</h4>
                    <button type="button" className="close" data-dismiss="modal" onClick={dismiss}>&times;</button>
                </div>

                <div className="modal-body">
                    <div className="row">
                        <div className="col-md-12">
                            <label className="control-label">
                                {confirmation}
                            </label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{marginTop: '25px'}}>
                            Комментарий:
                        </div>
                    </div>
                    <textarea
                      className="form-control"
                      style={{ height: 100 }}
                      value={this.props.commentText}
                      onChange={function (e) { self.setState({rejectCommentText: e.target.value}); }}
                    />
                </div>
                <div className="modal-footer text-right">
                    <button type="button" className="btn btn-regular" data-dismiss="modal" onClick={proceed}>OK</button>
                    <button type="button" className="btn btn-regular" data-dismiss="modal" onClick={cancel}>{i18next.t('Cancel')}</button>
                </div>
            </div>
        </div>
    </div>)
};*/

/*
    <Dialog onHide={dismiss} show={show}>
        {confirmation}
        <button onClick={() => cancel('arguments will be passed to the callback')}>CANCEL</button>
        <button onClick={() => proceed('same as cancel')}>OK</button>
    </Dialog>
 */

ConfirmCommentDialog.propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object        // arguments of your confirm function
};

export default ConfirmCommentDialog;
// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
//export default confirmable(ConfirmCommentDialog);