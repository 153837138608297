import PropTypes from 'prop-types';
import React from 'react';
import i18next from "i18next";
import {SizeMe} from "react-sizeme";
import ReactDOM from "react-dom";
import $ from 'jquery'
import VisibilitySensor from "react-visibility-sensor";
import {getScrollParent} from "@mnjsplatform/data/lib/utils/htmlUtils";
import CustomEventUtils from "../../utils/customEventUtils";

const modalRoot = document.getElementById('modal-root');

class MnComboBase extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isOpened: false,
            top: 0,
            left: 0,
        };

        this.el = document.createElement('div');
        this.myRef = React.createRef();
    }

    getTitle = () => {
        let title = '';
        if (!this.props.value) {
            title = this.props.defaultInputText;
        } else {
            title =  this.props.value;
        }
        if (!title || !title.replace)
            return title;

        if (!title || !title.replace)
            return title;

        return title.replace(/(?:\r\n|\r|\n)/g, ' ')
    }

    updateDimensions = () => {
        const offset = $(this.myRef.current).offset();
        if (!offset || (this.state.top === offset.top && this.state.left === offset.left))
            return;

        this.setState({top:offset.top, left:offset.left});
    }

    updateDimensionsResize = () => {

        console.log("resize");

        if (this.scrollNode)
            this.scrollNode.removeEventListener("scroll", this.updateDimensions);

        this.scrollNode = getScrollParent(this.myRef.current);
        if (this.scrollNode)
            this.scrollNode.addEventListener("scroll", this.updateDimensions);

        this.updateDimensions();
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener("resize", this.updateDimensions);
        window.addEventListener("scroll", this.updateDimensions);

        this.scrollNode = getScrollParent(this.myRef.current);
        if (this.scrollNode)
            this.scrollNode.addEventListener("scroll", this.updateDimensions);

        if (this.props.customEventToUpdateDimensions) {
            CustomEventUtils.addListener(this.props.customEventToUpdateDimensions, this.updateDimensions);
        }
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener("resize", this.updateDimensions);
        window.removeEventListener("scroll", this.updateDimensions);

        if (this.scrollNode)
            this.scrollNode.removeEventListener("scroll", this.updateDimensions);

        if (this.props.customEventToUpdateDimensions) {
            CustomEventUtils.removeListener(this.props.customEventToUpdateDimensions, this.updateDimensions);
        }

        clearTimeout(this.check);
    }

    handleClickOutside = (event) => {
        if (this.state.isOpened) {
            const inDropdown = this.dropdownRef && this.dropdownRef.contains(event.target);
            const inCombo = this.myRef && this.myRef.current && this.myRef.current.contains(event.target);
            let outsideClick = !inCombo && !inDropdown;
            if (!outsideClick && !!this.props.forceOutsideElementArray) {
                outsideClick = this.props.forceOutsideElementArray.some(elem => elem.current && elem.current.contains(event.target));
            }

            if (outsideClick) {
                this.setState({isOpened: false});

                if (this.props.afterOutsideClick) {
                    this.props.afterOutsideClick(event);
                }

                if (this.props.onAfterClosed) {
                    this.props.onAfterClosed();
                }
            }
        }
    };

    showDropDown = () => {
        if (!this.state.isOpened) {
            this.onToggle();
        }
    };

    hideDropDown = () => {
        if (this.state.isOpened) {
            this.onToggle();
        }
    };

    scrollToSelectedElement = () => {
        if (!this.props.value) {
            return;
        }
        if (!this.props.isDataReady && this.scrollAttempts) {
            this.scrollAttempts--;
            setTimeout(this.scrollToSelectedElement, 50);
            return;
        }
        if (!this.dropdownRef) {
            return;
        }
        const ul = this.dropdownRef.firstChild;
        const selectedElements = $(ul).find(".dropdown-item.selected");
        if (selectedElements.length === 0) {
            return;
        }
        const selectedElement = selectedElements[0];

        if (selectedElement.offsetTop + selectedElement.offsetHeight > ul.offsetHeight) {
            $(ul).animate({ scrollTop: selectedElement.offsetTop }, 50);
        }
    };

    onToggle = () => {
        if (this.props.onBeforeOpen)
            this.props.onBeforeOpen();
        this.updateDimensions();

        const openStatus = !this.state.isOpened;

        this.setState({ isOpened: openStatus },
          async() => {
              if (!openStatus) {
                  if (this.props.onAfterClosed) {
                      await this.props.onAfterClosed();
                  }
                  return;
              }

              if (this.props.onAfterOpen) {
                  await this.props.onAfterOpen();
              }

              this.scrollAttempts = 10;
              this.scrollToSelectedElement();
          });
    }

    onSelect = (selectedIndex) => {
        if(this.props.allowSelectChecker && !this.props.allowSelectChecker(selectedIndex))
            return;

        if (this.props.onSelected)
            this.props.onSelected(selectedIndex);

        this.setState({ isOpened: false });

        if (this.props.onAfterClosed) {
            this.props.onAfterClosed();
        }
    }

    onResetClick = (e) => {
        e.stopPropagation();
        this.props.onReset();
    }

    parentVisibilityChange = (isVisible) => {
        if (!this.state.isOpened)
            return;

        if (!isVisible)
            this.onToggle();
    }

    renderControl = () => {
        const allowReset = this.props.allowReset;

        if (this.props.renderFormControl) {
            return this.props.renderFormControl({
                getTitle: this.getTitle,
                onToggle: this.onToggle,
                showDropDown: this.showDropDown,
                hideDropDown: this.hideDropDown,
                allowReset: allowReset,
                onResetClick: this.onResetClick,
                readOnly: this.props.readOnly
            });
        }

        const value = this.getTitle();
        return (
            <div
                className="btn btn-input -icon dropdown-toggle d-block w100pc ellips comboBaseControl"
                value={value}
                style={{cursor:"pointer", padding: 0}}
                onClick={this.onToggle}
                disabled={this.props.readOnly}
            >
            	<input type="text"
                       style={{paddingRight: this.props.allowReset ? "38px" : "15px"}}
                       value={value || ""}
                       title={value}
                       disabled
                />
                <i className="zmdi zmdi-chevron-down"/>
                {allowReset &&
                <i className="zmdi zmdi-close" style={{right: "25px"}} onClick={this.onResetClick} name="resetBtn"/>}
            </div>
        );
    };

    render() {
        if (this.props.readOnly) {
            return (<span>{this.props.value}</span>);
        }

        return (
          <VisibilitySensor onChange={this.parentVisibilityChange} scrollCheck>
            <div className="dropdown" ref={this.myRef} style={this.props.style}>
                <SizeMe>{({size}) => <React.Fragment>
                      {this.renderControl()}
                      {this.renderDropdown(size)}
                    </React.Fragment>
                }</SizeMe>
            </div></VisibilitySensor>)
    }

    setDropdownRef = (node) => {
        this.dropdownRef = node;
    };

    renderDropdown = (size) => {

        if (!this.myRef.current)
            return null;

        //const offset = $(this.myRef.current).offset();

        const width = this.props.dropdownWidth || size.width;
        let left = this.state.left;
        if (this.props.alignRight) {
            left = Math.max(0, left + size.width - width);
        }
        const zIndex = this.props.dropdownZIndex || 1100;
        return ReactDOM.createPortal(
          (<div ref={this.setDropdownRef}>
              {this.state.isOpened && (<ul className="dropdown-menu"
                                           style={{
                                               left: left,
                                               top: this.state.top + 30,
                                               marginLeft: "0px",
                                               width: width + "px",
                                               display: "block",
                                               maxHeight: "250px",
                                               overflow: "auto",
                                               zIndex
                                           }}>
                  {!this.props.isDataReady ?
                    MnComboBase.renderDataNotReady()
                    :
                    this.getComboRows(this.props.selectedIndex)
                  }
              </ul>)}
          </div>),
          this.el,
        );
    }

    static renderDataNotReady() {
        return <div style={{ paddingLeft: 20, paddingTop: 3, paddingBottom: 3 }}>{i18next.t('LoadingLabel')}</div>;
    }

    getComboRows = (currentIndex) => {
        const selectHandle = (idx) => this.onSelect(idx);

/*
        if (!this.props.isDataReady)
            return (<div>{i18next.t('LoadingLabel')}</div>);

*/
        return this.props.renderRows(currentIndex, selectHandle);
    }
}

MnComboBase.defaultProps = {
    allowReset: true,
    selectedIndex: -1,
    onBeforeOpen: () => {
    },
    onAfterOpen: () => {
    },
    onAfterClosed: () => {},
    onSelected: () => {
    },
    onReset: () => {
    },
    renderRows: () => {
        return null
    },
    defaultInputText: i18next.t('PlsSelectLabel'),
    isDataReady: true,
    style: {}
};

MnComboBase.propTypes = {
    onSelected: PropTypes.func,
    onBeforeOpen: PropTypes.func,
    onAfterOpen: PropTypes.func,
    onAfterClosed: PropTypes.func,
    onReset: PropTypes.func,
    renderRows: PropTypes.func,
    allowReset: PropTypes.bool,
    value: PropTypes.string,
    selectedIndex: PropTypes.number,
    defaultInputText: PropTypes.string,
    isDataReady: PropTypes.bool,
    readOnly: PropTypes.bool,
    renderFormControl: PropTypes.func,
    afterOutsideClick: PropTypes.func,
    forceOutsideElementArray: PropTypes.array,
    style: PropTypes.object,
    dropdownWidth: PropTypes.number,
    alignRight: PropTypes.bool,
    customEventToUpdateDimensions: PropTypes.string,
    dropdownZIndex: PropTypes.number,
    allowSelectChecker: PropTypes.func
};

export default MnComboBase;
