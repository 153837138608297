import HttpQuery from "@mnjsplatform/data/lib/api/HttpQuery";

class HomeApi {
    static loadInitialData() {
        let hq = new HttpQuery();
        return hq.makeApiCall("SrHome/Ping");
    }

    static RegisterLogin() {
        let hq = new HttpQuery();
        return hq.makeApiCallPost("SrHome/RegisterLogin", {});
    }

    static quitSession() {
        let hq = new HttpQuery();
        return hq.makeApiCall("SrHome/QuitSession");
    }
}

export default HomeApi
