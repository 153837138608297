import * as types from "../actions/AppActions";
import * as errorTools from "@mnjsplatform/data/lib/utils/errorTools";

export default function homeReducer(
    state = {
        isLoaded: false,
        inOperation: false,
        isError: false,
        isAuthenticated: false,
        errorText: null,
        appParams: {}
    },
    action
) {
    if (action.type === types.QUERY_APP_PARAMS_START) {
        return Object.assign({}, state, {inOperation: true});
    } else if (action.type === types.QUERY_APP_PARAMS_SUCCESS) {
        return Object.assign({}, state, {
            isLoaded: true,
            inOperation: false,
            isError: false,
            appParams: action.data
        });
    } else if (action.type === types.QUERY_APP_PARAMS_ERROR) {
        return Object.assign({}, state,
            {
                inOperation: false,
                isError: true,
                isAuthenticated: !errorTools.isAuthFailed(action.data),
                isUserException: errorTools.isUserException(action.data),
                errorText: errorTools.default(action.data)
            }
        );
    }

    return state;
}
