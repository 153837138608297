import HttpQuery from './HttpQuery';

export default class SubgridApi {
  static initialize(controllerName, parentMethodName, parentRowIndex, isParamset, fieldName, subgridKey, readOnly = false, withParams = false) {
    const hq = new HttpQuery();
    return hq.makeApiCallPost("Subgrid/Initialize", {controllerName, parentMethodName, parentRowIndex, isParamset, fieldName, subgridKey, readOnly, withParams});
  }

  static close(controllerName, parentMethodName, parentRowIndex, isParamset, fieldName, subgridKey) {
    const hq = new HttpQuery();
    return hq.makeApiCallPost("Subgrid/OnClose", {controllerName, parentMethodName, parentRowIndex, isParamset, fieldName, subgridKey});
  }

  static reinitializeSubgrid(controllerName, parentMethodName, parentRowIndex, isParamset, fieldName, subgridKey, parameters) {
    const hq = new HttpQuery();
    return hq.makeApiCallPost("Subgrid/ReInitialize", {controllerName, parentMethodName, parentRowIndex, isParamset, fieldName, subgridKey, parameters});
  }
}