import React, {Component} from "react";
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import {connect} from "react-redux";
import debounce from "lodash/debounce";

const formatChars = {
    "9": "[0-9]",
    "0": "[0-9\\s]",
    "C": "[A-Za-zА-Яа-я]",
    "c": "[A-Za-zА-Яа-я\\s]",
    "A": "[A-Za-zА-Яа-я0-9]",
    "a": "[A-Za-zА-Яа-я0-9\\s]",
    "?": ".",
    "@": "[^!\\s]"
    //"+": "[\\+\\-]" Убрал по просьбе А. Лобачева
}

class InputFieldMask extends Component {
    constructor(props, context) {
        super(props, context);

        this.isValueChanged = false;

        this.state = {
            errorMsg: "",
            initialValue: "",
            currentValue: ""
        };

        this.maskChar = "_";
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    static getDerivedStateFromProps(props, state) {

        const rawVal = props.rawVal || "";
        if (rawVal !== state.initialValue) {
            const res = {currentValue: rawVal, initialValue: rawVal};
            if (rawVal === "") {
                res.errorMsg = "";
            }
            return res;
        }

        return null;
    }

    onChangeValue = (e) => {
        const val = e.target.value;
        this.isValueChanged = true;
        this.setState({"currentValue":val});

        if (this.props.autoApplyParams && val.length >= this.props.minSymbolsToAutoApply)
            this.onEndEditFieldDebounced()
    };

    onEndEditFieldInternal = async () => {

        let mask = this.props.boilerPicture;

        let val = this.getFixedText(mask, this.state.currentValue);

        if (this.isValueChanged) {
            await this.props.dispatch(this.props.dataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, val));
        }

        if (this.props.onEndEditField && this.isValueChanged) {
            this.props.onEndEditField(this.props.rowIndex);
            this.isValueChanged = false;
        }
    };

    getFixedText(mask, valWithMask)
    {
        if (valWithMask == null || valWithMask === "")
            return valWithMask;

        const indexes = [];
        let val = valWithMask;

        let targetPos = 0;
        for (let i = 0; i < mask.length; i++) {
            const maskChar = mask[i];

            if (maskChar === '\\') {
                indexes.push(targetPos);
                i++;
            }
            else if (!formatChars.hasOwnProperty(maskChar))
                indexes.push(targetPos - indexes.length);

            targetPos++;
        }

        for (let i in indexes)
        {
            val = val.substr(0, indexes[i]) + val.substr(indexes[i] + 1);
        }

        val = val.replace(/_/g, "");

        return val;
    }

    onEndEditFieldDebounced = debounce(this.onEndEditFieldInternal, this.props.debounceWait);

    render() {

        if (this.props.readOnly) {
            return (<span>{this.props.value}</span>);
        }

        //const mask = "\\+7(999) 999 99 99";//[firstLetter, digit, letter, " ", digit, letter, digit];

        let mask = this.props.boilerPicture;
        return (
            <div>
                <InputMask className="form-control"
                           maskChar={this.maskChar}
                           value={this.state.currentValue}
                           onChange={this.onChangeValue}
                           mask={mask}
                           alwaysShowMask={true}
                           onBlur={this.onEndEditFieldInternal}
                           formatChars={formatChars}
                />
                <span style={{color: "red", fontSize: '12px'}}>
              {this.state.errorMsg != null && this.state.errorMsg.length > 0 && this.state.errorMsg}
                    &nbsp;
            </span>
            </div>
        );
    }
}

InputFieldMask.propTypes = {
    dispatch: PropTypes.func,
    dataItem: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    value: PropTypes.string,
    rawVal: PropTypes.string,
    readOnly: PropTypes.bool,
    boilerPicture: PropTypes.string,
    onChange: PropTypes.func,
    onEndEditField: PropTypes.func
};

InputFieldMask.defaultProps = {};

export default connect()(InputFieldMask);