import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import MnComboBase from "./MnComboBase";
import i18next from "i18next";
import formatData from "@mnjsplatform/data/lib/utils/formatData";

class MnComboStandalone extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            textValue : ""
        }
    }

    isOwnReady = () => {
        return this.props.ownItem && this.props.ownItem.rawData && this.props.ownItem.isInitialized;
    };

    getFieldInfo = () => {
        return this.props.ownItem.getFieldInfo(this.props.valueFieldName);
    }

    getTitle = () => {
        if (!this.isOwnReady() || this.props.ownItem.rawData.Rows === 0)
            return i18next.t('GridViewEmptyLabel');

        let currentIndex = this.searchCurrentIndex();

        if (currentIndex < 0) {
            return i18next.t('GridViewEmptyLabel');
        }
        let row = this.props.ownItem.rawData.DataTable[currentIndex];
        if (!row) {
            console.error("Ошибка: row = [" + row + "]");
            return "Ошибка";
        }
//        let val = row[this.props.valueFieldName];
        let val = formatData(this.getFieldInfo(), row[this.props.valueFieldName]);

        if (!val && !this.props.readOnly)
            return i18next.t('PlsSelectLabel');

        return val;
    };

    onSelect = (selectedIndex) => {
        this.props.dispatch(this.props.ownItem.goto(selectedIndex))
          .then(() => this.props.onSelected(selectedIndex));
    };

    onResetClick = () => {
        if (this.isOwnReady()) {
            this.onSelect(-1);
        }
    };

    render() {
        const isRequired = !this.props.allowReset;
        const self = this;
        const currentIndex = self.searchCurrentIndex();

        return (
            <MnComboBase allowReset={!isRequired}
                         onBeforeOpen={this.props.onBeforeOpen}
                         onAfterOpen={this.props.onAfterOpen}
                         onReset={this.onResetClick}
                         onSelected={this.onSelect}
                         readOnly={this.props.readOnly}
                         renderRows={this.getComboRows}
                         value={this.getTitle()}
                         selectedIndex={currentIndex}
                         isDataReady={this.isOwnReady()}
            />
        )
    }

    getComboRows = (currentIndex, selectHandle) => {
        const self = this;

        const line = function (row) {

            let values = [];

            for (let fieldInfo of self.props.ownItem.rawData.ResultFieldInfo) {
                if (self.props.ownItem.getFieldIsHidden(fieldInfo))
                    continue;

                if (self.props.dropdownValueOnly && fieldInfo.Name!== self.props.valueFieldName)
                    continue;

                if (self.props.ownItem.getFieldIsHidden(fieldInfo))
                    continue;
                let name = fieldInfo.Name;
//                let val = row[name];
                let val = formatData(fieldInfo, row[name]);
                let width = (fieldInfo.Width ? fieldInfo.Width : 150);

                values.push({name, val, width});
            }

            return values;
        };

        let getRow = function (row) {
            let values = line(row);
            return (
                <div>
                    {values.map((value, index) =>
                        <span key={index} style={{
                            verticalAlign: 'top',
                            display: 'inline-block',
                            whiteSpace: 'normal',
                            width: value.width
                        }}>{value.val}</span>)
                    }
                </div>
            );
        };

        const rows = this.props.ownItem.dataTable();
        const filteredRows = this.getFilteredRowsIndexes(rows, this.state.textValue);

        let rowSet;
        if (filteredRows.length === 0) {
            rowSet = <div style={{paddingLeft: 20, paddingTop: 3, paddingBottom: 3}}>{i18next.t('GridViewEmptyLabel')}</div>;
        }
        else {
            rowSet = filteredRows.map((rowIndex) => {

                    const row = rows[rowIndex];
                    const className = (currentIndex === rowIndex) ? "dropdown-item selected" : "dropdown-item";

                    return (<li role="presentation" key={rowIndex}><a className={className} href=""
                                                               onClick={function(e) {
                                                                   e.preventDefault();
                                                                   selectHandle(rowIndex)
                                                               }}><span>{getRow(row)}</span></a></li>)
              }
            );
        }

        return (<>
                    {this.props.withSearch && <div className="m-2">{this.renderSearch()}</div>}
                    {rowSet}
                </>);
    };

    getFilteredRowsIndexes(rows, text)
    {
        const rowIndexes = rows.map((row, idx) => {
            return idx;
        });

        return rowIndexes.filter((idx) => {

            if (!text || !this.props.valueFieldName)
                return true;

            const row = rows[idx];
            const val = row[this.props.valueFieldName];

            if (!val)
                return false;

            return val.toUpperCase().indexOf(text.toUpperCase()) >= 0;
        });
    }

    renderSearch() {

        const defText = i18next.t("searchBar.defaultBackground");

        return (
          <div className="searchBar w100pc ellips -icon">
              <input type="text" className="form-control"
                     placeholder={defText} onChange={this.handleFilterChange}
                     value={this.state.textValue} />
              <i className="clear zmdi zmdi-close" onClick={this.handleClearSearch}/>
          </div>);
    }

    handleFilterChange = (e) =>
    {
        e.preventDefault();
        this.setState({textValue: e.target.value});
    };

    handleClearSearch = (e) =>
    {
        e.preventDefault();
        this.setState({textValue: ""});
    };

    searchCurrentIndex = () => {

        if (this.isOwnReady())
            return this.props.ownItem.rawData.CurrentRowIndex;

        return -1;
    }
}

MnComboStandalone.defaultProps = {
    rowIndex: 0,
    allowReset: false,
    valueFieldName : "TreeClassName",
    withSearch : false,
    onSelected : () => {},
    dropdownValueOnly: false
};

MnComboStandalone.propTypes = {
    dispatch: PropTypes.func,
    ownItem: PropTypes.object.isRequired,
    onSelected: PropTypes.func,
    bsSize: PropTypes.string,
    allowReset: PropTypes.bool,
    valueFieldName: PropTypes.string,
    readOnly: PropTypes.bool,
    onBeforeOpen: PropTypes.func,
    onAfterOpen: PropTypes.func,
    withSearch:PropTypes.bool,
    dropdownValueOnly:PropTypes.bool,
}

export default connect()(MnComboStandalone);
