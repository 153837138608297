import React, { Component } from "react";
import PropTypes from "prop-types";
import FileDownloadHandle from "./FileDownloadHandle";
import "../../style/ComponentsStyle.css";
import ProgressBar from "../ProgressBar";
import i18next from "i18next";
import FileUploadPanel from "../FileUploadPanel";

class FileInline extends Component {
  openFilePicker = () => {
    this.inputElement.click();
  };

  render() {
    if (this.props.readOnly)
      return (<div className="input-group">
        <FileDownloadHandle {...this.props}/>
      </div>);

    return (
      <FileUploadPanel {...this.props}>
        {({selectedFiles, uploadFiles, onReset}) => {
          const filesSelected = (e) => {
            uploadFiles(e.target.files);
          };

          const reset = (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.inputElement.value = "";
            onReset();
          };

          const selectedText = selectedFiles.length > 0 ? selectedFiles[0].name : "";

          return (
            <>
              <div className="input-group filePicker" onClick={this.openFilePicker}>
                <input ref={x => this.inputElement = x} type="file" lang="en"
                       multiple={this.props.allowUploadMultipleFiles}
                       onChange={filesSelected} />
                <div className="fileNameContainer">
                  <div className="fileNameText">
                    {selectedText}
                  </div>
                  <span className="clear zmdi zmdi-close" onClick={reset} />
                </div>
                <div className="input-group-append chooseFileButton">
                  {this.props.buttonText || i18next.t("FileInline_SelectFile")}
                </div>
              </div>
              <div className="pt-1">
                {this.renderProgressBar()}
              </div>
            </>
          );
        }}
      </FileUploadPanel>
    );
  }

  renderProgressBar() {
    const fileKey = this.props.rowIndex + "::" + this.props.fieldInfo.Name;
    const state = this.props.dataItem.fileProgress[fileKey];
    if (!state)
      return null;

    const pos = (state.loaded / state.total) * 100;
    return <React.Fragment><ProgressBar minimum={0} maximum={100} status={pos} hideOnFinish={false} />{state.uploadState === 0 ? i18next.t("FileInline_FileLoading") : i18next.t("FileInline_FileSaving")}</React.Fragment>
  }
}

FileInline.propTypes = {
  formattedVal: PropTypes.any.isRequired,
  readOnly: PropTypes.bool.isRequired,
  dataItem: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  buttonText: PropTypes.string,
  allowUploadMultipleFiles: PropTypes.bool,
  allowDragAndDrop: PropTypes.bool,
};

FileInline.defaultProps = {
  allowUploadMultipleFiles: false,
  allowDragAndDrop: true
};

export default FileInline;