export class FileStore {

  static files = {};
  static idCnt = 0;

  static addFile(file) {
    const id = "id"+FileStore.idCnt++;
    FileStore.files[id] = file;
    return id;
  }

  static removeFile(fileId) {
    delete FileStore.files[fileId];
  }

  static getFile(fileId) {
    return FileStore.files[fileId];
  }

  static clear() {
    FileStore.files = {};
  }
}