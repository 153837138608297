import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  changeHierarchy,
  changeTree,
  addItemToSet,
  singleItemSelected,
  deleteSelectedItem,
  syncSelectedItems,
  CLASS_TYPE_LIST,
  CLASS_TYPE_TREE
} from "@mnjsplatform/data/lib/actions/classActions";
import GridView from "../GridView/GridView";
import BaseMnTree from "../BaseMnTree";
import {errorRaised} from "@mnjsplatform/data/lib/actions/errorActions";
import MnComboStandalone from "../Dropdown/MnComboStandalone";
import ComplexParam from "../ComplexParam";
import ModalWrapper from "../modalWrapper";
import {ScreenFormatContext} from "@mnjsplatform/data";
import ClassPanelMobile from "./ClassPanelMobile";
import Spinner from "../Spinner";
import i18next from "i18next";
import ClassPanelList from "./ClassPanelList";
import MnSpinner from "../MnSpinner";

const CLASS_SEARCH_FIELD_NAME = "SearchString";

class ClassPanel extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      inSearching: false,
      isHierarchyReady: true,
      isTreeReady: true
    };
  }

  componentDidMount() {
    this.applySearchValue(this.props.searchValue);
  }

  applySearchValue = async(value) => {
    if (
      !!this.props.treeItemParams
      && this.props.treeItemParams.columnExists(CLASS_SEARCH_FIELD_NAME)
      && (this.props.treeItemParams.getField(0, CLASS_SEARCH_FIELD_NAME) || "") !== value
    ) {
      await this.props.dispatch(this.props.treeItemParams.setField(0, CLASS_SEARCH_FIELD_NAME, value));
      await this.props.dispatch(this.props.treeItem.applyParams(this.props.treeItemParams));
      await this.selectTreeRow(0);
    }
  };

  onlyTree = () => {
    return this.props.classDescription && this.props.classDescription.ClassSelectSource === CLASS_TYPE_TREE;
  };

  hierarchyChanged = (newIndex) => {

    if (newIndex < 0)
      return;

    this.setState({isHierarchyReady: false});
    changeHierarchy(this.props.classKey, newIndex)
      .then(() => this.props.dispatch(this.props.treeItem.refresh()))
      .then(() => {
        if (!this.onlyTree()) this.props.dispatch(this.props.listItem.refresh());
      })
      .catch(error => this.props.dispatch(errorRaised(error)))
      .finally(() => this.setState({isHierarchyReady: true}));
  }

  selectTreeRow = (index) => {

    this.setState({ isTreeReady: false });
    if (this.onlyTree()) {
      return this.props.dispatch(this.props.treeItem.goto(index))
        .then(() => this.selectItem(index, this.props.treeItem.getRow(index)))
        .finally(() => this.setState({ isTreeReady: true }));
    } else {
      return this.props.dispatch(this.props.treeItem.goto(index))
        .then(() => changeTree(this.props.classKey, index))
        .then(() => this.props.dispatch(this.props.listItem.refresh()))
        .catch(error => this.props.dispatch(errorRaised(error)))
        .finally(() => this.setState({ isTreeReady: true }));
    }
  }

  selectItem = (rowIndex, row) => {

    if (!this.props.classDescription.SingleSelect) {
      addItemToSet(this.props.classKey, rowIndex)
        .then(() => this.props.dispatch(this.props.selectItem.refresh()))
        .catch(error => this.props.dispatch(errorRaised(error)))
    }
    else {
      singleItemSelected(this.props.classKey, rowIndex)
        .then(() => this.onConfirm())
        .catch(error => this.props.dispatch(errorRaised(error)));
    }
  };

  deleteItem = (row) => {

    if (!this.props.selectItem)
      return;

    const rowIndex = this.props.selectItem.getRowIndex(row);

    if (rowIndex < 0)
      return;

    this.props.dispatch(deleteSelectedItem(this.props.classDescription, this.props.classKey, rowIndex))
      .catch(error => this.props.dispatch(errorRaised(error)));
  };

  deleteAllItems = () => {
    if (!this.props.selectItem || !this.props.selectItem.rawData || this.props.selectItem.rowsCount() === 0)
      return;

    return this.props.clearSelection(this.props.classKey)
      .then(data => this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, data.changedFields)))
      .then(() => this.props.dispatch(syncSelectedItems(this.props.classDescription, this.props.classKey, this.props.rowIndex)))
      .catch(error => this.props.dispatch(errorRaised(error)))
  };

  onConfirm = (dismissOnly = false) => {
    const targetDismiss = dismissOnly && this.props.classDescription.SingleSelect;
    this.props.onClose(targetDismiss);
  };

  beforeSearchApply = () => {

  };

  afterSearchApply = () => {
    this.selectTreeRow(0)
        .finally(() => {this.setState({inSearching: false})});
  };

  onSearchClear = (e, h) => {
    this.props.dispatch(this.props.treeItemParams.setField(0, CLASS_SEARCH_FIELD_NAME, null))
      .then(data => {
        h.applyParams(e)
      });
  };

  nodeRender = (r, i, lbl) => {

    return (
      <span onClick={() => this.selectTreeRow(i)} style={{ cursor: "pointer", lineHeight: "1.5" }}
            title={i18next.t('Choose')}>{lbl}</span>
    );
  };

  renderCellDelete = (row, fieldInfo, fieldIndex, renderCellDefault) => {

    if (fieldIndex === 0)
      return (
        <div>
          <i className="zmdi zmdi-file mr1ex"/>
          {renderCellDefault(row, fieldInfo, fieldIndex)}
          <i className="zmdi zmdi-close ml1em" onClick={() => this.deleteItem(row)} style={{ cursor: "pointer" }}/>
        </div>
      );

    return renderCellDefault(row, fieldInfo, fieldIndex);
  };

  checkAutoApplyParams = (f) => {
      return f.Name === "ShowClosed";
  };

  renderSearchParam = (h) => {
    if (!h.fi(CLASS_SEARCH_FIELD_NAME))
      return null;

      const containerStyle = {
          display: 'inline-block',
          position: 'absolute',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
          height: '100%',
          zIndex: 2
      };

    let showSpinner = this.state.inSearching;

    return (
      <div className="row">
          <div className="col-md-7">
              <span className="input-title">{h.lbl(CLASS_SEARCH_FIELD_NAME)}</span>
              <div className="form-group">
                        <span>
                            <div className="input-group classControl">
                                {h.ed(CLASS_SEARCH_FIELD_NAME)}
                                <div>
                                    <span className="clear zmdi zmdi-close" onClick={(e) => {
                                      this.onSearchClear(e, h)
                                    }}/>
                                </div>
                                <div className="input-group-append" onClick={(e) => {this.setState({inSearching: true}); h.applyParams(e);}}>
                                    <button className="btn" type="button">
                                        {showSpinner && <Spinner size={"20px"} colorHex={'FFFFFF'} containerStyle={containerStyle}/>}
                                        <i className="search zmdi zmdi-search" style={showSpinner ? {color: 'transparent'} : {}}/>
                                    </button>
                                </div>
                            </div>
                        </span>
              </div>
          </div>
          {
              h.fi("ShowClosed") &&
              <div className="col-md-5" style={{display: "flex", alignItems: "flex-end", marginBottom:"3px"}}>
                  <label className="form-check-inline">
                      {h.ed("ShowClosed")}&nbsp;{h.lbl("ShowClosed")}
                  </label>
              </div>
          }
      </div>
    );
  };

  renderPanelHeader = () => {

    let classSearchVisible = this.props.treeItemParams
      && this.props.treeItemParams.isInitialized
      && !this.props.treeItemParams.inOperation
      && this.props.treeItemParams.rawData
      && this.props.treeItemParams.rowsCount() > 0
      && this.props.treeItemParams.columnExists(CLASS_SEARCH_FIELD_NAME);

    return (
      <div className="modal-header">
        <div className="col-md-12 row" style={{ paddingLeft: "0px" }}>
          {this.props.classDescription.HierarchySelectAvailable
            ? <div className="col-md-4">
              <span className="input-title">{i18next.t('Hierarchy')}</span>
              <MnComboStandalone ownItem={this.props.comboItem} onSelected={this.hierarchyChanged} readOnly={this.props.classDescription.HierarchySelectGrayed}/>
            </div>
            : this.props.fieldInfo && this.props.fieldInfo.Title
              ? <div className="col-md-5"><h4 className="modal-title">{this.props.fieldInfo.Title}</h4></div>
              : <div className="col-md-4"><h4 className="modal-title">{i18next.t('Classifier')}</h4></div>
          }
          {classSearchVisible
          && <div className="col-md-8">
            <ComplexParam dataItem={this.props.treeItemParams} parentDataItem={this.props.treeItem}
                          autoFocusField={CLASS_SEARCH_FIELD_NAME}
                          renderParams={(h) => this.renderSearchParam(h)}
                          afterApplyParams={this.afterSearchApply}
                          beforeApplyParams={this.beforeSearchApply}
                          autoApplyParamsFunc={this.checkAutoApplyParams}
                          rootPath={this.props.rootPath} applyOnEnterKey />
          </div>
          }
        </div>
        <button type="button" className="close" data-dismiss="modal" onClick={()=>this.onConfirm(true)}>
          <i className="zmdi zmdi-close"/>
        </button>
      </div>
    );
  };

  renderPanelFooter = () => {
    return (
      <div className="modal-footer">
        {!this.props.classDescription.SingleSelect &&
        <div className="footbox -limited">
          <div>
            <p className="text-grey pb1ex">{i18next.t('Selected')}:</p>
          </div>
          <div>
            <button className="btn btn-link -pure -noborder antipod nom noplr" onClick={this.deleteAllItems}>
            {i18next.t('ResetSelected')}
              <i className="zmdi zmdi-close antipod"/>
            </button>
          </div>
        </div>
        }
        <div className="footbox -limited text-left">
          {
            !this.props.classDescription.SingleSelect &&
            <div style={{ maxHeight: "200px", overflow: "auto", paddingLeft: "0px" }} className="col-md-12">
              <GridView dataItem={this.props.selectItem} renderCell={this.renderCellDelete}/>
            </div>
          }
        </div>
        <div className="footbox -limited" style={{ marginTop: "10px" }}>
          <div>&nbsp;</div>
          {!this.props.classDescription.SingleSelect &&
          <div>
            <button type="button" className="btn btn-regular -wide" onClick={this.onConfirm}>
            {i18next.t('Next')}
            </button>
          </div>
          }
          {this.props.classDescription.SingleSelect &&
          <div>
            <button type="button" className="btn btn-regular -wide" onClick={()=>this.onConfirm(true)}>
            {i18next.t('Close')}
            </button>
          </div>
          }
        </div>
      </div>
    );
  };

  render() {
    if (this.props.classDescription.ClassSelectSource === CLASS_TYPE_LIST) {
      return (
        <ClassPanelList {...this.props}
                        selectItemFunc={this.selectItem}
                        deleteItem={this.deleteItem}
                        deleteAllItems={this.deleteAllItems}
                        onConfirm={this.onConfirm}
                        checkAutoApplyParams={this.checkAutoApplyParams}
        />
      );
    }

    if (!this.props.treeItem || (!this.onlyTree() && !this.props.listItem)) return null;

    const mainContainerHeight = `calc(100vh - ${!this.props.classDescription.SingleSelect ? "500" : "400"}px)`;

    return <ScreenFormatContext.Consumer>
      {
        isDesktop => {
          return (
            <>
              <MnSpinner active={!this.state.isHierarchyReady || !this.state.isTreeReady}/>{/*Y!!!*/}
              {isDesktop ? (<ModalWrapper>
                      <div className="modal fade show" id="myModal" style={{display: "block"}}>
                        <div className="modal-dialog modal-dialog-centered ClassPanel">
                          <div className="modal-content h-95 mh-95">
                            {this.renderPanelHeader()}
                            <div style={{ /*overflowX: "hidden", overflowY: "auto",*/
                              height: mainContainerHeight,
                              minHeight: "200px"
                            }}>
                              <div className="modal-body row h-100">
                                <div style={{ /*maxHeight: "500px",*/ overflow: "auto"}}
                                     className={"h-100 col-md-" + (this.onlyTree() ? "12" : "4")}>
                                  <BaseMnTree dataItem={this.props.treeItem} nodeRender={this.nodeRender}
                                              controlOnlyByImage={this.onlyTree()}/>
                                </div>
                                {!this.onlyTree()
                                // Включение вертикального скроллинга. Убрана предыдущая правка, так как непонятно, для чего она была.
                                && <div
                                  style={{overflow: "auto", /*overflowX: "auto", overflowY: "hidden", minHeight: "200px", maxHeight: "400px"*/}}
                                  className="h-100 col-md-8">
                                  <GridView dataItem={this.props.listItem} readOnly={true} onRowClick={this.selectItem}
                                            allowPagination={true}
                                            rowCursor={"pointer"}/>
                                </div>
                                }
                              </div>
                            </div>
                            {this.renderPanelFooter()}
                          </div>
                        </div>
                      </div>
                    </ModalWrapper>
                  )
                  : (
                    <ClassPanelMobile {...this.props}
                                      deleteItem={this.deleteItem}
                                      deleteAllItems={this.deleteAllItems}
                                      hierarchyChanged={this.hierarchyChanged}
                                      selectTreeRow={this.selectTreeRow}
                                      onlyTree={this.onlyTree}
                                      onSearchClear={this.onSearchClear}
                                      nodeRender={this.nodeRender}
                    />
                  )
              }
            </>
          );
        }
      }
    </ScreenFormatContext.Consumer>
  }
}

ClassPanel.propTypes = {
    dispatch: PropTypes.func.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    classKey: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    classDescription: PropTypes.object,
    searchValue: PropTypes.string
};

ClassPanel.defaultProps = {
};

function mapStateToProps(state, ownProps) {

    const classDescription = state.classRepository[ownProps.classKey].classDescription;
    if (classDescription) {
      const comboItem = state.dynState[classDescription.comboItemPath];
      const treeItem = state.dynState[classDescription.treeItemPath];
      const treeItemParams = state.dynState[classDescription.treeItemParamsPath];
      const listItem = classDescription.ClassSelectSource === CLASS_TYPE_LIST
        ? state.dynState[classDescription.listOnlyItemPath]
        : state.dynState[classDescription.listItemPath];
      const selectItem = state.dynState[classDescription.selectItemPath];

      return {
        classDescription,
        comboItem,
        treeItem,
        listItem,
        selectItem,
        treeItemParams
      };
    }
}

export default connect(mapStateToProps)(ClassPanel);