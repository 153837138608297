import React, { Component } from "react";
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import ToggleButton from "react-toggle-button";

class MnToggleButton extends Component {
  render() {
    return (
      <ToggleButton
        activeLabel={this.props.activeLabel}
        inactiveLabel={this.props.inactiveLabel}
        colors={{
          activeThumb: {
            base: 'rgb(255,255,255)',
            hover: null
          },
          /*inactiveThumb: {
            base: null,
            hover: null
          },*/
          active: {
            base: 'rgb(52,199,89)',
            hover: null
          },
          inactive: {
            base: 'rgb(233,233,234)',
            hover: null
          }
        }}
        containerStyle={{width:'39px'}}
        thumbAnimateRange={[2, 17]}
        thumbStyle={{width: '20px', height: '20px', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 6px 1px'}}
        trackStyle={{width: '39px', height: '24px'}}
        value={this.props.value}
        passThroughInputProps={{
          readOnly: this.props.readOnly,
          disabled: this.props.readOnly
        }}
        onToggle={this.props.onToggle}
      />
    )
  }
}

MnToggleButton.propTypes = {
  onToggle: PropTypes.func,
  value: PropTypes.bool,
  readOnly: PropTypes.bool,
  activeLabel: PropTypes.string,
  inactiveLabel: PropTypes.string,

}

MnToggleButton.defaultProps = {
  value: false,
  readOnly: false
}

const ToggleButtonCP = (props) => {
  const dispatch = useDispatch();

  const onToggle = async(value) => {
    const isChecked = value ? 0 : 1;
    await dispatch(props.dataItem.setField(props.rowIndex, props.fieldInfo.Name, isChecked));
    if (props.onEndEditField)
      props.onEndEditField();
  };

  return (
    <MnToggleButton value={!!props.value}
                    readOnly={!!props.readOnly}
                    activeLabel={null}
                    inactiveLabel={null}
                    onToggle={onToggle}
    />
  );
}

ToggleButtonCP.propTypes = {
  dataItem: PropTypes.object,
  fieldInfo: PropTypes.object,
  rowIndex: PropTypes.number,
  readOnly: PropTypes.bool,
  value: PropTypes.any,
  onEndEditField: PropTypes.func
};

export const WebCustomTemplate_ToggleButton = ToggleButtonCP;

export default MnToggleButton;