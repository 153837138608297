import PropTypes from 'prop-types';
import React from 'react';

class GridViewHeaderCell extends React.Component {
  onSort = () => {
    if (this.props.onSort) {
      this.props.onSort(this.props.fieldInfo);
    }
  }

  renderCellContent = (cellProps) => {
    const className = `table_column-header${!!cellProps.allowSort ? "_sortable" : ""}`;

    if (this.props.renderHeaderCell) {
      return this.props.renderHeaderCell(cellProps);
    }

    return (
      <span onClick={cellProps.onSort} className={className}>
        {cellProps.title}
        {cellProps.sortArrow}
      </span>
    );
  };

  render() {
    const title = this.props.overrideTitle || this.props.fieldInfo['Header'].replace(/\|/g, ' ');
    const allowSort = this.props.fieldInfo['IsSortable'] && this.props.sortInfo && this.props.onSort;
    const sortArrow = allowSort && this.props.sortInfo.FieldName === this.props.fieldInfo['Name']
      ? this.props.sortInfo.Asc ? '↑' : '↓'
      : "";

    const cellProps = {
      title,
      sortArrow,
      allowSort,
      onSort: this.onSort,
      fieldIndex: this.props.fieldIndex,
      fieldInfo: this.props.fieldInfo
    };

    const cellStyle = this.props.headerCellStyleProvider();
    return (
      <th rowSpan={this.props.fieldInfo.rowSpan || 1} colSpan={this.props.fieldInfo.colSpan || 1} style={cellStyle}>
        {this.renderCellContent(cellProps)}
      </th>
    );
  }
}

GridViewHeaderCell.propTypes = {
  fieldIndex: PropTypes.number,
  fieldInfo: PropTypes.object,
  onSort: PropTypes.func,
  sortInfo: PropTypes.object,
  overrideTitle: PropTypes.string,
  updateStateFlag: PropTypes.any,
  renderHeaderCell: PropTypes.func,
  headerCellStyleProvider: PropTypes.func
};

export default GridViewHeaderCell;