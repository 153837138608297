import React, {Component} from "react";
import PropTypes from 'prop-types';
import ClassSelectorBase from "./ClassSelectorBase";
import {clearSelection, initClassManager, saveValueToItem} from "@mnjsplatform/data/lib/actions/classActions";

class ClassSelectorEmbedded extends Component {
    render() {
        return (
          <ClassSelectorBase {...this.props}
                             saveValueToItem={saveValueToItem}
                             clearSelection={clearSelection}
                             initClassManager={initClassManager}
          />
        );
    }
}

ClassSelectorEmbedded.propTypes = {
    parentDataItem: PropTypes.object,
    rowIndex: PropTypes.number,
    isParamset  : PropTypes.bool,
    fieldInfo: PropTypes.object,
    value: PropTypes.string,
    onSelected: PropTypes.func,
    classKey: PropTypes.string,
    readOnly: PropTypes.bool,
    allowInlineSearch: PropTypes.bool,
    getOpenHandler: PropTypes.func
};

ClassSelectorEmbedded.defaultProps = {
    classKey: "",
    allowInlineSearch: false,
    getOpenHandler: () => {}
};

export default ClassSelectorEmbedded;
