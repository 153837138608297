import PropTypes from 'prop-types';
import React from 'react';
import GridViewCell from './GridViewCell';
import RowEditMenu from "./RowEditMenu";
import {GridRowEditMode} from "./GridView";
import i18next from "i18next";

class GridViewRow extends React.Component {
    constructor(props) {
        super(props);

        this.renderCellDefault = this.renderCellDefault.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (this.props.row !== nextProps.row
            || this.state !== nextState
            || this.props.mobileMode !== nextProps.mobileMode
            || this.props.readOnly !== nextProps.readOnly
            || this.props.updateStateFlag !== nextProps.updateStateFlag);
    }

    getRealFieldInfo(fieldInfo) {
        let realFieldInfo = fieldInfo;
        if (fieldInfo.Action.VirtCol) {
            realFieldInfo = this.props.dataItem.getVirtualFieldInfo(fieldInfo, this.props.row);
        }
        return realFieldInfo;
    }

    getTreeExpandField() {

        const getFieldInfo = (fieldInfoList) => {
            for (let fieldInfo of fieldInfoList) {
                if (fieldInfo.Action.IsLabel)
                    return fieldInfo;
            }

            return fieldInfoList[0];
        };

        if (this.treeExpandFieldKey!==this.props.resultFieldInfo) {
            this.treeExpandField = getFieldInfo(this.props.resultFieldInfo);
            this.treeExpandFieldKey = this.props.resultFieldInfo;
        }

        return this.treeExpandField;
    }

    renderCellDefault(row, fieldInfo, fieldIndex) {
        return (<GridViewCell key={fieldIndex} row={this.props.row} rowIndex={this.props.rowIndex} fieldInfo={fieldInfo}
                              dataItem={this.props.dataItem} readOnly={this.props.readOnly}
                              cellAttributes={this.props.cellAttributes[fieldInfo.Name]}
                              customFieldExMap={this.props.customFieldExMap}
                              rootPath={this.props.rootPath}
                              updateStateFlag={this.props.updateStateFlag}
                              cellStyleProvider={this.props.cellStyleProvider}
                              enableCellLinesLimit={this.props.enableCellLinesLimit}
                              cellLongTextMaxLength={this.props.cellLongTextMaxLength}
                              pageHeaderHeight={this.props.pageHeaderHeight}
                              renderSubgridRowEx={this.props.renderSubgridRowEx}
                              showSubgridHeader={this.props.showSubgridHeader}
                              maxSubgridWidthBody={this.props.maxSubgridWidthBody}
        />);
    }

    renderCell(row, fieldInfo, fieldIndex) {
        if (this.props.renderCell) {
            return this.props.renderCell(row, fieldInfo, fieldIndex, this.renderCellDefault, this.props.rowIndex,
              this.props.dataItem, this.props.cellAttributes, this.props.customFieldExMap, this.props.readOnly);
        }
        else if (this.props.renderCellEx)
        {
            const cellParams = this.getCellParams(row, fieldInfo, fieldIndex);
            return this.props.renderCellEx(cellParams);
        }

        return this.renderCellDefault(row, fieldInfo, fieldIndex);
    }

    getCellParams = (row, fieldInfo, fieldIndex) => {
        const cellParams = {
            key: fieldIndex,
            row: this.props.row,
            rowIndex: this.props.rowIndex,
            fieldInfo: fieldInfo,
            fieldIndex: fieldIndex,
            dataItem: this.props.dataItem,
            readOnly: this.props.readOnly,
            cellAttributes: this.props.cellAttributes[fieldInfo.Name],
            customFieldExMap: this.props.customFieldExMap,
            rootPath: this.props.rootPath,
            updateStateFlag: this.props.updateStateFlag,
            cellStyleProvider: this.props.cellStyleProvider,
            renderCellDefault: this.renderCellDefault,
            enableCellLinesLimit: this.props.enableCellLinesLimit,
            cellLongTextMaxLength: this.props.cellLongTextMaxLength,
            pageHeaderHeight: this.props.pageHeaderHeight
        };

        return cellParams;
    }

    renderCollapseButton = () => {
        return (
            <button className="btn mn-btn-link text-decoration-none" onClick={(e) => {
                e.preventDefault();
                this.props.onCollapse(this.props.rowIndex)
            }}><i className="zmdi zmdi-minus-square" /></button>
        );
    };

    renderExpandButton = () => {
        return (
            <button className="btn mn-btn-link text-decoration-none" onClick={(e) => {
                e.preventDefault();
                this.props.onExpand(this.props.rowIndex)
            }}><i className="zmdi zmdi-plus-square" /></button>
        );
    };

    renderCellExpandWrapped(row, fieldInfo, fieldIndex) {

        const rowTreeInfo = this.props.dataItem.rawData.RowTreeInfo[this.props.rowIndex];

        if (!rowTreeInfo)
            return this.renderCell(row, fieldInfo, fieldIndex);

        const showTreeControls = !rowTreeInfo.IsEmpty;

        const renderControls = () => {
            let control = rowTreeInfo.IsExpanded
              ? this.renderCollapseButton()
              : this.renderExpandButton();
            return <>{control}&nbsp;</>;
        }

        const levelOffset = rowTreeInfo.Level * 20 + "px";

        return <React.Fragment>
            <div style={{paddingLeft:levelOffset}}>
                {showTreeControls && renderControls()}
                {this.renderCell(row, fieldInfo, fieldIndex)}
            </div>
        </React.Fragment>

    }

    renderMoreItem({defaultText, onClick}) {
        return (<button className="btn btn-regular dropdown-toggle" type="button"
                        onClick={onClick}>{defaultText}</button>)
    }

    rowClick = (rowIndex, row, e) => {
        const rowTreeInfo = this.props.dataItem.rawData.RowTreeInfo[this.props.rowIndex] || {};

        if (this.props.isCollapseByRowClick && !rowTreeInfo.IsEmpty) {
            if (rowTreeInfo.IsExpanded) {
                this.props.onCollapse(this.props.rowIndex);
            } else {
                this.props.onExpand(this.props.rowIndex);
            }
        }

        this.props.onRowClick(rowIndex, row, e);
    }

    renderLinear() {
        const rowStyle = Object.assign({cursor: this.props.rowCursor}, this.props.rowStyleProvider(this.props.rowIndex, this.props.row, this.props.dataItem));

        return (
            <tr onClick={(e) => this.rowClick(this.props.rowIndex, this.props.row, e)}
                style={rowStyle}
                className={this.props.rowClassName}
            >
                {this.renderEditMenu()}
                {this.props.resultFieldInfo
                    .filter(fi => !fi.Action || !fi.Action.Unite)
                    .map(this.renderCellPlaceholder)}
            </tr>
        );
    }

    renderCellPlaceholder = (fieldInfo, fieldIndex) => {
        const realFieldInfo = this.getRealFieldInfo(fieldInfo);
        const isExpandableCell = this.getTreeExpandField() === fieldInfo;

        const style = Object.assign({textAlign: fieldInfo['AlignH']}, this.props.cellStyleProvider(this.props.dataItem, this.props.rowIndex, realFieldInfo));

        return (<td style={style} key={fieldIndex} onClick={(e) => this.props.onCellClick(this.props.row, fieldInfo, e)}>
            {isExpandableCell ? this.renderCellExpandWrapped(this.props.row, realFieldInfo, fieldIndex) :
            this.renderCell(this.props.row, realFieldInfo, fieldIndex)}
        </td>)
    };

    renderEditMenu() {

        const canEdit = this.props.dataItem.getCanEdit();
        const canDelete = this.props.dataItem.getCanDelete();
        const showMenu = !this.props.readOnly && (canEdit || canDelete);

        if (!showMenu)
            return null;

        return <RowEditMenu canEdit={canEdit} canDelete={canDelete}
                            onEdit={() => {
                                this.props.onEditRow(this.props.rowIndex);
                            }}
                            onDelete={() => {
                                this.props.onDeleteRow(this.props.rowIndex);
                            }}
                            onSave={() => {
                                this.props.onSaveRow();
                            }}
                            onCancel={() => {
                                this.props.onCancelEditRow();
                            }}
                            renderMoreItem={this.renderMoreItem}
                            moreItemClassName="ml1ex"
                            rowEditMode={this.props.rowEditMode}
                            inlineEditIsActive={this.props.dataItem.rowInEditIndex === this.props.rowIndex}
        />;
    }

    renderAdaptive() {
        const rowIsInInlineEditing = this.props.rowEditMode === GridRowEditMode.inline && this.props.dataItem.rowInEditIndex === this.props.rowIndex;

        const items = [];
        const maxColumns = rowIsInInlineEditing ? 1 : (this.props.adaptiveMaxColumns || 2);
        const itemsCount = this.props.resultFieldInfo.length;
        let currentItem = 0;
        let row = 0;
        let currentRowWeight = 0;
        let showTreeControls = false;
        let isExpanded = false;
        let treeLevel = 0;

        const columnWeight = 12 / maxColumns;

        const rowTreeInfo = this.props.dataItem.rawData.RowTreeInfo[this.props.rowIndex];
        let cellOffset = -1.5;
        if (rowTreeInfo) {
            cellOffset = cellOffset + rowTreeInfo.Level * 1;
            showTreeControls = !rowTreeInfo.IsEmpty;
            isExpanded = rowTreeInfo.IsExpanded;
            treeLevel = rowTreeInfo.Level;
        }

        const allRowCells = [];
        while (currentItem < itemsCount) {
            let fi = this.props.resultFieldInfo[currentItem++];

            const header = this.props.overrideTitle[fi.Name] ? this.props.overrideTitle[fi.Name] : fi.Header;

            const uniteCell = fi.Action && fi.Action.Unite;
            if (!rowIsInInlineEditing && !this.props.row[fi.Name] && !fi.IsFrozen && this.props.row[fi.Name] !== 0 && !this.props.overrideVisibility[fi.Name] && !uniteCell)
                continue;

            const showLbl = this.props.mobileHeaderVisibility[fi.Name] !== undefined ? this.props.mobileHeaderVisibility[fi.Name] : true;

            let span = (fi.FooterSpan ? fi.FooterSpan : 1);

            if (!showLbl || uniteCell)
                span = maxColumns;

            let composite = header + this.props.row[fi.Name];
            if (composite.length > 30)
                span = 2;

            let rowCell = {
                title: header.startsWith('^') ? "" : header.replace(/\|/g, ' '),
                span: span * columnWeight,
                weight: span,
                fieldInfo: fi,
                fieldIndex: currentItem,
                isExpandableCell : this.getTreeExpandField() === fi,
                showTreeControls,
                isExpanded,
                uniteCell,
                treeLevel
            };

            allRowCells.push(rowCell);
        }

        let topLevelCells = allRowCells;
        if (allRowCells.some(rowCell => rowCell.uniteCell)) {
            let innerCellNames = allRowCells
                .filter(rowCell => rowCell.uniteCell)
                .map(rowCell => rowCell.uniteCell)
                .join(",")
                .split(",");

            topLevelCells = allRowCells
                .filter(rowCell => !innerCellNames.some(cn => cn === rowCell.fieldInfo.Name))
                .map(rowCell => {
                    if (rowCell.uniteCell) {
                        const cellNames = rowCell.uniteCell.split(",");
                        rowCell.innerCells = allRowCells.filter(rowCell => cellNames.some(cn => cn === rowCell.fieldInfo.Name));
                    }

                    return rowCell;
                });
        }

        topLevelCells.forEach((rc, index, arr) => {
            let addCell = (rowCell) => {
                if (!items[row]) {
                    items[row] = [];
                }

                if (currentRowWeight + rowCell.weight > maxColumns) {
                    const currentRow = items[row];
                    const leftSpace = maxColumns - currentRowWeight;
                    if (currentRow.length > 0) {
                        const prevCell = currentRow[currentRow.length - 1];
                        prevCell.span = prevCell.span + leftSpace * columnWeight;
                        prevCell.weight = prevCell.weight + leftSpace;
                    }

                    items[++row] = [];
                    currentRowWeight = 0;
                }

                currentRowWeight += rowCell.weight;
                items[row].push(rowCell);
            };

            addCell(rc);
            if (rc.innerCells) {
                rc.innerCells.forEach(rc1 => addCell(rc1));
            }
        });

        if (items.length > 0 && items[items.length - 1].length === 1) {
            items[items.length - 1][0].span = 12;
            items[items.length - 1][0].weight = 2;
        }

        const canEdit = this.props.dataItem.getCanEdit();
        const canDelete = this.props.dataItem.getCanDelete();
        const showMenu = !this.props.readOnly && (canEdit || canDelete);

        const self = this;
        const rowStyle = Object.assign({cursor: this.props.rowCursor}, this.props.rowStyleProvider(self.props.rowIndex, self.props.row, self.props.rowIndex, self.props.dataItem));

        let buttonsForInlineEdit = null;
        if (this.props.rowEditMode === GridRowEditMode.inline && this.props.dataItem.rowInEditIndex === this.props.rowIndex) {
            buttonsForInlineEdit = (
                <div className="d-flex" style={{justifyContent: "space-around"}}>
                    <button type="button" className="btn gridInlineEditButton p-3" title={i18next.t('Save')} onClick={e => {
                        e.preventDefault();
                        this.props.onSaveRow();
                    }}>
                        <i className="fas fa-check" style={{color: "green"}} />
                    </button>
                    <button type="button" className="btn gridInlineEditButton p-3" title={i18next.t('Cancel')} onClick={e => {
                        e.preventDefault();
                        this.props.onCancelEditRow();
                    }}>
                        <i className="fas fa-times" style={{color: "red"}} />
                    </button>
                </div>
            );
        }

        return (
            <tr onClick={(e) => self.rowClick(self.props.rowIndex, self.props.row, e)}
                style={rowStyle}
                className={this.props.rowClassName}
            >
                <td style={{fontSize: "16px"}}>
                    {showMenu && <span className="float-right">
                                            <RowEditMenu canEdit={canEdit} canDelete={canDelete}
                                                         onEdit={function () {
                                                             self.props.onEditRow(self.props.rowIndex);
                                                         }}
                                                         onDelete={function () {
                                                             self.props.onDeleteRow(self.props.rowIndex);
                                                         }}
                                                         renderMoreItem={this.renderMoreItem}
                                                         moreItemClassName="ml1ex"
                                                         mobileMode={true}
                                                         rowEditMode={this.props.rowEditMode}
                                                         inlineEditIsActive={this.props.dataItem.rowInEditIndex === this.props.rowIndex}
                                            /></span>}
                    {items.map(
                        function (row, rowIndex) {//todo: rowIndex always 0!!!
                            let className = row.length === 1 && row[0].innerCells ? "row justify-content-center font-weight-bold" : "row";
                            return (
                                <div className={className} key={rowIndex}
                                     // style={{marginLeft: cellOffset + "em", marginRight: "-1.5em"}}
                                >
                                    {row.map(self.renderAdaptiveRow)}
                                </div>
                            );
                        },
                        this
                    )}
                    {buttonsForInlineEdit}
                </td>
            </tr>
        );
    }

    renderAdaptiveRow = (item, fieldIndex) => {//todo: fieldIndex always 0!!!
        const lblText = item.title ? item.title + " " : "";
        const fieldInfo = item.fieldInfo;
        let realFieldInfo = fieldInfo;

        if (fieldInfo.Action.VirtCol) {
            realFieldInfo = this.props.dataItem.getVirtualFieldInfo(fieldInfo, this.props.row);
        }

        const showLbl = this.props.mobileHeaderVisibility[realFieldInfo.Name] !== undefined ? this.props.mobileHeaderVisibility[realFieldInfo.Name] : true;
        const showTreeControls = item.isExpandableCell && item.showTreeControls;

        const renderControls = () => {
            return item.isExpanded
                ? this.renderCollapseButton()
                : this.renderExpandButton();
        };

        //fix стилей для режима inline-edit
        let classNameBase = "col-xs-";
        const marginBottom = (showLbl || !!this.props.row[realFieldInfo]) ? "0.2em" : 0;
        let rowStyle = {marginLeft: "0.25em", marginBottom};
        if (this.props.rowEditMode === GridRowEditMode.inline) {
            classNameBase = "col-";
            rowStyle = {marginBottom};
        }

        if (showTreeControls && item.treeLevel) {
            rowStyle.marginLeft = item.treeLevel + ".25em";
        }

        return (
            <div className={classNameBase + item.span + " vertical-align"} onClick={(e) => this.props.onCellClick(this.props.row, fieldInfo, e)}
                 key={fieldIndex}
                 style={rowStyle}>
                {showTreeControls && <span>{renderControls()}&nbsp;</span>}
                {showLbl && <span className="grid-label">{lblText}</span>}
                <span>{this.renderCell(this.props.row, realFieldInfo, item.fieldIndex)}</span>
            </div>
        );
    };

    render() {

        if (!this.props.row)
            return null;

        if (this.props.mobileMode)
            return this.renderAdaptive();

        return this.renderLinear();
    }
}

GridViewRow.defaultProps = {
    adaptiveMaxColumns: 2,
    rowCursor: "auto",
    onRowClick: () => {
    },
    onCellClick: () => {
    },
    readOnly: false,
    mobileMode: false,
    cellAttributes: {},
    mobileHeaderVisibility: {},
    overrideTitle: {},
    rowStyleProvider : () => {return {}},
    cellStyleProvider : () => {return {}},
    rowClassName: ""
};

GridViewRow.propTypes = {
    resultFieldInfo: PropTypes.array.isRequired,
    row: PropTypes.object,
    renderCell: PropTypes.func,
    readOnly: PropTypes.bool,
    isTree: PropTypes.bool,

    onEditRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
    onSaveRow: PropTypes.func,
    onCancelEditRow: PropTypes.func,
    onExpand: PropTypes.func,
    onCollapse: PropTypes.func,

    labelFieldInfo: PropTypes.object,

    rowIndex: PropTypes.number,
    dataItem: PropTypes.object,
    adaptiveThreshold: PropTypes.number,
    adaptiveMaxColumns: PropTypes.number,
    onRowClick: PropTypes.func,
    rowCursor: PropTypes.string,
    mobileMode: PropTypes.bool,
    cellAttributes: PropTypes.object,
    mobileHeaderVisibility: PropTypes.object,
    overrideTitle: PropTypes.object,
    overrideVisibility:PropTypes.object,

    updateStateFlag: PropTypes.any,
    customFieldExMap: PropTypes.object,
    onCellClick: PropTypes.func,
    rootPath: PropTypes.string,
    rowStyleProvider: PropTypes.func,
    cellStyleProvider: PropTypes.func,
    renderCellEx: PropTypes.func,
    rowEditMode: PropTypes.number,
    rowClassName: PropTypes.string,
    enableCellLinesLimit: PropTypes.bool,
    cellLongTextMaxLength: PropTypes.number,
    pageHeaderHeight: PropTypes.number,
    isCollapseByRowClick: PropTypes.bool,
    showSubgridHeader: PropTypes.bool,
    renderSubgridRowEx: PropTypes.func,
    maxSubgridWidthBody: PropTypes.string
};

export default GridViewRow;
