import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  addItemToSet, singleItemSelected
} from "@mnjsplatform/data/lib/actions/classActions";
import GridView from "../GridView/GridView";
import BaseMnTree from "../BaseMnTree";
import {errorRaised} from "@mnjsplatform/data/lib/actions/errorActions";
import MnComboStandalone from "../Dropdown/MnComboStandalone";
import ComplexParam from "../ComplexParam";
import ModalWrapper from "../modalWrapper";
import i18next from "i18next";
import PopupWrapper from "../PopupWrapper";
import Spinner from "../Spinner";

const CLASS_SEARCH_FIELD_NAME = "SearchString";

class ClassPanelMobile extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      view: "table",
      selectedText: "",
      selectSetVisible: false,
      inSearching: false
    }
  }

  componentDidMount() {
    console.log("ClassPanelMobile mount");
    if (this.onlyTree()) {
      this.toggleView()
    }
  }

  componentWillUnmount() {
    console.log("ClassPanelMobile unmounted.");
  }

  onlyTree = () => this.props.onlyTree();

  selectItem = (rowIndex, row) => {
    if (!this.props.classDescription.SingleSelect) {
      addItemToSet(this.props.classKey, rowIndex)
        .then(() => this.props.dispatch(this.props.selectItem.refresh()))
        .catch(error => this.props.dispatch(errorRaised(error)))
    }
    else {
      singleItemSelected(this.props.classKey, rowIndex)
        .then((data) => this.setState({selectedText: data.SelectedText}))
        .catch(error => this.props.dispatch(errorRaised(error)));
        //.then(()=>this.onConfirm());
    }
  };

  deleteAllItems = () => {
    if(!this.props.selectItem || !this.props.selectItem.rawData || this.props.selectItem.rowsCount() === 0)
      return;

    this.props.deleteAllItems()
      .then(() => this.setState({selectedText: ""}));
  };

  clearSingleSelected = () => {
    this.props.clearSelection(this.props.classKey)
      .then(() => this.setState({selectedText: ""}))
      .catch(error => this.props.dispatch(errorRaised(error)))
  };

  onConfirm = (dismissOnly = false) => {
    const targetDismiss = dismissOnly && this.props.classDescription.SingleSelect && !this.state.selectedText;
    this.props.onClose(targetDismiss);
  };

  beforeSearchApply = () => {
    this.setState({inSearching: true});
  };

  afterSearchApply = () => {
    this.props.selectTreeRow(0)
      .finally(() => this.setState({ inSearching: false }))
  };

  getTreeLabelFieldName = () => {
    for (let fieldInfo of this.props.treeItem.rawData.ResultFieldInfo)
    {
      if (fieldInfo.Action.IsLabel)
        return fieldInfo.Name;
    }
  };

  getSelectedTreeNode = () => {
    return this.props.treeItem.getField(this.props.treeItem.rawData.CurrentRowIndex, this.getTreeLabelFieldName())
  };

  renderCellDelete = (row, fieldInfo, fieldIndex, renderCellDefault) => {

    if (fieldIndex === 1)
      return(
        <div>
          <i className="zmdi zmdi-close mr1em" onClick={()=>this.props.deleteItem(row)} style={{cursor: "pointer"}}/>
          <i className="zmdi zmdi-file mr1ex"/>
          {renderCellDefault(row, fieldInfo, fieldIndex)}
        </div>
      );

    return renderCellDefault(row, fieldInfo, fieldIndex);
  };

  checkAutoApplyParams = (f) => {
      return f.Name === "ShowClosed";
  };

  renderSearchParam = (h) => {
    if (!h.fi(CLASS_SEARCH_FIELD_NAME))
      return null;

    const containerStyle = {
      display: 'inline-block',
      position: 'absolute',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
      height: '100%',
      zIndex: 2
    };

    return (
      <div>
        <span className="input-title">{h.lbl(CLASS_SEARCH_FIELD_NAME)}</span>
        <div className="form-group">
                    <span>
                        <div className="input-group classControl">
                            {h.ed(CLASS_SEARCH_FIELD_NAME)}
                          <div>
                                <span className="clear zmdi zmdi-close" onClick={(e) => {
                                  this.props.onSearchClear(e, h)
                                }}/>
                            </div>
                            <div className="input-group-append" onClick={h.applyParams}>
                                <button className="btn" type="button">
                                  {this.state.inSearching && <Spinner size={"20px"} colorHex={'FFFFFF'} containerStyle={containerStyle}/>}
                                  <i className="search zmdi zmdi-search" style={this.state.inSearching ? {color: 'transparent'} : {}}/>
                                </button>
                            </div>
                        </div>
                    </span>
            {
                h.fi("ShowClosed") &&
                <div className="input-group">
                    <label className="form-check-inline">
                        {h.ed("ShowClosed")}&nbsp;{h.lbl("ShowClosed")}
                    </label>
                </div>
            }
        </div>
      </div>
    )
  };

  toggleView = (viewState) => {
    let self = this;
    if (self.state.view === "table") {
      let index = self.props.treeItem.rawData.CurrentRowIndex;
      const treeData = self.props.treeItem.rawData.RowTreeInfo[index];
      !treeData.IsExpanded && !treeData.IsEmpty
        ? self.props.dispatch(self.props.treeItem.treeOperation(index, true))
        : Promise.resolve()
        .then(() => self.setState({ view: "tree" }));
    }
    else
      self.setState({ view: "table" })
  };

  renderPanelHeader = () => {
    let classSearchVisible = this.props.treeItemParams
      && this.props.treeItemParams.isInitialized
      && !this.props.treeItemParams.inOperation
      && this.props.treeItemParams.rawData
      && this.props.treeItemParams.rowsCount() > 0
      && this.props.treeItemParams.columnExists(CLASS_SEARCH_FIELD_NAME);

    return (
      <div className="modal-header">
        <div className="col-md-12 row" style={{ paddingLeft: "0px" }}>
          {this.props.classDescription.HierarchySelectAvailable && this.state.view === "tree"
            ? <div className="col-md-4">
                <span className="text-grey pb1ex" title={i18next.t('Hierarchy')}>{i18next.t('Hierarchy')}</span>
                <MnComboStandalone ownItem={this.props.comboItem} onSelected={this.props.hierarchyChanged}/>
              </div>
            : this.props.fieldInfo && this.props.fieldInfo.Title
              ? <div className="col-md-5"><h4 className="modal-title">{this.props.fieldInfo.Title.replace(/\|/g, ' ')}</h4></div>
              : <div className="col-md-4"><h4 className="modal-title">{i18next.t('Class')}</h4></div>
          }
          <div className="col-md-6">
            <label><span title={i18next.t('CurrentLevel')} className="text-grey pb1ex">{i18next.t('CurrentLevel')}: </span>
            {
              !this.onlyTree() &&
              <React.Fragment>
                <a href="" className="align-middle" data-toggle="collapse" data-target="#collaps01" aria-expanded="false" aria-controls="collaps01"
                   onClick={this.toggleView}><i className="zmdi zmdi-folder" /> {this.getSelectedTreeNode()}</a>
              </React.Fragment>
            }
            {
              this.onlyTree() &&
                <label className="align-middle lt-label">
                    <i className="zmdi zmdi-folder" /> {this.getSelectedTreeNode()}
                </label>
            }
            </label>
          </div>

          {classSearchVisible && this.state.view === "table"
          && <div className="col-md-5">
            <ComplexParam dataItem={this.props.treeItemParams} parentDataItem={this.props.treeItem}
                          renderParams={(h) => this.renderSearchParam(h)}
                          beforeApplyParams={this.beforeSearchApply}
                          afterApplyParams={this.afterSearchApply}
                          autoApplyParamsFunc={this.checkAutoApplyParams}
                          rootPath={this.props.rootPath} applyOnEnterKey />
          </div>
          }
        </div>
        <button type="button" className="close" data-dismiss="modal" onClick={this.state.view === "tree" ? this.toggleView : ()=>{this.onConfirm(true)}}>
          <i className="zmdi zmdi-close"/>
        </button>
      </div>
    )
  };

  renderPanelFooter = () => {
    let isMultiRowsSelected = !!this.props.selectItem && !!this.props.selectItem.rawData && this.props.selectItem.rowsCount() > 0;

    console.log(this.state.selectedText);

    return (
      <div className="modal-footer">
        {
          this.props.classDescription.SingleSelect && this.state.selectedText &&
          <div style={{ textAlign: "left" }}>
            <label className="text-grey pb1ex">{i18next.t('Choosen')}:&nbsp;</label>
            <button className="btn btn-link -pure -noborder nom noplr" onClick={this.clearSingleSelected}>
              <i className="zmdi zmdi-close antipod"/>&nbsp;<label style={{cursor: "pointer"}}>{this.state.selectedText}</label>
            </button>
          </div>
        }
        {!this.props.classDescription.SingleSelect && isMultiRowsSelected &&
        <React.Fragment>
        <div style={{ textAlign: "left" }}>
          <label className="text-grey pb1ex">{i18next.t('Choosen')}:&nbsp;</label>
            <button className="btn btn-link -pure -noborder antipod nom noplr" onClick={this.deleteAllItems}>
              <i className="zmdi zmdi-close antipod"/>
            </button>
          &nbsp;<a href="" onClick={(e) => { e.preventDefault(); this.setState(state => ({selectSetVisible: !state.selectSetVisible}))}}>({this.props.selectItem.rowsCount()})</a>
        </div>
        <div className="row">
          <PopupWrapper title={i18next.t('Choosen')} popupVisible={this.state.selectSetVisible} style={{zIndex: undefined}}
                        onClose={() => {this.setState({selectSetVisible: false})}} confirmText={i18next.t('Close')}>
            <GridView dataItem={this.props.selectItem} renderCell={this.renderCellDelete}/>
          </PopupWrapper>
        </div>
        </React.Fragment>}
        <div>
          <button type="button" className="btn btn-regular -default"
                  onClick={this.state.view === "tree" ? this.toggleView : ()=>{this.onConfirm(true)}}>
            {!this.onlyTree() && this.state.view === "tree" ? i18next.t('ChooseLevel') : i18next.t('Accept')}
          </button>
        </div>
      </div>
    )
  };

  render() {
    let isSelected = this.state.selectedText || (this.props.selectItem && this.props.selectItem.rawData && this.props.selectItem.rowsCount() > 0);
    let height = 300 + (isSelected ? 45 : 0) + (this.state.view === "table" ? 27 : 0);
    let divHeight =  "calc(100vh - "+ height + "px)";
    return (<ModalWrapper>
        <div className="modal fade show" id="myModal" style={{display: "block"}}>
          <div className="modal-dialog modal-dialog-centered ClassPanel h-100 mh-100">
            <div className="modal-content h-95 mh-95">
              {this.renderPanelHeader()}
              <div style={{/*overflowX: "hidden",*/ overflowY: "auto", height: divHeight}}>
                <div className="modal-body row">
                  {(this.state.view === "tree" || this.onlyTree()) &&
                  <div style={{ /*overflow: "auto",*/ minHeight: "200px"/*, maxHeight: "500px"*/}} className="col-md-12">
                    <BaseMnTree dataItem={this.props.treeItem} nodeRender={this.props.nodeRender} controlOnlyByImage={this.onlyTree()}/>
                  </div>
                  }
                  {this.state.view === "table" && !this.onlyTree() &&
                  <div style={{/*overflow: "auto",*/ minHeight: "200px"/*, maxHeight: "500px"*/}} className="col-md-8">
                    <GridView dataItem={this.props.listItem} readOnly={true} onRowClick={this.selectItem} rowCursor={"pointer"} allowPagination/>
                  </div>
                  }
                </div>
              </div>
              {this.renderPanelFooter()}
            </div>
          </div>
        </div></ModalWrapper>
    );
  }
}

ClassPanelMobile.propTypes = {
  dispatch: PropTypes.func.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  classKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  deleteAllItems: PropTypes.func.isRequired,
  hierarchyChanged: PropTypes.func.isRequired,
  selectTreeRow: PropTypes.func.isRequired,
  onlyTree: PropTypes.func.isRequired,
  onSearchClear: PropTypes.func.isRequired,
  nodeRender: PropTypes.func.isRequired,
  classDescription: PropTypes.object
};


ClassPanelMobile.defaultProps = {
};

export default connect()(ClassPanelMobile);
