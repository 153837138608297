import React from "react";
import purify from "dompurify";

export function renderHTML(escapedHTML, style = {}){
  return React.createElement("span",  { style: {...style, whiteSpace: "pre-wrap"}, dangerouslySetInnerHTML: { __html:purify.sanitize(escapedHTML) } });
}

export function renderHTMLInTag(escapedHTML, tag = 'span', attrs = {}){
  return React.createElement(tag,  { ...attrs, dangerouslySetInnerHTML: { __html:purify.sanitize(escapedHTML) } });
}

export function sanitizeHTML(escapedHTML){
  return purify.sanitize(escapedHTML);
}

export function getScrollParent(element, includeHidden) {
  if (!element)
    return null;

  let style = getComputedStyle(element);
  const excludeStaticParent = style.position === "absolute";
  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

  if (style.position === "fixed") return null;
  for (let parent = element; (parent = parent.parentElement);) {
    style = getComputedStyle(parent);
    if (excludeStaticParent && style.position === "static") {
      continue;
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) return parent;
  }

  return null;
}
