/**
 * Created by marks on 24.01.2017.
 */
import Spinner from './Spinner';
import PropTypes from 'prop-types';
import React from 'react';

export default function MnSpinner(props) {
  return props.active ?
    (<Spinner colorHex="#17b169" borderStyle="double" spinnerWidth="3px" spinDuration={.5} size="32px" />) : (<div/>);
}

MnSpinner.propTypes = {
  active: PropTypes.bool.isRequired
};