import AuthenticationContext_ from './adal';

const AuthenticationContext = AuthenticationContext_;



/*
window.Logging = {
    level: 3,
    log: function (message) {
        console.log(message);
    },
    piiLoggingEnabled: false
};
*/

class AdalWrapper {
    constructor(config) {

        this.adalConfig = {
            resourceId: config.resourceId,
            tenant: config.tenant,
            clientId: config.clientId,
            postLogoutRedirectUri: config.pageUrl,
            endpoints: config.endpoints,
            redirectUri: config.pageUrl,
            cacheLocation: config.cacheLocation ? config.cacheLocation : 'localStorage'
        };

        console.log("[ADAL CONFIG] " + JSON.stringify(this.adalConfig));

        this.authContext = new AuthenticationContext(this.adalConfig);
    }

    getToken() {
        return new Promise((resolve, reject) => {
            this.authContext.acquireToken(this.adalConfig.resourceId, (message, token, msg) => {

                if (!msg && !!token)
                    resolve(token);
                else {
                    alert(`Authentication error: ${message}`);
                    this.authContext.clearCache();
                    this.authContext.login();
                    //reject({message, msg});
                }
            });
        });
    }

    forceRelogin() {
        this.authContext.clearCache();
        this.authContext.login();
    }

    loginIfRequired(authContext) {
        return new Promise((resolve) => {

            //it must run in iframe to for refreshToken (parsing hash and get token)
            this.authContext.handleWindowCallback();

            //prevent iframe double app !!!

            const isSameWindow = window === window.parent;
            const isCallback = this.authContext.isCallback(window.location.hash);
            const isCachedClientToken = !!this.authContext.getCachedToken(this.authContext.config.clientId);
            const isCachedUser = !!this.authContext.getCachedUser();

            console.log(JSON.stringify({ isSameWindow, isCallback, isCachedClientToken, isCachedUser }));

            if (isSameWindow) {
                if (!isCallback) {
                    if (!isCachedClientToken /*|| !isCachedResourceToken*/ || !isCachedUser) {
                        this.authContext.clearCache();
                        this.authContext.login();
                    } else {
                        resolve(null);
                    }
                }
            }
        });
    }

    logout() {
        this.authContext.logOut();
    }

    canSignOut() {
        return true;
    }

    handleUnauthorized() {
    }
}

export default AdalWrapper