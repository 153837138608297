import React, {Component} from "react";
import PropTypes from "prop-types";
import "bootstrap";
import {connect} from "react-redux";
import Routes, { formObjectToRouteMap } from "../Navigation/Routes";
import i18next from "@mnjsplatform/data/lib/localization/i18";
import MnSpinner from "@mnjsplatform/components/lib/common/MnSpinner";
import {loadAppParameters} from "../../actions/AppActions";
import {withRouter} from "react-router";
import * as actions from "../../actions/loginActions";
import Menu from "../Navigation/Menu";
import Alert from "@mnjsplatform/components/lib/common/Alert";
import queryString from "query-string";
import AuthProvider from "@mnjsplatform/data/lib/security/authProvider";

class Master extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isAuthenticated: false,
            isError: false,
            errorText: ""
        };
    }

    componentDidMount() {
        this.loadAppParams();
    }

    componentWillUnmount() {
        console.log("Master will unmount");
    }

    retry = ()=> {
        this.loadAppParams();
    };

    loadAppParams = async () => {
        await this.props.dispatch(loadAppParameters(this.props.history));
        this.applyAppParams();
    };

    applyAppParams() {
        if(!this.props.homeState || !this.props.homeState.appParams || !this.props.homeState.appParams.currentCommand)
            return;

        if(!this.props.homeState.appParams.currentCommand.Param1)
            return;

        const objectName = this.props.homeState.appParams.currentCommand.Param1;

        const target = formObjectToRouteMap[objectName];
        if (!target)
            return;

        this.props.history.replace('/' + target.link);
    }

    signOut = () => {
        AuthProvider.logout();
    };

    render() {

        console.log(this.props.homeState);

        if (!this.props.homeState || (!this.props.homeState.inOperation && !this.props.homeState.isLoaded && !this.props.homeState.isError))
            return <div/>;

        if (!this.props.homeState.isLoaded && this.props.homeState.inOperation)
            return <div><MnSpinner active/></div>;

        if (this.props.homeState.isError)
            return (<div>
                <div className="alert alert-danger" role="alert">{this.props.homeState.isUserEception && i18next.t('AppError')}<b>&nbsp;{this.props.homeState.errorText}</b>
                    <button className="btn btn-primary" onClick={this.retry} style={{marginLeft: 10}}>{i18next.t('Retry')}</button>
                    {AuthProvider.canSignOut() && <button className="btn btn-primary" onClick={this.signOut} style={{marginLeft: 10}}>{i18next.t('Exit')}</button>}
                </div>
            </div>);

        let baseWidth = "1000px";
        let maxWidth = window.innerWidth >= 768 ? baseWidth : "100%";

        return (<div className="container-fluid">
                    <div className="row flex-md-nowrap">
                        {this.props.inOpState && <MnSpinner active/> }
                        <Alert />
                        <Menu />
                        <main className="p-2 p-md-3" role="main" style={{backgroundColor:"#f2f4f3", flexBasis: baseWidth, maxWidth: maxWidth}}>
                            <Routes />
                        </main>
                    </div>
                </div>);
    }
}

Master.propTypes = {
    dispatch: PropTypes.func.isRequired,
    homeState: PropTypes.object,
    history: PropTypes.object
};

Master.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state) {
    return {
        homeState: state.appState,
        inOpState: state.inOperationState
    };
}

export default withRouter(connect(mapStateToProps)(Master));