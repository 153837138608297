/**
 * Created by marks on 05.04.2017.
 */
export default function pathJoin(/* path segments */) {
  // Split the inputs into a list of path commands.
  let parts = [];
  for (let i = 0, l = arguments.length; i < l; i++) {
    if (arguments[i] === null) continue;

    const arg = arguments[i].toString();
    parts = parts.concat(arg.split("/"));
  }
  // Interpret the path commands to get the new resolved path.
  const newParts = [];
  for (let i = 0, l = parts.length; i < l; i++) {
    let part = parts[i];
    // Remove leading and trailing slashes
    // Also remove "." segments
    if (!part || part === ".") continue;
    // Interpret ".." to pop the last segment
    if (part === "..") newParts.pop();
    else
      // Push new path segments.
      newParts.push(part);
  }
  // Preserve the initial slash if there was one.
  if (parts[0] === "") newParts.unshift("");
  // Turn back into a single string path.
  const result = newParts.join("/") || (newParts.length ? "/" : ".");
  return result.replace(":/", "://");
}
