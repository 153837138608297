import AdalWrapper from "./adalWrapper";
import queryString from "query-string";

const AUTH_TYPE_WINDOWS = "windows";
const AUTH_TYPE_MSAL = "msal";
const AUTH_TYPE_ADAL = "adal";
const AUTH_TYPE_OID_BEHIND = "oidbehind";
const AUTH_TYPE_WS_FEDERATION = "wsfederation";

class DummyAuthWrapper
{
    constructor(config) {
        this.config = config;
    }

    loginIfRequired() {
        return Promise.resolve();
    }

    getToken() {
        return Promise.resolve(null);
    }

    logout() {
        return Promise.resolve();
    }

    canSignOut() {
        return false;
    }

    handleUnauthorized() {
    }
}

class OidBehind
{
    constructor(config) {
        this.config = config;
    }

    loginIfRequired() {
        return Promise.resolve();
    }

    getToken() {
        return Promise.resolve(null);
    }

    async logout() {

        console.log("logout");

        const currentUrl = window.location.href;
        const query = queryString.stringify({returnUrl:currentUrl});
        window.location = this.config.logoutUrl+"?"+query;
    }

    async changePassword() {

        console.log("change password");

        const currentUrl = window.location.href;
        const query = queryString.stringify({returnUrl:currentUrl});
        window.location = this.config.resetPasswordUrl+"?"+query;
    }

    canSignOut() {
        return Promise.resolve();
    }

    handleUnauthorized() {

        const currentUrl = window.location.href;
        const query = queryString.stringify({returnUrl:currentUrl});

        window.skipUnloadActions = true;
        window.location = this.config.loginUrl+"?"+query;

        return true;
    }
}

let currentAuthWrapper = new DummyAuthWrapper();

class AuthProvider {
    static loginIfRequired() {
        return currentAuthWrapper.loginIfRequired();
    }

    static getToken() {
        return currentAuthWrapper.getToken();
    }

    static logout() {
        return currentAuthWrapper.logout();
    }

    static changePassword() {
        return currentAuthWrapper.changePassword();
    }

    static canSignOut() {
        return currentAuthWrapper.canSignOut();
    }

    static handleUnauthorized() {
        return currentAuthWrapper.handleUnauthorized();
    }

    static initAuth(config)
    {
        const authType = config.authType;

        if (!authType || authType.toLowerCase() === AUTH_TYPE_WINDOWS )
            setAuthWrapper(new DummyAuthWrapper(config.oidBehind));
        else if (authType.toLowerCase() === AUTH_TYPE_MSAL)
            setAuthWrapper(new DummyAuthWrapper(config.oidBehind));
        else if (authType.toLowerCase() === AUTH_TYPE_ADAL)
            setAuthWrapper(new AdalWrapper(config.adal));
        else if (authType.toLowerCase() === AUTH_TYPE_OID_BEHIND)
            setAuthWrapper(new OidBehind(config.oidBehind));
        else if (authType.toLowerCase() === AUTH_TYPE_WS_FEDERATION)
            setAuthWrapper(new OidBehind(config.wsFederation));
    }
}

function setAuthWrapper(authWrapper)
{
    currentAuthWrapper = authWrapper;
}


export default AuthProvider
