import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ComplexParam from "@mnjsplatform/components/lib/common/ComplexParam";
import paramRender from "@mnjsplatform/components/lib/common/ParamRenderUniversal";
import toastr from 'toastr';
import i18next from "i18next";
import HomeApi from "../../api/homeApi";
import {errorRaised} from "@mnjsplatform/data/lib/actions/errorActions";

const toastrDefaultOptions = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "4000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
};

class InviteEdit extends Component {
    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        this.initItem().catch(e=>console.error(e));
    }

    componentWillUnmount() {
        console.log("InviteEdit will unmount");
    }

    async initItem() {
        await this.props.dispatch(this.props.inviteItem.execute());
    }

    sendInvite = async () => {

        if (!this.props.appParams.monNtLogin)
        {
            try {
                await HomeApi.RegisterLogin();
            } catch(err) {
                this.props.dispatch(errorRaised(err));
                return
            }
        }

        await this.props.dispatch(this.props.inviteItem.save());
        if (this.props.inviteItem.isError)
            return;

        await this.props.dispatch(this.props.inviteSend.execute());
        if (this.props.inviteItem.isError)
            return;

        await this.props.dispatch(this.props.regDataItem.execute());

        toastr.options = toastrDefaultOptions;
        toastr.success(i18next.t('SuccessSend'));
    }

    getCurrentInviteStatus = async () => {
    }

    render() {

        const regData = this.props.regDataItem.getRow(0);

        if (regData.ToShow_InviteForm === 0)
            return null;

        const isReadOnly = regData.ToShow_InviteForm === 2;
        const canSend = regData.ToShow_SendBtn === 1;

        return (<><ComplexParam dataItem={this.props.inviteItem}
                              readOnly={isReadOnly}
                              showSearchBar={false}
                              showApplyButton={false}
                              renderParams={paramRender}
        />
            {canSend && this.renderButton()}
        </>)
    }

    renderButton() {
        return (<div className="text-right">
                <button className="btn btn-regular -default -wide"
                        onClick={this.sendInvite}>{i18next.t('Submit')}
                </button>
        </div>)
    }

}

InviteEdit.propTypes = {
    dispatch: PropTypes.func.isRequired,
    regData: PropTypes.object,
    regDataItem: PropTypes.object,
};


InviteEdit.defaultProps = {};

function mapStateToProps(state, ownProps) {
    return {
        appParams: state.appState.appParams,
        inviteItem: state.regState.EDRequestInviteEdit,
        inviteSend: state.regState.EDRequestInviteSend
    }
}

export default connect(mapStateToProps)(InviteEdit)