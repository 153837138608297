export default class DrawAttributeProvider
{

    static rowStyleProvider(rowIndex, row, dataItem)
    {
        if (!dataItem.rawData)
            return DrawAttributeProvider.getStyleByDa();

        const ra = dataItem.rawData.RowDrawAttributes;
        if (!ra)
            return DrawAttributeProvider.getStyleByDa();

        const da = ra[rowIndex];

        return DrawAttributeProvider.getStyleByDa(da);
    }

    static cellStyleProvider(dataItem, rowIndex, fieldInfo)
    {
        if (!dataItem.rawData)
            return DrawAttributeProvider.getStyleByDa();

        const ca = dataItem.rawData.ColumnDrawAttributes;
        if (!ca || !ca[rowIndex])
            return DrawAttributeProvider.getStyleByDa();

        const da = ca[rowIndex][fieldInfo.Name];

        return DrawAttributeProvider.getStyleByDa(da);
    }

    static cellTooltipProvider(dataItem, rowIndex, fieldInfo)
    {
        if (!dataItem.rawData)
            return "";

        const ca = dataItem.rawData.ColumnDrawAttributes;
        if (!ca || !ca[rowIndex])
            return "";

        const da = ca[rowIndex][fieldInfo.Name];

        if (da && da.fieldTip && da.fieldTip.Value)
            return da.fieldTip.Value;

        return "";
    }

    static getStyleByDa(da = null)
    {
        let defStyle = {};

        if (!da)
            return defStyle;

        if (da.ClrBackground)
            defStyle.backgroundColor = da.ClrBackground;

        if (da.ClrForeground)
            defStyle.color = da.ClrForeground;

        if (da.font && da.font.Style === 1)
            defStyle.fontWeight = 600;

        if (da.font && da.font.Style === 0)
            defStyle.fontWeight = "normal";

        return defStyle;
    }
}