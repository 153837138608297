import {QUERY_CLASS_INIT_OK, QUERY_CLASS_INIT_BEGIN, QUERY_CLASS_INIT_FAILED, QUERY_CLASS_UNMOUNT} from "../actions/classActions";

export default function classRepositoryReducer(state = {}, action) {

    switch (action.type)
    {
        case QUERY_CLASS_INIT_BEGIN:
            return Object.assign({}, state, {
                [action.classKey]: {inInitialization: true, classDescription: null}
            });
        case QUERY_CLASS_INIT_FAILED:
            return Object.assign({}, state, {
                [action.classKey]: {inInitialization: false, classDescription: null}
            });
        case QUERY_CLASS_INIT_OK:
            return Object.assign({}, state, {
                [action.classKey]: {inInitialization: false, classDescription: action.data.classDescription}
            });
        case QUERY_CLASS_UNMOUNT:
            const newObj = Object.assign({}, state);
            delete newObj[action.classKey];
            return newObj;
    }

    return state;
}
