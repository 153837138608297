import {AppInsights} from "applicationinsights-js";
import * as nanoid from "nanoid";
import formatError from "./errorTools";

export function initTelemetry()
{
    initAppInsight();
}

export function setUserId(userId) {
    AppInsights.setAuthenticatedUserContext(userId);
}

export function trackNavigation(name, url) {
    AppInsights.trackPageView(name, url)
}

export function trackError(error) {
    if (Object.prototype.toString.call(error) !== "[object Error]")
        error = formatError(error);

    AppInsights.trackException(error);
}

export function updateOperationId() {
    if (AppInsights.context)
        AppInsights.context.operation.id = nanoid(7);
}

function initAppInsight() {
    if (window.config.appInsightInstrumentationKey)
        AppInsights.downloadAndSetup({ instrumentationKey: window.config.appInsightInstrumentationKey, enableCorsCorrelation:false});

    if (AppInsights.queue !== undefined) {
        AppInsights.queue.push(appInsightTelemetryInitializer);
    } else {
        appInsightTelemetryInitializer();
    }
}

function appInsightTelemetryInitializer() {

    AppInsights.context.addTelemetryInitializer((envelope) => {

        var telemetryItem = envelope.data.baseData;

        if (telemetryItem.type === "Ajax" && telemetryItem.data && telemetryItem.data.toLowerCase().indexOf("signalr")>=0) {
            return false;
        }

        if (telemetryItem.exceptions && telemetryItem.exceptions.length >0 && telemetryItem.exceptions[0].message.indexOf("ResizeObserver")>=0)
            return false;

        const tags = envelope.tags;
        if (tags && tags['ai.user.authUserId']) {
            tags['ai.user.id'] = tags['ai.user.authUserId'];
        }
    });
}