import i18next from "i18next";

export default class textUtils {
    static getNumber (count) {
        const cases = [2, 0, 1, 1, 1, 2];
        return (count % 100 > 4 && count % 100 < 20) ? 2 : cases[Math.min(count % 10, 5)];
    };

    static pluralize(count, words = ['день', 'дня', 'дней']) {
        return count + ' ' + words[this.getNumber(count)];
    }

    static pluralize_i18next(count, prefix = "daysCountPostfix") {
        return count + ' ' + i18next.t(`${prefix}.${this.getNumber(count)}`);
    }
};