import React, { Component } from "react";
import PropTypes from "prop-types";
import "bootstrap";
import { connect } from "react-redux";
import * as actions from "../../actions/loginActions";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { getUrlByMenuItem } from "./Routes";
import AuthProvider from "@mnjsplatform/data/lib/security/authProvider";
import HomeApi from "../../api/homeApi";
import $ from "jquery";
import logo from '../../images/logo.svg';
import browserUtils from "@mnjsplatform/data/lib/utils/browserUtils";
import i18next from "i18next";

class Menu extends Component {
    constructor(props, context) {
        super(props, context);
    }

    logout = (e) => {

        e.preventDefault();

        HomeApi.quitSession()
            .catch(()=>{})
            .then(()=>{AuthProvider.logout().catch()});
    };

    changePassword = (e) => {

        e.preventDefault();
        HomeApi.quitSession()
            .catch(()=>{})
            .then(()=>{AuthProvider.changePassword()});
    };

    render() {
        return (
            <div className="col-12 bd-sidebar cr-b-gr-1 p-0 h-auto border-bottom-0">

                <nav className="navbar navbar-dark bg-dark navbar-expand-xl d-xl-none d-md-none w-100"><Link to="./"><img src={logo} width="70px" alt=""/></Link>
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                            data-target="#bd-docs-nav"><span className="menu"><i className="material-icons">menu</i></span>
                    </button>
                </nav>
                <div className="sticky-top">
                    {this.renderMenu()}
                </div>
            </div>
        );
    }

    renderMenu()
    {
        let style = null;
        if (browserUtils.isIE()) {
            style = {height: "100%", maxHeight: "100%"};
        }
        return (<nav className="collapse bd-links mx-0" id="bd-docs-nav" style={style}>
            <div className="logobar d-none d-md-block pt-4 pb-4">
                <Link to="./">
                    <img src={logo} className="w-50" alt=""/>
                </Link>
            </div>
            {this.props.appState.menu.Items.map(mi => this.renderMenuItem2(mi))}
            <div className="bd-toc-item">
                <a className="bd-toc-link" onClick={this.changePassword} href="">{i18next.t('ResetPassword')}</a>
            </div>
            <div className="bd-toc-item">
                <a className="bd-toc-link" onClick={this.logout} href="">{i18next.t('Logout')}</a>
            </div>
            <div className="bd-toc-item">
                <span className="bd-toc-text">
                    {this.props.appState.apiVersion + " " + this.props.appState.login + " " + (this.props.appState.MonNtLogin ? " *" : "")}
                </span>
            </div>
        </nav>);
    }

    renderMenuItem2(menuItem) {

        if (!!menuItem.ParentID || !menuItem.IsVisible)
            return null;

        if (menuItem.Type === 0) {
            return this.renderItem(menuItem);
        }
        else if (menuItem.Type === 1) {
            return this.renderGroupItem(menuItem, 0);
        }

        return null;
    }

    renderItem = (menuItem) => {
        return (<div className="bd-toc-item" key={menuItem.MenuID} data-toggle="collapse" data-target="#bd-docs-nav.show">
            <Link className="bd-toc-link" to={getUrlByMenuItem(menuItem)}>{menuItem.Text}</Link>
        </div>);
    };

    renderGroupItem = (menuItem, level) => {
        const childMenuItems = this.getChildItems(menuItem);
        const groupId = "group_" + menuItem.MenuID;
        const groupParentId = "group_" + menuItem.MenuID + "_parent";

        const paddingLeftValue = (1.5 + 0.5 * level).toString() + "rem";

        return (<div className="bd-toc-item active" key={menuItem.MenuID}>
            <div className="menu-group-item-container">
                <a id={groupParentId} className="bd-toc-link collapsed" href={"#" + groupId}
                   data-toggle="collapse" aria-expanded="false"aria-controls={groupId}
                   style={{paddingLeft: paddingLeftValue}}
                   onClick={(e)=>{e.preventDefault()}}
                >
                    {menuItem.Text}
                </a>
                <i className="menu-group-item-chevron zmdi zmdi-chevron-down" onClick={(e) => {e.preventDefault(); $("#" + groupParentId).trigger("click")}} />
                <i className="menu-group-item-chevron zmdi zmdi-chevron-up" onClick={(e) => {e.preventDefault(); $("#" + groupParentId).trigger("click")}} />
            </div>
            <ul className="nav bd-sidenav collapse" id={groupId}>
                {childMenuItems.map(mi => this.renderSubItem(mi, level + 1))}
            </ul>
        </div>);
    };

    renderSubItem = (menuItem, level) => {
        if (menuItem.Type === 0) {
            const paddingLeftValue = (1.5 + 0.5 * level).toString() + "rem";

            return (<li key={menuItem.MenuID} data-toggle="collapse" data-target="#bd-docs-nav.show">
                <Link style={{paddingLeft: paddingLeftValue}} to={getUrlByMenuItem(menuItem)}>{menuItem.Text}</Link>
            </li>);
        }
        else if (menuItem.Type === 1) {
            return (<li key={menuItem.MenuID}>
                {this.renderGroupItem(menuItem, level)}
            </li>);
        }
        else if (menuItem.Type === 2) {
            return (<li key={menuItem.MenuID}>
                <div className="dropdown-divider"/>
            </li>);
        }
    };

    getChildItems(menuItem) {
        return this.props.appState.menu.Items.filter(mi => mi.ParentID === menuItem.MenuID && mi.IsVisible);
    }
}

Menu.propTypes = {
    dispatch: PropTypes.func.isRequired
};


Menu.defaultProps = {
    //myProp: <defaultValue>
};

function mapStateToProps(state) {
    return {
        appState: state.appState.appParams
    };
}

export default withRouter(connect(mapStateToProps)(Menu));