/**
 * Created by marks on 10.01.2017.
 */
import * as types from '../actions/loginActions';

export default function errorReducer(state = { inProcess: false, errorMessage: "", authenticated: false }, action) {
    if (action.type === types.LOGIN_PROCESS) {

        return { inProcess: true, errorMessage: "" };

    } else if (action.type === types.LOGIN_SUCCESS) {

        return { inProcess: false, errorMessage: "", authenticated: true };
    }
    else if (action.type === types.LOGIN_ERROR) {

        return { inProcess: false, errorMessage: action.errorData.error_description };
    }

    return state;
}
