import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  changeHierarchy,
  changeTree,
  addItemToSet,
  singleItemSelected,
  deleteSelectedItem,
  syncSelectedItems,
  CLASS_TYPE_LIST,
  CLASS_TYPE_TREE,
  CLASS_MANAGER_CONTROLLER
} from "@mnjsplatform/data/lib/actions/classActions";
import GridView from "../../GridView/GridView";
import BaseMnTree from "../../BaseMnTree";
import {errorRaised} from "@mnjsplatform/data/lib/actions/errorActions";
import MnComboStandalone from "../../Dropdown/MnComboStandalone";
import ComplexParam from "../../ComplexParam";
import ModalWrapper from "../../modalWrapper";
import {ScreenFormatContext} from "@mnjsplatform/data";
import ClassPanelMobile from "../ClassPanelMobile";
import Spinner from "../../Spinner";
import i18next from "i18next";
import MnSpinner from "../../MnSpinner";
import getClassTabDescription from "./ClassExTabsInitializer"
import TabPanel from "../../TabControls/TabPanel";
import TabsCallbackStore from "./TabsCallbackStore";
import {MnCheckbox} from "../../InputField/MnCheckbox";
import PopupWrapper from "../../PopupWrapper";

const CLASS_SEARCH_FIELD_NAME = "SearchString";

const mobileSteps = {
    "groupsListForm": 1,
    "groupSettingsForm": 2
}

class ClassExPanel extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      inSearching: false,
      isHierarchyReady: true,
      isTreeReady: true,
      classTabs: null,
      activeTab: null,
      mobileStep: mobileSteps.groupsListForm,
      mobileFiltersDisplay: false
    };

    this.callbackStore = new TabsCallbackStore();
    this.filtersPanelRef = React.createRef();

  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const currentRowIndex = this.props.treeItem.getCurrentRowIndex();
      this.selectTreeRow(currentRowIndex);
     this.applySearchValue(this.props.searchValue);
  }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.activeTab) {
        if (!prevState.activeTab || prevState.activeTab.id !== this.state.activeTab.id) {
            this.refreshActiveTab();
        }
    }
  }

  handleClickOutside = (e) => {
      let inFiltersPanelRef  = this.filtersPanelRef && this.filtersPanelRef.current && this.filtersPanelRef.current.contains(e.target);

      if (!inFiltersPanelRef) {
          this.closeMobileFilters();
      }
  }

  tabsUpdateHandler = async () => {
      await this.props.dispatch(this.props.treeItem.refresh());
      const currentRowIndex = this.props.treeItem.getCurrentRowIndex();
      await this.selectItem(0, this.props.treeItem.getRow(0));
      await this.selectTreeRow(currentRowIndex);
  }

  loadTabs = () => {
      if(!this.props.tabsItem)
          return;

      let prevActiveTab = this.state.activeTab;

      this.setState({activeTab: null});

      let tabList = [];
      let selectedHierarchy = {};
      let selectedTreeItem = {};

      if(this.props.comboItem && this.props.comboItem.rowsCount() > 0)
          selectedHierarchy = this.props.comboItem.getRow(this.props.comboItem.getCurrentRowIndex());

      if(this.props.treeItem && this.props.treeItem.rowsCount() > 0)
          selectedTreeItem = this.props.treeItem.getRow(this.props.treeItem.getCurrentRowIndex());

      let params = Object.assign({}, selectedHierarchy, selectedTreeItem);

      let isReadOnly = true;
      if (params && typeof(params.IsROPUG === "number"))
      {
          isReadOnly = params.IsROPUG === 1;
      }

      this.props.dispatch(this.props.tabsItem.execute(params))
           .then(() => {
               this.props.tabsItem.rawData.DataTable.forEach(row => {
                   const tab = getClassTabDescription(row,
                       this.callbackStore,
                       CLASS_MANAGER_CONTROLLER,
                       this.gridContentContainerStyleProvider,
                       this.tabsUpdateHandler,
                       isReadOnly);
                   tabList.push(tab);
               })

               let activeTab = null;

               if(tabList.length > 0) {
                   if(!prevActiveTab)
                       activeTab = tabList[0];
                   else {
                       let newActiveTab = tabList.filter((value) => {return value.id === prevActiveTab.id});

                       activeTab = newActiveTab && newActiveTab.length > 0 ? newActiveTab[0] : tabList[0];
                   }
               }

               this.setState({classTabs: tabList, activeTab: activeTab});
           })
  }

    refreshActiveTab = async() => {
      if(!this.state.activeTab)
          return;

        try {
            let selectedHierarchy = this.props.comboItem.getRow(this.props.comboItem.getCurrentRowIndex());
            let selectedTreeItem = this.props.treeItem.getRow(this.props.treeItem.getCurrentRowIndex());
            let treeItemParams = this.props.treeItemParams.getRow(0);
            let params = Object.assign({}, selectedHierarchy, treeItemParams, selectedTreeItem);

            await this.state.activeTab.refresh(params);
        }
        catch (e) {
            console.log(e);
        }
    };

    gridContentContainerStyleProvider = ({mobileMode}) => {
        if (!mobileMode) {
            return { maxHeight: "60vh" };
        }
        return null;
    };

  applySearchValue = async(value) => {
    if (
      !!this.props.treeItemParams
      && this.props.treeItemParams.columnExists(CLASS_SEARCH_FIELD_NAME)
      && (this.props.treeItemParams.getField(0, CLASS_SEARCH_FIELD_NAME) || "") !== value
    ) {
      await this.props.dispatch(this.props.treeItemParams.setField(0, CLASS_SEARCH_FIELD_NAME, value));
      await this.props.dispatch(this.props.treeItem.applyParams(this.props.treeItemParams));
      await this.selectTreeRow(0);
    }
  };

  onlyTree = () => {
    return this.props.classDescription && this.props.classDescription.ClassSelectSource === CLASS_TYPE_TREE;
  };

  hierarchyChanged = async(newIndex) => {

    if (newIndex < 0)
      return;

    this.setState({isHierarchyReady: false});
    /*changeHierarchy(this.props.classKey, newIndex)
      .then(() => this.props.dispatch(this.props.treeItem.refresh()))
      .then(() => {
        if (!this.onlyTree()) this.props.dispatch(this.props.listItem.refresh());
      })
      .then(() => this.loadTabs())
      .catch(error => this.props.dispatch(errorRaised(error)))
      .finally(() => this.setState({isHierarchyReady: true}));
      */
      try {
          await changeHierarchy(this.props.classKey, newIndex);
          await this.props.dispatch(this.props.treeItem.refresh({}, {withParams: true}));

          if (!this.onlyTree())
              await this.props.dispatch(this.props.listItem.refresh());

          this.loadTabs();
      } catch (error) {
          this.props.dispatch(errorRaised(error));
      }
      finally {
          this.setState({isHierarchyReady: true});
      }
  }

  selectTreeRow = async (index) => {
    this.setState({ isTreeReady: false });
    if (this.onlyTree()) {
      return this.props.dispatch(this.props.treeItem.goto(index))
        .then(() => this.selectItem(index, this.props.treeItem.getRow(index)))
        .then(() => this.loadTabs())
        .finally(() => this.setState({ isTreeReady: true }));
    } else {
      return this.props.dispatch(this.props.treeItem.goto(index))
        .then(() => changeTree(this.props.classKey, index))
        .then(() => this.props.dispatch(this.props.listItem.refresh()))
        .then(() => this.loadTabs())
        .catch(error => this.props.dispatch(errorRaised(error)))
        .finally(() => this.setState({ isTreeReady: true }));
    }
  }

  selectItem = (rowIndex, row) => {

    if (!this.props.classDescription.SingleSelect) {
      addItemToSet(this.props.classKey, rowIndex)
        .then(() => this.props.dispatch(this.props.selectItem.refresh()))
        .catch(error => this.props.dispatch(errorRaised(error)))
    }
    else {
      singleItemSelected(this.props.classKey, rowIndex)
        //.then(() => this.onConfirm())
        .catch(error => this.props.dispatch(errorRaised(error)));
    }
  };

  deleteItem = (row) => {

    if (!this.props.selectItem)
      return;

    const rowIndex = this.props.selectItem.getRowIndex(row);

    if (rowIndex < 0)
      return;

    this.props.dispatch(deleteSelectedItem(this.props.classDescription, this.props.classKey, rowIndex))
      .catch(error => this.props.dispatch(errorRaised(error)));
  };

  deleteAllItems = () => {
    if (!this.props.selectItem || !this.props.selectItem.rawData || this.props.selectItem.rowsCount() === 0)
      return;

    return this.props.clearSelection(this.props.classKey)
      .then(data => this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, data.changedFields)))
      .then(() => this.props.dispatch(syncSelectedItems(this.props.classDescription, this.props.classKey, this.props.rowIndex)))
      .catch(error => this.props.dispatch(errorRaised(error)))
  };

  onConfirm = (dismissOnly = false) => {
    const targetDismiss = dismissOnly && this.props.classDescription.SingleSelect;
    this.props.onClose(targetDismiss);
  };

  beforeSearchApply = () => {

  };

  afterSearchApply = () => {
    this.selectTreeRow(0)
        .finally(() => {this.setState({inSearching: false})});
  };

  onSearchClear = (e, h) => {
    this.props.dispatch(this.props.treeItemParams.setField(0, CLASS_SEARCH_FIELD_NAME, null))
      .then(data => {
        h.applyParams(e)
      });
  };

  nodeRender = (r, i, lbl) => {

    return (
      <span onClick={() => this.selectTreeRow(i)} style={{ cursor: "pointer", lineHeight: "1.5" }}
            title={i18next.t('Choose')}>{lbl}</span>
    );
  };

  renderCellDelete = (row, fieldInfo, fieldIndex, renderCellDefault) => {

    if (fieldIndex === 0)
      return (
        <div>
          <i className="zmdi zmdi-file mr1ex"/>
          {renderCellDefault(row, fieldInfo, fieldIndex)}
          <i className="zmdi zmdi-close ml1em" onClick={() => this.deleteItem(row)} style={{ cursor: "pointer" }}/>
        </div>
      );

    return renderCellDefault(row, fieldInfo, fieldIndex);
  };

  checkAutoApplyParams = (f) => {
      return f.Name !== CLASS_SEARCH_FIELD_NAME;
  };

  renderSearchParam = (h) => {
    if (!h.fi(CLASS_SEARCH_FIELD_NAME))
      return null;

      const containerStyle = {
          display: 'inline-block',
          position: 'absolute',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
          height: '100%',
          zIndex: 2
      };

    let showSpinner = this.state.inSearching;

    return (<>
      <div className="row">
         <div className="col-md-7">
              <span className="input-title">{h.lbl(CLASS_SEARCH_FIELD_NAME)}</span>
              <div className="form-group">
                    <span>
                        <div className="input-group classControl">
                            {h.ed(CLASS_SEARCH_FIELD_NAME)}
                            <div>
                                <span className="clear zmdi zmdi-close" onClick={(e) => {
                                  this.onSearchClear(e, h)
                                }}/>
                            </div>
                            <div className="input-group-append" onClick={(e) => {this.setState({inSearching: true}); h.applyParams(e);}}>
                                <button className="btn" type="button">
                                    {showSpinner && <Spinner size={"20px"} colorHex={'FFFFFF'} containerStyle={containerStyle}/>}
                                    <i className="search zmdi zmdi-search" style={showSpinner ? {color: 'transparent'} : {}}/>
                                </button>
                            </div>
                        </div>
                    </span>
              </div>
         </div>
          {
              h.fi("SearchType") &&
              <div className="col-md-5">
                  <span className="input-title">&nbsp;</span>
                  <div className="form-group">
                      <div className="input-group classControl">
                        {h.ed("SearchType")}
                      </div>
                  </div>
              </div>
          }
      </div>
      <div className="row">
          {
              (h.fi("ShowClosed") || h.fi("ShowClosedGroup")) &&
              <div className="col-md-6">
                  <MnCheckbox fieldName={"ShowClosed"} handler={h} />
                  <MnCheckbox fieldName={"ShowClosedGroup"} handler={h} />
              </div>
          }
          </div>
      </>
    );
  };

  renderPanelHeader = (closeFunc) => {

      if (typeof(closeFunc) !== "function"){
          closeFunc = ()=>this.onConfirm(true);
      }

    let classSearchVisible = this.props.treeItemParams
      && this.props.treeItemParams.isInitialized
      && !this.props.treeItemParams.inOperation
      && this.props.treeItemParams.rawData
      && this.props.treeItemParams.rowsCount() > 0
      && this.props.treeItemParams.columnExists(CLASS_SEARCH_FIELD_NAME);

    return (
      <div className="modal-header" style={{padding: "0 15px"}}>
        <div className="col-md-12 row" style={{ paddingLeft: "0px" }}>
          {this.props.classDescription.HierarchySelectAvailable
            ? <div className="col-md-4">
              <span className="input-title">{i18next.t('Hierarchy')}</span>
              <MnComboStandalone ownItem={this.props.comboItem} onSelected={this.hierarchyChanged} readOnly={this.props.classDescription.HierarchySelectGrayed}/>
            </div>
            : this.props.fieldInfo && this.props.fieldInfo.Title
              ? <div className="col-md-5"><h4 className="modal-title">{this.props.fieldInfo.Title}</h4></div>
              : <div className="col-md-4"><h4 className="modal-title">{i18next.t('Classifier')}</h4></div>
          }
          {classSearchVisible
          && <div className="col-md-8">
            <ComplexParam dataItem={this.props.treeItemParams} parentDataItem={this.props.treeItem}
                          autoFocusField={CLASS_SEARCH_FIELD_NAME}
                          renderParams={(h) => this.renderSearchParam(h)}
                          afterApplyParams={this.afterSearchApply}
                          beforeApplyParams={this.beforeSearchApply}
                          autoApplyParamsFunc={this.checkAutoApplyParams}
                          rootPath={this.props.rootPath} applyOnEnterKey />
          </div>
          }
        </div>
        <button type="button" className="close" data-dismiss="modal" onClick={closeFunc}>
          <i className="zmdi zmdi-close"/>
        </button>
      </div>
    );
  };

  renderPanelFooter = (isDesktop) => {
    return (
      <div className="modal-footer" style={{paddingTop: "0px"}}>
        {!this.props.classDescription.SingleSelect &&
        <div className="footbox -limited">
          <div>
            <p className="text-grey pb1ex">{i18next.t('Selected')}:</p>
          </div>
          <div>
            <button className="btn btn-link -pure -noborder antipod nom noplr" onClick={this.deleteAllItems}>
            {i18next.t('ResetSelected')}
              <i className="zmdi zmdi-close antipod"/>
            </button>
          </div>
        </div>
        }
        <div className="footbox -limited text-left">
          {
            !this.props.classDescription.SingleSelect &&
            <div style={{ maxHeight: "200px", overflow: "auto", paddingLeft: "0px" }} className="col-md-12">
              <GridView dataItem={this.props.selectItem} renderCell={this.renderCellDelete}/>
            </div>
          }
        </div>
        <div className="footbox -limited" style={{ marginTop: "10px" }}>
            {this.props.classDescription.SingleSelect &&
                <div>
                    {(isDesktop || this.state.classTabs && this.state.mobileStep === mobileSteps.groupSettingsForm) &&
                        <button type="button" className="btn btn-regular -wide" onClick={() => {this.setState({mobileStep: mobileSteps.groupsListForm})}}>
                            {i18next.t('Back')}
                        </button>
                    }
                    {(!isDesktop && this.state.mobileStep === mobileSteps.groupsListForm) &&
                        <button type="button" className="btn btn-regular -wide" onClick={()=>this.onConfirm(true)}>
                            {i18next.t('Close')}
                        </button>
                    }
                </div>
            }
            <div>&nbsp;</div>
          <div>
              {(isDesktop || this.state.classTabs && this.state.mobileStep === mobileSteps.groupSettingsForm) &&
                  <button type="button" className="btn btn-regular -wide" onClick={() => this.onConfirm()}>
                      {i18next.t('Choose')}
                  </button>
              }
              {(!isDesktop && this.state.mobileStep === mobileSteps.groupsListForm) &&
                  <button type="button" className="btn btn-regular -wide" onClick={() => {this.setState({mobileStep: mobileSteps.groupSettingsForm})}}>
                      {i18next.t('Next')}
                  </button>
              }
          </div>

        </div>
      </div>
    );
  };

  renderClassTabs = () => {
      if(!this.state.classTabs)
          return null;

      let renderTabContent = (tab) => tab.renderTab();
      let activeTabId = !!this.state.activeTab ? this.state.activeTab.id : null;

      return (
          <TabPanel tabs={this.state.classTabs}
                    valueField="id"
                    textField="name"
                    activeTabValue={activeTabId}
                    allowCollapse={false}
                    renderTabContent={renderTabContent}
                    onSelected={this.onTabSelected}
          />
      );
  }

    renderGroupsList = () => {
        return (
            <BaseMnTree dataItem={this.props.treeItem} nodeRender={this.nodeRender}
                        controlOnlyByImage={false} openRootNodeByDefault={true} />
        );
    }

  onTabSelected = (tab) => { this.setState({activeTab: tab}); };

  renderDesktop = () => {
      const mainContainerHeight = `calc(100vh - ${!this.props.classDescription.SingleSelect ? "500" : "400"}px)`;

      return (
              <PopupWrapper onClose={this.props.onClose} popupVisible={true} title={""}>
                      <div className="modal fade show" id="myModal" style={{display: "block"}}>
                          <div className="modal-dialog modal-dialog-centered ClassPanel">
                              <div className="modal-content h-95 mh-95">
                                  {this.renderPanelHeader(true)}
                                  <div style={{ height: mainContainerHeight, minHeight: "200px" }}>
                                      <div className="modal-body row h-100" style={{padding: "0 16px"}}>
                                          <div style={{ /*maxHeight: "500px",*/ overflow: "auto"}}
                                               className="h-100 col-md-4">
                                              {this.renderGroupsList()}
                                          </div>
                                          <div className="h-100 col-md-8 tabs-in-modal">
                                              {this.renderClassTabs()}
                                          </div>
                                      </div>
                                  </div>
                                  {this.renderPanelFooter(true)}
                              </div>
                          </div>
                      </div>
                  </PopupWrapper>
      )
  }

    openMobileFilters = () => {
        this.setState({"mobileFiltersDisplay": true})
    }

    closeMobileFilters = () => {
        this.setState({"mobileFiltersDisplay": false})
    }

  renderMobile = () => {
      const divHeight = `calc(100vh - ${!this.props.classDescription.SingleSelect ? "500" : "400"}px)`;
      const currentGroup = this.props.treeItem.getRow(this.props.treeItem.getCurrentRowIndex());
      const showFilters = this.state.mobileFiltersDisplay;
      return (
          <PopupWrapper onClose={()=>this.onConfirm(true)} popupVisible={true} title={""}>
              <div className="modal fade show" id="myModal" style={{display: "block"}}>
                  <div className="modal-dialog modal-dialog-centered ClassPanel h-100 mh-100">
                      <div className="modal-content h-95 mh-95">
                          <div className="modal-header">
                              <span className={"modal-title"}>{i18next.t('SelectGroup')}</span>
                              <button type="button" className="close" data-dismiss="modal" onClick={()=>this.onConfirm(true)}>
                                  <i className="zmdi zmdi-close"/>
                              </button>
                          </div>
                          <button className={"btn mn-btn-link m-2 ml-3"} onClick={this.openMobileFilters}>
                              {i18next.t("FiltersShow")}
                          </button>
                          <div style={{display: showFilters ? "block" : "none" , position: "absolute", background: "#fff", zIndex: "1", top: "20px"}} ref={this.filtersPanelRef}>
                              {this.renderPanelHeader(this.closeMobileFilters)}
                          </div>
                          <div style={{overflowY: "auto", height: divHeight}}>

                              <div className="modal-body row">
                                  {this.state.mobileStep === mobileSteps.groupsListForm &&
                                      <div style={{ minHeight: "200px" }} className="col-md-12">
                                          {this.renderGroupsList()}
                                      </div>
                                  }

                                  <div style={{ minHeight: "200px", display: this.state.mobileStep === mobileSteps.groupSettingsForm ? "block" : "none"}} className="col-md-12">
                                      <div style={{ margin: "5px" }}>
                                          <label className={"lt-label"} style={{paddingLeft: "5px", paddingRight: "5px"}}>
                                              <i className="zmdi zmdi-folder-outline" style={{color: "#17b169"}}/>&nbsp;
                                              <span title="Выбрать" style={{cursor: "pointer", lineHeight: "1.5"}}>
                                                  {currentGroup["TreeName"]}
                                              </span>
                                          </label>
                                      </div>
                                      {this.renderClassTabs()}
                                  </div>
                              </div>
                          </div>
                          {this.renderPanelFooter(false)}
                      </div>
                  </div>
              </div>
          </PopupWrapper>
      );
  }

  render() {
    if (!this.props.treeItem) return null;

    return <ScreenFormatContext.Consumer>
      {
        isDesktop => {
          return (
            <>
              <MnSpinner active={!this.state.isHierarchyReady || !this.state.isTreeReady}/>{/*Y!!!*/}
              {isDesktop ? this.renderDesktop() : this.renderMobile()}
            </>
          );
        }
      }
    </ScreenFormatContext.Consumer>
  }
}

ClassExPanel.propTypes = {
    dispatch: PropTypes.func.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    classKey: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    classDescription: PropTypes.object,
    searchValue: PropTypes.string
};

ClassExPanel.defaultProps = {
};

function mapStateToProps(state, ownProps) {

    const classDescription = state.classRepository[ownProps.classKey].classDescription;
    if (classDescription) {
      const comboItem = state.dynState[classDescription.comboItemPath];
      const treeItem = state.dynState[classDescription.treeItemPath];
      const treeItemParams = state.dynState[classDescription.treeItemParamsPath];
      /*
      пока поддерживается режим "только дерево"
      const listItem = classDescription.ClassSelectSource === CLASS_TYPE_LIST
        ? state.dynState[classDescription.listOnlyItemPath]
        : state.dynState[classDescription.listItemPath];
        */
      const selectItem = state.dynState[classDescription.selectItemPath];
      const tabsItem = state.dynState[classDescription.classTabsItemPath];

      return {
        classDescription,
        comboItem,
        treeItem,
        selectItem,
        treeItemParams,
        tabsItem
      };
    }
}

export default connect(mapStateToProps)(ClassExPanel);