import React, {Component} from "react";
import "bootstrap";
import {Route, Switch} from "react-router-dom";
import queryString from "query-string";
import Home from "../Home/Home";

export const commandToRouteMap = {
    //Profile: {link: "Profile", render: (id) => <ViewPerson commandId={id} />}
};

export const formObjectToRouteMap = {
    //About: {link: "AboutMe", render: (id) => <ViewPerson commandId={id} />},
};

export function getUrlByMenuItem(menuItem, commandParams) {

    if (!(menuItem && menuItem.AppCommand && menuItem.AppCommand.Param1))
        return "";

    const objectName = menuItem.AppCommand.Param1;

    const target = formObjectToRouteMap[objectName];
    if (!target)
        return "";

    const qs = queryString.stringify(commandParams);

    return "/" + target.link + "?" + qs;
}

export function navigateCommand(history, commandName, commandParams) {

    const target = commandToRouteMap[commandName];
    if (!target)
        return;

    const qs = queryString.stringify(commandParams);

    history.push("/" + target.link + "?" + qs);
}

class Routes extends Component {

    componentWillUnmount() {
        console.log("Routes will unmount");
    }

    render() {
        return (
            <Switch>
                <Route path="/">
                    <React.Fragment>
                        <Route exact={true} path="/" render={() => <Home />} />
                    </React.Fragment>
                </Route>
            </Switch>
        );
    }
}

export default Routes;
