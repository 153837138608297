/**
 * Created by marks on 22.02.2017.
 */
export const ITEM_LOAD_DATA_SUCCESS = 'ITEM_LOAD_DATA_SUCCESS';
export const ITEM_BEGIN_OPERATION = 'ITEM_BEGIN_OPERATION';
export const ITEM_END_OPERATION = 'ITEM_END_OPERATION';
export const ITEM_SET_FIELD = 'ITEM_SET_FIELD';
export const ITEM_SET_FIELDS = 'ITEM_SET_FIELDS';
export const ITEM_SET_FIELDS_COMPLETE = 'SET_FIELDS_COMPLETE';
export const ITEM_REFRESH_DATA_SUCCESS = 'ITEM_REFRESH_DATA_SUCCESS';
export const ITEM_DATA_SLICE_SUCCESS = 'ITEM_DATA_SLICE_SUCCESS';
export const ITEM_SET_FILTER = 'ITEM_SET_FILTER';
export const ITEM_RESET = 'ITEM_RESET';
export const ITEM_TREE_OP_SUCCESS = 'ITEM_TREE_OP_SUCCESS';
export const ITEM_SET_INDEX = 'ITEM_SET_INDEX';
export const ITEM_SAVE = 'ITEM_SAVE';
export const ITEM_SAVE_ADD = 'ITEM_SAVE_ADD';
export const ITEM_BEGIN_ADD_ROW = 'ITEM_BEGIN_ADD_ROW';
export const ITEM_BEGIN_EDIT = 'ITEM_BEGIN_EDIT';
export const ITEM_CANCEL_EDIT = 'ITEM_CANCEL_EDIT';
export const ITEM_CANCEL_ADD = 'ITEM_CANCEL_ADD';
export const ITEM_DELETE_ROW = 'ITEM_DELETE_ROW';
export const ITEM_ADD_FILE = 'ITEM_ADD_FILE';
export const ITEM_PROGRESS_FILE = 'ITEM_PROGRESS_FILE';
export const ITEM_STORING_FILE = 'ITEM_STORING_FILE';
export const ITEM_TREE_SET_ROW_SUCCESS = "ITEM_TREE_SET_ROW_SUCCESS";
