import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import debounce from 'lodash/debounce';

export class InputFieldInline extends Component {
    constructor(props, context) {
        super(props, context);

        this.autoFocusPerformed = false;
        this.isValueChanged = false;

        this.state = {
            currentValue : "",
            initialValue : ""
        }
    }

    render() {
        const self = this;
        const textEditPlaceholder = this.getTextEditPlaceholder();
        const fieldName = this.props.fieldInfo.Name;
        if (this.props.readOnly) {
            let value = this.props.formattedVal;
            if (value === null || value === undefined) {
                value = '';
            }
            if (value.split) {
                const strs = value.split(/(?:\r\n|\r|\n)/g);
                return (<span>{strs.map((str, i) => <Fragment key={`${fieldName}-${i}`}>{!!i && <br/>}{str}</Fragment>)}</span>);
            } else {
                return (<span>{value}</span>);
            }
        }

        const controlProps = {
            className: "form-control",
            placeholder: textEditPlaceholder,
            ref: function (input) {
                if (self.props.autoFocusedInput && input && !self.autoFocusPerformed)
                    setTimeout(() => {
                        input.focus();
                        self.autoFocusPerformed = true
                    }, 100)
            },
            key: this.props.fieldInfo.Name,
            value: this.state.currentValue,
            readOnly: this.props.readOnly,
            disabled: this.props.readOnly,
            onKeyDown: function (e) {
                const keyCode = e.keyCode;
                if (keyCode === 13 && self.props.applyOnEnter) {
                    self.onEnterKey();
                }
            },
            onChange: self.onChangeValue,
            onBlur: self.onEndEditFieldInternal
        };

        if (this.props.multiline) {
            controlProps.rows = 1;
        } else {
            controlProps.type = "text";
        }


        if (this.props.multiline) {
            return (
              <textarea {...controlProps} />
            );
        }
        return (<input {...controlProps}/>);
    }

    onChangeValue = (e) => {
        const val = e.target.value;
        this.isValueChanged = true;
        this.setState({"currentValue":val});

        if (this.props.autoApplyParams && val.length >= this.props.minSymbolsToAutoApply)
            this.onEndEditFieldDebounced()
    };

    onEndEditFieldInternal = async () => {

        if (this.isValueChanged) {
            await this.props.dispatch(this.props.dataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, this.state.currentValue));
        }

        if (this.props.onEndEditField && this.isValueChanged) {
            this.props.onEndEditField(this.props.rowIndex);
            this.isValueChanged = false;
        }
    };

    onEndEditFieldDebounced = debounce(this.onEndEditFieldInternal, this.props.debounceWait);

    onEnterKey = async () => {
        if (this.isValueChanged) {

            await this.props.dispatch(this.props.dataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, this.state.currentValue));

            if (this.props.onApply) {
                this.props.onApply();
                this.isValueChanged = false;
            }
        }
    };

    static getDerivedStateFromProps(props, state)
    {
        if (props.formattedVal!==state.initialValue)
            return {currentValue: props.formattedVal, initialValue: props.formattedVal};

        return null;
    }

    getTextEditPlaceholder() {

        const fi = this.props.fieldInfo;

        if (!fi.Style)
            return "";

        let pos = fi.Style.indexOf("WebPH:");

        if (pos >= 0) {
            let posEnd = fi.Style.indexOf(";", pos + 6);

            if (posEnd > 0)
                return fi.Style.substring(pos + 6, posEnd);
            else
                return fi.Style.substring(pos + 6, fi.Style.length);
        }

        return "";
    }
}

InputFieldInline.propTypes = {
    dispatch: PropTypes.func,

    formattedVal: PropTypes.any.isRequired,
    readOnly: PropTypes.bool.isRequired,
    dataItem: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    autoApplyParams : PropTypes.bool.isRequired,

    autoFocusedInput: PropTypes.bool,
    applyOnEnter: PropTypes.bool,
    minSymbolsToAutoApply: PropTypes.number,

    onEndEditField: PropTypes.func.isRequired,
    onApply: PropTypes.func,
    multiline: PropTypes.bool
};


InputFieldInline.defaultProps = {
    autoFocusedInput: false,
    applyOnEnter: false,
    minSymbolsToAutoApply: 3,
    debounceWait: 2000,
    multiline: false
};


export default connect()(InputFieldInline);