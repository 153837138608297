import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as ea from '@mnjsplatform/data/lib/actions/errorActions';
import formatError from '@mnjsplatform/data/lib/utils/errorTools';
import i18next from "i18next";
import ModalWrapper from "./modalWrapper";

const styleShow = {display:"block"};

export class Alert extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.closeAlert = this.closeAlert.bind(this);
  }

  closeAlert() {
    this.props.dispatch(ea.errorDissmissed());
  }

  render() {
      if (!this.props.showAlert)
          return null;

    return (<ModalWrapper><div className="modal fade show" id="myModal" style={styleShow}>
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header">
                        <h4 className="modal-title">{i18next.t("error.message")}</h4>
                        <button type="button" className="close" data-dismiss="modal" onClick={this.closeAlert}>&times;</button>
                    </div>

                    <div className="modal-body">
                        {
                            this.props.showAlert && this.props.errorLines &&
                                this.props.errorLines.map((line, index) => (<p>{line}</p>))
                        }
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-regular" data-dismiss="modal" onClick={this.closeAlert}>{i18next.t("error.close")}</button>
                    </div>
                </div>
            </div>
        </div></ModalWrapper>
    );
  }
}

Alert.propTypes = {
    dispatch: PropTypes.func,
    showAlert: PropTypes.bool,
    errorLines: PropTypes.array,
    newSession: PropTypes.bool,
    errorTxt: PropTypes.string
}

function mapStateToProps(state, ownProps) {

  const errorTxt = formatError(state.errorStatus.errorData);
  const errorLines = errorTxt.split('\n');

  return {
      showAlert: state.errorStatus.errorState,
      newSession: state.errorStatus.newSession,
      errorLines: errorLines,
      errorTxt: errorTxt
  }
}

export default connect(mapStateToProps)(Alert);
