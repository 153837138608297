import PropTypes from "prop-types";
import React from "react";
import i18next from "i18next";
//import {MenuItem, DropdownButton} from "react-bootstrap";

class GridViewHeaderDropdown extends React.Component {
  onSort(fieldInfo) {
    if (this.props.onSort) {
      this.props.onSort(fieldInfo);
    }
  }

  render() {
    /*   //const self = this;
       //let fieldInfo;

       if (this.props.sortInfo.FieldName) {
         fieldInfo = this.props.resultFieldInfo.find(fieldInfo => this.props.sortInfo.FieldName === fieldInfo["Name"], this);
       }
   */
    return null;

    /*
    (
        <thead>
        <tr>
            <td style={{textAlign: "right"}}>
                {this.props.resultFieldInfo.some(
                    fieldInfo => fieldInfo["IsSortable"] && fieldInfo["Header"]
                ) ? (
                    <DropdownButton
                        bsStyle="link"
                        bsSize="small"
                        title={
                            this.props.sortInfo.FieldName
                                ? fieldInfo["Header"].replace(/\|/g, " ") +
                                (this.props.sortInfo.Asc ? "↑" : "↓")
                                : "Sorting..."
                        }
                        id="btnSort"
                        pullRight
                    >
                        {this.props.resultFieldInfo.map(
                            (fieldInfo, fieldIndex) =>
                                fieldInfo["IsSortable"] ? (
                                    <MenuItem
                                        key={fieldInfo["Name"]}
                                        eventKey={fieldIndex}
                                        onClick={function () {
                                            self.onSort(fieldInfo);
                                        }}
                                    >
                                        {fieldInfo["Header"].replace(/\|/g, " ")}
                                        {this.props.sortInfo.FieldName !== fieldInfo["Name"]
                                            ? ""
                                            : this.props.sortInfo.Asc ? "↑" : "↓"}
                                    </MenuItem>
                                ) : null,
                            this
                        )}
                    </DropdownButton>
                ) : null}
            </td>
        </tr>
        </thead>
    );*/
  }
}

GridViewHeaderDropdown.propTypes = {
    resultFieldInfo: PropTypes.array,
    onSort: PropTypes.func,
    sortInfo: PropTypes.object,
    updateStateFlag: PropTypes.any
};

export default GridViewHeaderDropdown;
