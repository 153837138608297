export const tabsCallbackStoreItems = {
  init: "init",
  refresh: "refresh",
  save: "save",
  cancel: "cancel",
  tabId: "tabId"
};

class TabsCallbackStore {
  constructor() {
    this.callbacks = [];
  }

  add = (item) => {
    if (this.callbacks.indexOf(item) < 0) {
      this.callbacks.push(item);
    }
  };

  remove = (item) => {
    const index = this.callbacks.indexOf(item);
    if (index >= 0) {
      this.callbacks.splice(index, 1);
    }
  };

  findByTabId = (tabId) => {
    return this.callbacks.find(c => c[tabsCallbackStoreItems.tabId] === tabId);
  };

  call = (itemName, stepCallback, callbackParams) => {
    return this._reduce(this.callbacks, itemName, stepCallback, callbackParams);
  };

  _reduce = (arr, itemName, stepCallback, callbackParams) => {
    return arr.reduce((p, c) =>
      p.then(() => {
        if (stepCallback) {
          stepCallback(callbackParams);
        }
        return !c[itemName] ? Promise.resolve() : c[itemName](callbackParams);
      }),
      Promise.resolve()
    );
  };

  getTabIdArray = () => {
    return this.callbacks.map(c => c[tabsCallbackStoreItems.tabId]);
  };

  length = () => {
    return this.callbacks.length;
  };

  initAllTabs = (initParams) => {
    return this.call(tabsCallbackStoreItems.init, null, initParams);
  }

  refreshTab = (tabId, refreshParams) => {
    let itemsToRefresh = !!tabId ? this.callbacks.filter(c => c[tabsCallbackStoreItems.tabId] === tabId) : this.callbacks;

    if(itemsToRefresh.length === 0)
      return;

    return this._reduce(itemsToRefresh, tabsCallbackStoreItems.refresh, null, refreshParams);
  };
}

export default TabsCallbackStore;