import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class ColorPicker extends React.Component {
  onItemClick = async(e, value) => {
    if (value === this.props.value) {
      return;
    }
    await this.props.dispatch(this.props.parentDataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, value));
    this.props.onValueChanged(value);
  };

  renderItem = (item) => {
    const color = item["DBValue"];
    const className = `colorPicker-itemContainer${color === this.props.value ? " active" : ""}`
    return (
      <div key={color} className={className}>
        <div className="colorPicker-item"
             style={{backgroundColor: color}}
             onClick={(e) => this.onItemClick(e, color)}
        >&nbsp;</div>
      </div>
    );
  };

  render() {
    const items = this.props.fieldInfo.EditorInfo.enumList;
    return (
      <div className="colorPicker-container">
        {items.map(this.renderItem)}
      </div>
    );
  }
}

ColorPicker.propTypes = {
  dispatch: PropTypes.func,
  parentDataItem: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  value: PropTypes.string,
  onValueChanged: PropTypes.func
};

ColorPicker.defaultProps = {
  onValueChanged: () => {}
};

export default connect()(ColorPicker);