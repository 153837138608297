import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {errorRaised} from "@mnjsplatform/data/lib/actions/errorActions";
import {updateFileName} from "@mnjsplatform/data/lib/utils/fileUtils";
import "../style/ComponentsStyle.css";
import {FileSizeContext} from "@mnjsplatform/data";
import FileDropPanel from "./FileDropPanel";
import i18next from "i18next";

class FileUploadPanel extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedFiles: !!props.formattedVal ? [{name: props.formattedVal}] : {}
    };
  }

  onReset = async() => {
    await this.updateItem(null);
  };

  updateItem = async(files, appMax) => {
    const file = files ? files[0] : null;
    const fileName = !!file ? file.name : null;

    try {
      await this.props.dispatch(this.props.dataItem.setFile(this.props.rowIndex, this.props.fieldInfo.Name, file, appMax));
      await this.props.dispatch(updateFileName(this.props.dataItem, this.props.fieldInfo.Name, this.props.rowIndex, fileName));
      const newSelectedFiles = file ? [file] : [];
      this.setState({ selectedFiles: newSelectedFiles});
    }
    catch(e) {
      console.log(e);
    }
  };

  getFileCheckError = (file, approvedFiles) => {
    if (!this.props.allowUploadMultipleFiles && approvedFiles.length > 0) {
      return i18next.t("FileInline_NoMultiUpload", {name: file.name});
    }
    return null;
  };

  uploadFiles = async(files, appMax) => {
    if (!files || !files.length) {
      return;
    }
    const filesToUpload = [];
    const notUploadedFilesErrors = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const error = this.getFileCheckError(file, filesToUpload);
      if (!error) {
        filesToUpload.push(file);
      }
      else {
        notUploadedFilesErrors.push(error);
      }
    }

    if (filesToUpload.length > 0) {
      await this.updateItem(filesToUpload, appMax);
    }

    if (notUploadedFilesErrors.length > 0) {
      await this.props.dispatch(errorRaised(new Error(notUploadedFilesErrors.join("; "))));
    }
  };

  render() {
    return (
      <FileSizeContext.Consumer>
        {maxFileSize => {
          const content = this.props.children({
            selectedFiles: this.state.selectedFiles,
            uploadFiles: async(files) => await this.uploadFiles(files, maxFileSize),
            onReset: this.onReset
          });

          if (this.props.allowDragAndDrop) {
            return (
              <FileDropPanel onFilesDropped={files => this.uploadFiles(files, maxFileSize)}>
                {content}
              </FileDropPanel>
            );
          }
          return content;
        }}
      </FileSizeContext.Consumer>
    );
  }
}

FileUploadPanel.propTypes = {
  dispatch: PropTypes.func,

  dataItem: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  formattedVal: PropTypes.any,

  allowUploadMultipleFiles: PropTypes.bool,
  allowDragAndDrop: PropTypes.bool,
};

FileUploadPanel.defaultProps = {};

export default connect()(FileUploadPanel);