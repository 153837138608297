import UrProvider from "../api/UrProvider";

const USER_FILE_NAME = "UserFileName";

export function formatFileSize(size) {
  if (size < 1024) return (size + " Bytes");
  else if (size < 1048576) return ((size / 1024).toFixed(0) + " KB");
  else if (size < 1073741824) return ((size / 1048576).toFixed(1) + " MB");
  else return ((size / 1073741824).toFixed(1) + " GB");
}
export function cutFileName(longFileName){
  let split = longFileName.split('.');
  let fileName = split[0];
  let fileExt = split[1];
  if(fileName.length > 256){
    fileName = fileName.substring(0, 8) + '...' + fileName.substring(fileName.length-3);
  }
  return fileName + '.' + fileExt;
}

export function getFileNameTargetField(dataItem, fieldName) {
  const urProcessed = dataItem.rawData.ColumnUpdateRules[fieldName];
  const urProvider = new UrProvider(urProcessed);

  const ri = urProvider.updateRules.find(ri=> ri.strSrc === USER_FILE_NAME);
  if (ri)
    return ri.strDst;

  return null;
}

export function updateFileName(dataItem, fieldName, rowIndex, fileName) {
  return d => {
    const updatedFields = {[fieldName]: fileName};
    const dstForName = getFileNameTargetField(dataItem, fieldName);
    if (dstForName) {
      updatedFields[dstForName] = fileName;
    }
    d(dataItem.setFields(rowIndex, updatedFields));
    return Promise.resolve();
  };
}