import ReportApi from "../api/reportApi";
import {REPORT_INACTIVE} from "../reducers/reportReducer";
import { createItem, removeItem } from "./dynamicItemActions";
import { ITEM_LOAD_DATA_SUCCESS } from "./dataItemTypes";
import * as ea from '../actions/errorActions';

export const REPORT_CONTROLLER = "Report";

export const REPORT_PARAMS_AVAILABLE = "REPORT_PARAMS_AVAILABLE";
export const REPORT_PREPARE = "REPORT_PREPARE";
export const REPORT_INITIALIZED = "REPORT_INITIALIZED";
export const REPORT_CREATED = "REPORT_CREATED";
export const REPORT_UPDATE_TASK_STATUS = "REPORT_UPDATE_TASK_STATUS";
export const REPORT_BUILD_ERROR = "REPORT_BUILD_ERROR";
export const REPORT_COMPLETE = "REPORT_COMPLETE";
export const REPORT_DISMISS = "REPORT_DISMISS";
export const REPORT_UNMOUNT = "REPORT_UNMOUNT";

export function getReportParams(reportId, params = {}, reportKey = "defaultReport") {
    return async (d, s) => {

        try {
            await ReportApi.prepareWebReport(reportId, reportKey, params);
            const data = await ReportApi.getReportParamsItem(reportId, reportKey);
            const itemPath = await injectItem(data.reportItem, reportKey, d);

            d({ type: REPORT_PARAMS_AVAILABLE, paramPath: itemPath, reportKey});

            return itemPath;
        }
        catch (error) {
            d(ea.errorRaised(error));
        }
    }
}

export function buildReport(reportId, params = {}, reportKey = "defaultReport", skipPrepare = false) {
    return (dispatch, getState) => {

        let taskId = null;
        let lastEvent = 0;

            if (!skipPrepare)
                dispatch({type: REPORT_PREPARE, reportKey});

            const p = skipPrepare ? Promise.resolve() : ReportApi.prepareWebReport(reportId, reportKey, params);

            p.then(() => {
                dispatch({type: REPORT_INITIALIZED, reportKey});
                return ReportApi.createReport(reportKey);
            })
            .then(data => {

                if (isCancelled(getState))
                    return Promise.resolve();

                dispatch({type: REPORT_CREATED, data: data, reportKey});

                taskId = data.taskId;

                return ReportApi.getTaskStatus(data.taskId, 0)
            })
            .then(data => {

                if (isCancelled(getState))
                    return Promise.resolve();

                dispatch({type: REPORT_UPDATE_TASK_STATUS, data: data, reportKey});

                checkError(data);

                return promiseWhile(data, d => !isCancelled(getState) && d && d.statusCode !== 3, () => {

                    return delay(500)
                        .then(() => {
                            return ReportApi.getTaskStatus(taskId, lastEvent)
                        })
                        .then(data => {

                            if (isCancelled(getState))
                                return Promise.resolve();

                            dispatch({type: REPORT_UPDATE_TASK_STATUS, data: data, reportKey});

                            checkError(data);

                            lastEvent += data.ReportEvents.length;
                            return Promise.resolve(data);
                        })
                })
            })
            .then(data => {
                if (isCancelled(getState))
                    return Promise.resolve();

                dispatch({type: REPORT_COMPLETE, data: data, reportKey});
                return Promise.resolve();
            })
            .catch(error => {
                console.log(error);
                dispatch({type: REPORT_BUILD_ERROR, data: error, reportKey});
            });
    }
}

export function unmountReport(reportKey = "defaultReport"){
    return async (d, s) => {

        const repItem = s().reportBuildState[reportKey];

        if (!repItem || !repItem.paramPath)
            return;

        d(removeItem(repItem.paramPath));
        d({ type: REPORT_UNMOUNT, reportKey});
    }
}

function isCancelled(getState){
    const state = getState();
    return state.reportBuildState.currentState === REPORT_INACTIVE;
}

function isComplete(getState){
    const state = getState();
    return state.reportBuildState.currentState === REPORT_COMPLETE;
}

function checkError(data) {
    if (!!data && data.statusCode === 4)
        throw new Error(data.statusText);
}

export function reportDismiss(taskId, reportKey = "defaultReport") {
    return (dispatch, getState) => {

        if (!taskId || isComplete(getState)) {
            dispatch({type: REPORT_DISMISS, reportKey});
            return Promise.resolve();
        }

        ReportApi.cancelTask(taskId)
            .catch(error => console.log(error))
            .then(dispatch({type: REPORT_DISMISS, reportKey}))
    }
}

function delay(duration) {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve();
        }, duration)
    });
}

const promiseWhile = (data, condition, action) => {

    const whilst = (data) => {
        return condition(data) ? action(data).then(whilst) : Promise.resolve(data);
    };

    return whilst(data);
};

function injectItem(dataItem, reportKey, dispatch, withParamset = false) {
    const itemUpdateRequest = getItemUpdateRequest(dataItem.ItemId, reportKey, withParamset);

    return dispatch(createItem(itemUpdateRequest))
      .then(path => {
          dispatch({ type: ITEM_LOAD_DATA_SUCCESS, rawData: dataItem, name: path });
          return path;
      });
}

function getItemUpdateRequest(itemName, reportKey, withParamset = false) {
    return {
        controller: REPORT_CONTROLLER,
        methodName: itemName,
        fieldName: reportKey,
        withParamset: withParamset,
        isPartial: true
    };
}