import React, { Component } from "react";
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { connect } from "react-redux";
import formatData from "@mnjsplatform/data/lib/utils/formatData";

class MnInlineTimePicker extends Component {
  constructor(props, context) {
    super(props, context);
  }

  static isAcceptableEditFormat = (boilerPicture) => {
    if (null === boilerPicture)
      return false;

    return (boilerPicture === "99:99" ||
      boilerPicture === "99:99:99" //||
      //boilerPicture === "99.99.99" ||
      //boilerPicture === "99.99.9999" ||
      //boilerPicture === "99.99.9999 99:99" ||
      //boilerPicture === "99.99.9999 99:99:99"
    );
  };

  isValid = (value) => {
    let errorMsg = "";

    let re = /^(\d{1,2}):(\d{2})(:00)?([ap]m)?$/;
    let regs = value.match(re);
    if (value != "") {
      if (regs) {
        if (regs[4]) {
          // 12-hour time format with am/pm
          if (regs[1] < 1 || regs[1] > 12) {
            errorMsg = "Invalid value for hours: " + regs[1];
          }
        } else {
          // 24-hour time format
          if (regs[1] > 23) {
            errorMsg = "Invalid value for hours: " + regs[1];
          }
        }
        if (!errorMsg && regs[2] > 59) {
          errorMsg = "Invalid value for minutes: " + regs[2];
        }
      } else {
        errorMsg = "Invalid time format: " + value;
      }
    }

    if (errorMsg != "") {
      return false;
    }
    return true;
  }

  to2SignString = (number) => {
    let str = number.toString();
    if (str.length === 1) {
      str = '0' + str;
    }
    return str;
  };

  onChange = (e) => {
    let value = e.target.value;
    if (this.isValid(value)) {
      let date = new Date();
      let dateStr = `${this.to2SignString(date.getFullYear())}-${this.to2SignString(date.getMonth() + 1)}-${this.to2SignString(date.getDate())}`;
      let timeStr = `${value.substr(0, 2)}:${value.substr(3, 2)}:00`;
      this.props.onChange({target: {value: `${dateStr}T${timeStr}`}});
    }
  };

  render() {
    if (MnInlineTimePicker.isAcceptableEditFormat(this.props.boilerPicture)) {
      if (this.props.readOnly) {
        return (<span>{this.props.value}</span>);
      }
      let mask = this.props.boilerPicture.replace(/9/g, '1');
      switch (this.props.boilerPicture) {
        case "99:99": {
          mask = "Hh:Mm";
          break;
        }
        case "99:99:99": {
          mask = "Hh:Mm:Mm";
          break;
        }
        default: {
        }
      }

      let value = this.props.rawVal || "";

      if (!value.includes("T") && typeof value === "string") {
        let result = value.match(/(\d\d).(\d\d).(\d\d\d\d) (\d?\d):(\d\d)(:(\d\d))?/)
        if (result && result.length > 5) {
          value = `${result[3]}-${result[2]}-${result[1]}T${this.to2SignString(result[4])}:${result[5]}:${result[7] || "00"}.000`;
        }
      }
      
      if (value.includes("T") && this.props.fieldInfo) {
        let fieldInfo = this.props.fieldInfo;
        if (value.match(/\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d(.\d\d\d)?/) && fieldInfo.DataType !== "System.DateTime") {
          fieldInfo = {
            ...fieldInfo,
            DataType: "System.DateTime"
          };
        }
        value = formatData(fieldInfo, value);
      }

      return <InputMask className="form-control" maskChar="0"
                        value={value}
                        onChange={this.onChange}
                        mask={mask}
                        formatChars={{
                          'H': '[0-2]',
                          'h': '[0-9]',
                          'M': '[0-5]',
                          'm': '[0-9]'
                        }}
      />
    }
    else
      return (<span>{this.props.value}</span>);
  }
}

MnInlineTimePicker.propTypes = {
  dispatch: PropTypes.func,
  value: PropTypes.string,
  rawVal: PropTypes.string,
  readOnly: PropTypes.bool,
  boilerPicture: PropTypes.string,
  fieldInfo: PropTypes.object,
  onChange: PropTypes.func
}

MnInlineTimePicker.defaultProps = {
};

export const isTimePickerAcceptableEditFormat = MnInlineTimePicker.isAcceptableEditFormat;
export default connect()(MnInlineTimePicker);