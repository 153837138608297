import React from "react";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import PropTypes from "prop-types";
import i18next from "i18next";
import {GridRowEditMode} from "./GridView";

class RowEditMenu extends React.Component {
    onMenuSelected = (menuItem) => {
        if (menuItem.MenuItemId === GridActionMenuProvider.Edit)
            this.props.onEdit();
        else if (menuItem.MenuItemId === GridActionMenuProvider.Delete) {
            this.props.onDelete();
        }
    };

    renderDesktop = (innerNode, className) => {
        return (<td className={className || ""}>{innerNode}</td>);
    };

    renderMobile = (innerNode) => {
        return innerNode;
    };

    renderInlineMenu = () => {
        let buttons = !this.props.inlineEditIsActive ? (
            <div className="gridInlineEditButtonContainer">
                {this.props.canEdit &&
                <button type="button" className="btn gridInlineEditButton" title={i18next.t('Edit')} onClick={e => {
                    e.preventDefault();
                    this.props.onEdit();
                }}>
                    <i className="fas fa-pencil-alt" style={{color: "darkgreen"}} />
                </button>
                }
                {this.props.canDelete &&
                <button type="button" className="btn gridInlineEditButton" title={i18next.t('Delete')} onClick={e => {
                    e.preventDefault();
                    this.props.onDelete();
                }}>
                    <i className="fas fa-trash-alt" style={{color: "red"}} />
                </button>

                }
            </div>
        )
        : (
            <div className="gridInlineEditButtonContainer">
                <button type="button" className="btn gridInlineEditButton" title={i18next.t('Save')} onClick={e => {
                    e.preventDefault();
                    this.props.onSave();
                }}>
                    <i className="fas fa-check" style={{color: "green"}} />
                </button>
                <button type="button" className="btn gridInlineEditButton" title={i18next.t('Cancel')} onClick={e => {
                    e.preventDefault();
                    this.props.onCancel();
                }}>
                    <i className="fas fa-times" style={{color: "red"}} />
                </button>
            </div>
        );

        return this.renderDesktop(buttons, "gridView-rowInlineMenuCell");
    };

    render() {
        if (this.props.rowEditMode === GridRowEditMode.inline && this.props.mobileMode && this.props.inlineEditIsActive) {
            return null;
        }

        if (this.props.rowEditMode === GridRowEditMode.inline && !this.props.mobileMode) {
            return this.renderInlineMenu();
        }

        const menuAdapter = new GridActionMenuProvider(this.props.canEdit, this.props.canDelete)
        let dd = <DropdownMenu menuAdapter={menuAdapter} onSelected={this.onMenuSelected}
                               moreItemClassName={this.props.moreItemClassName}
                               renderMoreItem={this.props.renderMoreItem}
                               alignRight={this.props.mobileMode}
        />;

        return (
            <>
                {(this.props.canEdit || this.props.canDelete) && this.props.mobileMode ? this.renderMobile(dd) : this.renderDesktop(dd)}
            </>
        );
    }
}

RowEditMenu.defaultProps = {
    mobileMode: false,
    inlineEditIsActive: false
};

RowEditMenu.propTypes = {
    canEdit: PropTypes.any,
    canDelete: PropTypes.any,

    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,

    renderMoreItem: PropTypes.func,
    moreItemClassName: PropTypes.string,
    mobileMode: PropTypes.bool,
    rowEditMode: PropTypes.number,
    inlineEditIsActive: PropTypes.bool
};

class GridActionMenuProvider {
    static Edit = "Edit";
    static Delete = "Delete";

    constructor(canEdit, canDelete) {
        this.items = [];

        if (canEdit)
            this.items.push({
                MenuItemId: GridActionMenuProvider.Edit,
                MenuText: i18next.t('Edit')
            });

        if (canDelete)
            this.items.push({
                MenuItemId: GridActionMenuProvider.Delete,
                MenuText: i18next.t('Delete')
            });

    }

    getMenuItems() {
        return this.items;
    }

    getVisibleItemsCount() {
        return 0;
    }

    getDropDownText() {
        return "...";
    }
}

export default RowEditMenu;