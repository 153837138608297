import React, {Component} from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import i18next from "i18next";
import ReactDOM from "react-dom";
import "../../style/ComponentsStyle.css";

class MnDatePicker extends Component {
    constructor(props, context) {
        super(props, context);

        this.el = document.createElement('div');

        this.onChangeDate = this.onChangeDate.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.calendarContainer = this.calendarContainer.bind(this);

        this.modalRoot = document.getElementById('modal-root');
    }

    componentDidMount() {
        this.modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.el);
    }

    getFormattedDate = (value) => {
        return value.format("YYYY-MM-DDTHH:mm:ss.SSS");
    };

    onChangeDate(value) {
        if (value === null || value === "")
            this.props.onChange(null);
        else {
            this.props.onChange(this.getFormattedDate(value));
        }
    }

    onBlur(e) {
        let m = moment(e.target.value, "L");
        if (!m.isValid()) {
            this.props.onChange(null);
        }
        else this.onChangeDate(m);
    }

    onChangeRaw = (e) => {
        if (e.target.value == null)
            this.onChangeDate(null);
    };

    calendarContainer ({children}) {
        return ReactDOM.createPortal(
            children,
            this.el
        );
    };

    render() {
        const mom = (this.props.value) ? moment(this.props.value) : null;

        /*if(this.props.readOnly){
          return (<span>{this.props.value}</span>);
        }*/

        return (
            <div>
                <DatePicker
                    selected={mom}
                    onChange={this.onChangeDate}
                    className="form-control"
                    showYearDropdown={true}
                    readOnly={this.props.readOnly}
                    disabled={this.props.readOnly}
                    disabledKeyboardNavigation
                    onChangeRaw={this.onChangeRaw}
                    onBlur={this.onBlur}
                    customInput={<DateInputField/>}
                    popperClassName="mn_datePicker_popper"
                    popperContainer={this.calendarContainer}
                    todayButton={i18next.t("Today")}
                />
            </div>
        );
    }
}

class DateInputField extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.onResetClick = this.onResetClick.bind(this);
    }

    onResetClick(e) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onChange(e);
    }

    /*
                    <div className="input-group classControl">
                    <input
                        type="text"
                        className="form-control"
                        value={this.props.value}
                        disabled
                    />
                    <div onClick={this.onClearClass}><span className="clear zmdi zmdi-close"/></div>
                    <div className="input-group-append">
                        <button
                            className="btn"
                            type="button"
                            onClick={this.onOpenClass}
                        >
                            {showSpinner && <Spinner size={"20px"} colorHex={'FFFFFF'} containerStyle={containerStyle}/>}
                            {showSpinner
                                ? <i className="search zmdi zmdi-view-list-alt" style={{color: 'transparent'}}/>
                                : <i className="search zmdi zmdi-view-list-alt"/>
                            }
                        </button>
                    </div>
                </div>
     */

    render() {

        return (
            <div className="input-group datePicker" onClick={this.props.onClick}>
                <input
                    className="form-control"
                    type="text"
                    value={this.props.value}
                    onKeyDown={this.props.onKeyDown}
                    onChange={this.props.onChange}
                    onBlur={this.props.onBlur}
                />
                <div onClick={this.onResetClick}><span className="clear zmdi zmdi-close"/></div>
                <div className="input-group-append">
                    <button className="btn" type="button"><i className="zmdi zmdi-calendar"/></button>
                </div>
            </div>)
    }
}

MnDatePicker.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool
};


MnDatePicker.defaultProps = {
    onChange: () => {
    }
};

export default MnDatePicker;