import React, {Component} from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import ReactDOM from "react-dom";
import {getScrollParent} from "@mnjsplatform/data/lib/utils/htmlUtils";
import VisibilitySensor from "react-visibility-sensor";
import {SizeMe} from "react-sizeme";

class DropDownMenuCombo extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isOpened: false,
            maxHeight: null,
            top: 0,
            left: 0
        };

        this.myRef = React.createRef();
        this.containerRef = React.createRef();
        this.modalRoot = document.getElementById('modal-root');
        this.el = document.createElement('div');
    }

    updateDimensions = () => {
        const offset = $(this.myRef.current).offset();
        const clientRect = this.myRef.current.getBoundingClientRect();
        const maxHeight = document.body.clientHeight - clientRect.bottom;

        if (!offset || (this.state.top === offset.top && this.state.left === offset.left))
            return;

        this.setState({top:offset.top, left:offset.left, maxHeight: maxHeight });
    }

    componentDidMount() {
        this.modalRoot.appendChild(this.el);
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener("resize", this.updateDimensions);
        window.addEventListener("scroll", this.updateDimensions);

        this.scrollNode = getScrollParent(this.myRef.current);
        if (this.scrollNode)
            this.scrollNode.addEventListener("scroll", this.updateDimensions);
    }

    componentWillUnmount() {
        this.modalRoot.removeChild(this.el);
        document.removeEventListener('mousedown', this.handleClickOutside);
        window.removeEventListener("resize", this.updateDimensions);
        window.removeEventListener("scroll", this.updateDimensions);

        if (this.scrollNode)
            this.scrollNode.removeEventListener("scroll", this.updateDimensions);
    }

    onToggle = () => {
        if (this.props.onBeforeOpen)
            this.props.onBeforeOpen();
        this.updateDimensions();

        const openStatus = !this.state.isOpened;

        this.setState({ isOpened: openStatus },
          () => {
              if (!openStatus)
                  return;

              if (this.props.onAfterOpen)
                  this.props.onAfterOpen();
          });
    }

    onInvisibleItemClick = (e, selectedItem) => {
        e.preventDefault();
        this.onSelect(selectedItem);
    };

    onSelect = (selectedItem) => {
        if (this.props.onSelected)
            this.props.onSelected(selectedItem);

        this.setState({ isOpened: false });
    }

    setDropdownRef = (node) => {
        this.dropdownRef = node;
    };

    handleClickOutside = (event) => {
        let inDropdown = this.dropdownRef && this.dropdownRef.contains(event.target);
        let inCombo = this.myRef && this.myRef.current && this.myRef.current.contains(event.target);

        if (!inCombo && !inDropdown) {
            this.setState({ isOpened: false });

            if (this.props.afterOutsideClick) {
                this.props.afterOutsideClick();
            }
        }
    };

    parentVisibilityChange = (isVisible) => {
        if (!this.state.isOpened)
            return;

        if (!isVisible)
            this.onToggle();
    }

    renderDropDown = (size) => {
        if (!this.myRef.current) {
            return null;
        }
        const menuItems = this.props.dropDownItems || this.props.menuAdapter.getMenuItems();
        const top = this.state.top + 30;
        const maxHeight = this.state.maxHeight;
        const style = {
            top: `${top}px`,
            marginLeft: "0px",
            display: "block",
            overflow: "auto",
            zIndex: 2000,
            maxHeight: `${maxHeight}px`,
            maxWidth: "80vw"
        };

        if (!this.props.alignRight) {
            style.left = this.state.left;
        }
        else {
            style.left = "auto";
            style.right = document.body.clientWidth - this.state.left - (size.width || 0);
        }

        if (this.props.dropDownWidthPercentage) {
            style.width = Math.floor(size.width * this.props.dropDownWidthPercentage / 100);
        }

        return ReactDOM.createPortal((
          <div ref={this.setDropdownRef}>
              {this.state.isOpened &&
              <div className="dropdown-menu w-auto" style={style}>{menuItems.map(item => this.renderInvisibleItem(item))}</div>
              }
          </div>
        ), this.el);
    };

    render() {
        const defaultText = this.props.menuAdapter.getDropDownText();

        return (
          <VisibilitySensor onChange={this.parentVisibilityChange} scrollCheck>
              {this.renderContainer(
                <SizeMe>{({size}) => (
                  <>
                      {this.renderMoreItem(defaultText)}
                      {this.renderDropDown(size)}
                  </>
                )}</SizeMe>
              )}
          </VisibilitySensor>
        );
    }

    renderContainer = (children) => {
        const props = {
            className: "dropdown " + this.props.className,
            style: this.props.style,
            ref: this.myRef
        };
        if (this.props.renderCustomContainer) {
            return this.props.renderCustomContainer({props, children});
        }
        return (
          <div {...props}>
              {children}
          </div>
        );
    };

    renderMoreItem = (defaultText) => {
        if (this.props.renderMoreItem) {
            return this.props.renderMoreItem({
                defaultText,
                onClick: this.onToggle
            });
        }

        if (this.props.readOnly) {
            return defaultText;
        }

        return (
            <button className={this.props.buttonClassName}
                    type="button"
                    title={defaultText}
                    onClick={this.onToggle}
            >
                <i className="zmdi zmdi-chevron-down"/>
                <span className="btn-text">{defaultText}</span>
            </button>
        );
    };

    renderInvisibleItem = (item) => {

        const className = !item.IsActive ? "dropdown-item" : "dropdown-item selected";

        if (this.props.renderInvisibleItem)
            return this.props.renderInvisibleItem(item, this.onSelect);

        return <a className={className} href="" onClick={(e) => this.onInvisibleItemClick(e, item)}
                  key={item.MenuItemId}>{item.MenuText}</a>
    };
}

DropDownMenuCombo.propTypes = {
    menuAdapter: PropTypes.object,
    dropDownItems: PropTypes.array,
    onSelected: PropTypes.func,
    renderMoreItem: PropTypes.func,
    renderInvisibleItem: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    alignRight: PropTypes.bool,
    onBeforeOpen: PropTypes.func,
    onAfterOpen: PropTypes.func,
    readOnly: PropTypes.bool,
    buttonClassName: PropTypes.string,
    renderCustomContainer: PropTypes.func,
    dropDownWidthPercentage: PropTypes.number
};

DropDownMenuCombo.defaultProps = {
    readOnly: false,
    className: "",
    alignRight: false,
    buttonClassName: "btn btn-input -icon dropdown-toggle d-block w100pc"
};

export default DropDownMenuCombo;