import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import i18next from "i18next";

class MultiSelectRows extends React.Component {
  constructor(props) {
    super(props);

    const params = props.fieldInfo.EditorMethod.split(',').map(p => p.split(':'));
    const isSelectedFieldNameParam = params.find(p => p[0] === "DD_IsSelectedFieldName");
    const textFieldNameParam = params.find(p => p[0] === "DD_TextFieldName");

    this.controlOptions = {
      isSelectedFieldName: isSelectedFieldNameParam ? isSelectedFieldNameParam[1] : "IsLinked",
      textFieldName: textFieldNameParam ? textFieldNameParam[1] : "Text"
    };
  }

  get isAllRowsSelected() {
    return !this.props.subgridDataItem.dataTable().some(r => !r[this.controlOptions.isSelectedFieldName]);
  }

  onChangeRowSelected = async(rowIndex, isSelected) => {
    if (!!this.props.readOnly) {
      return;
    }
    if (rowIndex === -1) {
      await this.props.fillSubgrid(!isSelected);
    }
    else {
      await this.props.dispatch(this.props.subgridDataItem.setField(rowIndex, this.controlOptions.isSelectedFieldName, isSelected ? 0 : 1));
      await this.props.dispatch(this.props.subgridDataItem.save(rowIndex));
    }

    await this.props.updateLabel();
  };

  emptyFunc = () => {};

  renderRow = (title, isSelected, rowIndex = -1) => {
    let className = isSelected ? "dropdown-item selected" : "dropdown-item";
    if (!this.props.readOnly) {
      className += " c-pointer";
    }

    return (
      <li role="presentation" key={rowIndex}>
        <div className={`d-flex ${className}`}
             onClick={() => this.onChangeRowSelected(rowIndex, isSelected)}
        >
          <div>
            <label className="mn_checkbox">
              <input className="checkbox"
                     type="checkbox"
                     checked={isSelected}
                     disabled={true}
                     onChange={this.emptyFunc}
              />
              <span></span>
            </label>
          </div>
          <div className="ml-1">
            {title}
          </div>
        </div>
      </li>
    );
  };

  render() {
    const rows = this.props.subgridDataItem.dataTable();

    if (rows.length === 0) {
      return <div style={{paddingLeft: 20, paddingTop: 3, paddingBottom: 3}}>{i18next.t('GridViewEmptyLabel')}</div>;
    }

    return (
      <>
        {this.props.showSelectAll && this.renderRow(i18next.t("ComboBoxMultiSelectAll"), this.isAllRowsSelected)}
        {rows.map((row, idx) => this.renderRow(row[this.controlOptions.textFieldName], !!row[this.controlOptions.isSelectedFieldName], idx))}
      </>
    );
  }
}

MultiSelectRows.propTypes = {
  dispatch: PropTypes.func,
  parentDataItem: PropTypes.object.isRequired,
  subgridDataItem: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  updateLabel: PropTypes.func.isRequired,
  fillSubgrid: PropTypes.func.isRequired,
  showSelectAll: PropTypes.bool
};

MultiSelectRows.defaultProps = {
  showAllItem: false
};

export default connect()(MultiSelectRows);