import "core-js/es6/symbol";
import "core-js/fn/symbol/iterator";
import "core-js/fn/array/find";
import "core-js/fn/array/find-index";
import "core-js/fn/array/includes";
import "core-js/fn/string/includes";
import "core-js/fn/object/entries";
import "core-js/fn/object/assign";
import "core-js/fn/object/values";
import "core-js/fn/promise/finally";
import "core-js/fn/string/starts-with";

import React from 'react';
import ReactDOM from 'react-dom';

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {I18nextProvider} from "react-i18next";
import nprogress from 'nprogress/nprogress.js'
import * as nanoid from "nanoid";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import MediaQuery from "react-responsive";

import App from './components/App';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/docs.min.css";
import "font-awesome/css/font-awesome.css";
import "./assets/fonts/Ubuntu.css";
import "./assets/fonts/Ubuntu-condensed.css";
import "./assets/fonts/g-material-icons.css";
import "./assets/fonts/font-awesome.min.css";
import "./assets/fonts/z-material-icons.min.css";

import "./assets/css/style-carl.css";
import "./assets/css/style-carl-fix.css";
import "./styles/MnEmbeddedControls-carl.css";
import "./styles/nprogress.css";
import "./styles/gridview.css";
import "./styles/react-datepicker.css";

import "./styles/сarrier.css";
import "./styles/lk.css";
import "toastr/build/toastr.css";

import formatError from "@mnjsplatform/data/lib/utils/errorTools";
import cookieChecker from "@mnjsplatform/data/lib/utils/cookieChecker";
import {initTelemetry, trackError} from "@mnjsplatform/data/lib/utils/Telemetry";
import configureStore from "./store/configureStore";
import {errorRaised} from "@mnjsplatform/data/lib/actions/errorActions";
import {setStore, appendReducer} from "@mnjsplatform/data/lib/reducers";
import rootReducer from "./reducers/supplierRegReducers";
import {ScreenFormatContext, GlobalDebugMode} from "@mnjsplatform/data";

import dataResources from "@mnjsplatform/data/lib/localization";
import componentsResources from "@mnjsplatform/components/lib/localization";
import projectResources from "./localization";
import locResourceCombiner from "@mnjsplatform/data/lib/utils/locResourceCombiner";

import AuthProvider from "@mnjsplatform/data/lib/security/authProvider";
import {operationComplete, operationStarted} from "@mnjsplatform/data/lib/actions/operationActions";

AuthProvider.initAuth(window.config);
initTelemetry();

nprogress.configure({ showSpinner: false });
nprogress.start();

if (!cookieChecker())
{
    alert("Cookies must be enabled in browser");
    nprogress.done();
}
else {
        Promise.resolve()
        .then(() => nprogress.set(0.3))
        .then(() => i18Init())
        .then(() => nprogress.set(0.6))
        .then(() => render(App))
        .catch(e => {
            trackError(e);
            alert(formatError(e))
        })
        .then(() => nprogress.done());
}

const i18Init = () => {
    i18next
        .use(LanguageDetector)
        .init({
            debug: false,
            fallbackLng: "ru",
            interpolation: {
                escapeValue: false,
            },
            resources: locResourceCombiner([dataResources, componentsResources, projectResources])
        });

    return Promise.resolve();
};

appendReducer(rootReducer);
const reduxStore = configureStore();
setStore(reduxStore);

window.AppInstanceId = nanoid(7).replace("_", 'x');
window.NewSessionProcessor = (errorData) => {reduxStore.dispatch(errorRaised(errorData))};
window.asyncProcessor = (op, data) =>
{
    if (op)
        operationStarted(reduxStore.dispatch, data);
    else
        operationComplete(reduxStore.dispatch, data);
};

if (GlobalDebugMode)
    console.log("debug enabled");

const render = () => {
    ReactDOM.render(
            <Provider store={reduxStore}>
                <BrowserRouter basename={window.config.baseUrl}>
                    <I18nextProvider i18n={i18next}>
                        <MediaQuery minWidth={768}>
                            {(matches) => {
                                return (
                                    <ScreenFormatContext.Provider value={matches}><App/></ScreenFormatContext.Provider>)
                            }}
                        </MediaQuery>
                    </I18nextProvider>
                </BrowserRouter>
            </Provider>,
        document.getElementById("root")
    );
};