class CustomEventUtils {
    static addListener (event, handler) {
        window.addEventListener(event, handler);
    }

    static removeListener (event, handler) {
        window.removeEventListener(event, handler);
    }

    static dispatchEvent (event) {
        window.dispatchEvent(new Event(event));
    }
}

export default CustomEventUtils;
