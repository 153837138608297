import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Alert from "@mnjsplatform/components/lib/common/Alert";
import ItemInjector from "@mnjsplatform/components/lib/common/ItemInjector";
import ComplexParam from "@mnjsplatform/components/lib/common/ComplexParam";
import ReportPlaceholder from "@mnjsplatform/components/lib/common/Reports/ReportPlaceholder";

export default (props) => {
    return <ItemInjector dataItem={{
        controller: "MatHelp",
        //methodName: "EDocCardMDLine.InsSC",
        //exec bpInquiryFormRC_DescribeWEB @IsCentre=0,@InquiryFormDate='2018-12-27 00:00:00',@InquiryForm_Object='InquiryForm',@WorkDate='2019-09-12 00:00:00',@InquiryFormNumber='TA000245'
        methodName: "MatHelp.CreateFromNewPO_Marriage",
        withParamset : true,
        fieldName: "default"
    }}><ConnectedSandbox {...props} /></ItemInjector>
};

class SandboxInternal extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            currentYear : 2018,
            inOperation : true
        }
    }

    async componentDidMount() {
        await this.props.dispatch(this.props.dataItem.execute({}, {withParams:true, refresh:false}));
    }

    changeYear = (year) => {
        this.setState({currentYear:Number.parseInt(year), inOperation : true}, this.componentDidMount)
    };

    onApply = async ()=>{
        await this.props.dispatch(this.props.dataItem.applyParams(this.props.dataItemParams))
    };

    onSetParams = async (item, build) => {
        await this.props.dispatch(item.setField(0, "TabNumList", [{"TabNum":"00072"}]));
        await build();
    };

    render() {
        return (<>
            <Alert />
            <div className="col-md-5">
                <ComplexParam dataItem={this.props.dataItemParams} renderParams={this.paramRender} showApplyButton={false} />
                <button onClick={this.onApply} value="Apply" className="btn btn-regular" >Apply</button>
            </div>
        </>)
    }

    paramRender = (h) => {

        return (<div className="row">
            <div className="col-md-12">
                {h.fs.map((fi) => {

                    const fn = fi.Name;

                    return (<React.Fragment key={fn}>

                        <div className="row">
                            <div className="col-md-3 field-title">
                                {h.lbl(fn)}
                            </div>
                            <div className="col-md-9">
                                {h.ed(fn)}
                            </div>
                        </div>
                        <hr/>
                    </React.Fragment>)
                })}

            </div>
        </div>)
    };
}

SandboxInternal.propTypes = {
    dispatch: PropTypes.func.isRequired
};

SandboxInternal.defaultProps = {};

function mapStateToProps(state, ownProps) {
    return {
        dataItem: ownProps.getItemState(state),
        dataItemParams: ownProps.getItemStateParams(state),
        holidayPeriodsListUnuseForPO: state.vacationState.HolidayAppItemList
    }
}

const ConnectedSandbox = connect(mapStateToProps)(SandboxInternal);