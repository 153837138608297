import React from "react";
import ClassExTab from "./ClassExTab"

export default function getClassTabDescription(tabsItemRow, callbackStore, controllerName, gridContentContainerStyleProvider, onUpdated, isReadOnly)
{
    let id = tabsItemRow["WSTabId"];
    let caption = tabsItemRow["WSTabName"];

    let renderTabFunction = () => {
        return (
            <ClassExTab classDescriptionRow={tabsItemRow}
                        callbackStore={callbackStore}
                        controllerName={controllerName}
                        gridContentContainerStyleProvider={gridContentContainerStyleProvider}
                        onUpdated={onUpdated}
                        isReadOnly={!!isReadOnly}
            />
        )
    }

    let refreshTabFunction = (refreshParams) => {
        callbackStore.refreshTab(id, refreshParams);
    }

    return {
        id: id,
        name: caption,
        renderTab: renderTabFunction,
        refresh: refreshTabFunction
    }
}