const editorType = {
  None: 0,
  Enum: 1,
  ComboBox: 2,
  ComboBoxEdit: 3,
  Text: 4,
  MultiLineText: 5,
  Link: 6,
  EditForm: 7,
  EditSubGrid: 8,
  DatePicker: 9,
  EditLine: 10,
  MultiLineTextExt: 11
};

export default editorType;
