import React from "react";
import PropTypes from "prop-types";
import MnComboBase from "./Dropdown/MnComboBase";
import i18next from "i18next";
import {connect} from "react-redux";
import UrProvider from "@mnjsplatform/data/lib/api/UrProvider";
import ItemInjector from "./ItemInjector";

class IconPickerInternal extends React.Component {
  renderFormControl = (options) => {
    return (
      <div onClick={options.showDropDown}>
        {!this.props.value
          ? <button className="btn mn-btn-link">{i18next.t("SelectFromList")} <i className="zmdi zmdi-chevron-down" /></button>
          : <i className={`iconPicker-dropDownItem active ${this.props.value}`} />
        }
      </div>
    );
  };

  renderDropDown = (currentIndex, selectHandle) => {
    if (!this.props.ownItem.isInitialized) {
      return null;
    }
    return this.props.ownItem.dataTable().map((item, i) => {
      const icon = item["Icon"];
      return (
        <i key={icon}
           className={`iconPicker-dropDownItem${this.props.value === icon ? " active" : ""} ${icon}`}
           onClick={async () => {
             selectHandle(i);
             await this.onIconSelected(icon);
           }}
        />
      );
    });
  };

  onIconSelected = async(value) => {
    if (value === this.props.value) {
      return;
    }
    await this.props.dispatch(this.props.parentDataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, value));
    this.props.onValueChanged(value);
  };

  onBeforeOpen = async() => {
    if (!this.props.ownItem.isInitialized) {
      const urProcessed = this.props.fieldInfo.UpdateRules;
      const urProvider = new UrProvider(urProcessed);
      const execParams = urProvider.getParamsForExecRules(this.props.parentDataItem, this.props.rowIndex);

      await this.props.dispatch(this.props.ownItem.execute(execParams, {withParams: false}));
    }
  };

  render() {
    return (
      <div className="iconPicker-container">
        <MnComboBase renderFormControl={this.renderFormControl}
                     renderRows={this.renderDropDown}
                     onBeforeOpen={this.onBeforeOpen}
        />
      </div>
    );
  }
}

IconPickerInternal.propTypes = {
  dispatch: PropTypes.func,
  parentDataItem: PropTypes.object.isRequired,
  ownItem: PropTypes.object,
  rowIndex: PropTypes.number.isRequired,
  value: PropTypes.string,
  onValueChanged: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    ownItem: ownProps.getItemState(state)
  };
};

const ConnectedIconPicker = connect(mapStateToProps)(IconPickerInternal);

const IconPicker = (props) => {
  return (
    <ItemInjector dataItem={{
      controller: props.parentDataItem.controllerName,
      methodName: props.fieldInfo.EditorObject.split(':')[1],
      fieldName: props.fieldInfo.Name
    }}>
      <ConnectedIconPicker {...props} />
    </ItemInjector>
  );
};

IconPicker.propTypes = {
  parentDataItem: PropTypes.object.isRequired,
  ownItem: PropTypes.object,
  rowIndex: PropTypes.number.isRequired,
  value: PropTypes.string,
  onValueChanged: PropTypes.func
};

IconPicker.defaultProps = {
  onValueChanged: () => {}
};

export default IconPicker;