import * as ea from "./errorActions";
import comboApi from "../api/comboApi";

export function comboSelected(parentItem, comboItem, parentIndex, selectedIndex, fieldName) {
    return d => {

        const parentMethodName = parentItem.methodName;

        const comboControllerName = comboItem.controllerName;
        const comboMethodName = comboItem.methodName;
        const isParamset = parentItem.isParamItem;


        return comboApi.updateParentItem(comboControllerName, comboMethodName, parentMethodName, parentIndex, selectedIndex, fieldName, isParamset)
            .catch(error => {
                d(ea.errorRaised(error));
                throw error;
            });
    }
}

export function getSelectedIndex(parentItem, comboItem, parentIndex, fieldName) {
    return d => {

        const parentMethodName = parentItem.methodName;
        const isParamset = parentItem.isParamItem;

        const comboControllerName = comboItem.controllerName;
        const comboMethodName = comboItem.methodName;


        return comboApi.getSelectedIndex(comboControllerName, comboMethodName, parentMethodName, parentIndex, fieldName, isParamset)
            .catch(error => {
                d(ea.errorRaised(error));
                throw error;
            });
    }
}

export function initComboBox(parentItem, parentIndex, fieldName) {
  return d => {
    const controllerName = parentItem.controllerName;
    const parentMethodName = parentItem.methodName;
    const isParamset = parentItem.isParamItem;

    return comboApi.initialize(controllerName, parentMethodName, parentIndex, fieldName, isParamset)
      .catch(error => {
        d(ea.errorRaised(error));
        throw error;
      });
  };
}

export function unmountComboBox(parentItem, parentIndex, fieldName) {
  return d => {
    const controllerName = parentItem.controllerName;
    const parentMethodName = parentItem.methodName;
    const isParamset = parentItem.isParamItem;

    return comboApi.unmount(controllerName, parentMethodName, parentIndex, fieldName, isParamset)
      .catch(error => {
        d(ea.errorRaised(error));
        throw error;
      });
  };
}

export function reInitSelectSetFields(parentItem, parentIndex, fieldName) {
  const controllerName = parentItem.controllerName;
  const parentMethodName = parentItem.methodName;
  const isParamset = parentItem.isParamItem;

  return comboApi.reInitSelectSetFields(controllerName, parentMethodName, parentIndex, fieldName, isParamset);
}

export function getComboText(parentItem, parentIndex, fieldName) {
  return d => {
    const controllerName = parentItem.controllerName;
    const parentMethodName = parentItem.methodName;
    const isParamset = parentItem.isParamItem;

    return comboApi.getComboText(controllerName, parentMethodName, parentIndex, fieldName, isParamset)
      .catch(error => {
        d(ea.errorRaised(error));
        throw error;
      });
  };
}