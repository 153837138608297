/**
 * Created by marks on 10.01.2017.
 */
import LoginApi from '@mnjsplatform/data/lib/api/loginApi'
import * as ea from '@mnjsplatform/data/lib/actions/errorActions';

export const LOGIN_PROCESS = 'LOGIN_PROCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const USER_LOGOUT = 'USER_LOGOUT';

export function loginInit() {
    return { type: USER_LOGOUT };
}

function loginStart() {
    return { type: LOGIN_PROCESS };
}

function loginSuccess() {
    return { type: LOGIN_SUCCESS };
}

function loginFailed(errorData) {
    return { type: LOGIN_ERROR, errorData };
}

function navigateToPage(loc, history) {
    if (history.action === 'PUSH') {
        history.goBack();
    } else {
        history.replace('/');
    }
}

export function loginProcess(login, password, loc, history) {

    return dispatch => {
        dispatch(loginStart());
        return LoginApi.processLogin(login, password)
            .then(item => {
                dispatch(loginSuccess());
                navigateToPage(loc, history);
            })
            .catch(error => {
                dispatch(loginFailed(error));
            });
    };
}

export function logout(history) {
    return dispatch => {

        return LoginApi.processLogout()
            .then(item => {
                dispatch({ type: USER_LOGOUT });
                history.push("/login");
            })
            .catch(error => {
                console.log(error);
                dispatch(ea.errorRaised(error));
            });
    };
}

export function sessionRefresh(history) {
    return dispatch => {
        dispatch({ type: "SESSION_OVER" });
        history.replace("/Reloader");
    };
}
