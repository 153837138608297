import React, {Component} from "react";
import PropTypes from 'prop-types';
import {clearSelectionEx, initClassManagerAdvanced, saveValueToItemEx} from "@mnjsplatform/data/lib/actions/classActions";
import ClassSelectorBase from "./ClassSelectorBase";

class ClassSelectorAdvanced extends Component {
    render() {
        return (
          <ClassSelectorBase {...this.props}
                             saveValueToItem={saveValueToItemEx}
                             clearSelection={clearSelectionEx}
                             initClassManager={initClassManagerAdvanced}
          />
        );
    }
}

ClassSelectorAdvanced.propTypes = {
    parentDataItem: PropTypes.object,
    rowIndex: PropTypes.number,
    isParamset  : PropTypes.bool,
    fieldInfo: PropTypes.object,
    value: PropTypes.string,
    onSelected: PropTypes.func,
    classKey: PropTypes.string,
    readOnly: PropTypes.bool,
    allowInlineSearch: PropTypes.bool
};

ClassSelectorAdvanced.defaultProps = {
    classKey: "",
    allowInlineSearch: false
};

export default ClassSelectorAdvanced;
