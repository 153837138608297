import React, { Component } from "react";
import PropTypes from "prop-types";
import GridView from "../GridView/GridView";
import i18next from "i18next";
import PopupWrapper from "../PopupWrapper";

class ClassPanelList extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      inSearching: false
    };
  }

  renderCellDelete = (row, fieldInfo, fieldIndex, renderCellDefault) => {

    if (fieldIndex === 0)
      return (
        <div>
          <i className="zmdi zmdi-close mr1em" onClick={() => this.props.deleteItem(row)} style={{ cursor: "pointer" }}/>
          <i className="zmdi zmdi-file mr1ex"/>
          {renderCellDefault(row, fieldInfo, fieldIndex)}
        </div>
      );

    return renderCellDefault(row, fieldInfo, fieldIndex);
  };

  getClassTitle = ()=> {
    return this.props.fieldInfo && this.props.fieldInfo.Title
      ? this.props.fieldInfo.Title
      : i18next.t("Classifier")
  }

  renderPanelHeader = () => {
    return (
      <div className="modal-header">
        <div className="col-md-12 row" style={{ paddingLeft: "0px" }}>
          {this.props.fieldInfo && this.props.fieldInfo.Title
            ? <div className="col-md-5"><h4 className="modal-title">{this.props.fieldInfo.Title}</h4></div>
            : <div className="col-md-4"><h4 className="modal-title">{i18next.t("Classifier")}</h4></div>
          }
        </div>
        <button type="button" className="close" data-dismiss="modal" onClick={() => this.props.onConfirm(true)}>
          <i className="zmdi zmdi-close"/>
        </button>
      </div>
    );
  };

  renderPanelFooter = () => {
    return (
      <div className="modal-footer">
        {!this.props.classDescription.SingleSelect &&
        <div className="footbox -limited">
          <div>
            <p className="text-grey pb1ex">{i18next.t("Selected")}:</p>
          </div>
          <div>
            <button className="btn btn-link -pure -noborder antipod nom noplr" onClick={this.props.deleteAllItems}>
              {i18next.t("ResetSelected")}
              <i className="zmdi zmdi-close antipod"/>
            </button>
          </div>
        </div>
        }
        <div className="footbox -limited">
          {
            !this.props.classDescription.SingleSelect &&
            <div style={{ maxHeight: "200px", overflow: "auto", paddingLeft: "0px" }} className="col-md-12">
              <GridView dataItem={this.props.selectItem} renderCell={this.renderCellDelete}/>
            </div>
          }
        </div>
        <div className="footbox -limited" style={{ marginTop: "10px" }}>
          <div>&nbsp;</div>
          {!this.props.classDescription.SingleSelect &&
          <div>
            <button type="button" className="btn btn-regular -wide" onClick={this.props.onConfirm}>
              {i18next.t("Next")}
            </button>
          </div>
          }
          {this.props.classDescription.SingleSelect &&
          <div>
            <button type="button" className="btn btn-regular -wide" onClick={() => this.props.onConfirm(true)}>
              {i18next.t("Close")}
            </button>
          </div>
          }
        </div>
      </div>
    );
  };

  render() {
    if (!this.props.listItem) {
      return null;
    }

    return (
      <PopupWrapper onClose={() => this.props.onConfirm(true)} popupVisible={true} title={this.getClassTitle()}  renderButtons={this.renderPanelFooter} >
        <GridView dataItem={this.props.listItem} readOnly={true} onRowClick={this.props.selectItemFunc}
                  rowCursor={"pointer"}/>
      </PopupWrapper>
    );
  }
}

ClassPanelList.propTypes = {
  fieldInfo: PropTypes.object.isRequired,
  classKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  selectItemFunc: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  deleteAllItems: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  checkAutoApplyParams: PropTypes.func.isRequired,
  classDescription: PropTypes.object
};

ClassPanelList.defaultProps = {};

export default ClassPanelList;