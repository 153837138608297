import PropTypes from 'prop-types';
import React from 'react';
import GridViewHeaderCell from './GridViewHeaderCell';

class GridViewHeader extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            scrollTop: null
        };
    }

    componentDidMount() {
        if (this.isIE && this.props.contentContainerRef) {
            this.setEventListeners();
        }
    }

    componentWillUnmount() {
        if (this.isIE && this.props.contentContainerRef && this.props.contentContainerRef.current) {
            this.props.contentContainerRef.current.removeEventListener('scroll', this.handleScroll);
        }
    }

    get isIE() {
        return !!document.documentMode;
    }

    setEventListeners = () => {
        if (!this.props.contentContainerRef.current) {
            return setTimeout(this.setEventListeners, 500);
        }

        this.handleScroll();
        this.props.contentContainerRef.current.addEventListener('scroll', this.handleScroll);
    };

    handleScroll = () => {
        this.setState({scrollTop: this.props.contentContainerRef.current.scrollTop});
    };

    renderHeaderCell(fieldInfo, fieldIndex) {

        return <GridViewHeaderCell key={fieldIndex}
                                   fieldIndex={fieldIndex}
                                   fieldInfo={fieldInfo}
                                   onSort={this.props.onSort}
                                   sortInfo={this.props.sortInfo}
                                   overrideTitle={this.props.overrideTitle[fieldInfo.Name] || this.props.dataItem.getTitle(fieldInfo, 0).replace(/\|/g, ' ')}
                                   updateStateFlag={this.props.updateStateFlag}
                                   renderHeaderCell={this.props.renderHeaderCell}
                                   headerCellStyleProvider={this.props.headerCellStyleProvider}
        />;
    }

    render1LineHeader = (showEdit) => {
        return (
            <>
                <HeaderRowWrapper>{showEdit && <EditColumn/>}{this.props.resultFieldInfo.map(this.renderHeaderCell, this)}</HeaderRowWrapper>
            </>
        );
    };

    render2LinesHeader = (showEdit) => {
        let uniteFields = this.props.resultFieldInfo
            .filter(fi => fi.Action && fi.Action.Unite)
            .map(fi => {
                return {
                    name: fi.Name,
                    unite: fi.Action.Unite.split(',')
                };
            });

        let secondLineFieldNames = uniteFields
            .map(fi => fi.unite.join(','))
            .join(',')
            .split(',');

        let secondLineFieldInfo = this.props.resultFieldInfo.filter(fi => secondLineFieldNames.some(n => n === fi.Name));
        let firstLineFieldInfo = this.props.resultFieldInfo.filter(fi => !secondLineFieldNames.some(n => n === fi.Name))
            .map(fiSource => {
                let fi = {...fiSource};
                let uf = uniteFields.filter(uf => uf.name === fi.Name);
                if(uf.length > 0) {
                    uf = uf[0];
                    fi.colSpan = uf.unite.length;
                    fi['IsSortable'] = false;
                }
                else {
                    fi.rowSpan = 2;
                }

                return fi;
            });

        return (
            <>
                <HeaderRowWrapper>{showEdit && <EditColumn rowSpan="2" />}{firstLineFieldInfo.map(this.renderHeaderCell, this)}</HeaderRowWrapper>
                <HeaderRowWrapper>{secondLineFieldInfo.map(this.renderHeaderCell, this)}</HeaderRowWrapper>
            </>
        );
    };

    render() {

        const canEdit = this.props.dataItem.getCanEdit();
        const canDelete = this.props.dataItem.getCanDelete();
        const showEdit = !this.props.readOnly && (canEdit || canDelete);

        let renderHeaderFunc = this.render1LineHeader;
        if (this.props.resultFieldInfo.some(fi => fi.Action && fi.Action.Unite)) {
            renderHeaderFunc = this.render2LinesHeader;
        }

        let headerClass = null;
        let headerStyle = this.props.headerStyleProvider();
        if (this.isIE && !!this.props.contentContainerRef && !isNaN(this.state.scrollTop)) {
            headerClass = "thead-header-sticky-ie11";
            headerStyle.transform = `translate(0, ${this.state.scrollTop}px)`;
        }

        return (
            <thead onClick={this.props.onHeaderClick} className={headerClass} style={headerStyle}>
                {renderHeaderFunc(showEdit)}
            </thead>
        );

    }
}

function HeaderRowWrapper(props) {
    return <tr>{props.children}</tr>;
}

function EditColumn(props) {
    return <th rowSpan={props.rowSpan || 1}><span style={{float:"right"}}>#</span></th>;
}

GridViewHeader.defaultProps = {
    readOnly: false,
    overrideTitle : {},
    onHeaderClick: e => {}
};

GridViewHeader.propTypes = {
    resultFieldInfo: PropTypes.array,
    onSort: PropTypes.func,
    sortInfo: PropTypes.object,
    dataItem: PropTypes.object,
    readOnly : PropTypes.bool,
    overrideTitle: PropTypes.object,
    updateStateFlag: PropTypes.any,
    contentContainerRef: PropTypes.object,
    headerStyleProvider: PropTypes.func,
    onHeaderClick: PropTypes.func,
    renderHeaderCell: PropTypes.func,
    headerCellStyleProvider: PropTypes.func
};

export default GridViewHeader;
