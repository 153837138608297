export function findItemByItem(obj, item)
{
    if (!obj)
        return null;

    if (obj.methodName && obj.controllerName)
    {
       if (obj.methodName === item.methodName && obj.controllerName === item.controllerName && obj.isParamItem === item.isParamItem)
           return obj;

       return null;
    }

    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];

        if (typeof obj[key] === "object") {
            const o = findItemByItem(obj[keys[i]], item);
            if (o!=null) return o;
        }
    }

    return null;
}