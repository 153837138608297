let customObject = {};

export function setDefaultGridCustomization(customObjectDefault)
{
  customObject = customObjectDefault;
}

export function getDefaultGridCustomization()
{
  return customObject;
}

export function getCustomizationByMethodName(methodName, customObject) {
  let customProps = {};
  const pureMethodName = methodName.split('/')[0];

  const defaultCustomization = getDefaultGridCustomization();
  const co = customObject || defaultCustomization;

  if (co[methodName] !== undefined) {
    customProps = co[methodName];
  }
  else if (co[pureMethodName] !== undefined) {
    customProps = co[pureMethodName];
  }

  if (defaultCustomization.addGlobalCustomization) {
    customProps = defaultCustomization.addGlobalCustomization(customProps);
  }

  return customProps;
}