import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ComplexParam from "../../ComplexParam";
import GridView from "../../GridView/GridView";
import ItemInjector from "../../ItemInjector";
import {tabsCallbackStoreItems} from "./TabsCallbackStore";
import i18next from "i18next";

export default function classExTabWrapper(props) {
    let controllerName = props.controllerName || "DocumentView";

    return <ItemInjector dataItem={{
        controller: controllerName,
        methodName: props.classDescriptionRow.MethodName,
        fieldName: controllerName + "_" + props.classDescriptionRow.WSTabId,
        withParamset: false,
        isPartial: true
    }}><ConnectedClassExTab {...props}/></ItemInjector>
}

class ClassExTab extends Component {
    constructor(props, context) {
        super(props, context);

        this.callbacks = {
            [tabsCallbackStoreItems.init]: this.onInit,
            [tabsCallbackStoreItems.refresh]: this.onRefresh,
            [tabsCallbackStoreItems.tabId]: props.classDescriptionRow.WSTabId
        }
    }

    componentDidMount() {
        console.log("tab mount: " + this.props.classDescriptionRow.WSTabId);

        if (this.props.callbackStore) {
            this.props.callbackStore.add(this.callbacks);
        }
    }

    componentWillUnmount() {
        console.log("tab unmount: " + this.props.classDescriptionRow.WSTabId);

        if (this.props.callbackStore) {
            this.props.callbackStore.remove(this.callbacks);
        }
    }

    onRefresh = (refreshParams) => {
        return this.props.dispatch(this.props.dataItem.execute(refreshParams, {withParams: false}));
    };

    onInit = (initParams) => {
        //let params = getTabParameters(this.props.tabDescriptor.TabParameters);

        return this.props.dispatch(this.props.dataItem.reset())
            .then(() => this.props.dispatch(this.props.dataItem.initialize(initParams, {withParams: false})));
    };

    renderLandscapeTab = () => {
        let isEditable = this.props.classDescriptionRow["IsEditable"] === 1 && !this.props.isReadOnly;
        return (
            <GridView dataItem={this.props.dataItem}
                      contentContainerStyleProvider={this.props.gridContentContainerStyleProvider}
                      readOnly={!isEditable}
            />
        )
    }

    saveGroupSettings = async(e) => {
        e.preventDefault();
        await this.props.dispatch(this.props.dataItem.save());
        if (!this.props.dataItem.isError) {
            await this.props.onUpdated();
        }
    };

    renderPortraitField = (h) => {
        return (
            <>
                {h.fs.map(f => {
                    const control = h.ed(f.Name, {style: {"marginRight": "auto"}});
                    return (
                        <div key={f.Name} className="row mb-1">
                            <div className="col-md-4 field-title d-flex flex-column justify-content-center">
                                {h.lbl(f.Name)}
                            </div>
                            <div className="col-md-8 d-flex flex-column justify-content-center">
                                {control}
                            </div>
                        </div>
                    );
                }) }
                {this.props.classDescriptionRow["IsEditable"] === 1 && !this.props.isReadOnly &&
                    <button type="button"
                            className="btn btn-regular -goahead float-right mr-0 mt-2"
                            onClick={this.saveGroupSettings}
                    >
                        {i18next.t("Save")}
                    </button>
                }
            </>

        );
    }

    renderPortraitTab = () => {
        let isEditable = this.props.classDescriptionRow["IsEditable"] === 1 && !this.props.isReadOnly;

        return (
            <div className={"h-100 overflow-auto"} style={{paddingRight: "16px"}}>
                <ComplexParam dataItem={this.props.dataItem}
                             showSearchBar={false}
                             showApplyButton={true}
                             readOnly={!isEditable}
                             renderParams={this.renderPortraitField}

                />
            </div>
        )
    }

    render() {
        if(!this.props.dataItem.isInitialized || this.props.inOperation)
            return null;

        let isPortrait = this.props.classDescriptionRow["IsVertical"] === 1;

        if(isPortrait)
            return this.renderPortraitTab();
        else
            return this.renderLandscapeTab();
    }
}

ClassExTab.propTypes = {
    dispatch: PropTypes.func.isRequired,
    dataItem: PropTypes.object,
    classDescriptionRow: PropTypes.object,
    callbackStore: PropTypes.object,
    gridContentContainerStyleProvider: PropTypes.func.isRequired,
    controllerName: PropTypes.string,
    pageHeaderHeight: PropTypes.number,
    onUpdated: PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool
};


ClassExTab.defaultProps = {};

function mapStateToProps(state, ownProps) {
    return {
        dataItem: ownProps.getItemState(state),
        pageHeaderHeight: state.homeState.appParams.pageHeaderHeight
    };
}

const ConnectedClassExTab = connect(mapStateToProps)(ClassExTab);



