import DataItem from "../api/DataItem";
import { dataItemOp } from "./dataItemReducer";
import { CREATE_DYN_DATA_ITEM, REMOVE_DYN_DATA_ITEM } from "../actions/dynamicItemActions";

export default function dynReducer(state = {_counter: {}}, action) {
  if (action.type === CREATE_DYN_DATA_ITEM) {
    const newCounter = {...state._counter};
    const dataItemPath = getDynamicItemPath(action);
    const dataItemParamsPath = !!action.withParamset && getDynamicItemPath(action, true);

    newCounter[dataItemPath] = (newCounter[dataItemPath] || 0) + 1;
    if (dataItemParamsPath) {
      newCounter[dataItemParamsPath] = (newCounter[dataItemParamsPath] || 0) + 1;
    }
    const newState = Object.assign({}, state, {_counter: newCounter});
    const methodName = action.methodName + "/" + action.fieldName;
    if (newCounter[dataItemPath] === 1) {
      newState[dataItemPath] = new DataItem(action.controller, methodName, false, action.isPartial);
    }

    if (dataItemParamsPath && newCounter[dataItemParamsPath] === 1) {
      newState[dataItemParamsPath] = new DataItem(action.controller, methodName, true, false);
    }

    return newState;
  }

  if (action.type === REMOVE_DYN_DATA_ITEM) {
    const newCounter = {...state._counter};
    newCounter[action.itemPath]--;
    const newState = Object.assign({}, state, {_counter: newCounter});
    if (!newCounter[action.itemPath]) {
      delete newCounter[action.itemPath];
      delete newState[action.itemPath];
    }
    return newState;
  }

  if (!!state[action.name]) {
    const newState = Object.assign({}, state, {});
    newState[action.name] = dataItemOp(state[action.name], action);
    return newState;
  }

  return state;
}

export function getDynamicItemPath(itemRequest, isParamSet = false) {
  let path = `${itemRequest.controller}/${itemRequest.methodName}/${itemRequest.fieldName}`;
  if (isParamSet) {
    path = getParamSetPath(path);
  }
  return path;
}

export function getParamSetPath(dataItemPath) {
  return dataItemPath + "_params";
}