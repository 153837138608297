import { combineReducers } from "redux";
import { createItemReducer } from "./dataItemReducer";

export default function fiasItems() {
    return combineReducers({
        Region: createItemReducer("MasterData", "FIAS_AddressObjects.GetChildAO/Region", false),
        Area: createItemReducer("MasterData", "FIAS_AddressObjects.GetChildAO/Area", false),
        Town: createItemReducer("MasterData", "FIAS_AddressObjects.GetChildAO/Town", false),
        Settlement: createItemReducer("MasterData", "FIAS_AddressObjects.GetChildAO/Settlement", false),
        Street: createItemReducer("MasterData", "FIAS_AddressObjects.GetChildAO/Street", false),
        ZipCode: createItemReducer("MasterData", "FIAS_Houses.GetPostalcode", false)
    });
}