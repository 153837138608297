import {ITEM_LOAD_DATA_SUCCESS} from "./dataItemTypes";
import {getDynamicItemPath, getParamSetPath} from "../reducers/dynReducer";

export const CREATE_DYN_DATA_ITEM = "CREATE_DYN_DATA_ITEM";
export const REMOVE_DYN_DATA_ITEM = "REMOVE_DYN_DATA_ITEM";

export function createItem(itemCreateRequest) {
  return d => {
    d(Object.assign({
      type: CREATE_DYN_DATA_ITEM
    }, itemCreateRequest));

    return Promise.resolve(getDynamicItemPath(itemCreateRequest));
  };
}

export function createItemWithoutPromise(itemCreateRequest) {
  return d => {
    d(Object.assign({
      type: CREATE_DYN_DATA_ITEM
    }, itemCreateRequest));

    return getDynamicItemPath(itemCreateRequest);
  };
}

export function removeItem(itemPath) {
  return { type: REMOVE_DYN_DATA_ITEM, itemPath };
}

export function injectItem(dispatch, dataItem, itemUpdateRequest, dataItemParams) {
  return dispatch(createItem(itemUpdateRequest))
    .then(path => {
      dispatch({ type: ITEM_LOAD_DATA_SUCCESS, rawData: dataItem, name: path });
      if (!!itemUpdateRequest.withParamset && dataItemParams) {
        dispatch({ type: ITEM_LOAD_DATA_SUCCESS, rawData: dataItemParams, name: getParamSetPath(path) });
      }
      return path;
    });
}