import React, {Component} from "react";
import PropTypes from "prop-types";
import i18next from "i18next";

class PlaceholderWrapper extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            collapsed: !!props.collapsed
        };
    }

    changeCollapsed = () => {
        if (this.state.collapsed && this.props.onExpand) {
            this.props.onExpand();
        } else if (!this.state.collapsed && this.props.onCollapse) {
            this.props.onCollapse();
        }

        this.setState(state => ({collapsed: !state.collapsed}));
    };

    render() {
        const collapsed = this.state.collapsed;
        const contentContainerClassName = "placeholder-wrapper-content" + (collapsed ? " d-none" : "");
        const titleClass = `placeholder-wrapper-title ${collapsed ? "" : "show"}`;

        let title = this.props.title;
        if (this.props.showHeaderTitle && this.props.getTitle) {
            title = this.props.getTitle({collapsed});
        }
        const className = collapsed ? this.props.classNameClose : this.props.classNameOpen;
        {/*<i className={`placeholderWrapper-collapseButton zmdi zmdi-chevron-${collapsed ? "down" : "up"}`} title={collapsed ? i18next.t('Expand') : i18next.t('Collapse')}/>*/
        }
        /*const icon = <i className={`${this.props.classNamePrefix}${collapsed ? "close" : "open"}`} title={collapsed ? i18next.t('Expand') : i18next.t('Collapse')}/>;*/
        const icon = <i className={className} title={collapsed ? i18next.t('Expand') : i18next.t('Collapse')}/>;
        const header = this.props.canCollapse
            ? (this.props.addHeaderTitle
                ? <div className="row">
                    <div className="col-md-4">
                        <button className="btn mn-btn-link w-100" onClick={this.changeCollapsed}
                                style={{color: "currentColor"}}>
                            <h2 className={titleClass} style={this.props.headerStyle}>
                                {this.props.iconPlacement === "before" && icon}{this.props.iconPlacement === "before" && ' '}
                                {this.props.showHeaderTitle && title}
                                {this.props.iconPlacement === "after" && ' '}{this.props.iconPlacement === "after" && icon}
                            </h2>
                        </button>
                    </div>
                        {this.props.addHeaderTitle}
                </div>
                :
                <button className="btn mn-btn-link w-100" onClick={this.changeCollapsed}
                        style={{color: "currentColor"}}>
                    <h2 className={titleClass} style={this.props.headerStyle}>
                        {this.props.iconPlacement === "before" && icon}{this.props.iconPlacement === "before" && ' '}
                        {this.props.showHeaderTitle && title}
                        {this.props.iconPlacement === "after" && ' '}{this.props.iconPlacement === "after" && icon}
                    </h2>
                </button>)
            : this.props.showHeaderTitle && title &&
            <div className="row">
                <div className="col-md-12">
                    <h2 className={titleClass} style={this.props.headerStyle}>
                        {title}
                    </h2>
                </div>
            </div>;


        return (
            <div className={`placeholder-wrapper ${this.props.className}`} id={this.props.placeholderId}
                 key={this.props.placeholderId} style={this.props.style}>
                {header}
                <div className={contentContainerClassName}>
                    {this.props.showSplitterLine && <hr className="mt-1"/>}
                    {!this.props.showSplitterLine &&
                        <div className="placeholder-wrapper-spacer" style={{fontSize: "5px"}}>&nbsp;</div>}
                    <div className="row">
                        <div className="col-md-12" style={{overFlowY: "auto"}}>{this.props.children}</div>
                    </div>
                </div>
            </div>
        );
    }
}

PlaceholderWrapper.propTypes = {
    placeholderId: PropTypes.string,
    title: PropTypes.any,
    getTitle: PropTypes.func,
    showHeaderTitle: PropTypes.bool,
    showSplitterLine: PropTypes.bool,
    collapsed: PropTypes.bool,
    canCollapse: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    headerStyle: PropTypes.object,
    onCollapse: PropTypes.func,
    onExpand: PropTypes.func,
    classNameOpen: PropTypes.string,
    classNameClose: PropTypes.string,
    iconPlacement: PropTypes.string,
    addHeaderTitle: PropTypes.object
};

PlaceholderWrapper.defaultProps = {
    showHeaderTitle : true,
    showSplitterLine : true,
    collapsed : false,
    canCollapse : true,
    className: "inwhite card p-3 mb-3",
    headerStyle: {},
    classNameOpen: "placeholderWrapper-collapseButton zmdi zmdi-chevron-up",
    classNameClose: "placeholderWrapper-collapseButton zmdi zmdi-chevron-down",
    iconPlacement: "after"
};

export default PlaceholderWrapper;