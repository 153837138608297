import classApi from "../api/classApi";
import * as ea from '../actions/errorActions';
import {ITEM_LOAD_DATA_SUCCESS} from "./dataItemTypes";
import {injectItem, removeItem} from "./dynamicItemActions";
import {getDynamicItemPath, getParamSetPath} from "../reducers/dynReducer";

export const QUERY_CLASS_INIT_OK = "QUERY_CLASS_INIT_OK";
export const QUERY_CLASS_UNMOUNT = "QUERY_CLASS_UNMOUNT";
export const QUERY_CLASS_INIT_BEGIN = "QUERY_CLASS_INIT_BEGIN";
export const QUERY_CLASS_INIT_FAILED = "QUERY_CLASS_INIT_FAILED";
export const CLASS_MANAGER_CONTROLLER = "ClassManager";

export const CLASS_TYPE_TREE = 0;
export const CLASS_TYPE_LIST = 1;
export const CLASS_TYPE_FULL = 2;

function initClassManagerInternal(initFunction, fieldInfo, classKey, controllerName, methodName, isParamset, rowIndex, classExMode) {
  return d => {
    d({type: QUERY_CLASS_INIT_BEGIN, classKey: classKey});

    return initFunction(CLASS_MANAGER_CONTROLLER, fieldInfo, classKey, controllerName, methodName, isParamset, rowIndex, classExMode)
      .then(async(data) => {
        data.classDescription.comboItemPath = await injectClassItem(data.classDescription.ComboMethodName, classKey, data, d);
        data.classDescription.treeItemPath = await injectClassItem(data.classDescription.TreeItemName, classKey, data, d, true);
        data.classDescription.treeItemParamsPath = getParamSetPath(data.classDescription.treeItemPath);
        data.classDescription.listItemPath = await injectClassItem(data.classDescription.ListItemName, classKey, data, d);
        data.classDescription.selectItemPath = await injectClassItem(data.classDescription.SelectSetViewMethodName, classKey, data, d);
        data.classDescription.listOnlyItemPath = await injectClassItem(data.classDescription.ListOnlyItemName, classKey, data, d);
        data.classDescription.dropDownSearchInTreeItemPath = await injectClassItem(data.classDescription.DropDownSearchInTreeItemName, classKey, data, d);
        data.classDescription.dropDownSearchInListItemPath = await injectClassItem(data.classDescription.DropDownSearchInListItemName, classKey, data, d);
        data.classDescription.classTabsItemPath = await injectClassItem(data.classDescription.ClassTabListMethodName, classKey, data, d);

        d({
          type: QUERY_CLASS_INIT_OK,
          data: {classDescription: data.classDescription},
          classKey: classKey
        });

        return data;
      })
      .catch(error => {
        d({type: QUERY_CLASS_INIT_FAILED, classKey: classKey});
        d(ea.errorRaised(error));
        throw error;
      });
  };
}

export function initClassManager(fieldInfo, classKey, controllerName, methodName, isParamset, rowIndex, classExMode) {
    return initClassManagerInternal(classApi.initClassHandle, fieldInfo, classKey, controllerName, methodName, isParamset, rowIndex, classExMode);
}

function removeItemInternal(d, itemName) {
    if (!itemName) return;
    d(removeItem(itemName));
}

export function unmountClassManager(classKey) {
    return (d, getState) => {
        const repItem = getState().classRepository[classKey];

        if (!repItem || !repItem.classDescription) {
            return;
        }
        const classDescription = repItem.classDescription;

        return classApi.unmountClassHandle(CLASS_MANAGER_CONTROLLER, classKey)
          .then(() => {
              d({type: QUERY_CLASS_UNMOUNT, classKey: classKey});
              removeItemInternal(d, classDescription.comboItemPath);
              removeItemInternal(d, classDescription.treeItemPath);
              removeItemInternal(d, classDescription.treeItemParamsPath);
              removeItemInternal(d, classDescription.listItemPath);
              removeItemInternal(d, classDescription.selectItemPath);
              removeItemInternal(d, classDescription.listOnlyItemPath);
              removeItemInternal(d, classDescription.dropDownSearchInTreeItemPath);
              removeItemInternal(d, classDescription.dropDownSearchInListItemPath);
          });
    };
}

export function reInitSelectSetFields(classKey) {
  return classApi.reInitSelectSetFields(CLASS_MANAGER_CONTROLLER, classKey);
}

export function initClassManagerAdvanced(fieldInfo, classKey, controllerName, methodName, isParamset, rowIndex) {
    return initClassManagerInternal(classApi.initClassHandleAdvanced, fieldInfo, classKey, controllerName, methodName, isParamset, rowIndex);
}

export function changeHierarchy(classKey, rowIndex) {
    return classApi.changeClassHierarchy(CLASS_MANAGER_CONTROLLER, classKey, rowIndex);
}

export function addItemToSet(classKey, rowIndex) {
    return classApi.addItemToSet(CLASS_MANAGER_CONTROLLER, classKey, rowIndex)
}

export function singleItemSelected(classKey, rowIndex, isDropDown = false) {
    return classApi.singleItemSelected(CLASS_MANAGER_CONTROLLER, classKey, rowIndex, isDropDown)
}

export function changeTree(classKey, rowIndex) {
    return classApi.changeTree(CLASS_MANAGER_CONTROLLER, classKey, rowIndex);
}

export function saveValueToItem(classKey) {
    return classApi.saveValueToItem(CLASS_MANAGER_CONTROLLER, classKey)
}

export function saveValueToItemEx(classKey) {
    return classApi.saveValueToItemEx(CLASS_MANAGER_CONTROLLER, classKey)
}

export function clearSelection(classKey) {
    return classApi.clearSelection(CLASS_MANAGER_CONTROLLER, classKey)
}

export function clearSelectionEx(classKey) {
    return classApi.clearSelectionEx(CLASS_MANAGER_CONTROLLER, classKey)
}

export function syncSelectedItems(classDescription, classKey, rowIndex) {
    return d => {

        return classApi.syncSelectedItems(CLASS_MANAGER_CONTROLLER, classKey, rowIndex)
            .then(data => {

                    if(data && data.items && classDescription) {

                        if(classDescription.SelectSetViewMethodName)
                            applyDataItemIfExists(classKey, classDescription.SelectSetViewMethodName, data, d);

                        if(classDescription.ClassSelectSource === CLASS_TYPE_FULL)
                            applyDataItemIfExists(classKey, classDescription.ListItemName, data, d);

                        if(classDescription.ClassSelectSource === CLASS_TYPE_LIST)
                            applyDataItemIfExists(classKey, classDescription.ListOnlyItemName, data, d);

                        if(classDescription.ClassSelectSource !== CLASS_TYPE_LIST)
                            applyDataItemIfExists(classKey, classDescription.TreeItemName, data, d);
                    }
                }
            )
            .catch(error => {
                d(ea.errorRaised(error));
                throw error;
            });
    };
}

function applyDataItemIfExists(classKey, methodName, data, d) {
    const itemUpdateRequest = getItemUpdateRequest(methodName, classKey, false, CLASS_MANAGER_CONTROLLER);
    const fullName = getDynamicItemPath(itemUpdateRequest);
    const itemSafeName = getSafeMethodName(methodName);
    const rawData = data.items[itemSafeName];

    if (rawData && fullName)
        d({type: ITEM_LOAD_DATA_SUCCESS, rawData, name: fullName});
}

export function deleteSelectedItem(classDescription, classKey, rowIndex) {
    return d => {
        return classApi.deleteSelectedItem(CLASS_MANAGER_CONTROLLER, classKey, rowIndex)
            .then(data => {

                    if(data && data.items && classDescription && classDescription.SelectSetViewMethodName) {
                        const itemUpdateRequest = getItemUpdateRequest(classDescription.SelectSetViewMethodName, classKey, false, CLASS_MANAGER_CONTROLLER);
                        const fullName = getDynamicItemPath(itemUpdateRequest);
                        const itemSafeName = getSafeMethodName(classDescription.SelectSetViewMethodName);
                        const rawData = data.items[itemSafeName];

                        if(rawData && fullName)
                            d({type: ITEM_LOAD_DATA_SUCCESS, rawData, name: fullName});
                    }
                }
            )
            .catch(error => {
                d(ea.errorRaised(error));
                throw error;
            });
    };
}

export function updateControlText(classKey) {
  return classApi.updateControlText(CLASS_MANAGER_CONTROLLER, classKey)
}

export async function injectClassItem(itemName, classKey, data, dispatch, withParamset, controller = CLASS_MANAGER_CONTROLLER) {
    if (!itemName)
        return;

    const itemUpdateRequest = getItemUpdateRequest(itemName, classKey, withParamset, controller);
    const dataItem = getRawData(data, itemUpdateRequest);
    if (!dataItem) {
        return null;
    }

    let dataItemParams = null;
    if (!!withParamset) {
        dataItemParams = getRawData(data, itemUpdateRequest, true);
    }

    const path = await injectItem(dispatch, dataItem, itemUpdateRequest, dataItemParams);
    return path;
}

function getSafeMethodName(methodName) {
    return methodName.split('.').join('_');
}

function getItemUpdateRequest(itemName, classKey, withParamset, controller) {
    return {
        controller: controller,
        methodName: itemName,
        fieldName: classKey,
        withParamset: withParamset
    };
}

function getRawData(data, itemUpdateRequest, forParamset) {
    const itemName = itemUpdateRequest.methodName + (forParamset ? "Params" : "") + "/" + itemUpdateRequest.fieldName;
    const itemSafeName = getSafeMethodName(itemName);
    return data.items[itemSafeName];
}
