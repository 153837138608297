import React, { Component } from "react";
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { connect } from "react-redux";

export function isPhoneEditBoilerPicture(boilerPicture) {
  if (null === boilerPicture)
    return false;

  return (boilerPicture === "(999)999-99-99" ||
    boilerPicture === "(999) 999-99-99" ||
    boilerPicture === "999-99-99");
}

export function getPhoneEditMaskText(boilerPicture, maskChar = "_") {
  return boilerPicture.replace(/9/g, maskChar);
}

export function isPhoneEditEmpty(value, boilerPicture) {
  return !value || value === getPhoneEditMaskText(boilerPicture);
}

export function isValidPhone(value, boilerPicture) {
  for (let i = 0; i < value.length; i++) {
    if (boilerPicture[i] !== "9" || (i > 0 && boilerPicture[i - 1] === "\\"))
      continue;

    if ('0123456789'.indexOf(value[i]) !== -1)
      continue;

    return false;
  }
  return true;
}

class MnInlinePhoneEdit extends Component {
  constructor(props, context) {
    super(props, context);

    this.isValueChanged = false;

    this.state = {
      errorMsg: "",
      initialValue: "",
      currentValue: ""
    };

    this.maskChar = "_";
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.isValid(this.props.value);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const rawVal = props.rawVal || "";
    if (rawVal !== state.initialValue) {
      const res = {currentValue: rawVal, initialValue: rawVal};
      if (rawVal === "") {
        res.errorMsg = "";
      }
      return res;
    }

    return null;
  }

  isAcceptableEditFormat = (boilerPicture) => {
    return isPhoneEditBoilerPicture(boilerPicture);
  };

  isValid = (value) => {
    let error = "";

    if (!isPhoneEditEmpty(value, this.props.boilerPicture)) {
      if (!isValidPhone(value, this.props.boilerPicture)) {
        error = "Необходимо заполнить шаблон номера полностью.";
      }
    }

    this.setState({errorMsg: error});

    return true;
  };

  onChange = (e) => {
      if(this.isValid(e.target.value)) {
        this.setState({currentValue: e.target.value});
        if (isValidPhone(e.target.value, this.props.boilerPicture) && this.props.onChange) {
          this.props.onChange(e);

          if (this.props.onEndEditField) {
            this.props.onEndEditField();
          }
          this.isValueChanged = false;
        }
        else {
          this.isValueChanged = true;
        }
      }
  };

  onBlur = async(e) => {
    if (this.isValueChanged) {
      let value = this.state.currentValue;
      if (isPhoneEditEmpty(value, this.props.boilerPicture)) {
        value = "";
      }
      await this.props.dispatch(this.props.dataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, value));

      if (this.props.onEndEditField) {
        this.props.onEndEditField();
      }
      this.isValueChanged = false;
    }
  };

  render() {
    if (this.isAcceptableEditFormat(this.props.boilerPicture)) {
      if (this.props.readOnly) {
        return (<span>{this.props.value}</span>);
      }

      let mask = this.props.boilerPicture;

      return (
        <div>
          <InputMask className="form-control"
                     maskChar = {this.maskChar}
                     value={this.state.currentValue}
                     onChange={this.onChange}
                     mask={mask}
                     alwaysShowMask={false}
                     onBlur={this.onBlur}
          />
            <span style={{color: "red", fontSize: '12px'}}>
              {this.state.errorMsg != null && this.state.errorMsg.length > 0 && this.state.errorMsg}
              &nbsp;
            </span>
        </div>
      );
    }
    else
      return (<span>{this.props.value}</span>);
  }
}

MnInlinePhoneEdit.propTypes = {
  dispatch: PropTypes.func,
  dataItem:PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  value: PropTypes.string,
  rawVal: PropTypes.string,
  readOnly: PropTypes.bool,
  boilerPicture: PropTypes.string,
  onChange: PropTypes.func,
  onEndEditField: PropTypes.func
};

MnInlinePhoneEdit.defaultProps = {
};

export default connect()(MnInlinePhoneEdit);