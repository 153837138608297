import moment from "moment";
import dateFormat from "dateformat";

const getDateTimeDefaultFormat = (value) => {
  let format = "dd.mm.yyyy";
  const timePart = value.substring(11, 23);
  if (timePart && !timePart.startsWith("00:00:00")) {
    format += " HH:MM:ss";
  }
  return format;
};
const isDateTimeType = type => type === "System.DateTime";
const isDateTimeOffsetType = (type) => type === "System.DateTimeOffset";
const isRealNumberType = type => type === "System.Decimal" || type === "System.Double";
const getDateTimeDefaultTimezonePostfix = timezoneOffset => ` (UTC${timezoneOffset})`;
const getDateTimeOffsetTimezoneStartPosition = value => Math.max(value.lastIndexOf('+'), value.lastIndexOf('-'));
const getDateTimeStringFromDateTimeOffset = (value, timeZonePosition) => value.substring(0, timeZonePosition);
const getTimezoneStringFromDateTimeOffset = (value, timeZonePosition) => value.substring(timeZonePosition, 29);
const splitDateTimeOffsetToDateTimeAndTimezone = value => {
  const timezonePosition = getDateTimeOffsetTimezoneStartPosition(value);
  return [
    getDateTimeStringFromDateTimeOffset(value, timezonePosition),
    getTimezoneStringFromDateTimeOffset(value, timezonePosition)
  ];
};
const timezonePlaceholderRegEx = /zzz/g;

export function formatMnDate(value) {
  if (!value)
    return "";

  return dateFormat(new Date(value), getDateTimeDefaultFormat(value));
}

export function getUsabilityStringDate(value, showTime = false){
  if (!value)
    return null;

  let date = new moment(value);
  let dayDiff = Math.abs(date.diff(new moment(), 'days'));
  let dateString = date.fromNow();
  if(dayDiff > 1){
    dateString += ", " + (showTime ? date.format("LLL") : date.format("LL"));
  }
  return dateString;
}

export function getStringDateFromNow(value, showDate = false, showTime = false){
  if (!value)
    return null;

  let date = new moment(value);
  let dayDiff = Math.abs(date.diff(new moment(), 'days'));
  let dateString = date.fromNow();
  if(dayDiff > 1){
    dateString += showDate && (' ' + date.format("LL")) || '';
    dateString += showTime && (' ' + date.format("HH:mm")) || '';
  }
  return dateString;
}

export default function formatData(fieldInfo, value) {
  if (value === null) {
    return "";
  }

  const dataType = fieldInfo["DataType"];
  const strFormat = fieldInfo["Format"];

  if (!strFormat) {
    if (isDateTimeOffsetType(dataType)) {
      const [dateValue, timezone] = splitDateTimeOffsetToDateTimeAndTimezone(value);
      const format = getDateTimeDefaultFormat(dateValue);
      return dateFormat(new Date(value), format) + getDateTimeDefaultTimezonePostfix(timezone);
    }

    if (isDateTimeType(dataType)) {
      const format = getDateTimeDefaultFormat(value);
      return dateFormat(new Date(value), format);
    }

    if (isRealNumberType(dataType)) {
      return value.toLocaleString(window.config.language, {
        maximumFractionDigits: 4,
        useGrouping: false
      });
    }

    return value.toString();
  }

  const formatType = strFormat[0];

  if ((formatType === 'P' || formatType === 'p')
    && (isDateTimeType(dataType) || isDateTimeOffsetType(dataType))
  ) {
    let dateValue = value;
    let format = strFormat.substring(3);
    if (isDateTimeType(dataType)) {
      format = format.replace('T', ' ');
    }
    if (isDateTimeOffsetType(dataType)) {
      format = format.replace('T', '"T"');
    }

    if (isDateTimeOffsetType(dataType)) {
      let timezone;
      [dateValue, timezone] = splitDateTimeOffsetToDateTimeAndTimezone(value);
      format = format.replace(timezonePlaceholderRegEx, timezone);
    }

    format = Array.from(format).map(c => {
      if (c === 'm') return 'M';
      if (c === 'M') return 'm';
      return c;
    }).join('');

    return dateFormat(new Date(dateValue), format);
  }

  if (formatType === "N" || formatType === "n"
    || formatType === "G" || formatType === "g"
  ) {
    const dotPos = strFormat.indexOf(".");
    const useGrouping = formatType === "N" || formatType === "G";
    let decimalDigits = 2;

    if (-1 !== dotPos) {
      decimalDigits = parseInt(strFormat.substring(dotPos + 1), 10);
    }

    return value.toLocaleString(window.config.language, {
      minimumFractionDigits: decimalDigits,
      maximumFractionDigits: decimalDigits,
      useGrouping: useGrouping
    });
  }

  if (formatType === "S" || formatType === "s") {
    const dotPos = strFormat.indexOf(".");
    let decimalDigits = 4;

    if (-1 !== dotPos) {
      decimalDigits = parseInt(strFormat.substring(dotPos + 1), 10);
    }

    let formattedValue = value.toLocaleString(window.config.language, {
      minimumFractionDigits: decimalDigits,
      maximumFractionDigits: decimalDigits,
      useGrouping: formatType === "S"
    });

    if (value * 1 > 0) {
      formattedValue = "+" + formattedValue;
    }

    return formattedValue;
  }

  return typeof value === 'string' ? value.trim() : value;
}