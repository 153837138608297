import homeApi from "../api/homeApi";
import LanguageChanger from "@mnjsplatform/data/lib/api/LanguageChanger";
import {isAuthFailed} from "@mnjsplatform/data/lib/utils/errorTools";

export const QUERY_APP_PARAMS_START = "QUERY_APP_PARAMS_START";
export const QUERY_APP_PARAMS_SUCCESS = "QUERY_APP_PARAMS_SUCCESS";
export const QUERY_APP_PARAMS_ERROR = "QUERY_APP_PARAMS_ERROR";

export function loadAppParameters(history) {
    return dispatch => {
        dispatch({type: QUERY_APP_PARAMS_START});
        return homeApi
            .loadInitialData()
            .then(data => {LanguageChanger.syncUiLanguage(data.languageName); return data;})
            .then(item => {
                dispatch({type: QUERY_APP_PARAMS_SUCCESS, data: item});
            })
            .catch(error => {
                    dispatch({type: QUERY_APP_PARAMS_ERROR, data: error});
            });
    };
}