import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import * as ea from '@mnjsplatform/data/lib/actions/errorActions';
import formatError from '@mnjsplatform/data/lib/utils/errorTools';
import i18next from "i18next";
import ModalWrapper from "./modalWrapper";

const styleShow = {display:"block"};

class MessageBox extends React.Component {

  constructor(props, context) {
    super(props, context);
  }

  onClose = () => {
    if(this.props.onClose)
      this.props.onClose();
  };

  render() {
      if (!this.props.visible)
          return null;

    return (

            <div className="modal fade show" id="myModal" style={styleShow}>
                <div className="modal-dialog">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h4 className="modal-title">{this.props.title}</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={this.onClose}>&times;</button>
                        </div>

                        <div className="modal-body">
                            <p>{this.props.messageText}</p>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-regular" data-dismiss="modal" onClick={this.onClose}>{this.props.buttonText ? this.props.buttonText : i18next.t("Close")}</button>
                        </div>
                    </div>
                </div>
            </div>

    );
  }
}

MessageBox.defaultProps = {
  buttonText: null
};

MessageBox.propTypes = {
  title: PropTypes.node.isRequired,
  messageText: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  onClose: PropTypes.func,
  visible: PropTypes.bool
};

export default MessageBox;
