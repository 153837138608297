import PropTypes from 'prop-types';
import React from 'react';
import {ScreenFormatContext} from "@mnjsplatform/data";
import i18next from "i18next";
import ModalWrapper from "./modalWrapper";

const style = {display: "block", zIndex: "1020"};

export default class PopupWrapper extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = { width: window.innerWidth, height: window.innerHeight };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    onCloseClick = () => {
      if (this.props.onClose)
        this.props.onClose();
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    renderButtons() {

        if (this.props.renderButtons)
            return this.props.renderButtons();

        return <div className="modal-footer text-right">
            <button type="button" className="btn btn-regular -default" data-dismiss="modal"
                    onClick={this.onCloseClick}>{this.props.confirmText ? this.props.confirmText : i18next.t('Close')}</button>
        </div>
    }

    render() {

        if (!this.props.popupVisible)
            return null;

        const popupStyle = {
            ...style,
            ...(this.props.style || {})
        }

        return (
          <ModalWrapper>
            <ScreenFormatContext.Consumer>
                {
                    isDesktop => {

                            const clientHeight = this.props.bodyMaxHeightCalculateFunc
                                ? this.props.bodyMaxHeightCalculateFunc({height: this.state.height, isDesktop})
                                : (this.state.height - (isDesktop ? 180 : 300)) + "px";
                            const paddingTop = isDesktop ? "0px": "50px";
                            const maxWidth = !isDesktop ? "100%" : this.props.maxWidthBody;
                            let bodyStyle = Object.assign({}, this.props.bodyStyle, {maxHeight: clientHeight, overflow:"auto"});

                            if(this.props.minHeightBody)
                                bodyStyle['minHeight'] = this.props.minHeightBody;

                            return (<div className="modal fade show" style={popupStyle} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                                <div className="modal-dialog" style={{paddingTop: paddingTop,  maxWidth: maxWidth}}>
                                    <div className="modal-content" role="document">
                                        <div className="modal-header" style={this.props.headerStyle}>
                                            <h2 className="modal-title">{this.props.title}</h2>
                                            <span onClick={this.onCloseClick}>{this.props.closeControl}</span>
                                        </div>
                                        <div className="modal-body" style={bodyStyle}>
                                            {this.props.children}
                                        </div>
                                        {this.renderButtons()}
                                    </div>
                                </div>
                            </div>)}
                        }
            </ScreenFormatContext.Consumer>
          </ModalWrapper>
        );
    }
}

PopupWrapper.propTypes = {
    popupVisible: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    confirmText: PropTypes.string,
    renderButtons: PropTypes.func,
    minHeightBody: PropTypes.string,
    maxWidthBody: PropTypes.string,
    bodyMaxHeightCalculateFunc: PropTypes.func,
    style: PropTypes.object,
    headerStyle: PropTypes.object,
    closeControl: PropTypes.object,
    bodyStyle: PropTypes.object
};

PopupWrapper.defaultProps = {
    confirmText: i18next.t("Close"),
    maxWidthBody: "1000px",
    headerStyle: {},
    bodyStyle: {},
    closeControl: <button type="button" className="close" data-dismiss="modal"><>&times;</></button>
}