import * as ea from "./errorActions";
import subgridApi from "../api/subgridApi";
import { ITEM_LOAD_DATA_SUCCESS } from "./dataItemTypes";
import {injectItem, removeItem} from "./dynamicItemActions";
import { getDynamicItemPath, getParamSetPath } from "../reducers/dynReducer";

export const QUERY_SUBGRID_INIT_OK = "QUERY_SUBGRID_INIT_OK";
export const QUERY_SUBGRID_UNMOUNT = "QUERY_SUBGRID_UNMOUNT";
export const QUERY_SUBGRID_INIT_BEGIN = "QUERY_SUBGRID_INIT_BEGIN";
export const QUERY_SUBGRID_INIT_FAILED = "QUERY_SUBGRID_INIT_FAILED";

export const SUBGRID_CONTROLLER = "Subgrid";

export function initSubgrid(parentItem, fieldName, rowIndex, path = "", readOnly, withParams = false) {
  return d => {

    const methodName = parentItem.methodName;
    const controllerName = parentItem.controllerName;
    const isParamset = parentItem.isParamItem;
    const rowKey = parentItem.getRowKeyByIndex(rowIndex);
    const rowKeyString = JSON.stringify(rowKey);

    const subgridKey = controllerName + "::" + methodName + "::" + path + "::" + rowKeyString + "::" + fieldName;

    d({ type: QUERY_SUBGRID_INIT_BEGIN, subgridKey: subgridKey });

    return subgridApi.initialize(controllerName, methodName, rowIndex, isParamset, fieldName, subgridKey, readOnly, withParams)
      .then(data => injectItem(d, data.subgridItem, getItemUpdateRequest(data.subgridItem.ItemId, subgridKey, withParams), data.subgridItemParams))
      .then(itemPath => {
        d({
          type: QUERY_SUBGRID_INIT_OK,
          data: { subgridDescription: { key: subgridKey, subgridItemPath: itemPath, subgridItemParamsPath: withParams ? getParamSetPath(itemPath) : null, fieldName, rowIndex, path } },
          subgridKey: subgridKey
        });
        return subgridKey;
      })
      .catch(error => {
        d({ type: QUERY_SUBGRID_INIT_FAILED, subgridKey: subgridKey });
        d(ea.errorRaised(error));
      });
  };
}

export function unmountSubgrid(parentItem, subgridKey) {
  return (d, getState) => {

    if (!subgridKey)
      return;

    const repItem = getState().subgridRepository[subgridKey];

    if (!repItem || !repItem.subgridDescription)
      return;

    const { rowIndex, fieldName } = repItem.subgridDescription;

    const methodName = parentItem.methodName;
    const controllerName = parentItem.controllerName;
    const isParamset = parentItem.isParamItem;

    return subgridApi.close(controllerName, methodName, rowIndex, isParamset, fieldName, subgridKey)
      .then(data => {
        return d(parentItem.setFields(rowIndex, data.changedFields));
      })
      .then(() => {
        d({ type: QUERY_SUBGRID_UNMOUNT, subgridKey: subgridKey });
        d(removeItem(repItem.subgridDescription.subgridItemPath));
        d(() => {if(repItem.subgridDescription.subgridItemParamsPath) removeItem(repItem.subgridDescription.subgridItemParamsPath)})
      })
      .catch(error => {
        d(ea.errorRaised(error));
      });
  };
}

export function reinitializeSubgrid(parentItem, subgridKey, parameters = {}) {
  return (d, getState) => {

    if (!subgridKey)
      return;

    const repItem = getState().subgridRepository[subgridKey];

    if (!repItem || !repItem.subgridDescription)
      return;

    const {rowIndex, fieldName} = repItem.subgridDescription;

    const methodName = parentItem.methodName;
    const controllerName = parentItem.controllerName;
    const isParamset = parentItem.isParamItem;

    return subgridApi.reinitializeSubgrid(controllerName, methodName, rowIndex, isParamset, fieldName, subgridKey, parameters)
      .then(data => {
        const updateRequest = getItemUpdateRequest(data.subgridItem.ItemId, subgridKey, false);
        const path = getDynamicItemPath(updateRequest);
        d({ type: ITEM_LOAD_DATA_SUCCESS, rawData: data.subgridItem, name: path });
      })
      .catch(error => {
        d(ea.errorRaised(error));
      });
  };
}

function getItemUpdateRequest(itemName, subgridKey, withParamset = false) {
  return {
    controller: SUBGRID_CONTROLLER,
    methodName: itemName,
    fieldName: subgridKey,
    withParamset: withParamset,
    isPartial: true
  };
}