import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {
  initComboBox,
  unmountComboBox,
  getComboText,
  reInitSelectSetFields
} from "@mnjsplatform/data/lib/actions/comboActions";
import MultiSelectWrapper from "../MultiSelect/MultiSelectWrapper";
import MnComboBase from "./MnComboBase";
import MultiSelectRows from "../MultiSelect/MultiSelectRows";
import CheckBox from "../InputField/CheckBox";
import ComplexParam from "../ComplexParam";

class MnComboInlineMultiSelectSearch extends React.Component {
  init = async() => {
    let data = null;
    if (!this.isInitialized) {
      data = await this.props.dispatch(initComboBox(this.props.parentDataItem, this.props.rowIndex, this.props.fieldInfo.Name));
      this.isInitialized = true;
      if (data.changedFields) {
        this.selectSetFields = Object.keys(data.changedFields);
      }
    }
    else if (this.selectSetFields && this.selectSetFields.some(f => this.props.parentDataItem.getField(this.props.rowIndex, f) === null)) {
      data = await reInitSelectSetFields(this.props.parentDataItem, this.props.rowIndex, this.props.fieldInfo.Name);
    }

    if (data && data.changedFields) {
      await this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, data.changedFields));
    }
  };

  unmount = async() => {
    await this.props.dispatch(unmountComboBox(this.props.parentDataItem, this.props.rowIndex, this.props.fieldInfo.Name));
  };

  updateLabel = async() => {
    let label;
    const res = await this.props.dispatch(getComboText(this.props.parentDataItem, this.props.rowIndex, this.props.fieldInfo.Name));
    if (res && res.label) {
      label = res.label;
    }
    await this.props.dispatch(this.props.parentDataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, label || null));
  };

  onEndEditField = () => {
    if (this.props.onEndEditField) {
      this.props.onEndEditField();
    }
  };

  onResetClick = async(multiSelectProps) => {
    await multiSelectProps.init();
    await multiSelectProps.fill(false);
    await this.updateLabel();
    this.onEndEditField();
  };

  renderSearchParams = (h) => {

    let renderFindVal = !!h.fi("FindVal");
    let renderLinked = !!h.fi("Linked");
    let renderCrossFiltrationOn = !!h.fi("CrossFiltrationOn");

    return (
        <React.Fragment>
          {renderFindVal &&
            <div className="row">
              <div className="col-md-3 field-title" style={{ textAlign: "left" }}>
                {h.lbl("FindVal")}
              </div>
              <div className="col-md-9">
                {h.ed("FindVal")}
              </div>
            </div>
          }
          {renderLinked &&
            <div className="row">
              <div className="col-md-12">
                {h.ed("Linked")}
                <span className="field-title">&nbsp;&nbsp;&nbsp;{h.lbl("Linked")}</span>
              </div>
            </div>
          }
          {renderCrossFiltrationOn &&
            <div className="row">
              <div className="col-md-12">
                {h.ed("CrossFiltrationOn")}
                <span className="field-title">&nbsp;&nbsp;&nbsp;{h.lbl("CrossFiltrationOn")}</span>
              </div>
            </div>
          }
        </React.Fragment>
    )
  }

  getComboRows = (multiSelectProps) => {
    if (!multiSelectProps.subgridItem) {
      return null;
    }

    let showParams = multiSelectProps.subgridItemParams && multiSelectProps.subgridItemParams.isInitialized
                     && multiSelectProps.subgridItemParams.columnExists("FindVal");

    return (
        <React.Fragment>
          {showParams && <ComplexParam dataItem={multiSelectProps.subgridItemParams} parentDataItem={multiSelectProps.subgridItem} renderParams={this.renderSearchParams} autoApplyParams applyOnEnterKey/>}
          <div style={{height: "1px", background:"black", marginTop: "5px", marginBottom: "5px"}}/>
          <MultiSelectRows parentDataItem={this.props.parentDataItem}
                         subgridDataItem={multiSelectProps.subgridItem}
                         rowIndex={this.props.rowIndex}
                         fieldInfo={this.props.fieldInfo}
                         readOnly={this.props.readOnly}
                         showSelectAll={true}
                         updateLabel={this.updateLabel}
                         fillSubgrid={multiSelectProps.fill}
          />
        </React.Fragment>
    );
  };

  render() {
    const isRequired = !this.props.allowReset || this.props.parentDataItem.getFieldIsRequired(this.props.fieldInfo, this.props.rowIndex);
    const value = this.props.value;

    return (
      <MultiSelectWrapper {...this.props}
                          initComponent={this.init}
                          unmountComponent={this.unmount}
                          withSubgridParams
      >
        {multiSelectProps => (
          <MnComboBase allowReset={!isRequired && !this.props.readOnly}
                       onAfterOpen={multiSelectProps.init}
                       onReset={() => this.onResetClick(multiSelectProps)}
                       renderRows={() => this.getComboRows(multiSelectProps)}
                       value={value}
                       onAfterClosed={this.onEndEditField}
          />
        )}
      </MultiSelectWrapper>
    );
  }
}

MnComboInlineMultiSelectSearch.defaultProps = {
  rowIndex: 0,
  allowReset: true,
  useSearch: true
};

MnComboInlineMultiSelectSearch.propTypes = {
  dispatch: PropTypes.func,
  parentDataItem: PropTypes.object.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  value: PropTypes.string,
  allowReset: PropTypes.bool,
  rootPath: PropTypes.string,
  readOnly: PropTypes.bool,
  onEndEditField: PropTypes.func,
  useSearch: PropTypes.bool
};

export default connect()(MnComboInlineMultiSelectSearch);