import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import MnDatePicker from "./DatePicker";

export class MnInlineDatePicker extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        if (this.props.readOnly) {
            return (<span>{this.props.formattedVal}</span>);
        }
        return (
          <MnDatePicker
            value={!this.props.rawVal ? "" : this.props.rawVal}
            readOnly={this.props.readOnly}
            onChange={this.onChangeDate}
          />
        );
    }

    onChangeDate = (e) => {
        this.props.dispatch(this.props.dataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, e))
          .then(() => {
              if (this.props.onSelected) {
                  this.props.onSelected(this.props.rowIndex);
              }
          });
    }
}

MnInlineDatePicker.propTypes = {
    dispatch: PropTypes.func,
    formattedVal: PropTypes.string,
    rawVal: PropTypes.string,
    readOnly: PropTypes.bool.isRequired,
    dataItem: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    onSelected: PropTypes.func.isRequired,
    fieldInfo: PropTypes.object.isRequired
};


MnInlineDatePicker.defaultProps = {
};


export default connect()(MnInlineDatePicker);