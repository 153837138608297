import PropTypes from 'prop-types';
import React from 'react';

export default class ProgressBar extends React.Component {
  componentDidMount() {
    console.log("ProgressBar mount.");
  }

  componentWillUnmount() {
    console.log("ProgressBar unmount.");
  }

  render = () => {
    if (this.props.status === this.props.minimum || (this.props.status >= this.props.maximum && this.props.hideOnFinish)) return null;
    return (
      <React.Fragment>
        <div>
          <div className="progress">
            <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"
                 role="progressbar" style={{ width: this.props.status + "%" }} aria-valuenow={this.props.status}
                 aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </div>
        {/*<div>
        <button type="button" onClick={event => {
          event.preventDefault();
          this.reader.abort();
        }}>{i18next.t('Cancel')}
        </button>
      </div>*/}
      </React.Fragment>);
  }
}

ProgressBar.propTypes = {
  status: PropTypes.number,
  minimum: PropTypes.number,
  maximum: PropTypes.number,
  hideOnFinish: PropTypes.bool
}

ProgressBar.defaultProps ={
  status: 0,
  minimum: 0,
  maximum: 100,
  hideOnFinish: true
}