import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {createItemWithoutPromise, removeItem} from "@mnjsplatform/data/lib/actions/dynamicItemActions";
import {getDynamicItemPath, getParamSetPath} from "@mnjsplatform/data/lib/reducers/dynReducer";

class ItemInjector extends React.Component{
  constructor(props) {
    super(props);

    this.state = this.getDataItemPath(props.dataItem);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.dataItem && this.props.dataItem) {
      this.setState(this.getDataItemPath(this.props.dataItem));
    }
  }

  componentWillUnmount() {
    if (this.state.dataItemPath) {
      this.props.dispatch(removeItem(this.state.dataItemPath));
    }
    if (this.state.dataItemParamsPath) {
      this.props.dispatch(removeItem(this.state.dataItemParamsPath));
    }
  }

  getDataItemPath = (dataItem) => {
    let dataItemPath = null;
    let dataItemParamsPath = null;
    if (dataItem) {
      dataItemPath = this.props.dispatch(createItemWithoutPromise(dataItem));
      if (!!dataItem.withParamset) {
        dataItemParamsPath = getParamSetPath(dataItemPath);
      }
    }
    return {
      dataItemPath,
      dataItemParamsPath
    };
  };

  getItemState = state => {
    if (!this.state.dataItemPath) {
      return null;
    }
    return state.dynState[this.state.dataItemPath];
  };

  getItemStateParams = state => {
    if (!this.state.dataItemParamsPath) {
      return null;
    }
    return state.dynState[this.state.dataItemParamsPath];
  };

  render() {
    if (!this.state.dataItemPath) {
      return null;
    }

    const suffix = this.props.propsNamesSuffix || "";
    const propsNames = {
      dataItem: `dataItem${suffix}`,
      dataItemPath: `dataItemPath${suffix}`,
      getItemState: `getItemState${suffix}`,
      getItemStateParams: `getItemStateParams${suffix}`
    };

    return (
      <div key={this.props.dataItem.methodName}>
        {React.cloneElement(this.props.children, {
          [propsNames.dataItem]: this.props.dataItem,
          [propsNames.dataItemPath]: getDynamicItemPath(this.props.dataItem),
          [propsNames.getItemState]: this.getItemState,
          [propsNames.getItemStateParams]: this.getItemStateParams
        })}
      </div>
    );
  }
}

ItemInjector.propTypes = {
  dispatch: PropTypes.func,
  dataItem: PropTypes.object.isRequired,
  propsNamesSuffix: PropTypes.string
};

export default connect()(ItemInjector);
