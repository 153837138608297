/**
 * Created by marks on 18.01.2017.
 */
class UrProvider {
    constructor(strEditorPrefix) {
        this.editorPrefix = strEditorPrefix;
        this.updateRules = [];
        this.execRules = [];
        this.init();
    }

    init() {

        if (!this.editorPrefix) {
            return;
        }

        const rules = this.editorPrefix.split(';');
        let bUpdateRules = true;

        for (let rule of rules) {

            let ruleType = " ";
            let strRule;

            if (rule.startsWith("!")) {
                ruleType = "!";
                strRule = rule.trim().replace("!", "");
            } else if (rule.startsWith(">")) {
                ruleType = ">";
                strRule = rule.trim().replace(">", "");
            } else {
                strRule = rule;
            }

            let ruleItems = strRule.split(',');
            if (ruleItems.length > 0) {
                for (let ruleItem of ruleItems) {
                    let parts = ruleItem.split('=');
                    let part_l, part_r;
                    part_l = parts[0];
                    if (parts.length > 1) {
                        part_r = parts[1];
                    } else {
                        if (ruleType === '!' || ruleType === '>') {
                            part_r = part_l;
                        } else {
                            if (!part_l.endsWith(part_l, "*")) {
                                part_l = part_l + "*";
                            }
                            part_r = "*";
                        }
                    }

                    if (ruleType === "!") {
                        this.updateRules.push(new RulesItem(part_l, part_r));
                    } else {
                        if (ruleType === ">") {
                            this.execRules.push(new RulesItem(part_l, part_r));
                        } else {
                            if (bUpdateRules) {
                                this.updateRules.push(new RulesItem(part_l, part_r));
                            } else {
                                this.execRules.push(new RulesItem(part_l, part_r));
                            }
                        }
                    }
                }
            }

            bUpdateRules = false;
        }
    }

    findUpdateSrc(strSrc) {
        for (let ri of this.updateRules) {
            if (strSrc === ri.strSrc) {
                return ri;
            }
        }

        return null;
    }

    findUpdateDst(strDst) {
        for (let ri of this.updateRules) {
            if (strDst === ri.strDst) {
                return ri;
            }

            if (ri.strDst.endsWith(ri.strDst, "*") && strDst.startsWith(ri.strDst.substr(0, ri.strDst.length - 1))) {
                return ri;
            }
        }

        return null;
    }

    findExecSrc(strSrc) {
        for (let ri of this.execRules) {
            if (strSrc === ri.strSrc) {
                return ri;
            }
        }

        return null;
    }

    findExecDst(strDst) {
        for (let ri of this.execRules) {
            if (strDst === ri.strDst) {
                return ri;
            }
        }

        return null;
    }

    getParamsForExecRules(parentDataItem, rowIndex) {

        let lstNames = [];
        let lstValues = [];

        let cols = parentDataItem.rawData.ResultFieldInfo.map(fi => fi.Name);

        for (let fi of parentDataItem.rawData.ResultFieldInfo) {
            let name = fi.Name;

            let lstDstNames = [];

            let bWillRenamed = false;
            let oVal = null;
            for (let ri of this.execRules) {
                if (ri.strSrc === name)
                    lstDstNames.push(ri.strDst);

                if (ri.strDst === name && (cols.includes(ri.strSrc) || ri.getConstant().success))
                    bWillRenamed = true;
            }

            oVal = parentDataItem.rawData.DataTable[rowIndex][name];
            if (0 === lstDstNames.length) {
                if (!lstNames.includes(name) && !bWillRenamed) {
                    lstNames.push(name);
                    lstValues.push(oVal);
                }
            }
            else {
                for (let strDstName of lstDstNames) {
                    lstNames.push(strDstName);
                    lstValues.push(oVal);
                }
            }
        }

        for (let ri of this.execRules) {
            let result = ri.getConstant();
            if (result.success) {
                lstNames.push(ri.strDst);
                lstValues.push(result.value);
            }
        }

        let execParams = {};
        for (let i in lstNames) {
            execParams[lstNames[i]] = lstValues[i];
        }
        return execParams;
    }


    processColKeys(updateRules, colKey ={}) {
      if (!colKey || 0 === Object.keys(colKey).length || -1 === updateRules.indexOf("%"))
        return updateRules;

      /*
      const vals = Object.values(obj);
      for (let i = 0; i < Object.keys(colKey).length; i++)
        updateRules = updateRules.replace("%" + (i + 1), JSON.stringify(vals[i]));
        */

      return updateRules;
    }
}

class RulesItem {
    constructor(strDst, strSrc) {
        this.strDst = strDst;
        this.strSrc = strSrc;
    }

    getConstant() {
        const iPos = this.strSrc.indexOf('$');
        if (0 > iPos)
        {
            return { success: false };
        }

        const strType = this.strSrc.substring(0, iPos);
        const strValue = this.strSrc.substring(iPos + 1);

        if (!strValue) {
            return { success: true, value: null };
        }

        switch (strType.toLowerCase()) {
            case "unknown":
                return { success: false };
            case "bool": {
                if (this.strValue.toLowerCase() === "true") {
                    return { success: true, value: true };
                }

                if (this.strValue.toLowerCase() === "false") {
                    return { success: true, value: false };
                }

                return { success: false };
            }
            case "int":
            case "long":
            case "decimal":
            case "double": {
                let result = Number(strValue);
                if (isNaN(result)) {
                    return { success: false };
                }

                return { success: true, value: result };
            }
            case "date":
            case "string": {
                return { success: true, value: strValue };
            }
            default:
                return { success: false };
        }
    }
}

/*Polyfill for IE*/
if (!String.prototype.endsWith) {
  String.prototype.endsWith = function(search, this_len) {
    if (this_len === undefined || this_len > this.length) {
      this_len = this.length;
    }
    return this.substring(this_len - search.length, this_len) === search;
  };
}

export default UrProvider;