import { combineReducers } from "redux";
import errorStatus from "./errorReducer";
import userSettingsReducer from "./userSettingsReducer";
import classRepositoryReducer from "../reducers/classRepositoryReducer";
import reportReducer from "./reportReducer";
import subgridRepositoryReducer from "./subgridRepositoryReducer";
import dynReducer from "./dynReducer";
import fiasItems from "./fiasReducer"
import inOperationReducer from "./operationReducer";

let externalReducer = {};
let reduxStore;

export function appendReducer(reducer) {
  externalReducer = reducer;
}

export function setStore(store) {
  reduxStore = store;
}

export function dispatch(action) {
  reduxStore.dispatch(action);
}

const reducerCreator = function() {
  return combineReducers(Object.assign({},
    externalReducer,
    {
      errorStatus: errorStatus,
      userSettingsState: userSettingsReducer,
      classRepository: classRepositoryReducer,
      subgridRepository: subgridRepositoryReducer,
      reportBuildState: reportReducer,
      dynState: dynReducer,
      inOperationState : inOperationReducer,
      fiasState: fiasItems()
    }
  ));
};

export function getItemPath(itemRequest) {
  return `${itemRequest.controller}_${itemRequest.methodName}_${itemRequest.fieldName}`;
}

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT" || action.type === "SESSION_OVER") {
    state = undefined;
  }

  const appReducer = reducerCreator();
  return appReducer(state, action);
};

export default rootReducer;
