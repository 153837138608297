import PropTypes from "prop-types";
import React from "react";

const HtmlCleanup = (props) => {

    if (!props.value)
        return null;

    const res = props.value.replace(/<style[\s\S]*?<\/style>/gi, '')
    const res2 = res.replace(/<(.|\n|\r\n)*?>/g, '');

    return <>{res2}</>
};

HtmlCleanup.propTypes = {
    value: PropTypes.string
};

export default HtmlCleanup;