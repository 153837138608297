import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import UrProvider from '@mnjsplatform/data/lib/api/UrProvider';
import isEqual from 'lodash/isEqual';
import MnComboBase from "./MnComboBase";
import BaseMnTree from "../BaseMnTree";
import i18next from "i18next";
import {GlobalDebugMode} from "@mnjsplatform/data/lib";
import {comboSelected, getSelectedIndex} from "@mnjsplatform/data/lib/actions/comboActions";
import formatData from '@mnjsplatform/data/lib/utils/formatData';
import { errorRaised } from "@mnjsplatform/data/lib/actions/errorActions";
import ItemInjector from "../ItemInjector";
import ComplexParam from "../ComplexParam";

class MnComboInlineTreeInternal extends React.Component {
    constructor(props, context) {
        super(props, context);

        //this.onToggle = this.onToggle.bind(this);
        //this.onResetClick = this.onResetClick.bind(this);

        this.searchFieldName = "FindVal";

        this.state = {
            selectedIndex: -1
        }
    }

    isParentReady() {
        return this.props.parentDataItem.isInitialized;
    }

    isOwnReady() {
        return this.props.ownItem && this.props.ownItem.isInitialized;
    }

    getTitle() {
        if (!this.isParentReady())
            return i18next.t('GridViewEmptyLabel');

        let val = formatData(this.props.fieldInfo, this.props.parentDataItem.rawData.DataTable[this.props.rowIndex][this.props.fieldInfo.Name]);

        if (!val && !this.props.readOnly)
            return i18next.t('PlsSelectLabel');

        return val;
    }

    onToggle = async () => {

        const urProcessed = this.props.parentDataItem.rawData.ColumnUpdateRules[this.props.fieldInfo.Name];
        const urProvider = new UrProvider(urProcessed);

        const execParams = urProvider.getParamsForExecRules(this.props.parentDataItem, this.props.rowIndex);

        const needRefresh = this.props.parentDataItem.rowsCount() > 1 || !this.execParamsPrev || !isEqual(this.execParamsPrev, execParams);

        if (needRefresh)
            this.execParamsPrev = execParams;

        if (!this.isOwnReady() || needRefresh) {
            try {
                await this.props.dispatch(this.props.ownItem.execute(execParams, {withParams: true, execMap: this.props.parentDataItem.rawData.ExecMap}));

                if(!this.props.ownItem.isError) {
                    const data = await this.props.dispatch(getSelectedIndex(this.props.parentDataItem, this.props.ownItem, this.props.rowIndex, this.props.fieldInfo.Name));
                    this.setState({ selectedIndex: data.selectedIndex })
                }
            }
            catch (e) {
                this.props.dispatch(errorRaised(e));
            }

        }
    }

    checkLeafNode = (selectedIndex) => {
        if(!this.props.selectOnlyLeafs)
            return true;

        if(!this.props.ownItem || !this.props.ownItem.isInitialized || !this.props.ownItem.rawData)
            return true;

        const treeData = this.props.ownItem.rawData.RowTreeInfo[selectedIndex];

        return treeData && treeData.IsEmpty;
    }

    onSelect(selectedIndex) {

        if(this.props.selectOnlyLeafs)
        {
            const treeData = this.props.ownItem.rawData.RowTreeInfo[selectedIndex];

            if(!treeData.IsEmpty)
                return;
        }

        this.props.dispatch(comboSelected(this.props.parentDataItem,
            this.props.ownItem,
            this.props.rowIndex,
            selectedIndex,
            this.props.fieldInfo.Name, false))
            .then(data => this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, data.changedFields)))
            .then(() => {
                if (this.props.onSelected) {
                    this.props.onSelected(this.props.rowIndex);
                }
            });
    }

    onResetClick = () => {

        if (!this.isOwnReady()) {
            const urProcessed = this.props.parentDataItem.rawData.ColumnUpdateRules[this.props.fieldInfo.Name];
            const ur = new UrProvider(urProcessed);
            const execParams = ur.getParamsForExecRules(this.props.parentDataItem, this.props.rowIndex);

            this.props.dispatch(this.props.ownItem.initialize(execParams, {execMap:this.props.parentDataItem.rawData.ExecMap}))
                .then(v => this.onSelect(-1));
        }
        else
            this.onSelect(-1);
    }

    render() {

        const isRequired = !this.props.allowReset || this.props.parentDataItem.getFieldIsRequired(this.props.fieldInfo, this.props.rowIndex);
        const self = this;

        return (<React.Fragment>
                {GlobalDebugMode && this.props.ownItem && this.props.ownItem.methodName}
                <MnComboBase allowReset={!isRequired} onAfterOpen={this.onToggle} onReset={this.onResetClick}
                             allowSelectChecker={this.checkLeafNode}
                             onSelected={(index) => this.onSelect(index)} readOnly={this.props.readOnly}
                             renderRows={(idx, h) => self.renderTreeRows(idx, h)} value={this.getTitle()}
                             selectedIndex={self.state.selectedIndex} isDataReady={this.isOwnReady() && this.isParentReady()}
                /></React.Fragment>
        )
    }

    renderSearchParams = (h) => {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-md-2 field-title" style={{textAlign: "left"}}>
                        {h.lbl("FindVal")}
                    </div>
                    <div className="col-md-9">
                        {h.ed("FindVal")}
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderTreeRows(currentIndex, selectHandle) {
        let self = this;

        const rows = this.props.ownItem.dataTable();

        if (rows.length === 0) {
            return <div style={{paddingLeft: 20, paddingTop: 3, paddingBottom: 3}}>{i18next.t('GridViewEmptyLabel')}</div>;
        }

        let showParams = !this.props.disableSearch && this.props.ownItemParams
                         && this.props.ownItemParams.isInitialized
                         && this.props.ownItemParams.columnExists(this.searchFieldName);

        if(showParams) {
            let searchFieldInfo = this.props.ownItemParams.getFieldInfo(this.searchFieldName);
            showParams = !this.props.ownItemParams.getFieldIsHidden(searchFieldInfo, 0);
        }

        return (
            <React.Fragment>
                {showParams && <ComplexParam dataItem={this.props.ownItemParams} parentDataItem={this.props.ownItem} renderParams={this.renderSearchParams} autoApplyParams applyOnEnterKey/>}
                {showParams && <div style={{height: "1px", background:"black", marginTop: "5px", marginBottom: "5px"}}/>}
                <BaseMnTree dataItem={this.props.ownItem}
                            controlOnlyByImage={!this.props.selectOnlyLeafs}
                            nodeRender={function (r, i) {
                    return self.nodeRender(r, i, selectHandle, currentIndex);
                }}/>
            </React.Fragment>

        );
    }

    nodeRender(r, i, selectHandle, currentIndex, treeData) {

        let self = this;

        let line = function (row) {

            let values = [];

            for (let fieldInfo of self.props.ownItem.rawData.ResultFieldInfo) {
                if (self.props.ownItem.getFieldIsHidden(fieldInfo))
                    continue;

                let name = fieldInfo.Name;
                let val = formatData(fieldInfo, row[name]);
                let width = (fieldInfo.Width ? fieldInfo.Width : 150);

                values.push({name, val, width});
            }

            return values;
        };

        let getRow = function (row) {
            let values = line(row);
            return (
                <React.Fragment>
                    {values.map((value, index) =>
                        <span key={index} style={{
                            verticalAlign: 'top',
                            display: 'inline',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            width: values.length > 1 ? value.width : "100%"
                        }}>{value.val}</span>)
                    }
                </React.Fragment>
            );
        };

        for (let fieldInfo of this.props.ownItem.rawData.ResultFieldInfo) {
            if (fieldInfo.IsHidden)
                continue;

            const className = (currentIndex === i) ? "dropdown-item selected" : "dropdown-item";

            return (<li role="presentation" key={i} style={{display: "inline"}}>
                        <a className={className} style={{display: "inline"}} key={i} onClick={function (e) {
                                                             e.preventDefault();
                                                             selectHandle(i, treeData)
                                                         }}>
                            <span>{getRow(r)}</span>
                        </a>
                    </li>
            )
        }

        return "[...]";
    }

    searchCurrentIndex() {
        if (!(this.isOwnReady() && this.isParentReady()))
            return -1;

        const urProcessed = this.props.parentDataItem.rawData.ColumnUpdateRules[this.props.fieldInfo.Name];
        const urProvider = new UrProvider(urProcessed);

        const keyStructure = this.props.ownItem.rawData.KeyStructure;
        if (keyStructure.length !== 0) {
            let targetKey = {};
            for (let fieldInfo of this.props.parentDataItem.rawData.ResultFieldInfo) {
                if (keyStructure.includes(fieldInfo.Name))
                    targetKey[fieldInfo.Name] = this.props.parentDataItem.getRow(this.props.rowIndex)[fieldInfo.Name];
            }

            for (let ri of urProvider.updateRules) {
                const dst = ri.strDst;
                const src = ri.strSrc;

                if (keyStructure.includes(src)) {
                    targetKey[src] = this.props.parentDataItem.getRow(this.props.rowIndex)[dst];
                }
            }

            console.log(JSON.stringify(targetKey));
            console.log(JSON.stringify(urProcessed));

            return this.props.ownItem.getRowIndex(targetKey);
        }

        let name = this.props.fieldInfo.Name;
        const ri = urProvider.findUpdateDst(name);
        if (ri) {
            if (ri.strSrc === "*") {
                name = name.substring(ri.strDst.length - 1);
                if (!this.props.ownItem.columnExists(name)) {
                    name = this.props.fieldInfo.Name;
                }
            }
            else
                name = ri.strSrc;
        }

        console.log("NAME:" + JSON.stringify({[name]: this.getTitle()}));

        this.props.ownItem.getRowIndex({[name]: this.getTitle()})
    }
}

MnComboInlineTreeInternal.propTypes = {
    dispatch: PropTypes.func,
    parentDataItem: PropTypes.object.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    rowIndex: PropTypes.number,
    ownItem: PropTypes.object,
    ownItemParams: PropTypes.object,
    onSelected: PropTypes.func,
    bsSize: PropTypes.string,
    allowReset: PropTypes.bool,
    rootPath: PropTypes.string,
    readOnly: PropTypes.bool,
    selectOnlyLeafs: PropTypes.bool
};

MnComboInlineTreeInternal.defaultProps = {
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownItem: ownProps.getItemState(state),
        ownItemParams: ownProps.getItemStateParams(state)
    };
};

const ConnectedMnComboInlineTree = connect(mapStateToProps)(MnComboInlineTreeInternal);

const MnComboInlineTree = (props) => {
    return (
        <ItemInjector dataItem={{
            controller: props.parentDataItem.controllerName,
            methodName: props.fieldInfo.EditorMethod,
            fieldName: props.rootPath,
            withParamset: true
        }}>
            <ConnectedMnComboInlineTree {...props} />
        </ItemInjector>
    );
};

MnComboInlineTree.propTypes = {
    parentDataItem: PropTypes.object.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    rowIndex: PropTypes.number,
    onSelected: PropTypes.func,
    bsSize: PropTypes.string,
    allowReset: PropTypes.bool,
    rootPath: PropTypes.string,
    readOnly: PropTypes.bool,
    selectOnlyLeafs: PropTypes.bool,
    disableSearch: PropTypes.bool
};

MnComboInlineTree.defaultProps = {
    rowIndex: 0,
    allowReset: true,
    selectOnlyLeafs: false,
    disableSearch: false
};

export default MnComboInlineTree;

