import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as e from "@mnjsplatform/data/lib/reducers/reportReducer";
import f from "@mnjsplatform/data/lib/utils/errorTools";
import {reportDismiss} from "@mnjsplatform/data/lib/actions/reportActions";
import pathJoin from "@mnjsplatform/data/lib/utils/pathJoin";
import i18next from "i18next";
import AuthProvider from "@mnjsplatform/data/lib/security/authProvider";
import ModalWrapper from "../modalWrapper";

class ReportBuildViewer extends Component {
    constructor(props, context) {
        super(props, context);

        this.close = this.close.bind(this);
        this.download = this.download.bind(this);

        this.state = {
            showDetails: false,
        }
    }

    close() {
        const taskId = this.props.reportBuildState.taskId;
        this.hideDetails();
        this.props.dispatch(reportDismiss(taskId, this.props.reportKey))
    }

    download() {

        const taskId = this.props.reportBuildState.taskId;
        const reportType = this.props.reportBuildState.reportType;

        const tokenPromise = AuthProvider.getToken();

        tokenPromise.then(token => {

            const downloadPath = pathJoin(window.config.apiRoot, "Report", "DownloadReport") + "?" + $.param({
                taskId,
                reportType,
                b_token: token
            });

            this.props.dispatch(reportDismiss(undefined, this.props.reportKey))
                .then(() => {
                        window.skipUnloadActions = true;
                        window.location.href = downloadPath;
                    }
                );
        });
    }

    onShowDetails = () => {
        this.setState({showDetails: true});
    };

    hideDetails = () => {
        this.setState({showDetails: false});
    };

    getDetailsText = () => {

        const statusLog = this.props.reportBuildState.statusLog;

        if (!statusLog)
            return null;

        const text = statusLog.map(i => {
            return (<div>{i.text}</div>)
        });
        return text;
    };

    render() {

        const isActive = this.props.reportBuildState.currentState !== e.REPORT_INACTIVE;
        let statusText = this.props.reportBuildState.statusText;

        if (this.props.reportBuildState.currentState === e.REPORT_ERROR)
            statusText = f(this.props.reportBuildState.error);


        const canCancel = this.props.reportBuildState.currentState === e.REPORT_INITIALIZED
            || this.props.reportBuildState.currentState === e.REPORT_CREATED
            || this.props.reportBuildState.currentState === e.REPORT_COMPLETE
            || this.props.reportBuildState.currentState === e.REPORT_PREPARE;

        const canOpen = this.props.reportBuildState.currentState === e.REPORT_COMPLETE;
        const canDetails = this.props.reportBuildState.currentState === e.REPORT_ERROR && this.props.reportBuildState.statusLog.length > 0 && !this.state.showDetails;
        const canClose = this.props.reportBuildState.currentState === e.REPORT_ERROR;


        if (!isActive)
            return null;

        return (
            <ModalWrapper>
                <div className="modal" role="dialog" style={{display: "block"}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" aria-label="Close" id="btnClose1"
                                        onClick={this.close}><span
                                    aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="title">{i18next.t('ReportBuilding')}</h4>
                            </div>
                            <div className="modal-body form-horizontal">
                                <div style={{margin: "5px"}}>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        padding: "1px"
                                    }}>{i18next.t('ReportBuildingStatus')}:</span>&nbsp;
                                    <span className="text">{statusText || i18next.t('Waiting')}</span>
                                    {this.state.showDetails &&
                                        <div className="report-logFrame">{this.getDetailsText()}</div>}
                                    <div style={{width: "100%", paddingTop: "5px", textAlign: "right"}}>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {canDetails && <button className="btn btn-regular -wide -default m-1"
                                                       onClick={this.onShowDetails}>{i18next.t('Details')}</button>}
                                {canOpen && <button className="btn btn-regular -default m-1"
                                                    onClick={this.download}>{i18next.t('Open')}</button>}
                                {canCancel && <button className="btn btn-regular m-1"
                                                      onClick={this.close}>{i18next.t('Cancel')}</button>}
                                {canClose && <button className="btn btn-regular m-1"
                                                     onClick={this.close}>{i18next.t('Close')}</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </ModalWrapper>);
    }
}

ReportBuildViewer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    reportBuildState: PropTypes.object,
    reportKey: PropTypes.string
};


ReportBuildViewer.defaultProps = {};

function mapStateToProps(state, ownProps) {

    let reportKey = "defaultReport";

    if (ownProps.reportKey)
        reportKey = ownProps.reportKey;

    return {
        reportBuildState: state.reportBuildState[reportKey]
    };
}

export default connect(mapStateToProps)(ReportBuildViewer);