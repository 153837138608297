import HttpQuery from './HttpQuery';

class ClassApi {
    static initClassHandle(classManagerController, fieldInfo, classKey, controllerName, methodName, isParamset, rowIndex, classExMode)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/InitClassManager`, {fieldInfo,classKey,controllerName, methodName, isParamset, rowIndex, classExMode});
    }

    static unmountClassHandle(classManagerController, classKey) {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/UnmountClassManager`, {classKey});
    }

    static reInitSelectSetFields(classManagerController, classKey) {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/ReInitSelectSetFields`, {classKey});
    }

    static initClassHandleAdvanced(classManagerController, fieldInfo, classKey, controllerName, methodName, isParamset, rowIndex)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/InitClassManagerAdvFx`, {fieldInfo,classKey,controllerName, methodName, isParamset, rowIndex});
    }

    static changeClassHierarchy(classManagerController, classKey, hierarchyIndex)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/HierarchyChanged`, {classKey, hierarchyIndex});
    }

    static addItemToSet(classManagerController, classKey, rowIndex)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/AddItemToSet`, {classKey, rowIndex});
    }

    static changeTree(classManagerController, classKey, treeIndex)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/TreeChanged`, {classKey, treeIndex});
    }

    static saveValueToItem(classManagerController, classKey)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/SaveValueToItem`, {classKey});
    }

    static saveValueToItemEx(classManagerController, classKey)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/SaveValueToItemEx`, {classKey});
    }

    static singleItemSelected(classManagerController, classKey, rowIndex, isDropDown)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/SingleItemSelected`, {classKey, rowIndex, isDropDown});
    }

    static clearSelection(classManagerController, classKey)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/ClearSelection`, {classKey});
    }

    static clearSelectionEx(classManagerController, classKey)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/ClearSelectionEx`, {classKey});
    }

    static syncSelectedItems(classManagerController, classKey, rowIndex)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/SyncSelectedItems`, {classKey, rowIndex});
    }

    static deleteSelectedItem(classManagerController, classKey, rowIndex)
    {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/DeleteSelectedItem`, {classKey, rowIndex});
    }

    static updateControlText(classManagerController, classKey) {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${classManagerController}/UpdateControlTextMultiSelect`, {classKey});
    }
}

export default ClassApi