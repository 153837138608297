/**
 * Created by marks on 12.01.2017.
 */
import DataItemApi, { GROUP_OPERATION_ADD, GROUP_OPERATION_REMOVE_ALL, GROUP_OPERATION_REMOVE } from "./dataItemApi";
import * as ea from '../actions/errorActions';
import UrProvider from './UrProvider';
import * as dt from '../actions/dataItemTypes';
import _findIndex from 'lodash/findIndex'
import formatData from "../utils/formatData";
import i18next from "i18next";

let FileStore;
if (process.env.NODE_ENV !== "access" && process.env.NODE_ENV !== "test")
    FileStore = require("./FileStore").FileStore;

import HttpQuery from "./HttpQuery";
import {findItemByItem} from "../utils/deepSearch";
import editMode from "../utils/editMode";
import {formatFileSize} from "../utils/fileUtils";

const DEFAULT_MAX_FILE_SIZE = 4000000;
const MAX_FILE_SIZE_MAP_NAME = "MaxVolume";

class DataItem {
    constructor(controllerName = "", methodName = "", isParamItem = false, isPartial = false, persistParamSet = false, itemRawData = {}) {
        this.controllerName = controllerName;
        this.methodName = methodName;
        this.rawData = itemRawData;
        this.isInitialized = false;
        this.inOperation = false;
        this.isParamItem = isParamItem;
        this.isPartial = isPartial;
        this.isError = false;
        this.rowInEditBackup = {};
        this.rowInEditIndex = -1;
        this.isAddRow = false;
        this.persistParamSet = persistParamSet;
        this.filesToUpload = {};
        this.fileProgress = {};
    }

    getFullName() {
        if (!this.isParamItem)
            return this.controllerName + '/' + this.methodName;

        return this.getFullNameParams();
    }

    getFullNameParams() {
        return this.controllerName + '/' + this.methodName + "_params";
    }

    loadDataSuccess(rawData) {
        return {type: dt.ITEM_LOAD_DATA_SUCCESS, rawData, name: this.getFullName()};
    }

    refreshDataSuccess(rawData) {
        return {type: dt.ITEM_REFRESH_DATA_SUCCESS, rawData, name: this.getFullName()};
    }

    refreshDataSuccessParams(rawData) {
        return {type: dt.ITEM_REFRESH_DATA_SUCCESS, rawData, name: this.getFullNameParams()};
    }

    loadDataSuccessParams(rawData) {
        return {type: dt.ITEM_LOAD_DATA_SUCCESS, rawData, name: this.getFullNameParams()};
    }

    beginOperation(d) {
        d({type: dt.ITEM_BEGIN_OPERATION, name: this.getFullName()});
    }

    endOperation(d, withError = false) {
        d({type: dt.ITEM_END_OPERATION, name: this.getFullName(), withError});
    }

    async waitNoOp(state)
    {
        return await this.waitOpComplete(state, this);
    }

    waitOpComplete(state, item) {
        const poll = resolve => {
            const di = findItemByItem(state(), item);
            if (!di)
                resolve();

            if(!di.inOperation) resolve(di);

            else setTimeout(_ => poll(resolve), 100);
        };

        return new Promise(poll);
    }

    beginEdit(rowIndex) {
        return {type: dt.ITEM_BEGIN_EDIT, name: this.getFullName(), rowIndex};
    }

    cancelEdit() {

        return d => {
            this.beginOperation(d);

            return DataItemApi.cancelEdit(this.controllerName, this.methodName)
                .then(rawData => {
                    return d({type: dt.ITEM_CANCEL_EDIT, name: this.getFullName()});
                })
                .catch(error => {
                    this.endOperation(d, true);
                    d(ea.errorRaised(error));
                });
        };
    }

    setField(rowIndex, fieldName, fieldValue) {
        return this.setFields(rowIndex, {[fieldName]: fieldValue});
    }

    setFields(rowIndex, fieldSet) {

        const set = (d) =>{
            d({type: dt.ITEM_SET_FIELDS, name: this.getFullName(), rowIndex, fieldSet});
            return Promise.resolve();
        };

        if (!this.checkServerSetFieldRequired(fieldSet))
            return d => {
                return set(d);
            };


        return d => {
            this.beginOperation(d);

            const editedRow = Object.assign({}, this.getRow(rowIndex), fieldSet);

            return DataItemApi.setFields(this.controllerName, this.methodName, editedRow, rowIndex)
                .then(rawData => {
                    d({type: dt.ITEM_SET_FIELDS_COMPLETE, rawData, name: this.getFullName()});
                })
                .catch(error => {
                    this.endOperation(d, true);
                    d(ea.errorRaised(error));
                });
        };
    }

    reset(rowsOnly = false) {
        let self = this;
        return d => {
            return new Promise(function (resolve, reject) {
                d({type: dt.ITEM_RESET, name: self.getFullName(), rowsOnly});
                resolve();
            });
        };
    }

    execute(parameters = {}, options = undefined) {
        if (!this.isInitialized)
            return this.initialize(parameters, options);
        else
            return this.refresh(parameters, options);
    }

    initialize(parameters = {}, options = {newItem: false}) {
        return d => {

            if (this.isInitialized && !options.newItem)
                return new Promise(function (resolve, reject) {
                    resolve();
                });

            const defOpts = {
                withParams: true,
                fullDataSet: !this.isPartial,
                persistParamSet: this.persistParamSet
            };

            const resOpts = Object.assign(defOpts, options);

            this.beginOperation(d);

            return DataItemApi.loadDataItem(this.controllerName, this.methodName, resOpts, parameters).then(rawData => {

                const itemSafeName = this.methodName.split('.').join('_');
                const itemSafeNameParams = itemSafeName + "_params";

                d(this.loadDataSuccess(rawData.items[itemSafeName]));

                if (rawData.items[itemSafeNameParams])
                    d(this.loadDataSuccessParams(rawData.items[itemSafeNameParams]));
            }).catch(error => {

                this.endOperation(d, true);
                d(ea.errorRaised(error));
            });
        };
    }

    refresh(parameters = {}, options = {newItem: false}) {
        return d => {

            this.beginOperation(d);

            const defOpts = {
                withParams: false,
                fullDataSet: !this.isPartial,
                persistParamSet: this.persistParamSet
            };

            const resOpts = Object.assign(defOpts, options);


            return DataItemApi.loadDataItem(this.controllerName, this.methodName, resOpts, parameters)
                .then(rawData => {

                const itemSafeName = this.methodName.split('.').join('_');
                const itemSafeNameParams = itemSafeName + "_params";

                d(this.refreshDataSuccess(rawData.items[itemSafeName]));

                if (rawData.items[itemSafeNameParams])
                        d(this.loadDataSuccessParams(rawData.items[itemSafeNameParams]));

            }).catch(error => {

                this.endOperation(d, true);
                d(ea.errorRaised(error));
            });
        };
    }

    applyParams(paramsItem, withParams = false) {
        return async (d, getState) => {

            const pi = await this.waitOpComplete(getState, paramsItem);
            this.beginOperation(d);

            if (pi.processRequired(d)) {
                this.endOperation(d, true);
                return Promise.resolve({type: "dummy"});
            }

            try {
                const fileSet = await pi.uploadFiles(0, d); //Todo check&fix

                console.log(JSON.stringify(fileSet));

                const rawData = await DataItemApi.applyParams(this.controllerName, this.methodName, pi.rawData.DataTable, !this.isPartial, this.persistParamSet, fileSet, withParams);
                const itemSafeName = this.methodName.split('.').join('_');
                d(this.refreshDataSuccess(rawData.items[itemSafeName]));

                const itemSafeNameParams = itemSafeName + "_params";
                if (withParams && rawData.items[itemSafeNameParams]) {
                    d(this.refreshDataSuccessParams(rawData.items[itemSafeNameParams]));
                }
            }
            catch (error) {
                this.endOperation(d, true);
                d(ea.errorRaised(error));
            }
            finally {
                pi.endOperation(d, false);
            }
        };
    }

    getParamsAndRefresh(restore = false) {
        return d => {
            this.beginOperation(d);

            return DataItemApi.getParamsAndRefresh(this.controllerName, this.methodName, restore)
                .then(rawData => {

                    const itemSafeName = this.methodName.split('.').join('_');
                    const itemSafeNameParams = itemSafeName + "_params";

                    d(this.refreshDataSuccess(rawData.items[itemSafeName]));
                    d(this.refreshDataSuccessParams(rawData.items[itemSafeNameParams]));
                }).catch(error => {

                    this.endOperation(d, true);
                    d(ea.errorRaised(error));
                });
        };
    }

    getFileCheckError(rowIndex, fieldName, file, appMax) {
        const urProcessed = this.rawData.ColumnUpdateRules[fieldName];
        const urProvider = new UrProvider(urProcessed);
        const execParams = urProvider.getParamsForExecRules(this, rowIndex);
        let maxFileSize = DEFAULT_MAX_FILE_SIZE;
        if (execParams[MAX_FILE_SIZE_MAP_NAME] && execParams[MAX_FILE_SIZE_MAP_NAME] > 0) {
            maxFileSize = execParams[MAX_FILE_SIZE_MAP_NAME];
        }
        else if (appMax && appMax > 0) {
            maxFileSize = appMax;
        }
        if (file.size > maxFileSize) {
            return (i18next.t("FileUpload_Oversize", {name: file.name, size: formatFileSize(file.size), max_size: formatFileSize(maxFileSize)}));
        }
        return null;
    }

    setFile(rowIndex, fieldName, file, appMax) {
        if (!!file) {
            const fileCheckError = this.getFileCheckError(rowIndex, fieldName, file, appMax);
            if (!!fileCheckError) {
                return d => {
                    d(ea.errorRaised(fileCheckError));
                    return Promise.reject(fileCheckError);
                };
            }
        }
        console.log("rowind:"+rowIndex);
        const fileKey = rowIndex + "::" + fieldName;

        if (this.filesToUpload[fileKey])
            FileStore.removeFile(this.filesToUpload[fileKey]);

        let fileId = null;
        if (!!file) {
            fileId = FileStore.addFile(file);
            console.log("File stored with id:" + fileId + " row:" + rowIndex);
        }

        return this.setExistingFile(rowIndex, fileKey, fileId);
    }

    setExistingFile(rowIndex, fileKey, fileId) {
        return d => {
            d({type: dt.ITEM_ADD_FILE, name: this.getFullName(), fileId, fileKey});
            return Promise.resolve();
        }
    }

    save(rowIndex = 0) {
        return async (d, getState) => {

            console.log("Before Save:" + this.inOperation);

            const di = await this.waitNoOp(getState);
            this.beginOperation(d);

            try {

                if (di.processRequired(d, rowIndex)) {
                    this.endOperation(d, true);
                    return Promise.resolve({type: "dummy"});
                }

                const fileSet = await di.uploadFiles(rowIndex, d);
                console.log(JSON.stringify(fileSet));
                const rawData = await DataItemApi.save(this.controllerName, this.methodName, di.getRow(rowIndex), rowIndex, fileSet, this.isParamItem);
                d({type: dt.ITEM_SAVE, rawData, name: this.getFullName()});
            }
            catch (error) {
                this.endOperation(d, true);
                d(ea.errorRaised(error));
            }
            console.log("After Save");
        };
    }

    saveAdd(rowIndex = 0) {
        return async (d, getState) => {

            const di = await this.waitNoOp(getState);
            this.beginOperation(d);

            if (this.processRequired(d, rowIndex)) {
                this.endOperation(d, true);
                return Promise.resolve({type: "dummy"});
            }

            try {
                const fileSet = await di.uploadFiles(rowIndex, d);
                console.log(JSON.stringify(fileSet));

                const rawData = await DataItemApi.saveAdd(this.controllerName, this.methodName, di.getRow(rowIndex), fileSet);
                d({type: dt.ITEM_SAVE_ADD, rawData, name: this.getFullName()});
            }
            catch (error) {
                this.endOperation(d, true);
                d(ea.errorRaised(error));
            }
        };
    }

    async uploadFiles(rowIndex, d) {

        const result = {};

        for (let fi of this.rawData.ResultFieldInfo) {
            const fileKey = rowIndex + "::" + fi.Name;
            if (!this.filesToUpload[fileKey])
                continue;

            const id = this.filesToUpload[fileKey];
            const file = FileStore.getFile(id);

            if (!file)
                continue;

            console.log("found file for field: " + fi.Name);

            const data = new FormData();
            data.append("inputFile", file);

            const q = new HttpQuery();
            console.log("Uploading file:" + file.name);
            
            const remoteFileName = await q.makeApiCallUploadFile(data, (e) => {this.trackUploading(e, d, fileKey)}, "/FileEx/Upload");

            console.log("Upload complete:" + file.name + " " + remoteFileName);

            d({type: dt.ITEM_STORING_FILE, fileKey, name: this.getFullName()});

            result[fi.Name] = {remoteFileName, fileName : file.name};
        }

        return result;
    }

    trackUploading(e, d, fileKey)
    {
        d({type: dt.ITEM_PROGRESS_FILE, fileKey, loaded:e.loaded, total:e.total, name: this.getFullName()});
    }

    deleteRow(rowIndex) {
        return d => {
            this.beginOperation(d);

            return DataItemApi.deleteRow(this.controllerName, this.methodName, rowIndex)
                .then(rawData => {
                    d({type: dt.ITEM_DELETE_ROW, rawData, name: this.getFullName()});
                })
                .catch(error => {
                    this.endOperation(d, true);
                    d(ea.errorRaised(error));
                });
        };
    }

    beginAdd() {
        return d => {
            this.beginOperation(d);

            return DataItemApi.beginAdd(this.controllerName, this.methodName)
                .then(rawData => {
                    d({type: dt.ITEM_BEGIN_ADD_ROW, rawData, name: this.getFullName()});
                })
                .catch(error => {
                    this.endOperation(d, true);
                    d(ea.errorRaised(error));
                });
        };
    }

    cancelAdd() {
        return d => {
            this.beginOperation(d);

            return DataItemApi.cancelAdd(this.controllerName, this.methodName)
                .then(rawData => {
                    d({type: dt.ITEM_CANCEL_ADD, rawData, name: this.getFullName()});
                })
                .catch(error => {
                    this.endOperation(d, true);
                    d(ea.errorRaised(error));
                });
        };
    }

    ensureRows(startRow, endRow) {
        return d => {

            if (!this.isInitialized || startRow >= this.rawData.Rows)
                return Promise.resolve({type: "dummy"});

            let rowsExist = true;
            let queryRow = startRow;

            for (let i = startRow; i <= endRow; i++) {
                queryRow = i;
                if (!this.rawData.DataTable[i]) {
                    rowsExist = false;
                    break;
                }
            }

            if (rowsExist)
                return Promise.resolve({type: "dummy"});

            this.beginOperation(d);
            return DataItemApi.dataSlice(this.controllerName, this.methodName, queryRow, endRow).then(rawData => {
                d({type: dt.ITEM_DATA_SLICE_SUCCESS, name: this.getFullName(), rawData, startRow:queryRow, endRow});
            }).catch(error => {
                this.endOperation(d, true);
                d(ea.errorRaised(error));
            });
        };
    }

    sortRows(fieldName, isAsc, isClear = false) {
        return d => {
            this.beginOperation(d);
            return DataItemApi.sortRows(this.controllerName, this.methodName, fieldName, isAsc, isClear, !this.isPartial).then(rawData => {

                let itemSafeName = this.methodName.split('.').join('_');
                d(this.refreshDataSuccess(rawData.items[itemSafeName]));
            }).catch(error => {
                this.endOperation(d, true);
                d(ea.errorRaised(error));
            });
        };
    }

    clearSort() {
        return this.sortRows(null, false, true);
    }

    menuSelected(menuId, isGroupField) {
        return d => {
            this.beginOperation(d);
            return DataItemApi.updateGroup(this.controllerName, this.methodName, menuId, isGroupField).then(rawData => {

                let itemSafeName = this.methodName.split('.').join('_');
                d(this.refreshDataSuccess(rawData.items[itemSafeName]));
            }).catch(error => {
                this.endOperation(d, true);
                d(ea.errorRaised(error));
            });
        };
    }

    filterRows(filterText, fieldList) {
        return d => {
            d({type: dt.ITEM_SET_FILTER, name: this.getFullName(), filterText});
            this.beginOperation(d);
            return DataItemApi.quickFilter(this.controllerName, this.methodName, filterText, fieldList, !this.isPartial).then(rawData => {

                let itemSafeName = this.methodName.split('.').join('_');
                d(this.refreshDataSuccess(rawData.items[itemSafeName]));
            }).catch(error => {
                this.endOperation(d, true);
                d(ea.errorRaised(error));
            });
        };
    }

    treeOperation(rowIndex, expand) {
        return d => {

            this.beginOperation(d);
            return DataItemApi.treeOperation(this.controllerName, this.methodName, rowIndex, expand).then(rawData => {

                const itemSafeName = this.methodName.split('.').join('_');
                const data = rawData.items[itemSafeName];
                d({type: dt.ITEM_TREE_OP_SUCCESS, rawData, name: this.getFullName(), data});
            }).catch(error => {
                this.endOperation(d, true);
                d(ea.errorRaised(error));
            });
        };
    }

    treeSetCurrentRow(rowKey) {
        return d => {
            this.beginOperation(d);
            return DataItemApi.treeSetCurrentRow(this.controllerName, this.methodName, rowKey).then(rawData => {
                const itemSafeName = this.methodName.split('.').join('_');
                const data = rawData.items[itemSafeName];
                d({type: dt.ITEM_TREE_SET_ROW_SUCCESS, rawData, name: this.getFullName(), data});
            }).catch(error => {
                this.endOperation(d, true);
                d(ea.errorRaised(error));
            });
        };
    }

    groupAdd(columnName)
    {
        return this.groupOperation(columnName, GROUP_OPERATION_ADD);
    }

    groupRemove(columnName)
    {
        return this.groupOperation(columnName, GROUP_OPERATION_REMOVE);
    }

    groupRemoveAll()
    {
        return this.groupOperation(undefined, GROUP_OPERATION_REMOVE_ALL);
    }

    groupOperation(columnName = null, operationType)
    {
        return d => {

            this.beginOperation(d);
            return DataItemApi.groupOperation(this.controllerName, this.methodName, 0, columnName, operationType).then(rawData => {

                const itemSafeName = this.methodName.split('.').join('_');
                d(this.refreshDataSuccess(rawData.items[itemSafeName]));
            }).catch(error => {
                this.endOperation(d, true);
                d(ea.errorRaised(error));
            });
        };
    }

    goto(rowIndex) {
        return d => {
            d({type: dt.ITEM_SET_INDEX, name: this.getFullName(), rowIndex});
            return Promise.resolve();
        }
    }

    getCurrentRowIndex() {
        if (!this.isInitialized)
            return null;

        return this.rawData.CurrentRowIndex;
	}
	
    getFieldUniqueValues(fieldName) {
        return d => {
            this.beginOperation(d);

            return DataItemApi.getFieldUniqueValues(this.controllerName, this.methodName, fieldName)
                .then(rawData => {
                    this.endOperation(d, false);
                    return rawData;
                })
                .catch(error => {
                    this.endOperation(d, true);
                    d(ea.errorRaised(error));
                });
        };
    }

    applyAutoFilter(fieldName, valueList) {
        return d => {
            this.beginOperation(d);

            return DataItemApi.applyAutoFilter(this.controllerName, this.methodName, fieldName, valueList)
                .then(rawData => {
                    let itemSafeName = this.methodName.replace('.', '_');
                    d(this.refreshDataSuccess(rawData.items[itemSafeName]));
                })
                .catch(error => {
                    this.endOperation(d, true);
                    d(ea.errorRaised(error));
                });
        };
    }

    resetAutoFilter() {
        return d => {
            this.beginOperation(d);

            return DataItemApi.applyAutoFilter(this.controllerName, this.methodName, undefined, undefined, true)
                .then(rawData => {
                    let itemSafeName = this.methodName.replace('.', '_');
                    d(this.refreshDataSuccess(rawData.items[itemSafeName]));
                })
                .catch(error => {
                    this.endOperation(d, true);
                    d(ea.errorRaised(error));
                });
        };
    }

    columnExists(columnName) {

        if (!this.isInitialized)
            return false;

        let cols = this.rawData.ResultFieldInfo.map(fi => fi.Name);
        return cols.includes(columnName);
    }

    rowsCount() {
        if (!this.isInitialized)
            return 0;

        return this.rawData.Rows;
    }

    getRow(rowIndex) {
        const row = this.dataTable()[rowIndex];
        if (row) return row;

        return {};
    }

    getRowIndex2(row) {
        const rows = this.dataTable();
        return rows.indexOf(row);
    }

    getField(rowIndex, fieldName) {
        const row = this.dataTable()[rowIndex];
        if (row) return row[fieldName];

        return null;
    }

    getCanEdit() {
        if (!this.isInitialized)
            return false;

        return this.rawData.ResultProperties.EditRow;
    }

    getCanDelete() {
        if (!this.isInitialized)
            return false;

        return this.rawData.ResultProperties.DeleteRow;
    }

    getCanInsert() {
        if (!this.isInitialized)
            return false;

        return this.rawData.ResultProperties.InsertRow;
    }

    dataTable() {
        if (!this.isInitialized)
            return [];

        return this.rawData.DataTable;
    }

    doUpdateRules(itemSrc, srcRow, strRules) {
        if (itemSrc == null || !strRules)
            return {};

        let src, dst, prefix;

        let fieldsToUpdate = {};

        const urProvider = new UrProvider(strRules);
        for (let ri of urProvider.updateRules) {
            dst = ri.strDst;
            src = ri.strSrc;

            if (src === "*" && this.columnExists(dst.substr(0, dst.length - 1)) && !strRules.contains("*")) {
                src = dst.substring(0, dst.length - 1);
                dst = src;
            }

            if (src === "*") {
                let arr = dst.split("*");
                prefix = arr.length <= 0 ? "" : arr[0];

                for (let fi of itemSrc.rawData.ResultFieldInfo) {
                    let srcFldName = fi.Name;
                    let dstFldName = prefix + srcFldName;
                    let val = !itemSrc.isInitialized || srcRow < 0 ? null : itemSrc.getField(srcRow, srcFldName);
                    if (this.columnExists(dstFldName))
                        fieldsToUpdate[dstFldName] = val;
                }
            }
            else {
                let val = null;
                if (itemSrc.columnExists(src)) {
                    if (itemSrc.isInitialized && srcRow >= 0)
                        val = itemSrc.getField(srcRow, src);
                    if (this.columnExists(dst))
                        fieldsToUpdate[dst] = val;
                }
                else if (ri.getConstant()) {
                    if (this.columnExists(dst))
                        fieldsToUpdate[dst] = val;
                }
            }
        }
        return fieldsToUpdate;
    }

    getFieldInfo(fieldName) {

        if (!this.isInitialized || !fieldName)
            return null;

        for (let fi of this.rawData.ResultFieldInfo) {
            if (fi.Name.toLowerCase() === fieldName.toLowerCase())
                return fi;
        }

        return null;
    }

    getSortableFields() {

        if (!this.isInitialized || !this.rawData.ResultFieldInfo)
            return [];

        const sortableColums = this.rawData.ResultFieldInfo.filter(fi => !this.getFieldIsHidden(fi) && !!fi.IsSortable && fi.Header);
        return sortableColums.map(fi => {
            return {Name: fi.Name, Header: fi.Header.replace(/\|/g, " "), fieldInfo: fi}
        });
    }

    getSort() {
        if (!this.isInitialized || !this.rawData)
            return {};

        return {SortColumn: this.rawData.SortColumn, SortIsAsc: this.rawData.SortIsAsc}
    }

    getTitle(fi, rowIndex) {
        if (!fi) {
            return '';
        }
        if (!this.rawData.ResultFieldInfo) {
            return '';
        }
        let title;
        if (rowIndex >= 0) {
            let name = fi.WebView_Name || fi.Name;
            title = this.tryGetValueFromExecRules(name, "WebView_FieldName", rowIndex);
        }
        if (!title && rowIndex >= 0) {
            title = fi.Title;
        }
        return title;
    }

    getRowTextDescription(rowIndex = 0) {

        const textDesc = [];

        if (!this.isInitialized || !this.rawData)
            return textDesc;

        for (let fi of this.rawData.ResultFieldInfo.concat().sort(f => f.ViewNumber)) {
            if (this.getFieldIsHidden(fi, rowIndex) || !fi.Title)
                continue;

            const value = this.getField(rowIndex, fi.Name);

            if (!value || value.toString().indexOf("(0)") >= 0) //todo: hack for classSelector
                continue;

            const valueFormatted = formatData(fi, value);

            const fieldTextItem = {
                fieldInfo: fi,
                textValue: valueFormatted
            };

            textDesc.push(fieldTextItem);
        }

        return textDesc;
    }

    getRulesValues(rowIndex, fieldName) {

        if (!this.isInitialized)
            return {};

        const fieldInfo = this.getFieldInfo(fieldName);
        if (fieldInfo === null)
            return {};

        const row = this.getRow(rowIndex);

        let up = new UrProvider(fieldInfo.UpdateRules);

        let q = {};
        for (let ri of up.updateRules) {
            q[ri.strDst] = row[ri.strSrc];
        }

        return q;
    }

    getRowKey(row) {
        let key = {};
        const keyStructure = this.rawData.KeyStructure;

        for (let i = 0; i < keyStructure.length; i++) {
            if (row[keyStructure[i]] !== null) {
                key[keyStructure[i]] = row[keyStructure[i]];
            }
        }

        return key;
    }

    getRowKeyByIndex(rowIndex) {
        const row = this.getRow(rowIndex);
        return this.getRowKey(row)
    }

    getRowIndex(key) {
        return _findIndex(this.dataTable(), key)
    }

    processRequired(d, rowIndex = 0, row = null) {
        const missing = this.checkRequired(rowIndex, row);
        if (missing.length > 0) {
            const errMsg = i18next.t('FillRequired') + missing.join(", ");
            d(ea.errorRaised(errMsg));
            return true;
        }

        const format = this.checkFormat(rowIndex, row);
        if (format) {
            const errMsg = i18next.t('FormatError') + format;
            d(ea.errorRaised(errMsg));
            return true;
        }

        return false;
    }

    checkRequired(rowIndex, row = null) {
        const missing = [];

        if (this.isInitialized)
            for (let fi of this.rawData.ResultFieldInfo) {
                if (this.getFieldIsRequired(fi, rowIndex)) {
                    let underlyingFi = fi;

                    if (fi.Action.VirtCol) {
                        const row = this.getRow(rowIndex);
                        underlyingFi = this.getFieldInfo(row[fi.Name]);
                        if (!underlyingFi)
                            continue;
                    }

                    const val = !!row ? row[underlyingFi.Name] : this.getField(rowIndex, underlyingFi.Name);

                    if (val == null || val === "") {
                        var t = this.getTitle(fi, rowIndex).replace(/\|/g, ' ');
                        missing.push(t);
                    }
                }
            }

        return missing;
    }

    getParamsForExecRules (fieldName, rowIndex) {
        if (this.isInitialized
          && this.rawData.ColumnUpdateRules
          && this.rawData.ColumnUpdateRules[fieldName]
        ) {
            const urProvider = new UrProvider(this.rawData.ColumnUpdateRules[fieldName]);

            return urProvider.getParamsForExecRules(this, rowIndex);
        }

        return {};
    }

    tryGetValueFromExecRules(fieldName, paramName, rowIndex) {
        try{

            if (!this.isInitialized || !this.rawData.ColumnUpdateRules || !this.rawData.ColumnUpdateRules[fieldName]) {
                return null;
            }

            const urProvider = new UrProvider(this.rawData.ColumnUpdateRules[fieldName]);

            for (let ri of urProvider.execRules) {
                if (ri.strDst === paramName) {

                    let result = ri.getConstant();
                    if (result.success) {
                        return result.value
                    }

                    return this.getField(rowIndex, ri.strSrc)
                }
            }

            return null;
        }
        catch (e) {
            console.error(e);
            return null;
        }
    };

    getFieldIsRequired (fieldInfo, rowIndex) {
        if (!fieldInfo) {
            return false;
        }
        if (!this.rawData.ResultFieldInfo) {
            return false;
        }
        if (this.getFieldIsHidden(fieldInfo, rowIndex)) {
            return false;
        }

        let isRequired = fieldInfo.IsRequired || fieldInfo.IsEditNotNull;
        if (!isRequired && rowIndex >= 0) {
            let name = fieldInfo.WebView_Name || fieldInfo.Name;
            isRequired = !!this.tryGetValueFromExecRules(name, "WebView_IsRequired", rowIndex);
        }

        return isRequired;
    };

    getFieldIsHidden (fieldInfo, rowIndex) {
        if (!fieldInfo) {
            return false;
        }
        if (!this.rawData.ResultFieldInfo) {
            return false;
        }

        let isHidden = fieldInfo.IsHidden;
        if(!isHidden && rowIndex >= 0) {
            let name = fieldInfo.WebView_Name || fieldInfo.Name;
            isHidden = !!this.tryGetValueFromExecRules(name, "WebView_IsHidden", rowIndex);
        }

        return isHidden;
    };

    getFieldIsReadOnly (fieldInfo, rowIndex) {
        if (!fieldInfo) {
            return false;
        }
        if (!this.rawData.ResultFieldInfo) {
            return false;
        }

        let enabled = fieldInfo.EditMode !== editMode.Disable && !fieldInfo.IsDisabled;
        if (enabled && fieldInfo.EditMode === editMode.IfInsert && !this.isAddRow) {
            enabled = false;
        }

        let isReadOnly = !enabled;

        if (!isReadOnly && rowIndex >= 0) {
            let name = fieldInfo.WebView_Name || fieldInfo.Name;
            isReadOnly = !!this.tryGetValueFromExecRules(name, "WebView_IsReadOnly", rowIndex);
        }

        return isReadOnly;
    };

    checkServerSetFieldRequired(fieldSet) {
        if (!this.isInitialized)
            return false;

        for (const fieldName in fieldSet) {
            if (fieldName in this.rawData.OnUpdateColumns) {
                return true;
            }
        }

        return false;
    }

    checkFormat(rowIndex, row = null) {
        for (let fi of this.rawData.ResultFieldInfo) {
            if (this.getFieldIsHidden(fi, rowIndex) || this.getFieldIsReadOnly(fi, rowIndex))
                continue;

            let underlyingFi = fi;

            if (fi.Action.VirtCol) {
                const row = this.getRow(rowIndex);
                underlyingFi = this.getFieldInfo(row[fi.Name]);
                if (!underlyingFi)
                {
                    continue;
                }
            }

            const val = !!row ? row[underlyingFi.Name] : this.getField(rowIndex, underlyingFi.Name);
            if (val == null || val === "")
                continue;

            if (underlyingFi.FldType === "decimal"
                    && (typeof val === 'string')
                    && (isNaN(parseFloat(val.replace(/\s+/g, "").replace(',', '.')))
                            || !isFinite(val.replace(/\s+/g, "").replace(',', '.'))))
            {
                var t = this.getTitle(fi, rowIndex).replace(/\|/g, ' ');
                return t.replace(/\|/g, ' ') + i18next.t('WrongDigitFormat');
            }
        }
    }

    getVirtualFieldInfo(fieldInfo, row)
    {
        if (!fieldInfo || !row)
            return null;

        return this.getFieldInfo(row[fieldInfo.Name]);
    }

    getVirtualFieldValue(fieldInfo, row)
    {
        if (!fieldInfo || !row)
            return null;

        const realFi = this.getFieldInfo(row[fieldInfo.Name]);
        if (!realFi)
            return null;

        return row[realFi.Name]
    }
}

export default DataItem;
