export default class JSONUtils {
  static mergeObjects(objectArray) {
    if (objectArray.length === 1) {
      return objectArray[0];
    }

    const result = {};

    for (let i = 0; i < objectArray.length; i++) {
      const o = objectArray[i];
      Object.keys(o).forEach(k => {
        if (typeof o[k] === 'object') {
          if (!result.hasOwnProperty(k)) {
            let arr = [o[k]];
            for (let j = i + 1; j < objectArray.length; j++) {
              if (objectArray[j].hasOwnProperty(k) && typeof objectArray[j][k] === 'object') {
                arr.push(objectArray[j][k]);
              }
            }
            result[k] = this.mergeObjects(arr);
          }
        }
        else {
          result[k] = o[k];
        }
      });
    }

    return result;
  }
}