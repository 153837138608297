import React from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import Tabs from "./Tabs";
import "../../style/ComponentsStyle.css";

class TabPanel extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            collapsed: !!props.collapsed,
            maxHeight: null
        };
    }

    collapseButtonClick = (e) => {
        e.preventDefault();
        this.setState(state => ({collapsed: !state.collapsed}));
    };

    onSelected = (tab) => {
        this.setState({collapsed: false});
        this.props.onSelected(tab);
    };

    renderTabControlWithVisibility = (tab) => {
        const tabStyle = tab[this.props.valueField] === this.props.activeTabValue
            ? {display: "block"}
            : {display: "none"};

        return (
            <span id={tab.WSTabId} key={tab[this.props.valueField]} style={tabStyle}>
                {this.props.renderTabContent(tab)}
            </span>
        );
    };

    renderTabsGroup = () => {
        let tabsToRender = this.props.tabs.filter(tab => {
            if (tab[this.props.valueField] === this.props.activeTabValue) {
                return true;
            }
            return !!this.props.isBackgroundField && !!tab[this.props.isBackgroundField];
        });

        if (tabsToRender.length === 0)
            return null;

        return (
            <>
                {tabsToRender.map(tab => this.renderTabControlWithVisibility(tab))}
            </>
        );
    };

    setContainerRef = (el) => {
        if (el && el.clientHeight) {
            this.setState({maxHeight: el.clientHeight - 20});
        }
    }

    render() {
        const collapsed = this.state.collapsed;
        const containerClass = "card tabPanel-contentContainer collapse" + (collapsed ? "" : " show");

        return (
            <div className={`position-relative ${this.props.className || ""}`} style={this.state.maxHeight && {maxHeight: this.state.maxHeight + 'px'} || {}} ref={this.setContainerRef}>
                {this.props.allowCollapse &&
                <button type="button" className="btn -icon tabPanel-tabsCollapseButton" style={{height: "25px"}}
                        title={collapsed ? i18next.t('Expand') : i18next.t('Collapse')}
                        aria-expanded={!collapsed}
                        onClick={this.collapseButtonClick}>
                    <i className="zmdi zmdi-chevron-down"/>
                </button>
                }

                <Tabs tabs={this.props.tabs}
                      valueField={this.props.valueField}
                      textField={this.props.textField}
                      activeTabValue={collapsed ? null : this.props.activeTabValue}
                      className="tabPanel-tabsContainer"
                      onSelected={this.onSelected}
                      tabClassName={collapsed ? "tabPanel-collapsed-tab" : ""}
                />

                <div className={containerClass}>
                    <div className="tabPanel-contentContainer-inner p-3">
                        {this.renderTabsGroup()}
                    </div>
                </div>
            </div>
        );
    }
}

TabPanel.propTypes = {
    tabs: PropTypes.array.isRequired,
    activeTabValue: PropTypes.any,
    valueField: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
    isBackgroundField: PropTypes.string,
    onSelected: PropTypes.func.isRequired,
    collapsed: PropTypes.bool,
    className: PropTypes.string,
    renderTabContent: PropTypes.func.isRequired,
    allowCollapse: PropTypes.bool
};

TabPanel.defaultProps = {
    collapsed: false,
    allowCollapse: true
};

export  default TabPanel;