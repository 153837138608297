import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import i18next from "i18next";
import MnComboStandalone from "../Dropdown/MnComboStandalone";
import { errorRaised } from "@mnjsplatform/data/lib/actions/errorActions";
import PopupWrapper from "../PopupWrapper";

class FIAS extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isDataReady: false,
      house: "",
      corpus: "",
      flat: "",
      zipCode: props.row["ZipCode"] ? props.row["ZipCode"] : "",
      comment: props.row["Comment"] ? props.row["Comment"] : "",
      location: ""
    }
    this.KladrCODE = null;
    this.FiasGUID = null;
  }

  componentDidMount() {
    console.log("FIAS mount");
    this.initialize();
  }

  componentWillUnmount() {
    console.log("FIAS unmount");
  }

  initialize = () => {
    this.setState({ isDataReady: false });

    this.FiasGUID = this.props.row["FiasGUID"];
    this.params = {
      Street:
        {
          AOLEVELTYPE: "Street",
          Init: 1,
          Order: 5
        },
      Settlement:
        {
          AOLEVELTYPE: "Settlement",
          Init: 1,
          Order: 4
        },
      Town:
        {
          AOLEVELTYPE: "City",
          Init: 1,
          Order: 3
        },
      Area:
        {
          AOLEVELTYPE: "Area",
          Init: 1,
          Order: 2
        },
      Region:
        {
          AOLEVELTYPE: "Region",
          Init: 0,
          Order: 1
        },
      ZipCode: {
        AOGUID: null,
        HOUSENUM: null
      }
    };
    let kladrLocation = this.props.row["KladrLocation"];
    if (kladrLocation) {
      let elements = kladrLocation.split(',');
      if (elements.length === 10) {
        this.params.ZipCode.HOUSENUM = elements[7];
        this.setState({ house: elements[7], corpus: elements[8], flat: elements[9] });
      }
    }
    let street = {};
    this.props.dispatch(this.props.itemRegion.reset())
      .then(() => this.props.dispatch(this.props.itemArea.reset()))
      .then(() => this.props.dispatch(this.props.itemTown.reset()))
      .then(() => this.props.dispatch(this.props.itemSettlement.reset()))
      .then(() => this.props.dispatch(this.props.itemStreet.reset()))
      .then(() => this.props.dispatch(this.props.itemZipCode.reset()))
      .then(() => {
        if (this.FiasGUID) {
          this.params.Street = Object.assign({}, this.params.Street, { Self: 1, Init: 0, PARENTGUID: this.FiasGUID });
        }
        return this.props.dispatch(this.props.itemStreet.initialize(this.params.Street, {
          withParams: false,
          newItem: true,
          execMap: this.props.execMap
        }))
          .then(() => {
            if (this.FiasGUID) {
              street.SettlementGUID = this.props.itemStreet.getField(0, "SettlementGUID");
              street.CityGUID = this.props.itemStreet.getField(0, "CityGUID");
              street.AreaGUID = this.props.itemStreet.getField(0, "AreaGUID");
              street.RegionGUID = this.props.itemStreet.getField(0, "RegionGUID");

              this.params.Street.Self = 0;
              this.params.Street.PARENTGUID = street.SettlementGUID || street.CityGUID || street.AreaGUID || street.RegionGUID;
              let key = this.props.itemStreet.getRowKeyByIndex(0);
              key.AOGUID = this.FiasGUID;
              street.RowIndex = this.props.itemStreet.getRowIndex(key);
              street.SettlementGUID = this.props.itemStreet.getField(street.RowIndex, "SettlementGUID");
              street.CityGUID = this.props.itemStreet.getField(street.RowIndex, "CityGUID");
              street.AreaGUID = this.props.itemStreet.getField(street.RowIndex, "AreaGUID");
              street.RegionGUID = this.props.itemStreet.getField(street.RowIndex, "RegionGUID");

              return this.props.dispatch(this.props.itemStreet.goto(street.RowIndex));
            }
          })
      })
      .then(() => {
        if (this.FiasGUID) {
          this.params.Settlement.Init = 0;
          this.params.Settlement.PARENTGUID = street.CityGUID || street.AreaGUID || street.RegionGUID;
        }
        return this.props.dispatch(this.props.itemSettlement.initialize(this.params.Settlement, {
          withParams: false,
          newItem: true,
          execMap: this.props.execMap
        }))
          .then(() => {
            if (this.FiasGUID) {
              let key = this.props.itemSettlement.getRowKeyByIndex(0);
              key.AOGUID = street.SettlementGUID || street.CityGUID || street.AreaGUID || street.RegionGUID;
              return this.props.dispatch(this.props.itemSettlement.goto(this.props.itemSettlement.getRowIndex(key)));
            }
          })
      })
      .then(() => {
        if (this.FiasGUID) {
          this.params.Town.Init = 0;
          this.params.Town.PARENTGUID = street.AreaGUID || street.RegionGUID;
        }
        return this.props.dispatch(this.props.itemTown.initialize(this.params.Town, {
          withParams: false,
          newItem: true,
          execMap: this.props.execMap
        }))
          .then(() => {
            if (this.FiasGUID) {
              let key = this.props.itemTown.getRowKeyByIndex(0);
              key.AOGUID = street.CityGUID || street.AreaGUID || street.RegionGUID;
              return this.props.dispatch(this.props.itemTown.goto(this.props.itemTown.getRowIndex(key)));
            }
          })
      })
      .then(() => {
        if (this.FiasGUID) {
          this.params.Area.Init = 0;
          this.params.Area.PARENTGUID = street.RegionGUID;
        }
        return this.props.dispatch(this.props.itemArea.initialize(this.params.Area, {
          withParams: false,
          newItem: true,
          execMap: this.props.execMap
        }))
          .then(() => {
            if (this.FiasGUID) {
              let key = this.props.itemArea.getRowKeyByIndex(0);
              key.AOGUID = street.AreaGUID || street.RegionGUID;
              return this.props.dispatch(this.props.itemArea.goto(this.props.itemArea.getRowIndex(key)));
            }
          })
      })
      .then(() => {
        if (this.FiasGUID) {
          this.params.Region.Init = 0;
          this.params.Region.PARENTGUID = null;
        }
        return this.props.dispatch(this.props.itemRegion.initialize(this.params.Region, {
          withParams: false,
          newItem: true,
          execMap: this.props.execMap
        }))
          .then(() => {
            if (this.FiasGUID) {
              let key = this.props.itemRegion.getRowKeyByIndex(0);
              key.AOGUID = street.RegionGUID;
              return this.props.dispatch(this.props.itemRegion.goto(this.props.itemRegion.getRowIndex(key)));
            }
          })
      })
      .then(() => {
        if (street.RowIndex > -1) {
          let streetRowKey = this.props.itemStreet.getRowKeyByIndex(street.RowIndex);
          this.params.ZipCode.AOGUID = streetRowKey.AOGUID;//Y!!!
        }
        return this.state.zipCode
          ? Promise.resolve()
          : this.initZipCode()
      })
      .finally(() => this.setState({ isDataReady: true }))
      .catch(error => this.props.dispatch(errorRaised(error)));
  }

  initZipCode = () => {
    return this.props.dispatch(this.props.itemZipCode.initialize(this.params.ZipCode, {
      withParams: false,
      newItem: true,
      execMap: this.props.execMap
    }))
      .then(() => this.setState({ zipCode: this.props.itemZipCode.getField(0, "POSTALCODE") || "" }))
  }

  gotoRowKey = (dataItem, newGuid, propertyName, currentPropertyName) => {
    if (dataItem.rawData.Rows === 0) {
      console.error('Ошибка позиционирования!');
      return Promise.reject('Ошибка позиционирования!');
    }

    let key = dataItem.getRowKeyByIndex(dataItem.rawData.CurrentRowIndex);
    key.AOGUID = newGuid;
    let rowIndex = dataItem.getRowIndex(key);
    console.warn('propertyName: [' + propertyName + '] rowIndex: [' + rowIndex + '] CurrentRowIndex: [' + dataItem.rawData.CurrentRowIndex + ']');
    rowIndex = rowIndex > -1 ? rowIndex : 0;
    return this.props.dispatch(dataItem.goto(rowIndex))
      .then(() => {
        return propertyName ? this.selectValue(dataItem, rowIndex, propertyName, currentPropertyName) : Promise.resolve();
      })
  }

  selectValue = (dataItem, rowIndex, propertyName, currentPropertyName) => {

    if (rowIndex < 0)
      return;

    currentPropertyName = currentPropertyName || propertyName;

    let promise = Promise.resolve();

    let curOrder = this.params[propertyName].Order;
    let iniOrder = this.params[currentPropertyName].Order;

    switch (propertyName) {
      case "Region": {
        if (iniOrder <= curOrder) {
          let newParentGuid = dataItem.getField(rowIndex, "RegionGUID");
          if (this.params.Area.PARENTGUID !== newParentGuid) {
            this.params.Area.PARENTGUID = newParentGuid;
            this.params.Area.Init = 0;//Y!!!
            promise = promise
              .then(() => this.props.dispatch(this.props.itemArea.refresh(this.params.Area)))
              .then(() => this.gotoRowKey(this.props.itemArea, this.params.Area.PARENTGUID, "Area", currentPropertyName))
          }
        }
        break;
      }
      case "Area": {
        //region Up recursion
        if (iniOrder >= curOrder) {
          let newParentGuid = dataItem.getField(rowIndex, "RegionGUID");
          let regionGuid = this.props.itemRegion.getField(this.props.itemRegion.rawData.CurrentRowIndex, "AOGUID");
          if (regionGuid !== newParentGuid) {
            promise = promise
              .then(() => this.gotoRowKey(this.props.itemRegion, newParentGuid, "Region", currentPropertyName))
          }
        }
        //endregion
        //region Down recursion
        if (iniOrder <= curOrder) {
          promise = promise
            .then(() => {
              this.params.Area.ChildPARENTGUID = dataItem.getField(rowIndex, "AreaGUID") || dataItem.getField(rowIndex, "RegionGUID");
              if (this.params.Town.PARENTGUID !== this.params.Area.ChildPARENTGUID) {
                this.params.Town.PARENTGUID = this.params.Area.ChildPARENTGUID;
                this.params.Town.Init = 1;//Y!!!
                return this.props.dispatch(this.props.itemTown.refresh(this.params.Town))
                  .then(() => this.gotoRowKey(this.props.itemTown, this.params.Town.PARENTGUID, "Town", currentPropertyName))
              }
            })
        }
        //endregion
        break;
      }
      case "Settlement": {
        //region Up recursion
        if (iniOrder >= curOrder) {
          let newParentGuid = dataItem.getField(rowIndex, "CityGUID") || dataItem.getField(rowIndex, "AreaGUID") || dataItem.getField(rowIndex, "RegionGUID");
          let townGuid = this.props.itemTown.getField(this.props.itemTown.rawData.CurrentRowIndex, "AOGUID");
          if (townGuid !== newParentGuid) {
            if (this.params.Town.PARENTGUID !== (dataItem.getField(rowIndex, "AreaGUID") || dataItem.getField(rowIndex, "RegionGUID"))) {
              this.params.Town.PARENTGUID = dataItem.getField(rowIndex, "AreaGUID") || dataItem.getField(rowIndex, "RegionGUID");
              this.params.Town.Init = 0;//Y!!!
              promise = promise
                .then(() => this.props.dispatch(this.props.itemTown.refresh(this.params.Town)))
                .then(() => this.gotoRowKey(this.props.itemTown, newParentGuid, "Town", currentPropertyName))
            }
          }
        }
        //endregion
        //region Down recursion
        if (iniOrder <= curOrder) {
          this.params.Settlement.ChildPARENTGUID = dataItem.getField(rowIndex, "SettlementGUID") || dataItem.getField(rowIndex, "CityGUID") || dataItem.getField(rowIndex, "AreaGUID") || dataItem.getField(rowIndex, "RegionGUID");
          if (this.params.Street.PARENTGUID !== this.params.Settlement.ChildPARENTGUID) {
            this.params.Street.PARENTGUID = this.params.Settlement.ChildPARENTGUID;
            if (iniOrder < curOrder)
              this.params.Street.Init = 1;//Y!!!
            promise = promise
              .then(() => this.props.dispatch(this.props.itemStreet.refresh(this.params.Street)))
              .then(() => this.gotoRowKey(this.props.itemStreet, this.params.Street.PARENTGUID, "Street", currentPropertyName))
          }
        }
        //endregion
        break;
      }
      case "Town": {
        //region Up recursion
        if (iniOrder >= curOrder) {
          let newParentGuid = dataItem.getField(rowIndex, "AreaGUID") || dataItem.getField(rowIndex, "RegionGUID");
          let areaGuid = this.props.itemArea.getField(this.props.itemArea.rawData.CurrentRowIndex, "AOGUID");
          if (areaGuid !== newParentGuid) {
            if (this.params.Area.PARENTGUID !== dataItem.getField(rowIndex, "RegionGUID") || this.params.Area.Init === 1) {
              this.params.Area.PARENTGUID = dataItem.getField(rowIndex, "RegionGUID");
              this.params.Area.Init = 0;//Y!!!
              promise = promise
                .then(() => this.props.dispatch(this.props.itemArea.refresh(this.params.Area)))
            }
            promise = promise
              .then(() => this.gotoRowKey(this.props.itemArea, newParentGuid, "Area", currentPropertyName))
          }
        }
        //endregion
        //region Down recursion
        if (iniOrder <= curOrder) {
          this.params.Town.ChildPARENTGUID = dataItem.getField(rowIndex, "CityGUID") || dataItem.getField(rowIndex, "AreaGUID") || dataItem.getField(rowIndex, "RegionGUID");
          if (this.params.Settlement.PARENTGUID !== this.params.Town.ChildPARENTGUID) {
            this.params.Settlement.PARENTGUID = this.params.Town.ChildPARENTGUID;
            if (iniOrder < curOrder)
              this.params.Settlement.Init = 1;//Y!!!
            promise = promise
              .then(() => this.props.dispatch(this.props.itemSettlement.refresh(this.params.Settlement)))
              .then(() => this.gotoRowKey(this.props.itemSettlement, this.params.Settlement.PARENTGUID, "Settlement", currentPropertyName))
          }
        }
        //endregion
        break;
      }
      case "Street": {
        //region Up recursion
        if (iniOrder >= curOrder) {
          let newParentGuid = dataItem.getField(rowIndex, "CityGUID") || dataItem.getField(rowIndex, "AreaGUID") || dataItem.getField(rowIndex, "RegionGUID");
          let settlementGuid = this.props.itemSettlement.getField(this.props.itemSettlement.rawData.CurrentRowIndex, "AOGUID");
          if (settlementGuid !== (dataItem.getField(rowIndex, "SettlementGUID") || newParentGuid)) {
            if (this.params.Settlement.PARENTGUID !== newParentGuid || this.params.Settlement.Init === 1) {
              this.params.Settlement.PARENTGUID = newParentGuid;
              this.params.Settlement.Init = 0;//Y!!!
              promise = promise
                .then(() => this.props.dispatch(this.props.itemSettlement.refresh(this.params.Settlement)))
            }
            promise = promise
              .then(() => this.gotoRowKey(this.props.itemSettlement, dataItem.getField(rowIndex, "SettlementGUID") || newParentGuid, "Settlement", currentPropertyName))
          }
        }
        promise = promise
          .then(() => {
            this.KladrCODE = (dataItem.getField(rowIndex, "PLAINCODE") + "00000000000000000").substring(0, 17);
            this.FiasGUID = dataItem.getField(rowIndex, "AOGUID");
            this.params.ZipCode.AOGUID = dataItem.getField(rowIndex, "AOGUID");
            return this.initZipCode()
          })
        //endregion
        break;
      }
    }
    return promise;
  }

  onValueSelected = (dataItem, rowIndex, propertyName) => {
    this.setState({ isDataReady: false });

    return this.selectValue(dataItem, rowIndex, propertyName)
      .catch((error) => this.props.dispatch(errorRaised(error)))
      .finally(() => this.setState({ isDataReady: true }))
  }

  onChangeZipCode = (e) => {
    this.setState({ zipCode: e.target.value })
  }

  onChangeHouse = (e) => {
    this.params.ZipCode.HOUSENUM = e.target.value;
    this.initZipCode()
      .then(() => this.setState({ house: this.params.ZipCode.HOUSENUM }))
  }

  onChangeCorpus = (e) => {
    this.setState({ corpus: e.target.value })
  }

  onChangeFlat = (e) => {
    this.setState({ flat: e.target.value })
  }

  onChangeComment = (e) => {
    this.setState({ comment: e.target.value })
  }

/*
  onChangeLocation = (e) => {
    this.setState({ location: e.target.value })
  }
*/

  initCombo = (dataItem, propertyName) => {
    if (this.params[propertyName].Init !== 0) {
      this.params[propertyName].Init = 0;
      this.props.dispatch(dataItem.refresh(this.params[propertyName]));
    }
  }

  getFullAddress = () => {
    let locationArray = [];
    let kladrLocationArray = ["643", ","];
    if (this.state.zipCode) {
      locationArray.push(this.state.zipCode);
      kladrLocationArray.push(this.state.zipCode);
    }
    kladrLocationArray.push(",");

    let value = this.props.itemRegion.getField(this.props.itemRegion.rawData.CurrentRowIndex, "PLAINCODE");
    if (value) {
      kladrLocationArray.push(value.substring(0, 2));
    }
    kladrLocationArray.push(",");

    value = this.props.itemRegion.getField(this.props.itemRegion.rawData.CurrentRowIndex, "Name");
    if (value) {
      locationArray.push(value);
    }

    value = this.props.itemArea.getField(this.props.itemArea.rawData.CurrentRowIndex, "Name");
    if (value) {
      locationArray.push(value);
      kladrLocationArray.push(value);
    }
    kladrLocationArray.push(",");

    value = this.props.itemTown.getField(this.props.itemTown.rawData.CurrentRowIndex, "Name");
    if (value) {
      locationArray.push(value);
      kladrLocationArray.push(value);
    }
    kladrLocationArray.push(",");

    value = this.props.itemSettlement.getField(this.props.itemSettlement.rawData.CurrentRowIndex, "Name");
    if (value) {
      locationArray.push(value);
      kladrLocationArray.push(value);
    }
    kladrLocationArray.push(",");

    value = this.props.itemStreet.getField(this.props.itemStreet.rawData.CurrentRowIndex, "Name");
    if (value) {
      locationArray.push(value);
      kladrLocationArray.push(value);
    }
    kladrLocationArray.push(",");

    if (this.state.house) {
      locationArray.push(" д." + this.state.house);
      kladrLocationArray.push(this.state.house);
    }
    kladrLocationArray.push(",");

    if (this.state.corpus) {
      locationArray.push("корп." + this.state.corpus);
      kladrLocationArray.push(this.state.corpus);
    }
    kladrLocationArray.push(",");

    if (this.state.flat) {
      locationArray.push("кв. " + this.state.flat);
      kladrLocationArray.push(this.state.flat);
    }

    locationArray.push(this.state.comment);

    let location = locationArray.join(', ');
    let kladrLocation = kladrLocationArray.join('');

    let changedFields = {
      KladrCODE: this.KladrCODE,
      Location: location,
      ZipCode: this.state.zipCode,
      KladrLocation: kladrLocation,
      Comment: this.state.comment,
      FiasGUID: this.FiasGUID
    };

    return changedFields;
  }

  onConfirm = (dismissOnly = false) => {
    if (dismissOnly) {
      this.props.onClose(null, true);
      return;
    }

    let result = this.getFullAddress();
/*
    if(this.state.location) {
      result.Location = this.state.location;
    }
    if(this.state.kladrLocation) {
      result.KladrLocation = this.state.kladrLocation;
    }
*/
    this.props.onClose(result, dismissOnly);
  }

  createFIAS = () => {
    let result = this.getFullAddress();
    this.setState({ location: result.Location/*, kladrLocation: result.KladrLocation*/ })
  }

  renderButtons = () => {
    return (
      <div className="modal-footer text-right">
        <button type="button" className="btn btn-regular -wide" data-dismiss="modal"
                onClick={this.createFIAS}
                disabled={!this.state.isDataReady}>
          {i18next.t('FIASLocationCreate')}
        </button>
        <button type="button" className="btn btn-regular -wide" data-dismiss="modal"
                onClick={() => this.onConfirm(false)}
                disabled={!this.state.isDataReady}>
          {i18next.t('Save')}
        </button>
        <button type="button" className="btn btn-regular -wide  -default" data-dismiss="modal"
                disabled={!this.state.isDataReady}
                onClick={() => this.onConfirm(true)}>
          {i18next.t('Close')}
        </button>
      </div>)
  }

  render() {
    return (
        <PopupWrapper popupVisible={true} title={i18next.t('TitleFIAS')} onClose={() => this.onConfirm(true)}
                      renderButtons={this.renderButtons}>
            <div className="col-md-9" style={{ padding: "10px" }}>
              <div className="row">
                <div className="col-md-3 field-title">
                  <label>{i18next.t('Region')}:</label>
                </div>
                <div className="col-md-6">
                  {
                    this.state.isDataReady
                      ? <MnComboStandalone ownItem={this.props.itemRegion} valueFieldName="Name" withSearch={true}
                                           onSelected={(rowIndex) => this.onValueSelected(this.props.itemRegion, rowIndex, "Region")}
                      />
                      : i18next.t('LoadingLabel')
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 field-title">
                  <label>{i18next.t('Region')}:</label>
                </div>
                <div className="col-md-6">
                  {
                    this.state.isDataReady
                      ? <MnComboStandalone ownItem={this.props.itemArea} valueFieldName="Name" withSearch={true}
                                           onSelected={(rowIndex) => this.onValueSelected(this.props.itemArea, rowIndex, "Area")}
                                           onBeforeOpen={() => this.initCombo(this.props.itemArea, "Area")}
                      />
                      : i18next.t('LoadingLabel')
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 field-title">
                  <label>{i18next.t('City')}:</label>
                </div>
                <div className="col-md-6">
                  {
                    this.state.isDataReady
                      ? <MnComboStandalone ownItem={this.props.itemTown} valueFieldName="Name" withSearch={true}
                                           onSelected={(rowIndex) => this.onValueSelected(this.props.itemTown, rowIndex, "Town")}
                                           onBeforeOpen={() => this.initCombo(this.props.itemTown, "Town")}
                      />
                      : i18next.t('LoadingLabel')
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 field-title">
                  <label>{i18next.t('Settlement')}:</label>
                </div>
                <div className="col-md-6">
                  {
                    this.state.isDataReady
                      ? <MnComboStandalone ownItem={this.props.itemSettlement} valueFieldName="Name" withSearch={true}
                                           onSelected={(rowIndex) => this.onValueSelected(this.props.itemSettlement, rowIndex, "Settlement")}
                                           onBeforeOpen={() => this.initCombo(this.props.itemSettlement, "Settlement")}
                      />
                      : i18next.t('LoadingLabel')
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 field-title">
                  <label>{i18next.t('Street')}:</label>
                </div>
                <div className="col-md-6">
                  {
                    this.state.isDataReady
                      ? <MnComboStandalone ownItem={this.props.itemStreet} valueFieldName="Name" withSearch={true}
                                           onSelected={(rowIndex) => this.onValueSelected(this.props.itemStreet, rowIndex, "Street")}
                                           onBeforeOpen={() => this.initCombo(this.props.itemStreet, "Street")}
                      />
                      : i18next.t('LoadingLabel')
                  }
                </div>
              </div>
              <div className="row" style={{ padding: "5px" }}>
                <div className="col-md-2 field-title">
                  <label>{i18next.t('House')}:</label>
                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" onChange={this.onChangeHouse}
                         value={this.state.house}
                         disabled={!this.state.isDataReady}/>
                </div>
                <div className="col-md-2 field-title">
                  <label>{i18next.t('Corpus')}:</label>
                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" onChange={this.onChangeCorpus}
                         value={this.state.corpus}
                         disabled={!this.state.isDataReady}/>
                </div>
                <div className="col-md-2 field-title">
                  <label>{i18next.t('Flat')}:</label>
                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" onChange={this.onChangeFlat} value={this.state.flat}
                         disabled={!this.state.isDataReady}/>
                </div>
                <div className="col-md-2 field-title">
                  <label>{i18next.t('ZipCode')}:</label>
                </div>
                <div className="col-md-2">
                  <input type="text" className="form-control" value={this.state.zipCode}
                         onChange={this.onChangeZipCode} disabled={!this.state.isDataReady}/>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 field-title">
                  <label>{i18next.t('FIASComment')}:</label>
                </div>
                <div className="col-md-6">
                  <input type="text" className="form-control" value={this.state.comment}
                         onChange={this.onChangeComment} disabled={!this.state.isDataReady}/>
                </div>
              </div>
              <div className="row">
{/*
                <div className="col-md-2 field-title">
                  <label>{i18next.t('FIASLocation')}:</label>
                </div>
*/}
                <div>&nbsp;</div>
                <div className="col-md-12">
                  <input type="text" className="form-control alert-info" value={this.state.location} readOnly={true}
                         disabled={!this.state.isDataReady}/>
                </div>
              </div>
            </div>
        </PopupWrapper>
    )
  }
}

FIAS.propTypes = {
    dispatch: PropTypes.func.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    row: PropTypes.object,
    itemRegion: PropTypes.object,
    itemArea: PropTypes.object,
    itemTown: PropTypes.object,
    itemSettlement: PropTypes.object,
    itemStreet: PropTypes.object,
    itemZipCode: PropTypes.object,
    execMap: PropTypes.string
};

FIAS.defaultProps = {};

function mapStateToProps(state, ownProps) {
  return {
    itemRegion: state.fiasState.Region,
    itemArea: state.fiasState.Area,
    itemTown: state.fiasState.Town,
    itemSettlement: state.fiasState.Settlement,
    itemStreet: state.fiasState.Street,
    itemZipCode: state.fiasState.ZipCode
  }
}

export default connect(mapStateToProps)(FIAS);