import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import UrProvider from '@mnjsplatform/data/lib/api/UrProvider';
import isEqual from 'lodash/isEqual';
import MnComboBase from "./MnComboBase";
import i18next from "i18next";
import {GlobalDebugMode} from "@mnjsplatform/data/lib";
import {comboSelected, getSelectedIndex} from "@mnjsplatform/data/lib/actions/comboActions";
import formatData from '@mnjsplatform/data/lib/utils/formatData';
import { errorRaised } from "@mnjsplatform/data/lib/actions/errorActions";
import ItemInjector from "../ItemInjector";

class MnComboInlineInternal extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.onToggle = this.onToggle.bind(this);
        this.onResetClick = this.onResetClick.bind(this);

        this.state = {
            selectedIndex: -1
        }
    }

    isParentReady() {
        return this.props.parentDataItem.isInitialized;
    }

    isOwnReady() {
        return this.props.ownItem && this.props.ownItem.isInitialized;
    }

    getTitle() {
        if (!this.isParentReady())
            return i18next.t('GridViewEmptyLabel');

        let val = formatData(this.props.fieldInfo, this.props.parentDataItem.rawData.DataTable[this.props.rowIndex][this.props.fieldInfo.Name]);

        if (!val && !this.props.readOnly)
            return i18next.t('PlsSelectLabel');

        return val;
    }

    async onToggle() {

        const urProcessed = this.props.parentDataItem.rawData.ColumnUpdateRules[this.props.fieldInfo.Name];
        const urProvider = new UrProvider(urProcessed);

        const execParams = urProvider.getParamsForExecRules(this.props.parentDataItem, this.props.rowIndex);

        const needRefresh = this.props.parentDataItem.rowsCount() > 1 || !this.execParamsPrev || !isEqual(this.execParamsPrev, execParams);

        if (needRefresh)
            this.execParamsPrev = execParams;

        if (!this.isOwnReady() || needRefresh) {
            try {
                await this.props.dispatch(this.props.ownItem.execute(execParams, {execMap: this.props.parentDataItem.rawData.ExecMap}));

                if(!this.props.ownItem.isError) {
                    const data = await this.props.dispatch(getSelectedIndex(this.props.parentDataItem, this.props.ownItem, this.props.rowIndex, this.props.fieldInfo.Name));
                    this.setState({ selectedIndex: data.selectedIndex })
                }
            }
            catch (e) {
                this.props.dispatch(errorRaised(e));
            }

        }
    }

    onSelect(selectedIndex) {

        this.props.dispatch(comboSelected(this.props.parentDataItem,
                this.props.ownItem,
                this.props.rowIndex,
                selectedIndex,
                this.props.fieldInfo.Name, false))
            .then(data => this.props.dispatch(this.props.parentDataItem.setFields(this.props.rowIndex, data.changedFields)))
            .then(() => {
                if (this.props.onSelected) {
                    this.props.onSelected(this.props.rowIndex);
                }
            });
    }

    onResetClick() {

        if (!this.isOwnReady()) {
            const urProcessed = this.props.parentDataItem.rawData.ColumnUpdateRules[this.props.fieldInfo.Name];
            const ur = new UrProvider(urProcessed);
            const execParams = ur.getParamsForExecRules(this.props.parentDataItem, this.props.rowIndex);
            
            this.props.dispatch(this.props.ownItem.initialize(execParams, {execMap:this.props.parentDataItem.rawData.ExecMap}))
            .then(v => this.onSelect(-1));
        }
        else
            this.onSelect(-1);
    }

    render() {

        const isRequired = !this.props.allowReset || this.props.parentDataItem.getFieldIsRequired(this.props.fieldInfo, this.props.rowIndex);
        const self = this;

        return (<React.Fragment>
                {GlobalDebugMode && this.props.ownItem && this.props.ownItem.methodName}
                <MnComboBase allowReset={!isRequired} onAfterOpen={this.onToggle} onReset={this.onResetClick}
                             onSelected={(index) => this.onSelect(index)} readOnly={this.props.readOnly}
                             renderRows={(idx, h) => self.getComboRows(idx, h)} value={this.getTitle()}
                             selectedIndex={self.state.selectedIndex} isDataReady={this.isOwnReady() && this.isParentReady()}
                /></React.Fragment>
        )
    }

    getComboRows(currentIndex, selectHandle) {
        let self = this;

        let line = function (row) {

            let values = [];

            for (let fieldInfo of self.props.ownItem.rawData.ResultFieldInfo) {
                if (self.props.ownItem.getFieldIsHidden(fieldInfo))
                    continue;

                let name = fieldInfo.Name;
                let val = formatData(fieldInfo, row[name]);
                let width = (fieldInfo.Width ? fieldInfo.Width : 150);

                values.push({name, val, width});
            }

            return values;
        };

        let getRow = function (row) {
            let values = line(row);
            return (
                <div>
                    {values.map((value, index) =>
                        <span key={index} style={{
                            verticalAlign: 'top',
                            display: 'inline-block',
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            width: values.length > 1 ? value.width : "100%"
                        }}>{value.val}</span>)
                    }
                </div>
            );
        };

        const rows = this.props.ownItem.dataTable();

        if (rows.length === 0) {
            return <div style={{paddingLeft: 20, paddingTop: 3, paddingBottom: 3}}>{i18next.t('GridViewEmptyLabel')}</div>;
        }

        return rows.map((row, idx) => {

                const className = (currentIndex === idx) ? "dropdown-item selected" : "dropdown-item";

                return (<li role="presentation" key={idx}><a className={className} href=""
                                                             onClick={function (e) {
                                                                 e.preventDefault();
                                                                 selectHandle(idx)
                                                             }}><span>{getRow(row)}</span></a></li>)
            }
        );
    }

    searchCurrentIndex() {
        if (!(this.isOwnReady() && this.isParentReady()))
            return -1;

        const urProcessed = this.props.parentDataItem.rawData.ColumnUpdateRules[this.props.fieldInfo.Name];
        const urProvider = new UrProvider(urProcessed);

        const keyStructure = this.props.ownItem.rawData.KeyStructure;
        if (keyStructure.length !== 0) {
            let targetKey = {};
            for (let fieldInfo of this.props.parentDataItem.rawData.ResultFieldInfo) {
                if (keyStructure.includes(fieldInfo.Name))
                    targetKey[fieldInfo.Name] = this.props.parentDataItem.getRow(this.props.rowIndex)[fieldInfo.Name];
            }

            for (let ri of urProvider.updateRules) {
                const dst = ri.strDst;
                const src = ri.strSrc;

                if (keyStructure.includes(src)) {
                    targetKey[src] = this.props.parentDataItem.getRow(this.props.rowIndex)[dst];
                }
            }

            console.log(JSON.stringify(targetKey));
            console.log(JSON.stringify(urProcessed));

            return this.props.ownItem.getRowIndex(targetKey);
        }

        let name = this.props.fieldInfo.Name;
        const ri = urProvider.findUpdateDst(name);
        if (ri) {
            if (ri.strSrc === "*") {
                name = name.substring(ri.strDst.length - 1);
                if (!this.props.ownItem.columnExists(name)) {
                    name = this.props.fieldInfo.Name;
                }
            }
            else
                name = ri.strSrc;
        }

        console.log("NAME:" + JSON.stringify({[name]: this.getTitle()}));

        this.props.ownItem.getRowIndex({[name]: this.getTitle()})
    }
}

MnComboInlineInternal.propTypes = {
    dispatch: PropTypes.func,
    parentDataItem: PropTypes.object.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    rowIndex: PropTypes.number,
    ownItem: PropTypes.object,
    onSelected: PropTypes.func,
    bsSize: PropTypes.string,
    allowReset: PropTypes.bool,
    rootPath: PropTypes.string,
    readOnly: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    return {
        ownItem: ownProps.getItemState(state)
    };
};

const ConnectedMnComboInline = connect(mapStateToProps)(MnComboInlineInternal);

const MnComboInline2 = (props) => {
    return (
      <ItemInjector dataItem={{
          controller: props.parentDataItem.controllerName,
          methodName: props.fieldInfo.EditorObject + "." + props.fieldInfo.EditorMethod,
          fieldName: props.rootPath
      }}>
          <ConnectedMnComboInline {...props} />
      </ItemInjector>
    );
};

MnComboInline2.propTypes = {
    parentDataItem: PropTypes.object.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    rowIndex: PropTypes.number,
    onSelected: PropTypes.func,
    bsSize: PropTypes.string,
    allowReset: PropTypes.bool,
    rootPath: PropTypes.string,
    readOnly: PropTypes.bool
};

MnComboInline2.defaultProps = {
    rowIndex: 0,
    allowReset: true
};

export default MnComboInline2;

