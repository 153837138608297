import React, { Component } from "react";
import PropTypes from 'prop-types';
import * as tinymce from 'tinymce/tinymce';
import 'tinymce/themes/modern';

import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/table';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/preview';

import './tinymce/ru';
import "tinymce/skins/lightgray/content.min.css";
import "tinymce/skins/lightgray/content.inline.min.css";
import "tinymce/skins/lightgray/content.mobile.min.css";
import "tinymce/skins/lightgray/skin.min.css";
import "tinymce/skins/lightgray/skin.mobile.min.css";

import {Editor} from '@tinymce/tinymce-react';
import { renderHTML } from "@mnjsplatform/data/lib/utils/htmlUtils";
import { connect } from "react-redux";
import debounce from "lodash/debounce";

import i18next from "i18next";
import {getMnHtmlEditorCustomization} from "./Customization/MnHtmlEditorCustomization";

class MnHtmlEditorInner extends Component {
  constructor(props, context) {
    super(props, context);

    this.isValueChanged = false;
  }

  render() {
    if (this.props.readOnly) {
      let value = this.props.value;
      if (this.props.modificationRegExpArray) {
        for (let item of this.props.modificationRegExpArray) {
          value = value.replace(item.regExpValue, item.replaceValue);
        }
      }
      return (renderHTML(value));
    }

    return (<Editor
      initialValue={this.props.value}
      value={this.props.value}
      readOnly={this.props.readOnly}

      onBlur={this.onEndEditField}
      onSaveContent={this.onSaveContent}
      onEditorChange={this.onEditorChange}
      onInit={this.onInitInternal}
      init={
        {
          max_chars: this.props.fieldAttributes && this.props.fieldInfo && this.props.fieldAttributes[this.props.fieldInfo.Name] ? this.props.fieldAttributes[this.props.fieldInfo.Name].max_chars : 4000,
          elementpath: false,
          plugins: 'link table textcolor preview paste',
          toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor',
          language: i18next.language === "en" ? "en_US" : i18next.language,
          branding: false,
          skin: false,
          forced_root_block : 'div',
          //paste_as_text: false,
          selector: 'textarea'
        }
      }
    />)
  }

  onEditorChange = (value) => {
    //console.trace();
    let self = this;
    this.isValueChanged = true;
    if (self.props.onEditorChange) {
      self.props.onEditorChange(value);
    }
    if ((!this.editor.settings.max_chars || this.editor.settings.max_chars <= 0) ||
      this.editor.getContent({ format: 'text' }).length <= this.editor.settings.max_chars) {
      self.props.dataItem && self.props.fieldInfo
        ? self.props.dispatch(self.props.dataItem.setField(self.props.rowIndex, self.props.fieldInfo.Name, value))
        : Promise.resolve()
          .then(() => {
            if (value.length >= self.props.minSymbolsToAutoApply)
              self.onEndEditFieldDebounced()
          })
    }
  }

  onSaveContent = (e) => {
    e.content = e.content.replace(/&#39/g, "&apos");
  }

  onEndEditField = () => {
    //console.trace();
    if (this.props.onEndEditField && this.isValueChanged) {
      this.props.onEndEditField();
      this.isValueChanged = false;
    }
  }

  onEndEditFieldDebounced = debounce(this.onEndEditField, this.props.debounceWait);

  onInitInternal = (e) => {
    let self = this;
    self.editor = e.target;
    
    const charsToText = function(length, max_chars) {
      return length + "/" + max_chars;
    }

    const showNotification = function(message) {
      //editor.notificationManager.close();
      let notifications = self.editor.notificationManager.getNotifications();
      let oldNotifications = notifications.length > 0
        ? notifications.filter(n => n.args.text === message)
        : null;
      let oldNotification = oldNotifications && oldNotifications.length > 0 ? oldNotifications[0] : null;
      if (!oldNotification) {
        self.editor.notificationManager.open({
          text: message,
          type: 'error',
          timeout: 3000
        });
      }
    }
    const onPaste = function(e) {
      let newContent = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
      //console.trace('onPaste: ' + newContent);
      let oldContent = self.editor.getContent({ format: 'text' });
      //console.trace('onPaste: ' + oldContent);
      let length = newContent.length + oldContent.length;
      let isLimit = length >= self.editor.settings.max_chars;
      if (isLimit) {
        console.log('onPaste: prevent!');
        showNotification(i18next.t('HTMLEditor_1') + length + i18next.t('HTMLEditor_2'));
        e.preventDefault();
        e.stopImmediatePropagation();
        e.stopPropagation();
      }
    }
    const onKeyDown = function(e) {
      //console.trace();

      let length = self.editor.getContent({ format: 'text' }).length;
      let isLimit = length >= self.editor.settings.max_chars;
      if (isLimit) {
        if ((e.keyCode > 47 && e.keyCode < 91) || (e.keyCode > 95 && e.keyCode < 112)) {
          console.log('onKeyDown: prevent!');
          e.preventDefault();
          e.stopImmediatePropagation();
          e.stopPropagation();
        }
      }
    }
    const update = function(e) {
      //console.trace();
      let label = self.editor.theme.panel.find('#charcount');
      let length = self.editor.getContent({ format: 'text' }).length;
      label.text(charsToText(length, self.editor.settings.max_chars));
      let isLimit = length >= self.editor.settings.max_chars;
      if (isLimit) {
        if (!label.hasClass('error')) {
          label.addClass('error');
        }
        showNotification(i18next.t('HTMLEditor_3') + length + i18next.t('HTMLEditor_4'));
      }
      else {
        if (label.hasClass('error')) {
          label.removeClass('error');
        }
      }
    }

    if (!self.editor.settings.max_chars || self.editor.settings.max_chars <= 0) return;

    let statusBar = self.editor.theme.panel && self.editor.theme.panel.find('#statusbar')[0];
    const debouncedUpdate = tinymce.util.Delay.debounce(update, 300);

    if (statusBar) {
      tinymce.util.Delay.setEditorTimeout(self.editor, function() {
        let length = self.editor.getContent({ format: 'text' }).length;
        statusBar.insert({
          type: 'label',
          name: 'charcount',
          text: charsToText(length, self.editor.settings.max_chars),
          classes: 'charcount' + (length >= self.editor.settings.max_chars ? ' error' : ''),
          disabled: self.editor.settings.readonly
        }, 0);
        self.editor.on('setcontent beforeaddundo undo redo keyup', debouncedUpdate);
        self.editor.on('keydown', onKeyDown);
        self.editor.on('paste', onPaste);
      }, 100);
    }
  }
}

MnHtmlEditorInner.propTypes = {
  dispatch: PropTypes.func,
  dataItem: PropTypes.object,
  rowIndex: PropTypes.number,
  fieldInfo: PropTypes.object,
  minSymbolsToAutoApply: PropTypes.number,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onEndEditField: PropTypes.func,
  init: PropTypes.object,
  fieldAttributes: PropTypes.object,
  modificationRegExpArray: PropTypes.array
}

MnHtmlEditorInner.defaultProps = {
  minSymbolsToAutoApply: 3,
  debounceWait: 2000
};

class MnHtmlEditor extends React.Component {
  render() {
    const customProps = getMnHtmlEditorCustomization();
    return (
      <MnHtmlEditorInner {...this.props} {...customProps} />
    );
  }
}

export default connect()(MnHtmlEditor);