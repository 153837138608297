/**
 * Created by marks on 01.01.2017.
 */
import * as types from '../actions/errorActions';

export default function errorReducer(state = {errorState: false, errorData: "", newSession: false}, action) {
  if (action.type === types.ERROR_RAISED) {

    if (state.newSession)
      return state;

    return {errorState: true, errorData: action.error, newSession: action.isNewSession};
  }
  else if (action.type === types.ERROR_DISMISSED) {
    return {errorState: false, errorData: "", newSession: false};
  }

  return state;
}
