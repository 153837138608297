import React from "react";
import PropTypes from "prop-types";

const MnCheckbox = ({handler, fieldName, className = "mn-checkbox"}) => {

    const forId = `${fieldName}_${new Date().getTime()}`;

    if (handler.fi(fieldName)) {
        return (
            <label className={className} htmlFor={forId}>
                {handler.ed(fieldName, {forId: forId})}&nbsp;{handler.lbl(fieldName)}
            </label>
        );
    }

    return null;
}

MnCheckbox.propTypes = {
    handler: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    className: PropTypes.string
};

export {MnCheckbox};