import React from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import MultiSelectRows from "../MultiSelect/MultiSelectRows";
import debounce from "lodash/debounce";
import {connect} from "react-redux";

class ClassMultiSelectDropDown extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.multiSelectSearchCallback = debounce(this.multiSelectSearch, 500);
  }

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  multiSelectSearch = async(value, dataItem) => {
    await this.props.dispatch(dataItem.execute({FindVal: value.trim()}, {withParams: false}));
  };

  render() {
    if (!this.props.multiSelectProps.subgridItem) {
      return null;
    }

    return (
      <>
        {!this.props.readOnly &&
        <input ref={this.inputRef}
               type="text"
               className="form-control"
               placeholder={i18next.t("SearchLabel")}
               value={this.props.inputValue}
               onChange={(e) => {
                 const value = e.target.value;
                 this.props.onInputValueChange(value);
                 this.multiSelectSearchCallback(value, this.props.multiSelectProps.subgridItem);
               }}
        />
        }
        <MultiSelectRows parentDataItem={this.props.parentDataItem}
                         subgridDataItem={this.props.multiSelectProps.subgridItem}
                         rowIndex={this.props.rowIndex}
                         fieldInfo={this.props.fieldInfo}
                         readOnly={this.props.readOnly}
                         showSelectAll={false}
                         updateLabel={this.props.updateLabel}
                         fillSubgrid={this.props.multiSelectProps.fill}
        />
      </>
    );
  }
}

ClassMultiSelectDropDown.propTypes = {
  dispatch: PropTypes.func,
  multiSelectProps: PropTypes.object.isRequired,
  parentDataItem: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  fieldInfo: PropTypes.object.isRequired,
  updateLabel: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  inputValue: PropTypes.string,
  onInputValueChange: PropTypes.func.isRequired
};

ClassMultiSelectDropDown.defaultProps = {};

export default connect()(ClassMultiSelectDropDown);