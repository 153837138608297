/**
 * Created by marks on 13.01.2017.
 */
import DataItem from '../api/DataItem';
import * as act from '../actions/dataItemTypes';

export function dataItemOp(state, action) {
    switch (action.type) {
        case act.ITEM_LOAD_DATA_SUCCESS:

            return Object.assign(new DataItem(), state, {
                isInitialized: true,
                rawData: action.rawData,
                inOperation: false,
                isError: false,
                isAddRow: false,
                rowInEditIndex: -1
            });

        case act.ITEM_BEGIN_OPERATION:
            return Object.assign(new DataItem(), state, {
                inOperation: true,
                isError: false
            });
        case act.ITEM_SET_FILTER:
            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    Filter: action.filterText
                }),
                inOperation: false,
                isError: false
            });
        case act.ITEM_END_OPERATION:
            return Object.assign(new DataItem(), state, {
                inOperation: false,
                isError: action.withError,
                fileProgress : {}
            });
        case act.ITEM_BEGIN_EDIT:
            return Object.assign(new DataItem(), state, {
                rowInEditBackup: Object.assign({}, state.rawData.DataTable[action.rowIndex]),
                rowInEditIndex: action.rowIndex
            });

        case act.ITEM_CANCEL_EDIT: {

            const newTable = [
                ...state.rawData.DataTable
            ];

            newTable[state.rowInEditIndex] = Object.assign({}, state.rowInEditBackup);

            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: newTable
                }),
                rowInEditIndex:-1,
                inOperation: false,
                isError: false
            });
        }
        case act.ITEM_SET_FIELD: {

            //rowIndex, fieldName, fieldValue

            if (!state.isInitialized)
                return state;

            let newTable = [
                ...state.rawData.DataTable
            ];

            newTable[action.rowIndex] = Object.assign({}, newTable[action.rowIndex]);
            newTable[action.rowIndex][action.fieldName] = action.fieldValue;

            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: newTable
                })
            });
        }
        case act.ITEM_SET_FIELDS: {

            //rowIndex, fieldName, fieldValue

            if (!state.isInitialized)
                return state;

            let newTable = [
                ...state.rawData.DataTable
            ];

            newTable[action.rowIndex] = Object.assign({}, newTable[action.rowIndex]);
            for (let name in action.fieldSet) {
                if (newTable[action.rowIndex].hasOwnProperty(name))
                    newTable[action.rowIndex][name] = action.fieldSet[name];
            }

            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: newTable
                })
            });
        }
        case act.ITEM_REFRESH_DATA_SUCCESS: {
            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    ResultFieldInfo : action.rawData.ResultFieldInfo,
                    DataTable: action.rawData.DataTable,
                    RowTreeInfo: action.rawData.RowTreeInfo,
                    RowGroupInfo: action.rawData.RowGroupInfo,

                    RowDrawAttributes: action.rawData.RowDrawAttributes,
                    ColumnDrawAttributes: action.rawData.ColumnDrawAttributes,

                    GroupColumns: action.rawData.GroupColumns,
                    Rows: action.rawData.Rows,
                    Filter: action.rawData.Filter,
                    AutoFilteredColumns: action.rawData.AutoFilteredColumns,
                    GroupMenu:action.rawData.GroupMenu,
                    SortColumn:action.rawData.SortColumn,
                    SortIsAsc:action.rawData.SortIsAsc,
                    CurrentRowIndex: action.rawData.CurrentRowIndex
                }),
                inOperation: false,
                isError: false,
                isAddRow: false,
                rowInEditIndex: -1,
                filesToUpload:{},
                fileProgress:{}
            });
        }
        case act.ITEM_DATA_SLICE_SUCCESS: {

            let tableSlice = action.rawData.DataTable;
            let treeSlice = action.rawData.RowTreeInfo;
            let groupSlice = action.rawData.RowGroupInfo;

            let  rowDrawAttributesSlice = action.rawData.RowDrawAttributes;
            let  columnDrawAttributesSlice = action.rawData.ColumnDrawAttributes;

            let start = action.startRow;

            let newTable = [
                ...state.rawData.DataTable
            ];

            for (let i = 0; i < tableSlice.length; i++)
                newTable[i + start] = tableSlice[i];

            let newRowTreeInfo = [
                ...state.rawData.RowTreeInfo
            ];

            for (let i = 0; i < treeSlice.length; i++)
                newRowTreeInfo[i + start] = treeSlice[i];

            let newRowGroupInfo = [
                ...state.rawData.RowGroupInfo
            ];

            for (let i = 0; i < groupSlice.length; i++)
                newRowGroupInfo[i + start] = groupSlice[i];

            let newRowDA = [
                ...state.rawData.RowDrawAttributes
            ];

            for (let i = 0; i < rowDrawAttributesSlice.length; i++)
                newRowDA[i + start] = rowDrawAttributesSlice[i];

            let newColumnDA = [
                ...state.rawData.ColumnDrawAttributes
            ];

            for (let i = 0; i < columnDrawAttributesSlice.length; i++)
                newColumnDA[i + start] = columnDrawAttributesSlice[i];

            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: newTable,
                    RowTreeInfo: newRowTreeInfo,
                    RowGroupInfo: newRowGroupInfo,
                    RowDrawAttributes: newRowDA,
                    ColumnDrawAttributes: newColumnDA,
                }),
                inOperation: false,
                isError: false
            });
        }
        case act.ITEM_RESET: {
            return Object.assign(new DataItem(), state, {
                rawData: {},
                isInitialized: false,
                inOperation: false,
                isError: false,
                rowInEditBackup: {},
                isAddRow: false,
                rowInEditIndex: -1,
                filesToUpload:{},
                fileProgress:{}
            });
        }
        case act.ITEM_TREE_OP_SUCCESS : {

            const isTree = state.rawData.IsTree;
            let tableSlice = action.data.DataTable;
            let start = action.data.RowStart;
            let rowsRemove = action.data.RowsRemove;
            let rowTreeInfo = action.data.RowTreeInfo;
            let rowGroupInfo = action.data.RowGroupInfo;

            let newTable = [
                ...state.rawData.DataTable
            ];

            let newRowTreeInfo = [...state.rawData.RowTreeInfo];
            let newRowGroupInfo = [...state.rawData.RowGroupInfo];

            newTable.splice(start + 1, rowsRemove);
            newTable.splice(start, 1, ...tableSlice);

            if (isTree) {
                newRowTreeInfo.splice(start + 1, rowsRemove);
                newRowTreeInfo.splice(start, 1, ...rowTreeInfo);
            }

            newRowGroupInfo.splice(start + 1, rowsRemove);
            newRowGroupInfo.splice(start, 1, ...rowGroupInfo);

            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: newTable,
                    RowTreeInfo: newRowTreeInfo,
                    RowGroupInfo: newRowGroupInfo,
                    Rows:newTable.length
                }),
                inOperation: false,
                isError: false
            });
        }
        case act.ITEM_TREE_SET_ROW_SUCCESS: {
            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: action.data.DataTable,
                    RowTreeInfo: action.data.RowTreeInfo,
                    RowGroupInfo: action.data.RowGroupInfo,
                    Rows: action.data.DataTable.length,
                    CurrentRowIndex: action.data.CurrentRowIndex
                }),
                inOperation: false,
                isError: false
            });
        }
        case act.ITEM_SAVE:
        case act.ITEM_SET_FIELDS_COMPLETE: {
            const dataTable = action.rawData.DataTable;
            const oldRowIndex = action.rawData.OldRowIndex;
            const newRowIndex = action.rawData.NewRowIndex;
            const newTable = [...state.rawData.DataTable];

            if (dataTable != null && dataTable.length > 0) {
                newTable.splice(oldRowIndex, 1);

                if (newRowIndex < newTable.length) {
                    newTable.splice(newRowIndex, 0, dataTable[0]);
                }
                else {
                    newTable[newRowIndex] = dataTable[0];
                }
            }

            let sourceObj = {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: newTable
                }),
                inOperation: false,
                isError: false,
                filesToUpload:{},
                fileProgress:{}
            };
            if (action.type === act.ITEM_SAVE) {
                sourceObj.rowInEditIndex = -1;
            }

            return Object.assign(new DataItem(), state, sourceObj);
        }
        case act.ITEM_SAVE_ADD: {
            const dataTable = action.rawData.DataTable;
            const newRowIndex = action.rawData.NewRowIndex;
            const initialRowIndex = action.rawData.InitialRowIndex;
            const newTable = [...state.rawData.DataTable];

            if (dataTable !== null && dataTable.length > 0) {

                if (initialRowIndex!==newRowIndex)
                    newTable.splice(initialRowIndex, 1);

                newTable[newRowIndex] = dataTable[0];
            }

            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: newTable
                }),
                inOperation: false,
                isError: false,
                isAddRow : false,
                rowInEditIndex: -1,
                filesToUpload:{},
                fileProgress:{}
            });
        }
        case act.ITEM_BEGIN_ADD_ROW: {
            const dataTable = action.rawData.DataTable;
            const newRowIndex = action.rawData.NewRowIndex;
            const newTable = [...state.rawData.DataTable];
            const totalRows = action.rawData.Rows;

            if (dataTable !== null && dataTable.length > 0) {
                newTable[newRowIndex] = dataTable[0];
            }

            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: newTable,
                    Rows : totalRows
                }),
                inOperation: false,
                isError: false,
                rowInEditIndex: newRowIndex,
                isAddRow : true
            });
        }
        case act.ITEM_CANCEL_ADD: {
            const newTable = [...state.rawData.DataTable];
            const totalRows = action.rawData.Rows;

            newTable.splice(state.rowInEditIndex, 1);

            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: newTable,
                    Rows : totalRows
                }),
                inOperation: false,
                isError: false,
                rowInEditIndex: -1,
                isAddRow : false,
                filesToUpload:{},
                fileProgress:{}
            });
        }
        case act.ITEM_DELETE_ROW: {
            const newTable = [...state.rawData.DataTable];
            const totalRows = action.rawData.Rows;
            const rowIndex = action.rawData.RowIndex;

            newTable.splice(rowIndex, 1);

            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    DataTable: newTable,
                    Rows : totalRows
                }),
                inOperation: false,
                isError: false,
                rowInEditIndex: -1
            });
        }
        case act.ITEM_SET_INDEX : {
            return Object.assign(new DataItem(), state, {
                rawData: Object.assign({}, state.rawData, {
                    CurrentRowIndex: action.rowIndex,
                }),
                inOperation: false,
                isError: false
            });
        }
        case act.ITEM_ADD_FILE : {

            let newFileSet;
            if (action.fileId)
                newFileSet = Object.assign({}, state.filesToUpload,{[action.fileKey]: action.fileId});
            else
            {
                newFileSet = Object.assign({}, state.filesToUpload);
                delete newFileSet[action.fileKey];
            }

            return Object.assign(new DataItem(), state, {filesToUpload: newFileSet});
        }
        case act.ITEM_PROGRESS_FILE:{
            const fileProgress = Object.assign({}, state.fileProgress);
            fileProgress[action.fileKey] = {loaded: action.loaded, total:action.total, uploadState: 0};
            return Object.assign(new DataItem(), state ,{fileProgress: fileProgress});
        }
        case act.ITEM_STORING_FILE: {
            const fileProgress = Object.assign({}, state.fileProgress);
            fileProgress[action.fileKey] = Object.assign({}, state.fileProgress[action.fileKey], {uploadState: 1});
            return Object.assign(new DataItem(), state ,{fileProgress: fileProgress});
        }
        default:
            return state;
    }
}

export function createItemReducer(controllerName, itemName, isPartial = false) {
    return (state, action) => {
        const {name} = action;
        const isInitializationCall = state === undefined;
        if (name !== controllerName + '/' + itemName && !isInitializationCall)
            return state;

        return dataItemOp(isInitializationCall ? new DataItem(controllerName, itemName, false, isPartial) : state, action);
    };
}

export function createItemReducerParams(controllerName, itemName) {
    return (state, action) => {
        const {name} = action;
        const isInitializationCall = state === undefined;
        if (name !== controllerName + '/' + itemName + "_params" && !isInitializationCall)
            return state;

        return dataItemOp(isInitializationCall ? new DataItem(controllerName, itemName, true) : state, action);
    };
}
