import React from 'react';
import PropTypes from 'prop-types';
import 'intersection-observer';//ie11 support

class IntersectionObserverWrapper extends React.Component {
    componentDidMount() {
        this.observer = new IntersectionObserver(this.handleObserverUpdate, this.props.options);

        if (this.props.active) {
            this.startObserving();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.active && !prevProps.active) {
            this.startObserving();
        }

        if (!this.props.active && prevProps.active) {
            this.stopObserving();
        }
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    startObserving() {
        this.observer.observe(this.node);
    }

    stopObserving() {
        this.observer.unobserve(this.node);
    }

    handleObserverUpdate = entries => {
        entries.forEach(entry => {
            const {
                intersectionRect
            } = entry;
            const {
                top,
                left,
                bottom,
                right
            } = intersectionRect;
            if ([ top, bottom, left, right ].some(Boolean)) {
                if (this.props.onShow) {
                    this.props.onShow(entry);
                }
            } else if (this.props.onHide) {
                this.props.onHide(entry);
            }

            this.props.onIntersect(entry);
        });
    };

    render() {
        let props = {
            ...(this.props.containerElementProps || {}),
            ref: node => this.node = node,
            children: this.props.children
        };
        return React.createElement(this.props.containerElementType, props);
    }
}

IntersectionObserverWrapper.propTypes = {
    active: PropTypes.bool,
    onShow: PropTypes.func,
    onHide: PropTypes.func,
    onIntersect: PropTypes.func,
    options: PropTypes.object,
    containerElementType: PropTypes.string,
    containerElementProps: PropTypes.object
};

IntersectionObserverWrapper.defaultProps = {
    active: true,
    onShow: () => {},
    onHide: () => {},
    onIntersect: () => {},
    containerElementType: "div"
};

export default IntersectionObserverWrapper;