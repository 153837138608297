import HttpQueryJq from "./HttpQuery";

class ReportApi {
    static prepareWebReport(reportId, reportKey, params) {
        let hq = new HttpQueryJq();
        return hq.makeApiCallPost("Report/PrepareWebReport", {reportId, reportKey, params});
    }

    static createReport(reportKey) {
        let hq = new HttpQueryJq();
        return hq.makeApiCallPost("Report/CreateReport", {reportKey});
    }

    static getTaskStatus(taskId, lastIndex) {
        let hq = new HttpQueryJq();
        return hq.makeApiCallPost("Report/GetTaskStatus", {taskId, lastIndex});
    }

    static cancelTask(taskId) {
        let hq = new HttpQueryJq();
        return hq.makeApiCallPost("Report/CancelTask", {taskId});
    }

    static getReportParamsItem(reportId, reportKey) {
        let hq = new HttpQueryJq();
        return hq.makeApiCallPost("Report/GetReportParamsItem", {reportId, reportKey});
    }
}

export default ReportApi