import React, { Component } from "react";
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { connect } from "react-redux";
import formatData from "@mnjsplatform/data/lib/utils/formatData";
import moment from "moment";

class MnInlineDateTimeEdit extends Component {
  constructor(props, context) {
    super(props, context);

    let dateTimeValue = this.normalizeInitialValue(props.rawVal || "");

    this.state={
      value: dateTimeValue,
      isValid: this.isValid(dateTimeValue)
    }
  }

  /*componentDidUpdate(prevProps, prevState, snapshot) {

    let dateTimeValue = this.normalizeInitialValue(this.props.rawVal || "");

    this.state.value = dateTimeValue;
    this.state.isValid = this.isValid(dateTimeValue);

  }*/

  static isAcceptableEditFormat = (boilerPicture) => {
    if (null === boilerPicture)
      return false;

    return (
      boilerPicture === "99.99.99" ||
      boilerPicture === "99.99.9999" ||
      boilerPicture === "99.99.9999 99:99" ||
      boilerPicture === "99.99.9999 99:99:99"
    );
  };

  getDateFormatForParse = () => {
    switch (this.props.boilerPicture) {
      case "99.99.99" :
        return "DD.MM.YY";
      case "99.99.9999" :
        return "DD.MM.YYYY";
      case "99.99.9999 99:99" :
        return "DD.MM.YYYY HH:mm";
      case "99.99.9999 99:99:99" :
        return "DD.MM.YYYY HH:mm:ss";
      default :
        return "";
    }
  }

  normalizeInitialValue = (value) => {

    if (!value.includes("T") && typeof value === "string") {
      let result = value.match(/(\d\d).(\d\d).(\d\d\d\d) (\d?\d):(\d\d)(:(\d\d))?/)
      if (result && result.length > 5) {
        value = `${result[3]}-${result[2]}-${result[1]}T${this.to2SignString(result[4])}:${result[5]}:${result[7] || "00"}.000`;
      }
    }

    if (value.includes("T") && this.props.fieldInfo) {
      let fieldInfo = this.props.fieldInfo;
      if (value.match(/\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d(.\d\d\d)?/) && fieldInfo.DataType !== "System.DateTime") {
        fieldInfo = {
          ...fieldInfo,
          DataType: "System.DateTime"
        };
      }
      value = formatData(fieldInfo, value);
    }

    return value;
  }

  isValid = (value) => {
    let date = moment(value, this.getDateFormatForParse(), true);

    if(!date.isValid())
      return false;

    if(date.year() < 1951 || date.year() > 2050)
      return false;

    return true;
  }

  to2SignString = (number) => {
    let str = number.toString();
    if (str.length === 1) {
      str = '0' + str;
    }
    return str;
  };

  onChange = (e) => {
    let value = e.target.value;
    let isValid = this.isValid(value);

    if (isValid) {
      let date = moment(value, this.getDateFormatForParse(), true);
      let dateStr = date.format("YYYY-MM-DDTHH:mm:ss");
      this.props.onChange({target: {value: `${dateStr}`}});
    }

    this.setState({value: e.target.value, isValid: isValid});
  };

  render() {
    if (MnInlineDateTimeEdit.isAcceptableEditFormat(this.props.boilerPicture)) {
      if (this.props.readOnly) {
        return (<span>{this.props.value}</span>);
      }

      let value = this.state.value;
      let showIncorrectDateError = !this.state.isValid && value !== "";

      return (
        <div>
          <InputMask className="form-control" maskChar="0"
                        value={value}
                        onChange={this.onChange}
                        mask={this.props.boilerPicture}
                        alwaysShowMask={false}
              />
          {showIncorrectDateError && <span style={{color: "#C60000", fontSize: "10px"}}>Некорректная дата</span>}
        </div>
      )
    }
    else
      return (<span>{this.props.value}</span>);
  }
}

MnInlineDateTimeEdit.propTypes = {
  dispatch: PropTypes.func,
  value: PropTypes.string,
  rawVal: PropTypes.string,
  readOnly: PropTypes.bool,
  boilerPicture: PropTypes.string,
  fieldInfo: PropTypes.object,
  onChange: PropTypes.func
}

MnInlineDateTimeEdit.defaultProps = {
};

export const isDateTimeEditAcceptableEditFormat = MnInlineDateTimeEdit.isAcceptableEditFormat;
export default connect()(MnInlineDateTimeEdit);