import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import MnDatePicker from "./DatePicker";
import DropDownMenuComboLink from "../DropdownMenu/DropDownMenuComboLink";
import moment from "moment";
import MnDateRangePicker from "../DateRangePicker/MnDateRangePicker";
import ItemInjector from "../ItemInjector";
import UrProvider from "@mnjsplatform/data/lib/api/UrProvider";

class MnComboDatePicker extends Component {

    async componentDidMount() {
        await this.refreshPeriodType();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        await this.refreshPeriodType();
    }

    refreshPeriodType = async() => {
        if(this.props.dataListItem && !this.props.dataListItem.inOperation && !this.props.dataListItem.isInitialized) {
            const urProcessed = this.props.dataItem.rawData.ColumnUpdateRules[this.props.fieldInfo.Name];
            const urProvider = new UrProvider(urProcessed);
            const execParams = urProvider.getParamsForExecRules(this.props.dataItem, this.props.rowIndex);

            await this.props.dispatch(this.props.dataListItem.execute(execParams, {execMap: this.props.dataItem.rawData.ExecMap}));
        }
    }

    isDateString = (str) => {
        return str.match(/(0[1-9]|[12]\d|3[01]).(0[1-9]|1[0-2]).([12]\d{3})/g);
    };

    onSelectConstant = (menuItem) => {
        let param = menuItem.MenuItemId;
        if (menuItem.MenuItemId === DateMenuProvider.const.date) {
            param = moment().format("DD.MM.YYYY");
        }
        else if (menuItem.MenuItemId === DateMenuProvider.const.period) {
            param = moment().format("DD.MM.YYYY");
            param += " - " + param;
        }
        return this.setDate(param);
    };

    onChangeDate = (date) => {
        return this.setDate(date ? moment(date).format("DD.MM.YYYY") : null);
    };

    onChangePeriod = (start, end) => {
        return this.setDate(moment(start).format("DD.MM.YYYY") + " - " + moment(end).format("DD.MM.YYYY"));
    };

    isPeriodListReady = () => {
        return this.props.dataListItem && this.props.dataListItem.isInitialized;
    }

    render() {
        if (this.props.readOnly || !this.isPeriodListReady()) {
            return (<span>{this.props.value}</span>);
        }

        let ddValue = this.props.value;
        let startDate = null;
        let endDate = null;
        if (typeof this.props.value === "string") {
            let datesArr = this.props.value.split("-");
            if (this.isDateString(datesArr[0].trim())) {
                startDate = moment(datesArr[0], "DD.MM.YYYY");
                ddValue = DateMenuProvider.const.date;
                if (datesArr.length > 1 && this.isDateString(datesArr[1].trim())) {
                    endDate = moment(datesArr[1], "DD.MM.YYYY");
                    ddValue = DateMenuProvider.const.period;
                }
            }
        }

        const dateMenuProvider = new DateMenuProvider(ddValue, this.props.dataListItem);
        const comboClassName = dateMenuProvider.isValueDate ? "col-md-6" : "col-md-5";

        let showDateMenu = this.props.dataItem && this.props.dataItem.isInitialized
            && this.props.dataListItem && this.props.dataListItem.isInitialized && this.props.dataListItem.rowsCount() > 1;

        let datePickerClassName = showDateMenu ? "col-md-6" : "col-md-12";
        let dateRangePickerClassName = showDateMenu ? "col-md-7" : "col-md-12";

        return (
            <div className="row">
                {showDateMenu &&
                    <div className={comboClassName}>
                        <DropDownMenuComboLink menuAdapter={dateMenuProvider}
                        style={{display: 'inline-block', cursor: 'pointer'}}
                        onSelected={this.onSelectConstant}
                        />
                        &nbsp;
                        <span className="clear zmdi zmdi-close" style={{ cursor: 'pointer' }} onClick={(e) => {
                            this.setDate(null);
                        }} />
                    </div>
                }
                {dateMenuProvider.isValueDate && <div className={datePickerClassName}>
                    <MnDatePicker
                        value={startDate}
                        readOnly={this.props.readOnly}
                        onChange={this.onChangeDate}
                    /></div>
                }
                {dateMenuProvider.isValuePeriod && <div className={dateRangePickerClassName}>
                    <MnDateRangePicker
                        startDate={startDate}
                        endDate={endDate}
                        readOnly={this.props.readOnly}
                        onChange={this.onChangePeriod}
                        showCalendarIcon={false}
                    /></div>
                }
            </div>
        )
    }

    setDate = async(value = null) => {
        try {
            await this.props.dispatch(this.props.dataItem.setField(this.props.rowIndex, this.props.fieldInfo.Name, value));
            if (this.props.onSelected) {
                await this.props.onSelected(this.props.rowIndex);
            }
            if (this.props.onEndEditField) {
                await this.props.onEndEditField();
            }
        }
        catch (e) {
            console.log(e);
        }
    }
}

MnComboDatePicker.propTypes = {
    dispatch: PropTypes.func,
    value: PropTypes.string,
    readOnly: PropTypes.bool.isRequired,
    dataItem: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    dataListItem: PropTypes.object.isRequired,
    onSelected: PropTypes.func,
    onEndEditField: PropTypes.func
};

MnComboDatePicker.defaultProps = {
};

const injectedItemSuffix = "_ListItem";

const mapStateToProps = (state, ownProps) => {
    if(ownProps["getItemState" + injectedItemSuffix])
        return {
            dataListItem: ownProps["getItemState" + injectedItemSuffix](state),
        };

    return {
        dataListItem: state.listViewItems.EDocCardListView_GetDateOffset
    }
};

export const MnComboDatePickerSimple = connect(mapStateToProps)(MnComboDatePicker);

const MnComboDatePickerEx = (props) => {
    return (
        <ItemInjector dataItem={{
            controller: props.dataItem.controllerName,
            methodName: props.fieldInfo.EnumInfo,
            fieldName: props.dataItem.methodName + "_" + props.fieldInfo.Name
        }}
                      propsNamesSuffix={injectedItemSuffix}>
            <MnComboDatePickerSimple {...props}/>
        </ItemInjector>
    );
};

MnComboDatePickerEx.propTypes = {
    dispatch: PropTypes.func,
    value: PropTypes.string,
    readOnly: PropTypes.bool.isRequired,
    dataItem: PropTypes.object.isRequired,
    rowIndex: PropTypes.number.isRequired,
    fieldInfo: PropTypes.object.isRequired,
    onSelected: PropTypes.func,
    onEndEditField: PropTypes.func
};

MnComboDatePickerEx.defaultProps = {
};

//export const MnComboDatePickerEx = connect(mapStateToProps)(MnComboDatePickerEx);
export default MnComboDatePickerEx;

class DateMenuProvider {
    static get const() {
        return {
            date: "01.01.2020",
            period: "01.01.2000 - 01.01.2050"
        };
    }

    constructor(value, dataItem) {
        this.items = null;
        if (dataItem && dataItem.isInitialized) {
            const rows = dataItem.dataTable();
            if (rows.length > 0) {
                this.items = rows.map((row, idx) => {
                    return {
                        MenuItemId: row.DateOffsetId,
                        MenuText: row.DateOffsetName,
                        IsActive: value === row.DateOffsetId
                    }
                });
                if (!this.items.some(i => i.IsActive)) {
                    this.items[0].IsActive = true;
                }
            }
        }
    }

    get isValueConstant(){
        return this.items && this.items.find(item => item.IsActive && item.MenuItemId !== DateMenuProvider.const.date && item.MenuItemId !== DateMenuProvider.const.period);
    }

    get isValueDate() {
        return this.items && this.items.find(item => item.IsActive && item.MenuItemId === DateMenuProvider.const.date);
    }

    get isValuePeriod() {
        return this.items && this.items.find(item => item.IsActive && item.MenuItemId === DateMenuProvider.const.period);
    }

    getMenuItems() {
        return this.items;
    }

    getVisibleItemsCount() {
        return 1;
    }

    getDropDownText() {
        if(!this.items)
            return null;
        const activeItem = this.items.find(i => i.IsActive);
        if (activeItem) {
            return activeItem.MenuText + "";
        }

        return this.items[0].MenuText;
    }
}