/**
 * Created by marks on 01.01.2017.
 */

import HttpQuery from './HttpQuery';

export const GROUP_OPERATION_ADD = 0;
export const GROUP_OPERATION_REMOVE = 1;
export const GROUP_OPERATION_REMOVE_ALL = 2;

class DataItemApi {
    static loadDataItemBatch(controllerName, requestArray) {
        const hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/DataItemGetBatch`, {requestArray});
    }

    static loadDataItem(controllerName, methodName, options = { withParams: false, fullDataSet: true }, parameters = {}) {
        const hq = new HttpQuery();

        let query = { methodName, parameters };
        query = Object.assign(query, options);

        return hq.makeApiCallPost(`${controllerName}/DataItemGet`, query);
    }

    static applyParams(controllerName, methodName, paramTable, fullDataSet = false, persistParamSet = false, fileSet = {}, withParams = false) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/DataItemApplyParams`, {
            methodName,
            paramTable: paramTable,
            fullDataSet,
            persistParamSet,
            fileSet,
            withParams
        });
    }

    static getParamsAndRefresh(controllerName, methodName, restore = false) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/GetParamsAndRefresh`, {
            methodName, restore
        });
    }

    static dataSlice(controllerName, methodName, startIndex, stopIndex) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/DataSlice`, { methodName: methodName, startIndex, stopIndex });
    }

    static sortRows(controllerName, methodName, columnName, isAsc, isClear, fullDataSet = true) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/Sort`, { methodName, columnName, isAsc, isClear, fullDataSet });
    }

    static updateGroup(controllerName, methodName, menuId, isField) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/UpdateGroup`, { methodName, menuId, source : isField ? "Field" : "FieldItem" });
    }

    static quickFilter(controllerName, methodName, filterText, fieldList, fullDataSet = true) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/QuickFilter`, { methodName, filterText, fieldList, fullDataSet });
    }

    static treeOperation(controllerName, methodName, rowIndex, expand) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/TreeOperation`, { methodName, rowIndex, expand });
    }

    static treeSetCurrentRow(controllerName, methodName, rowKey) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/TreeSetCurrentRow`, { methodName, rowKey });
    }

    static groupOperation(controllerName, methodName, rowIndex, columnName, operationType = GROUP_OPERATION_ADD) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/GroupOperation`, { methodName, rowIndex, columnName, operationType });
    }

    static save(controllerName, methodName, row, oldRowIndex, fileSet, isParamset) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/DataItemSave`, { methodName, row, oldRowIndex, fileSet, isParamset });
    }

    static setFields(controllerName, methodName, row, oldRowIndex) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/DataItemSetFields`, { methodName, row, oldRowIndex});
    }

    static saveAdd(controllerName, methodName, row, fileSet) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/DataItemCommitAdd`, { methodName, row, fileSet });
    }

    static beginAdd(controllerName, methodName) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/DataItemBeginAdd`, { methodName});
    }

    static cancelEdit(controllerName, methodName) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/DataItemCancelEdit`, { methodName});
    }

    static cancelAdd(controllerName, methodName) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/DataItemCancelAdd`, { methodName});
    }

    static deleteRow(controllerName, methodName, rowIndex) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/DataItemDeleteRow`, { methodName, rowIndex });
    }

    static getFieldUniqueValues(controllerName, methodName, fieldName) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/GetFieldUniqueValues`, { methodName, fieldName });
    }

    static applyAutoFilter(controllerName, methodName, fieldName = null, valueList = [], reset = false) {
        let hq = new HttpQuery();
        return hq.makeApiCallPost(`${controllerName}/ApplyAutoFilter`, { methodName, fieldName, valueList, reset });
    }
}

export default DataItemApi;
